import { queryProductDetail } from "@/api/product";
import {
  carrierContextParams,
  CarrierElementType,
  ContentType,
  envContextParams,
  SourceScene,
} from "@/api/scode/scode_params";
import { fetchShareCardInfo } from "@/api/scode/share_card_info";
import { useCommonContext } from "@/utils/context/common";
import { shareCardByH5 } from "@/utils/share/share";
import { ProductDetailModel, QuestionAnswerModel } from "@akc/biz-api";
import { Toast } from "antd-mobile";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTrack } from "./useTrack";

const pageName = "商品问答列表页";
export const useQuestionsAnswers = () => {
  const [search] = useSearchParams();
  //活动商品id
  const activitySpuId = search.get("activitySpuId");
  //档期id
  const activityId = search.get("activityId");
  //选中的skuId
  const skuId = search.get("selectSkuId") || search.get("skuId");
  //商品模型
  const [productDetail, setProductDetail] = useState<ProductDetailModel>();
  //问答列表
  const [qaList, setQaList] = useState<QuestionAnswerModel[]>([]);
  // 加购面板显示
  const [cartModalVisible, setCartModalVisible] = useState<boolean>(false);
  // 购物车数量
  const [cartTotal, setCartTotal] = useState<number>();
  // 获取购物车数量
  const { cartNum, setCartNum } = useCommonContext();
  //是否空态页面
  const [showEmpty, setShowEmpty] = useState<boolean>(false);

  const { trackPageView } = useTrack(pageName);

  const previousPageName = search.get("previous_page_name") ?? search.get("page_name") ?? pageName;

  //获取购物车数量
  const changeCartNum = () => {
    setCartNum().then(num => {
      setCartTotal(num);
    });
  };

  //右上角三个点分享
  const getProductSysTemShareInfo = async (pdModel?: ProductDetailModel) => {
    let params = {
      bizInfo: {
        contentType: ContentType.productAnswer,
        contentId: pdModel?.activitySpuId,
        contentTypeConfig: 1,
        selectSkuId: pdModel?.selectSkuId || "",
        contentDetailId: pdModel?.selectSkuId || "",
      },
      envContext: envContextParams({
        sourceScene: SourceScene.productAnswer,
      }),
      contentContext: {
        contentType: ContentType.productAnswer,
        contentId: pdModel?.activitySpuId,
      },
      carrierContext: carrierContextParams([CarrierElementType.h5Link]),
      withForwarder: 1,
      withPreview: 0,
    };
    let res = await fetchShareCardInfo(params);

    const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = res?.data || {};
    if (!urlShareImg || !urlShareTitle || !urlShareDesc || !urlShareUrl) {
      return;
    }
    let confirmParam = {
      requestId: String(new Date().getTime()),
      scene: (res?.data && res?.data?.scode) || "",
      extraMap: {
        contentId: pdModel?.activitySpuId ?? "",
        contentType: "6",
        activity_spu_code: pdModel?.activitySpuId ?? "",
        sku_id: pdModel?.selectSkuId ?? "",
      },
      hideMenus: pdModel?.skuExtendInfoVO?.isOTC ? ["menuItem:share:timeline"] : [],
    };
    shareCardByH5(
      {
        title: urlShareTitle,
        desc: urlShareDesc,
        link: urlShareUrl,
        imgUrl: urlShareImg,
        scode,
      },
      confirmParam,
    );
  };

  useEffect(() => {
    document.title = "全部问答";

    trackPageView({ previousPageName: previousPageName, activitySpuCode: activitySpuId });
    // 请求商详
    const fetchProductDetail = (params?: { selectSkuId?: string | null }) => {
      queryProductDetail({
        activityId: activityId,
        activitySpuId: activitySpuId,
        selectSkuId: skuId,
        pageId: "H5_ProductDetail",
      })
        .then(res => {
          setProductDetail(res);
          setQaList(res?.productAnswerVO?.productDetailAnswerList ?? []);
          setShowEmpty(res?.productAnswerVO?.productDetailAnswerList?.length === 0);
          getProductSysTemShareInfo(res);
        })
        .catch(e => {
          setQaList([]);
          setShowEmpty(true);
          if (e?.message) {
            Toast.show(e?.message);
          }
        });
    };
    fetchProductDetail();
  }, []);

  return {
    productDetail,
    qaList,
    cartModalVisible,
    cartTotal,
    cartNum,
    changeCartNum,
    setCartModalVisible,
    getProductSysTemShareInfo,
    showEmpty,
    pageName,
    previousPageName,
  };
};
