import { queryConvertDailyPrize, type OrderOptions } from "@/api/dailyReward";
import { queryLogisticsList } from "@/api/order";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./rewardOrder.module.less";

interface LogisticOptions {
  content: string
  time: string
  traceStatus: string
  traceStatusCode: number
}

const formatAmount = (value, num = 2) => {
  value = Number(value) || 0;
  value = `${parseFloat((`${value}`).replace(/[^\d.-]/g, "")).toFixed(num)}`; // 将金额转成比如 123.45的字符串
  const valueArr = value.split(".")[0].split("").reverse(); // 将字符串的数变成数组
  const valueFloat = value.split(".")[1]; // 取到 小数点后的值
  let valueString = "";
  for (let i = 0; i < valueArr.length; i += 1) {
    valueString += valueArr[i] + ((i + 1) % 3 === 0 && (i + 1) !== valueArr.length ? "," : ""); // 循环 取数值并在每三位加个','
  }
  const money = `${valueString.split("").reverse().join("")}.${valueFloat}`; // 拼接上小数位
  return money;
};

const RewardOrder: React.FC = () => {
  const [search] = useSearchParams();
  const { navigate2Logistics } = useNavigation();
  const [order, setOrder] = useState<OrderOptions>({} as OrderOptions);
  const [logisticInfo, setLogisticInfo] = useState<LogisticOptions>({} as LogisticOptions);
  const [isFinish, setIsFinish] = useState(false);
  const [item, setItem] = useState<any>({});
  // 获取订单信息
  const getOrderDetail = async () => {
    const res = await queryConvertDailyPrize({ orderNo: search.get("orderNo") as string });
    if (res) {
      setOrder(res);
      setItem(res.productList[0]);
    }
  };
  // 获取物流信息
  const getLogisticsInfo = async () => {
    const { data } = await queryLogisticsList({
      orderNo: search.get("orderNo"),
      type: "2",
    });

    const { deliveryNodes } = data[0] || {};

    if (deliveryNodes && deliveryNodes.length) {
      const result = deliveryNodes[0];

      setIsFinish([4, 5].includes(result.traceStatusCode));
      setLogisticInfo(result);
    }
  };
  // 调整到物流详情页面
  const toLogisticsPage = () => {
    console.log("toLogisticsPage");
    const query = {
      orderNo: order.orderNo,
      roleType: 2
    };
    navigate2Logistics(query);
  };
  // 复制
  const onCopy = () => {
    const { recipientName, mobile, address } = order?.receiver || {};
    const str = `${recipientName || ""} ${mobile || ""} ${address || ""}`.trim();

    copy(str);
    Toast.show({
      content: "复制成功",
      position: "center",
    });
  };
  useEffect(() => {
    getOrderDetail();
    getLogisticsInfo();
    document.title = "订单详情";
  }, []);
  return (
    <div id="page" className={styles.container}>
      <div className={styles.header}>
        {isFinish ? "奖品已送达" : "奖品派送中"}
        {(platform.isH5 || platform.isWxH5) && (
          <div className={styles.back} onClick={() => history.back()}>
            <img src='https://akim-oss.aikucun.com/11d024154013c0ca47dfaeca33ecdeb6a5b9fcb6_1733812732033_95.png' alt="返回" />
          </div>)
        }

      </div>
      <div className={styles.content}>
        {/* 物流信息、地址信息 */}
        <div className={styles.transport}>
          {
            logisticInfo.content && (
              <>
                <div className={styles.transportStatus}>
                  <div className={styles.transportIcon}></div>
                  <div className={styles.transportInfo} onClick={toLogisticsPage}>
                    <p className={styles.transportTitle}>{logisticInfo?.traceStatus}</p>
                    <p className={styles.transportDesc}>{logisticInfo?.content}</p>
                  </div>
                  <img className={styles.transportRight} src="https://akim-oss.aikucun.com/0ad0150707b5377c9f9034bf15fa52f5ecf7e2aa_1733812732280_91.png" />
                </div>
                <div className={styles.transportLine}></div>
              </>
            )
          }
          <div className={styles.address}>
            <div className={styles.addressIcon}></div>
            <div className={styles.addressContent}>
              <div className={styles.addressInfo}>
                <span className={styles.addressName}>{order?.receiver?.recipientName}</span>
                <span className={styles.addressPhone}>{order?.receiver?.mobile}</span>
                <div className={styles.addressCopy} onClick={onCopy}>复制</div>
              </div>
              <div className={styles.addressDetail}>{order?.receiver?.address}</div>
            </div>
          </div>
        </div>
        {/* 店铺信息 */}
        <div className={styles.storeInfo}>
          <div className={styles.storeHeader}>
            <img src={order.brandUrl} className={styles.storeLogo} />
            <div className={styles.storeName}>{order.brandName}</div>
            {/* <div className={styles.storeArrow}></div> */}
          </div>
          <div className={styles.product}>
            <div className={styles.productItem}>
              <img src={item.picUrl} alt="" className={styles.productImage} />
              <div className={styles.productInfo}>
                <p className={styles.productTitle}>{item.productTitle}</p>
                <div className={styles.productDetail}>
                  <span>{item.size}</span>
                  <span>x{item.integrateQuantity ?? 1}</span>
                </div>
                <div className={styles.productPrice}>
                  <div>零售价: <span className={styles.productPriceValue}>¥{formatAmount(item.productAmount)}</span></div>
                  <div className={styles.productTotal}>实付款: <span className={styles.productTotalValue}>¥{formatAmount(item.productSettleAmount)}</span></div>
                </div>
              </div>
            </div>
            {
              logisticInfo.content && (
                <div className={styles.productFooter}>
                  <div className={styles.productLogistics} onClick={toLogisticsPage}>查看物流</div>
                </div>
              )
            }
          </div>
        </div>
        {/* 商品价格信息 */}
        <div className={styles.summary}>
          <div className={styles.summaryItem}>
            <span>商品总售价</span>
            <span className={styles.summaryValue}>¥{formatAmount(order.totalProductAmount)}</span>
          </div>
          <div className={styles.summaryItem}>
            <span>运费</span>
            <span className={styles.summaryValue}>¥{formatAmount(order.freight)}</span>
          </div>
          <div className={styles.summaryLine}></div>
          <div className={styles.summaryItem}>
            <span>实付总金额</span>
            <span className={styles.summaryValueRed}>¥{formatAmount(order.paymentAmount)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardOrder;
