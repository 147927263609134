import { getPushOrderRemind } from "@/api/my/shop";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShopDispatchMaterial from "../shop-dispatch-material";
import ShopDispatchProduct from "../shop-dispatch-product";
import styles from "./index.module.less";
import { btn_click, resource_click, resource_expose } from "./track";

const ShopDispatchTask: React.FC = () => {
  const [taskInfo, setTaskInfo] = useState<any>({});
  const navigater = useNavigate();
  const { navigate2H5 } = useNavigation();

  const queryPushOrderRemind = async () => {
    const data = await getPushOrderRemind({
      pageId: "H5_Member",
      pageNo: 1,
      pageSize: 1,
      queryType: "TODAY",
    });
    data && setTaskInfo(data);
    data && resource_expose(data?.shoppingMessageCardVO);
  };

  const goDispatchTask = e => {
    e.stopPropagation();
    const query = location.href.split("?")[1];
    navigater(`/mx-shop-micro/dispatch-task?${query}&e`);
    resource_click("全部", taskInfo?.shoppingMessageCardVO);
  };

  const goFootPrint = e => {
    e.stopPropagation();
    navigate2H5("/#/analysis/customer/footPrint", { query: `role=e&customerId=${taskInfo?.shoppingMessageCardVO?.userId}` });
    btn_click("TA的足迹", taskInfo?.shoppingMessageCardVO);
  };

  useEffect(() => {
    queryPushOrderRemind();
  }, []);

  return (
    <>
      {taskInfo && taskInfo.userCount > 0 && (
        <div className={styles["dispatch-task"]}>
          <div className={styles["header"]}>
            <div className={styles["header__left"]}>
              <div className={styles["left__title"]}>派单任务</div>
              <div className={styles["left__customer"]}>{taskInfo.userCount}名新客加购</div>
            </div>
            <div className={styles["header__right"]} onClick={goDispatchTask}>
              <div>查看全部</div>
              <i className={styles["icon__arrow"]} />
            </div>
          </div>
          <div className={styles["body"]}>
            <div className={styles["user-card"]} onClick={goFootPrint}>
              <div className={styles["user__info"]}>
                <div className={styles["info__left"]}>
                  <img
                    className={styles["left__avatar"]}
                    src={taskInfo?.shoppingMessageCardVO?.headPicture}
                    alt=""
                  />
                  <div className={styles["left__nickname"]}>
                    {taskInfo?.shoppingMessageCardVO?.nickName}
                  </div>
                </div>
                <div className={styles["info__right"]}>
                  <span>TA的足迹</span>
                  <img
                    className={styles["icon__arrow"]}
                    src="https://akim-oss.aikucun.com/mshop/b14f97413e78f590853ff4772d76c33925e6bfe3_1710331826116_91.png"
                  />
                </div>
              </div>
              <div className={styles["user__action"]}>
                {taskInfo?.shoppingMessageCardVO?.shoppingMessage}
              </div>
            </div>
            {taskInfo?.shoppingMessageCardVO?.showType === 1 && (
              <ShopDispatchProduct productInfo={taskInfo?.shoppingMessageCardVO?.productVO} />
            )}
            {taskInfo?.shoppingMessageCardVO?.showType === 2 && (
              <ShopDispatchMaterial cardInfo={taskInfo.shoppingMessageCardVO} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ShopDispatchTask;
