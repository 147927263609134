import { useDebounceFn, useUnmount } from "ahooks";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";

import MarketingPopup from "@/components/marketing-popup";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import RedPacketNotice from "@/components/red-packet-notice";
import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";
import { Toast } from "antd-mobile";

import { drawLotteryAward, signIn } from "@/api/dailyReward";
import {
  CarrierElementType,
  ContentType,
  SourceScene,
  bizInfoParams,
  carrierContextParams,
  envContextParams,
} from "@/api/scode/scode_params";
import { shareCard } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { platform } from "@/utils/platform";
import { useShare } from "@/utils/share/share";
import { NetworkError } from "@akc/biz-api";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { shouldShowHighValueOther, updateShowHighValueOther } from "./utils";

import useCheckBind from "../new-person/hooks/useCheckBind";
import ActiveSignList from "./components/ActiveSignList";
import CarouselSwiperCopy from "./components/CarouselSwiperCopy";
import CelebrateModule, { CelebrateModuleRef } from "./components/CelebrateModule";
import DrawResultPop from "./components/DrawResultPop";
import LotteryControl from "./components/LotteryControl";
import LotteryDialog, { LotteryDialogRef } from "./components/LotteryDialog";
import Notice from "./components/Notice";
import ProductList from "./components/ProductList";
import RegularPopup from "./components/RegularPopup";
import TopBackSticky from "./components/TopBackSticky";
import { TaskPriority, createAsyncControl, useAsyncTask } from "./hook/useAsyncTask";
import useLottery from "./hook/useLottery";
import { useNoticeControl } from "./hook/useNoticeControl";

import styles from "./index.module.less";
import type { LotteryParams } from "./types.d";

let sharePosterData: any = null;
let hasSetTrackData = false;

let pageId = "H5_SmartRecommend";
if (platform.isMini) {
  pageId = "Mini_Program_SmartRecommend";
} else if (platform.isApp) {
  pageId = "App_SmartRecommend";
}

const Index: React.FC = () => {
  const [refreshTimes, setRefreshTimes] = useState(0);

  const { track, isExternal } = useCommonContext();
  const [search] = useSearchParams();
  const { setTrackData } = useContext(TrackDataContext) as { setTrackData: (data: any) => void };
  const { activityInfo, ranList, highDataOther, queryLotteryInfo, queryHighValue } = useLottery({ refreshTimes });
  const { shareDailyReward } = useShare();
  const { addTask, clearTasks, isRunning } = useAsyncTask();
  const {
    isVisitor,
    bindPhonePopupVisible,
    bindPhone,
    setBindPhonePopupVisible,
  } = useCheckBind();
  const redPacketNoticeControl = useNoticeControl();

  const celebrateModuleRef = useRef<CelebrateModuleRef>(null);
  const lotteryDialogRef = useRef<LotteryDialogRef>(null);
  const hasAutoSignedIn = useRef(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [curShareType, setCurShareType] = useState("DAILY_AWARD");
  const [showPoster, setShowPoster] = useState(false);
  const [drawResultVisible, setDrawResultVisible] = useState(false);
  const [drawResultInfo, setDrawResultInfo] = useState<any>(null);

  const [showRegularPop, setShowRegularPop] = useState(false);

  const bindPhonePopupControl = useMemo(() => {
    return createAsyncControl({
      onStart: bindPhone,
      onComplete: () => {
        setBindPhonePopupVisible(false);
      }
    });
  }, []);

  const signedPopControl = useMemo(() => {
    return createAsyncControl({
      onStart: (content?: string) => {
        Toast.show({
          content: content || "",
          duration: 3000,
          maskClickable: false,
          afterClose: () => {
            signedPopControl.complete();
          }
        });
      },
    });
  }, []);

  const otherHighValuePopupControl = useMemo(() => {
    return createAsyncControl({
      onStart: async (res) => {
        updateShowHighValueOther();
        const panelType = res.awardType === "PRODUCT" ? "gift" : "certificate";
        celebrateModuleRef.current?.setPanelType(panelType);
        celebrateModuleRef.current?.setType("other");
        celebrateModuleRef.current?.setVisible(true);
      },
    });
  }, []);

  const drawConfirmPopControl = useMemo(() => {
    return createAsyncControl({
      onStart: async (res) => {
        lotteryDialogRef.current?.setInfo({
          title: res?.lotteryInfo?.confirmTitle,
          content: res?.lotteryInfo?.confirmSubTitle,
        });
        lotteryDialogRef.current?.setVisible(true);
      },
      onComplete: () => {
        lotteryDialogRef.current?.setVisible(false);
      }
    });
  }, []);

  const ownerHighValuePopupControl = useMemo(() => {
    return createAsyncControl({
      onStart: async (result) => {
        const panelType = result?.awardType === "PRODUCT" ? "gift" : "certificate";
        celebrateModuleRef.current?.setPanelType(panelType);
        celebrateModuleRef.current?.setType("default");
        celebrateModuleRef.current?.setVisible(true);
      },
    });
  }, []);

  const drawReultPopupControl = useMemo(() => {
    return createAsyncControl({
      onStart: async () => {
        setDrawResultVisible(true);
      },
      onComplete: () => {
        setDrawResultVisible(false);
      }
    });
  }, []);

  const posterControl = useMemo(() => {
    return createAsyncControl({
      onStart: async () => {
        setShowPoster(true);
      },
      onComplete: () => {
        setShowPoster(false);
      }
    });
  }, []);

  const handleOtherHighValuePopup = async () => {
    const res = await queryHighValue();
    const flag = await shouldShowHighValueOther();
    if (res && flag) {
      // 当前有任务执行则延迟5s展示高价值
      if (isRunning()) {
        addTask(() => {
          timerRef.current = setTimeout(() => {
            otherHighValuePopupControl.start(res);
          }, 5000);
        }, {
          completeFn: otherHighValuePopupControl.complete,
        });
      } else {
        addTask(() => otherHighValuePopupControl.start(res), {
          completeFn: otherHighValuePopupControl.complete,
        });
      }
    }
  };

  const handleSignIn = async () => {
    console.log("自动签到");
    const { btnStatus } = activityInfo.lotteryInfo || {};
    if (btnStatus === 2) {
      const res = await signIn({
        taskId: activityInfo.signInTaskInfo?.taskId,
        promoActivityId: activityInfo.promoActivityId,
      });


      if (res?.isGetSignInAward) {
        addTask(() => signedPopControl.start("签到成功，立即去抽奖"));
      } else if (!res?.isGetSignInAward && res?.recommendLotteryAward) {
        addTask(() => signedPopControl.start("签到成功"));
      }
      setRefreshTimes(n => n + 1);
    }
    hasAutoSignedIn.current = true;
    addTask(redPacketNoticeControl.start);

    // 清除之前可能存在的定时器
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // 设置新的定时器并保存引用
    timerRef.current = setTimeout(() => {
      timerRef.current = null; // 定时器执行后清空引用
      handleOtherHighValuePopup();
    }, 10000);

    if (track?.track) {
      track?.track("resource_click", {
        page_name: "日日奖",
        previous_page_name: search.get("previous_page_name"),
        btn_name: "签到",
        page_code: activityInfo.promoActivityId,
        task_id: activityInfo.signInTaskInfo?.taskId,
      });
    }
  };

  const handleClickLottery = useDebounceFn(async () => {
    const toast = Toast.show({
      icon: "loading",
      content: "加载中…",
      maskClickable: false,
      duration: 0,
    });
    try {
      const res = await queryLotteryInfo({ promoActivityId: activityInfo.promoActivityId });
      toast.close();
      addTask(() => drawConfirmPopControl.start(res), { priority: TaskPriority.HIGH, completeFn: drawConfirmPopControl.complete });
    } catch (error) {
      toast.close();
    }
  }, { wait: 500 }).run;

  const handleClickConfirmLottery = useDebounceFn(
    async (type?: string) => {
      drawConfirmPopControl.complete();
      if (type === "cancel") {
        return;
      }
      const { promoActivityId, lotteryInfo, lotteryPointInfo } = activityInfo;

      if (!lotteryInfo?.remainCount && !lotteryPointInfo?.availableDrawLotteryCount) {
        Toast.show({
          content: "暂无抽奖机会",
        });
        return;
      }

      const toast = Toast.show({
        icon: "loading",
        content: "抽奖中...",
        duration: 0,
      });

      const params: LotteryParams = {
        promoActivityId,
        pageId,
      };

      if (lotteryInfo?.remainCount) {
        params.type = "CHECK_IN_BASED";
      } else if (lotteryPointInfo?.availableDrawLotteryCount) {
        params.type = "POINT_BASED";
      } else {
        delete params.type;
      }

      try {
        const result = await drawLotteryAward(params);
        setDrawResultInfo(result);

        if (result?.highValue) {
          addTask(() => ownerHighValuePopupControl.start(result), { completeFn: ownerHighValuePopupControl.complete });
        } else {
          addTask(() => drawReultPopupControl.start(), { priority: TaskPriority.NORMAL, completeFn: drawReultPopupControl.complete });
        }
        Toast.clear();

        if (track?.track) {
          track?.track("resource_click", {
            page_name: "日日奖",
            previous_page_name: search.get("previous_page_name"),
            btn_name: "抽奖",
            page_code: activityInfo.promoActivityId,
            task_id: activityInfo.signInTaskInfo?.taskId,
          });
        }
      } catch (error: any) {
        toast.close();
        Toast.show({
          content: (error as NetworkError)?.message || "抽奖失败，请稍后重试",
          position: "top",
        });
      }

      setRefreshTimes(n => n + 1);
    },
    { wait: 500 }
  ).run;

  const handleShare = (type = "dailyReward", reward?: any) => {
    const rewardName = () => {
      if (reward?.awardType === "RED_PACKET") {
        return reward?.redPacketAward?.amount;
      }
      if (reward?.awardType === "COUPON") {
        const { baseAmount, quotaAmount } = reward?.couponAwd as any;
        return `满${baseAmount}减${quotaAmount}`;
      }
      if (reward?.awardType === "POINTS") {
        return reward?.pointsAmount;
      }
    };
    if (platform.isH5) {
      sharePosterData = {
        bizInfo: bizInfoParams({
          contentType: ContentType[type],
          contentId: activityInfo.promoActivityId,
          extra: {
            awardName: reward && rewardName(),
            awardType: reward?.awardType,
          },
        }),
        envContext: envContextParams({
          sourceScene: SourceScene[type],
          sourceSceneId: ContentType[type],
        }),
        carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
      };
      setCustomEditData({
        promoActivityId: activityInfo.promoActivityId,
        awardName: reward && rewardName(),
        awardType: reward?.awardType,
      });
      const params = {
        contentId: activityInfo.promoActivityId,
        shareType: ContentType[type],
        awardName: reward && rewardName(),
        awardType: reward?.awardType,
      };
      console.log("appPosterParams", params);
      setCurShareType(ContentType[type]);
      addTask(posterControl.start, { completeFn: posterControl.complete });
    } else {
      const params = {
        contentId: activityInfo.promoActivityId,
        shareType: ContentType[type],
        awardName: reward && rewardName(),
        awardType: reward?.awardType,
      };
      console.log("appPosterParams", params);
      shareDailyReward(params);
    }
  };

  useActivate(() => {
    document.title = "日日奖";
  });

  useEffect(() => {
    document.title = "日日奖";
  }, []);

  useEffect(() => {
    if (activityInfo?.promoActivityId && !hasAutoSignedIn.current && isVisitor) {
      addTask(bindPhonePopupControl.start, {
        completeFn: bindPhonePopupControl.complete,
      });
    }
  }, [activityInfo, isVisitor]);

  useEffect(() => {
    if (activityInfo?.promoActivityId && !hasAutoSignedIn.current && !isVisitor && !bindPhonePopupVisible) {
      handleSignIn();
    }
  }, [activityInfo, bindPhonePopupVisible, isVisitor]);

  useEffect(() => {
    if (!activityInfo) {
      return;
    }
    if (!hasSetTrackData && activityInfo.promoActivityId) {
      hasSetTrackData = true;
      setTrackData({
        page_name: "日日奖",
      });
      if (track?.track) {
        track?.track("$pageview", {
          page_name: "日日奖",
          previous_page_name: search.get("previous_page_name"),
          page_code: activityInfo.promoActivityId,
        });
      }
    }
    if (activityInfo.promoActivityId) {
      shareCard({ contentType: "dailyReward", contentId: activityInfo.promoActivityId });
    }
  }, [activityInfo]);

  useUnmount(() => {
    Toast.clear();
    clearTasks();

    // 清理定时器
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  });

  if (!activityInfo) {
    return (
      <div className={styles.noActivity}>
        <img
          src="https://akim-oss.aikucun.com/b37f6ddcefad7e8657837d3177f9ef2462f98acf_1724119290589_81.png"
          className={styles.noActivityPic}
        />
        暂无可参加的活动，敬请期待~
      </div>
    );
  }
  return (
    <div
      className={styles.wrap + " " + (platform.isH5 ? styles.isInH5 : "")}
      id="dailyRewardContainer"
      style={{
        backgroundImage: `url(${activityInfo?.dailyTheme?.topBgImgUrl || "https://akim-oss.aikucun.com/mshop/77de68daecd823babbb58edb1c8e14d7106e83bb_1728900334396_5.png"})`,
      }}>
      <TopBackSticky />
      <div className={styles.topFixedLabel} onClick={() => setShowRegularPop(true)}>
        活动规则
      </div>
      {
        activityInfo?.canUsePointsForLottery ?
          <div className={styles.count}>每日积分抽奖次数：{activityInfo?.lotteryPointInfo?.availableDrawLotteryCount ?? 0}/
            {activityInfo?.lotteryPointInfo?.userDayMaxCount ?? 0}</div> : <div style={{ height: "30px" }}></div>
      }
      <div className={styles.productSwiperWrap}>
        <CarouselSwiperCopy
          list={activityInfo.lotteryInfo?.topAwardList || []}
          promoActivityId={activityInfo?.promoActivityId || ""}
          taskId={activityInfo?.signInTaskInfo?.taskId || ""}
        />
      </div>
      <LotteryControl callback={handleClickLottery} activityInfo={activityInfo} />
      <Notice dataSource={ranList} promoActivityId={activityInfo?.promoActivityId} activityStatus={activityInfo?.status} />
      <div className={styles.signListWrap}>
        <ActiveSignList activityInfo={activityInfo} handleSignIn={handleSignIn} />
      </div>
      <ProductList pageId={pageId} activityInfo={activityInfo} />
      {!isExternal && (
        <div className={styles.footer} onClick={() => handleShare()}>
          <div className={styles.longBtn}>邀请好友一起抽奖</div>
        </div>
      )}
      {sharePosterData && (
        <PosterSwipe
          show={showPoster}
          customEditData={customEditData}
          posterData={sharePosterData}
          shareType={curShareType}
          btnConfig={["copy", "edit"]}
          close={posterControl.complete}
        />
      )}
      <RegularPopup
        visible={showRegularPop}
        setVisible={setShowRegularPop}
        activityRuleDesc={activityInfo.activityRuleDesc}
      />
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={() => bindPhonePopupControl.complete()}
      />
      <RedPacketNotice autoplay={redPacketNoticeControl.autoplay} onCheckComplete={redPacketNoticeControl.onCheckComplete} onClose={redPacketNoticeControl.onClose} />
      <MarketingPopup serverCode="daily_reward" />
      <CelebrateModule
        ref={celebrateModuleRef}
        dataSource={drawResultInfo}
        highDataSource={highDataOther}
        onAfterClose={({ type, panelType, isButtonClose }) => {
          // 无论如何都执行 complete 逻辑
          otherHighValuePopupControl.complete();
          ownerHighValuePopupControl.complete();

          // 只有在非按钮关闭的情况下，才执行 addTask 逻辑
          if (!isButtonClose && type === "default" && panelType === "gift") {
            addTask(drawReultPopupControl.start, { priority: TaskPriority.HIGH, completeFn: drawReultPopupControl.complete });
          }
        }}
      />
      <LotteryDialog ref={lotteryDialogRef} callbcak={handleClickConfirmLottery} />
      <DrawResultPop
        visible={drawResultVisible}
        onClose={drawReultPopupControl.complete}
        result={drawResultInfo}
        handleShare={handleShare}
        setRefreshTimes={setRefreshTimes}
      />
    </div>
  );
};

export default Index;
