import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./ScrollBanner.module.less"; // 样式文件

interface ScrollBannerProps {
  children: ReactNode;  // 接受任意 React 组件作为子组件
  baseDuration: number; // 基础时长，用于计算滚动速度
}

const ScrollBanner: React.FC<ScrollBannerProps> = ({ children, baseDuration }) => {
  const containerRef = useRef<HTMLDivElement | null>(null); // 用于获取容器的引用
  const [scrollWidth, setScrollWidth] = useState<number>(0); // 滚动内容的总宽度
  const [containerWidth, setContainerWidth] = useState<number>(0); // 父容器的宽度
  const [scrollDuration, setScrollDuration] = useState<number>(0); // 动画时长

  // 计算滚动内容的总宽度和父容器的宽度
  useEffect(() => {
    if (containerRef.current) {
      const totalWidth = Array.from(containerRef.current.children).reduce(
        (total, child) => total + (child as HTMLElement).offsetWidth,
        0
      );
      setScrollWidth(totalWidth);
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [children]);

  // 根据宽度计算动画时长
  useEffect(() => {
    if (containerWidth && scrollWidth) {
      const duration = (scrollWidth / containerWidth) * baseDuration;
      setScrollDuration(duration);
    }
  }, [scrollWidth, containerWidth, baseDuration]);

  return (
    <div className={styles.scrollBannerWrapper}>
      <div
        className={styles.scrollBannerContent}
        ref={containerRef}
        style={{ animationDuration: `${scrollDuration}s` }}  // 动态设置动画时长
      >
        {/* 内容和克隆副本 */}
        <div className={styles.scrollBannerItem}>
          {children}
        </div>
        <div className={styles.scrollBannerItem}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ScrollBanner;
