import AddPurchasePanel from "@/components/add-purchase-panel";
import TopNavigation from "@/components/top-navigation";
import { QAItemComp, QAProductCardComp } from "@akc/mx-taro-h5-components";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React from "react";
import { useQuestionsAnswers } from "./hook/useQuestionsAnswers";
import styles from "./index.module.less";

//全部问答页面
const QuestionsAnswers: React.FC = () => {
    const control = useQuestionsAnswers();
    return (
        <div className={styles.qaContainer}>
            {/* 导航 */}
            <div className={styles.topNavigation}>
                <TopNavigation
                    hideHomeText={true}
                    hideSearchIcon={true}
                    hideFavoriteIcon={true}
                    showShareIcon={false}
                    backgroundColor="#ffffff"
                />
            </div>

            {/* 商品卡片 */}
            {control.productDetail && (
                <div className={styles.productC}>
                    <QAProductCardComp
                        product={control.productDetail}
                        onBuyClick={() => {
                            control.setCartModalVisible(true);
                        }}
                        trackData={{
                            activity_spu_code: control.productDetail?.activitySpuId,
                            page_name: control.pageName,
                            previous_page_name: control.previousPageName,
                        }}
                    />
                </div>
            )}

            {/* 问答列表 */}
            <div className={styles.list}>
                {control.qaList.map((item, index) => (
                    <div key={index} className={styles.item}>
                        <QAItemComp
                            key={index}
                            qaInfo={item}
                            onCopyClick={txt => {
                                if (txt) {
                                    copy(txt);
                                    Toast.show("复制成功");
                                }
                            }}
                            trackData={{
                                activity_spu_code: control.productDetail?.activitySpuId,
                                page_name: control.pageName,
                                previous_page_name: control.previousPageName,
                            }}
                        />
                    </div>
                ))}
                {control.qaList?.length > 0 && (<div className={styles.bottom} />)}
                {control.showEmpty && (
                    <div className={styles.empty}>
                        <img
                            className={styles.empty_icon}
                            src="https://akim-oss.aikucun.com/5679fa0533683de3ae789448b07766da168a56d4_1740479354297_77.png"
                        />
                        <div>暂无相关问答</div>
                    </div>
                )}
            </div>


            {/* 加购弹窗 */}
            {control.productDetail && (
                <AddPurchasePanel
                    visible={control.cartModalVisible}
                    product={control.productDetail}
                    needLoading={false}
                    close={e => {
                        control.setCartModalVisible(false);
                    }}
                    confirm={control.changeCartNum}
                    trackData={{ page_name: control.pageName }}
                />
            )}
        </div>
    );
};

export default QuestionsAnswers;
