import { SharePosterParams } from "@/api/scode/poster";
import nullPng from "@/assets/null.png";
import loadingPng from "@/base64";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import { ProductModel } from "@/models/product";
import { sharePosterParams4H5Product, useActions } from "@/utils/actions";
import { formatImgUrl } from "@/utils/crop";
import { identity } from "@/utils/identity";
import { platform } from "@/utils/platform";
import { project } from "@/utils/project";
import { showSKUPreview } from "@/utils/select-color";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { Grid, Image } from "antd-mobile";
import { isEmpty, throttle } from "lodash";
import Macy from "macy";
import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import buyPng from "../../assets/buy.png";
import buyNowPng from "../../assets/buy_now.png";
import moneyPng from "../../assets/money.png";
import morePng from "../../assets/more.png";
import playPng from "../../assets/play.png";
import advancePng from "../../assets/product_advance.png";
import advancePng13 from "../../assets/product_advance13.png";
import scorePng from "../../assets/score.png";
import yuPng from "../../assets/yu.png";
import exposure from "../../utils/expose/exposure";
import { pxtorem } from "../../utils/pxtorem";
import SizePopup from "../size-popup";
import PriceCom from "./components/PriceCom";
import SaveMoney from "./components/SaveMoney";
import "./index.less";
import styles from "./index.module.less";
interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  resourceRank?: any;
  small?: boolean;
  last?: boolean;
  loadMore?: any;
  isNav?: any;
  navType?: any;
  navigationType?: any;
  navResourceRank?: any;
  navStyle?: any;
  trackInfo?: any;
  [key: string]: any;
}

const ProductCom: React.FC<Props> = (props: Props) => {
  const { navigate2Product, navigate2Activity, navigate2LiveRoom, navigate2RankScale } = useNavigation();
  const [, setMasonry] = useState(null);
  const [, setMasonryFlag] = useState(false);
  const [, setFlag] = useState(false);
  const [dataInfo, setDataInfo] = useState({ ...props.propData });
  const [macyObject, setMacyObject] = useState<any>({});
  const [proIndex, setProIndex] = useState(0);
  // const [loading, setLoading] = useState(false);
  const myRef: any = useRef();

  const colorList = [
    "linear-gradient(90deg, #F36226 0%, #ED1533 100%)",
    "linear-gradient(90deg, #40BBFC 0%, #4291D6 100%)",
    "linear-gradient(270deg, #BB48F6 0%, #D67CFD 100%)",
    "linear-gradient(90deg, #FE9241 0%, #FE8739 100%)",
    "linear-gradient(270deg, #FC3461 0%, #FD588F 100%)",
  ];
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);

  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<SharePosterParams>(
    {} as SharePosterParams,
  );
  const [isOTC, setIsOTC] = useState(false);
  const track = Track();

  useEffect(() => {
    if (
      props.propData.layoutType === "12" ||
      props.propData.layoutType === null ||
      props.propData.layoutType === undefined ||
      isEmpty(props.propData.layoutType)
    ) {
      const str = props.small
        ? `small_one2_${props.propData.componentCode}`
        : `one2_${props.navStyle === "HORIZONTAL"
          ? props.propData.componentDetailCode
          : props.propData.componentCode
        }`;
      const dom = document.getElementById(str);
      if (dom) {
        const macyInstance = Macy({
          container: props.small
            ? `#small_one2_${props.propData.componentCode}`
            : `#one2_${props.navStyle === "HORIZONTAL"
              ? props.propData.componentDetailCode
              : props.propData.componentCode
            }`, // 图像列表容器
          trueOrder: false,
          waitForImages: true,
          useOwnImageLoader: false,
          debug: true,
          margin: { x: 9, y: 9 }, // 设计列与列的间距
          columns: 2, // 设置列数
        });
        setMasonry(macyInstance);
        macyObject[`${props.propData.componentCode}`] = macyInstance;
        setMacyObject(macyObject);
        setMasonryFlag(false);
        setTimeout(() => {
          macyObject[`${props.propData.componentCode}`].recalculate(false, false);
          let sessionStorageValue: string = sessionStorage.getItem(
            "conference3_product_12",
          ) as string;
          sessionStorageValue = JSON.parse(sessionStorageValue);
          const objName = props.small
            ? `small_one2_${props.propData.componentCode}`
            : `one2_${props.navStyle === "HORIZONTAL"
              ? props.propData.componentDetailCode
              : props.propData.componentCode
            }`;
          if (sessionStorageValue) {
            sessionStorageValue[objName] = document.getElementById(objName)?.style.height;
            sessionStorage.setItem("conference3_product_12", JSON.stringify(sessionStorageValue));
          } else {
            const obj: any = {};
            obj[objName] = document.getElementById(objName)?.style.height;
            sessionStorage.setItem("conference3_product_12", JSON.stringify(obj));
          }
          setMasonryFlag(true);
        }, 500);
        setFlag(true);
      } else {
        const domInterval = setInterval(() => {
          clearInterval(domInterval);
          const domPro = document.getElementById(str);
          if (domPro) {
            const macyInstance = Macy({
              container: props.small
                ? `#small_one2_${props.propData.componentCode}`
                : `#one2_${props.navStyle === "HORIZONTAL"
                  ? props.propData.componentDetailCode
                  : props.propData.componentCode
                }`, // 图像列表容器
              trueOrder: false,
              waitForImages: true,
              useOwnImageLoader: false,
              debug: true,
              margin: { x: 9, y: 9 }, // 设计列与列的间距
              columns: 2, // 设置列数
            });
            setMasonry(macyInstance);
            macyObject[`${props.propData.componentCode}`] = macyInstance;
            setMacyObject(macyObject);
            setMasonryFlag(false);
            setTimeout(() => {
              macyObject[`${props.propData.componentCode}`].recalculate(false, false);
              let sessionStorageValue: string = sessionStorage.getItem(
                "conference3_product_12",
              ) as string;
              sessionStorageValue = JSON.parse(sessionStorageValue);
              const objName = props.small
                ? `small_one2_${props.propData.componentCode}`
                : `#one2_${props.navStyle === "HORIZONTAL"
                  ? props.propData.componentDetailCode
                  : props.propData.componentCode
                }`;
              if (sessionStorageValue) {
                sessionStorageValue[objName] = document.getElementById(objName)?.style.height;
                sessionStorage.setItem(
                  "conference3_product_12",
                  JSON.stringify(sessionStorageValue),
                );
              } else {
                const obj: any = {};
                obj[objName] = document.getElementById(objName)?.style.height;
                sessionStorage.setItem("conference3_product_12", JSON.stringify(obj));
              }
              setMasonryFlag(true);
            }, 500);
            setFlag(true);
            clearInterval(domInterval);
          }
        }, 500);
      }
    }
    setDataInfo(JSON.parse(JSON.stringify(props.propData)));
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < (props.propData.dataInfo?.result?.length || 0); i++) {
        const dom = document.getElementById(
          `${props.propData.componentCode || props.propData.componentDetailCode}-${props.propData.dataInfo?.result?.[i].activitySpuId
          }`,
        );
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [props?.propData?.dataInfo?.result]);

  const naviThr = throttle(
    (item, salePropertyFirstListPicItem?: any, index?: any, btnInfo?: any) => {
      navigate2Product(
        {
          activityId: item.activityId,
          activitySpuId: item.activitySpuId,
          selectSkuId: item.selectSkuId,
          firstSalePropertyValue: salePropertyFirstListPicItem?.value,
          marketName: props.pageData.name,
        },
        {
          type: props.navType ? (props.navType === "normal" ? "普通导航" : "电梯导航") : "商品",
          targetId: item.activitySpuId,
          resourceRank: props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
          relativeRank: index,
          hasSpecially: item.animationType === "2" ? true : false,
          btnInfo: btnInfo,
          ...props.trackInfo,
          btn_name: "商品点击",
          resource_content: "商品",
        },
      );
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );

  // 进入商品详情
  const goDetail = (item, index?: any, salePropertyFirstListPicItem?: any, type?: any) => {
    if (type === "btn") {
      const btnInfo = {
        page_name: document.title,
        module: "商品",
        btn_name: "立即购买",
        btn_text: "立即购买",
      };
      naviThr(item, salePropertyFirstListPicItem, index, btnInfo);
    } else if (type === "size") {
      const btnInfo = {
        page_name: document.title,
        module: "商品选色",
        btn_name: "查看详情",
        btn_text: "查看详情",
      };
      naviThr(item, salePropertyFirstListPicItem, index, btnInfo);
    } else {
      naviThr(item, salePropertyFirstListPicItem, index);
    }
  };

  // 跳转热销榜
  const goRank = (item: any) => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: "商品列表",
        btn_name: "榜单入口",
        btn_text: item.skuExtendInfoVO?.categoryHotRankVO?.rankName,
        spu_id: item.activitySpuId,
        product_name: item.title,
      });
    }
    navigate2RankScale({ rankNo: item.skuExtendInfoVO?.categoryHotRankVO?.rankNo });
  };

  const { shareProductPosterAction } = useActions();

  //转发商品
  const forwardProduct = (e: React.MouseEvent<HTMLDivElement>, item: any) => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: "商品",
        btn_name: "立即转发",
        btn_text: "立即转发",
        market_id: props.pageData.pageCode,
        market_name: document.title,
        activity_spu_code: item.activitySpuId,
        sku_id: item.selectSkuId,
        ...props.trackInfo,
      });
    }
    /* eslint-enable */
    e.stopPropagation();
    if (!platform.isApp && !platform.isMini) {
      setSharePosterData(
        sharePosterParams4H5Product({
          pageCode: props.pageData.pageCode,
          activitySpuId: item.activitySpuId,
        }),
      );
      setCustomEditData({
        activitySpuId: item.activitySpuId,
        activityId: item.activityId,
        selectSkuId: item.selectSkuId,
      });
      setIsOTC(!!item?.skuExtendInfoVO?.isOTC);
      setShowPoster(true);
    } else {
      shareProductPosterAction({
        product: item,
        pageCode: props.pageData.pageCode,
        pageName: props.pageData.name,
      });
    }
    /* eslint-disable */
    if (track.track) {
      track.track("poster_expose", {
        page_name: document.title,
        expose_content: props.navType
          ? props.navType === "normal"
            ? "普通导航"
            : "电梯导航"
          : "商品",
        type: "手动",
        market_id: props.pageData.pageCode,
        market_name: document.title,
      });
    }
    /* eslint-enable */
  };
  const onClosePoster = () => {
    setShowPoster(false);
    if (location.href.includes("/decorate?")) {
      document.body.style.overflow = "unset";
    }
  };

  //跳转会场
  const goConference = (e, item) => {
    e.stopPropagation();
    navigate2Activity({
      activityNo: item.activityId,
      marketName: props.pageData.pageCode,
    });
  };

  //跳转直播间
  const goLive = (e, item) => {
    e.stopPropagation();
    navigate2LiveRoom({
      liveId: item.liveVO.liveNo,
      liveStatus: item.liveVO.liveStatus,
    });
  };

  // 选色
  const [sizePopupProduct, setSizePopupProduct] = useState<ProductModel>({} as ProductModel);
  const [showSizePopup, setShowSizePopup] = useState(false);
  const skuPreview = (e: React.MouseEvent<HTMLDivElement>, item: any, index?: any) => {
    e.stopPropagation();
    const productInfo: ProductModel = {
      salePropertyFirstList: item.salePropertyFirstList,
      spuInfoVO: {
        sellOut: item.spuSellOut,
      },
      spuId: item.spuId,
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
    };
    showSKUPreview(productInfo).then(result => {
      if (result) {
        return;
      }
      setSizePopupProduct(productInfo);
      setShowSizePopup(true);
      setProIndex(index);
    });
  };
  const onCloseSizePopup = () => {
    setShowSizePopup(false);
  };

  const sliderSupplement = dataInfo => {
    if (dataInfo.result.length >= 8) {
      return "";
    }
    return (
      <>
        {dataInfo.result.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              className="swiper_pro_item"
              style={{
                background: colorList[index % 5],
                // translate: pxtorem(index * 0),
              }}>
              <div
                className="item_img"
                style={{
                  background: `url(${item?.skuMainImageUrls?.[0] || nullPng}) no-repeat`,
                  backgroundSize: "100% 100%",
                }}
                id={`${props.propData.componentCode || props.propData.componentDetailCode}-${item.activitySpuId
                  }`}
                data-param={JSON.stringify({
                  type: props.navType
                    ? props.navType === "normal"
                      ? "普通导航"
                      : "电梯导航"
                    : "商品",
                  content: "商品",
                  name: item.title,
                  id: item.activitySpuId,
                  picture: true,
                  hasSpecially: true,
                  activitySpuId: item.activitySpuId,
                  activityId: item.activityId,
                  resourceRank:
                    props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
                  relativeRank: index,
                  ...props.trackInfo,
                })}
                data-eventid={
                  (props.propData.componentCode || props.propData.componentDetailCode) +
                  "-" +
                  item.activitySpuId
                }
                onClick={() => goDetail(item, index)}></div>
              <div className="price_real">
                <div className="symbol">¥</div>
                <div className="price_big">
                  {Math.trunc(item?.skuCurrentPriceInfo?.marketingPrice)}
                </div>
                <div className="decimal">
                  .{Math.round((item?.skuCurrentPriceInfo?.marketingPrice % 1) * 10)}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </>
    );
  };

  const formatLabel = (list, type) => {
    if ((list?.length ?? 0) <= 0) {
      return [];
    }
    const arr: any = [];
    for (const label of list) {
      if (label.locationCode === type && label?.labelInfoVOList?.length > 0) {
        for (const labelList of label.labelInfoVOList) {
          if (type.indexOf("TitleDown") < 0 && arr.length > 0) {
            break;
          }
          arr.push({
            type: labelList.contentType,
            value: labelList.labelIconUrl ? labelList.labelIconUrl : labelList.labelText,
            height: 16,
            width: labelList.labelIconWidth / (labelList.labelIconHeight / 16),
          });
        }
      }
    }
    return arr;
  };

  const getDecimal = marketingPrice => {
    const decimal = marketingPrice.toString().split(".")[1];
    if (decimal) {
      return <span className={styles["decimal"]}>.{decimal}</span>;
    }
    return "";
  };

  const slideChange = slide => {
    //滚动偏移量
    const realPx = window.screen.width / 4 + 2.5;
    const wantPx = (2.26667 * window.screen.width) / 10 + 10;
    const offset = realPx - wantPx;
    console.log("偏移量------", offset);
    console.log("当前slide------", slide.activeIndex);
    const countOffset = (slide.activeIndex - 4) * offset;
    const dom: any = document
      .getElementById(dataInfo.componentCode)
      ?.getElementsByClassName("swiper-wrapper");
    if (dom && dom[0]) {
      dom[0].style.translate = `${countOffset}px`;
    }
  };

  //加载更多(仅限最后一个组件为档期或商品)
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean; }) => {
    return (
      <div className={styles.infinite} style={{ padding: hasMore ? pxtorem(20) : "0" }}>
        {hasMore ? (
          <div>
            <img
              className={styles.loadingMore}
              src={loadingPng}
              style={{ width: pxtorem(20), height: pxtorem(20) }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const onScroll = async () => {
    console.log(
      "滚动----",
      myRef.current.scrollWidth,
      myRef.current.clientWidth,
      myRef.current.scrollLeft,
    );
    const threshold =
      myRef.current.scrollWidth - myRef.current.clientWidth - myRef.current.scrollLeft;
    if (dataInfo.dataInfo?.hasNext && threshold <= 20) {
      console.log("滚动到底部------");
      await props.loadMore();
      console.log("滚动到底部------请求结束");
    }
  };

  return (
    <>
      {(isEmpty(dataInfo.animationType) ||
        dataInfo.animationType === null ||
        dataInfo.animationType === undefined ||
        dataInfo.animationType === "1") && (
          <div
            className={styles.product}
            style={{
              borderTopLeftRadius: pxtorem(props.propData?.borderTopLeftRadius),
              borderTopRightRadius: pxtorem(props.propData?.borderTopRightRadius),
              borderBottomLeftRadius: pxtorem(props.propData?.borderBottomLeftRadius),
              borderBottomRightRadius: pxtorem(props.propData?.borderBottomRightRadius),
              marginTop: props.small ? 0 : pxtorem(props.propData?.paddingTop),
              marginRight: props.small ? 0 : props.isNav ? pxtorem(0) : pxtorem(12),
              marginBottom: props.small ? 0 : pxtorem(props.propData?.paddingBottom),
              marginLeft: props.small ? 0 : props.isNav ? pxtorem(0) : pxtorem(12),
            }}>
            {dataInfo.layoutType === "11" && (
              <div className={styles.content}>
                {dataInfo.dataInfo?.result.map((item, index) => {
                  return (
                    <div
                      className={props.small ? styles.small_one1 : styles.one1}
                      key={index}
                      onClick={() => goDetail(item, index)}
                      id={`${props.propData.componentCode || props.propData.componentDetailCode}-${item.activitySpuId
                        }`}
                      data-param={JSON.stringify({
                        type: props.navType
                          ? props.navType === "normal"
                            ? "普通导航"
                            : "电梯导航"
                          : "商品",
                        content: "商品",
                        name: item.title,
                        id: item.activitySpuId,
                        picture: true,
                        hasSpecially: false,
                        activitySpuId: item.activitySpuId,
                        activityId: item.activityId,
                        resourceRank:
                          props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
                        relativeRank: index,
                        ...props.trackInfo,
                      })}
                      data-eventid={
                        (props.propData.componentCode || props.propData.componentDetailCode) +
                        "-" +
                        item.activitySpuId
                      }>
                      <div className={styles.img}>
                        <Image
                          width="100%"
                          height="100%"
                          src={
                            item?.skuMainImageUrls?.[0]
                              ? formatImgUrl(item?.skuMainImageUrls?.[0], 120, 120)
                              : nullPng
                          }
                          style={{ borderRadius: pxtorem(8) }}></Image>
                        {item?.skuCurrentPriceInfo?.commission > 0 && (
                          <SaveMoney
                            platform={platform}
                            skuCurrentPriceInfo={item?.skuCurrentPriceInfo}
                          />
                        )}
                        {item.spuSellOut && (
                          <div className={styles.sell_out} onClick={() => goDetail(item, index)}>
                            <div className={styles.sell_out_circle}>已售罄</div>
                          </div>
                        )}
                        {!item.spuSellOut &&
                          item.skuQuantity > 0 &&
                          item?.skuCurrentPriceInfo?.commission <= 0 && (
                            <div className={styles.quantity}>仅剩{item.skuQuantity}件</div>
                          )}
                        {item?.skuExtendInfoVO?.rewardCreditAmount > 0 && (
                          <div className={styles.score}>
                            <div className={styles.score_text}>
                              至多积{item?.skuExtendInfoVO?.rewardCreditAmount}
                            </div>
                            <Image className={styles.img_score} src={scorePng}></Image>
                          </div>
                        )}
                        {formatLabel(
                          item?.labelLocationVOList,
                          project() === 2
                            ? "H5_Shop_DecorateSales_ColumnProductPicRight"
                            : "H5_SuperMarketing_ProductStyleNo1_ProductPicRight",
                        ).map((label, labelIndex) => {
                          if (label.type === "image") {
                            return (
                              <Image
                                key={labelIndex}
                                src={label.value}
                                style={{
                                  height: pxtorem(40),
                                  width: pxtorem(40),
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                                fit="contain"></Image>
                            );
                          }
                          if (label.type === "text") {
                            return (
                              <div
                                key={labelIndex}
                                style={{
                                  height: pxtorem(16),
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}>
                                {label.value}
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div className={styles.content}>
                        <div className={styles.content_header}>
                          <div className={styles.label} style={{ position: "relative" }}>
                            {formatLabel(
                              item?.labelLocationVOList,
                              project() === 2
                                ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                            ).map((label, labelIndex) => {
                              if (label.type === "image") {
                                return (
                                  <Image
                                    key={labelIndex}
                                    src={label.value}
                                    className={styles.label_img}
                                    style={{
                                      width: pxtorem(label.width),
                                      position: "absolute",
                                      top: pxtorem(2),
                                    }}
                                    fit="contain"></Image>
                                );
                              }
                              if (label.type === "text") {
                                return (
                                  <div
                                    key={labelIndex}
                                    style={{
                                      paddingRight: pxtorem(4),
                                    }}>
                                    {label.value}
                                  </div>
                                );
                              }
                            })}
                            <div
                              className={styles.name}
                              style={{
                                textIndent:
                                  formatLabel(
                                    item?.labelLocationVOList,
                                    project() === 2
                                      ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                      : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                  )?.length > 0
                                    ? pxtorem(
                                      formatLabel(
                                        item?.labelLocationVOList,
                                        project() === 2
                                          ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                          : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                      )[0]?.width + 1 || 33,
                                    )
                                    : 0,
                              }}>
                              {item.title}
                            </div>
                            {item?.skuCurrentPriceInfo?.promotionTimeStatus === 1 && (
                              <Image className={styles.label_advance} src={advancePng}></Image>
                            )}
                          </div>
                          {item?.skuExtendInfoVO?.categoryHotRankVO && (
                            <div className={styles.rank_label_wrapper} onClick={(e) => { e.stopPropagation(); goRank(item); }}>
                              <div className={styles.rank_label_main}>
                                {item.skuExtendInfoVO.categoryHotRankVO.rankName}第 {item.skuExtendInfoVO.categoryHotRankVO.rankIng} 名
                              </div>
                            </div>
                          )}
                          <div className={styles.discount_label} style={{ height: pxtorem(16) }}>
                            {formatLabel(
                              item?.labelLocationVOList,
                              project() === 2
                                ? "H5_Shop_DecorateSales_ColumnProductNameDown"
                                : "H5_SuperMarketing_ProductStyleNo1_TitleDown",
                            ).map((label, labelIndex) => {
                              if (label.type === "image") {
                                return (
                                  <Image
                                    key={labelIndex}
                                    src={label.value}
                                    style={{
                                      height: pxtorem(16),
                                      width: pxtorem(label.width),
                                      paddingRight: pxtorem(4),
                                    }}></Image>
                                );
                              }
                              if (label.type === "text") {
                                return (
                                  <div
                                    key={labelIndex}
                                    style={{
                                      paddingRight: pxtorem(4),
                                    }}>
                                    {label.value}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                        <div className={styles.content_content}>
                          <div
                            className={styles.style_list}
                            onClick={e => skuPreview(e, item, index)}>
                            {item?.salePropertyFirstList?.slice(0, 4).map((style, styleIndex) => {
                              return (
                                <div className={styles.style_list_item} key={styleIndex}>
                                  <Image
                                    className={styles.item_img}
                                    src={
                                      style.picList?.[0]
                                        ? formatImgUrl(style.picList?.[0], 28, 28)
                                        : nullPng
                                    }
                                    style={{
                                      opacity: style.sellOut ? "0.1" : "1",
                                    }}></Image>
                                  {style.sellOut && <div className={styles.item_sell_out}>售罄</div>}
                                </div>
                              );
                            })}
                            {item?.salePropertyFirstList?.length > 0 && (
                              <div className={styles.style_list_item}>
                                <div className={styles.item_font}>
                                  <div>{item?.salePropertyFirstList?.length}款</div>
                                  可选
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={styles.price_btn}>
                            <div className={styles.price}>
                              {(item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                                item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                                item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE") && (
                                  <div className={styles.price_text}>
                                    {item?.skuCurrentPriceInfo?.promotionTypeName}
                                  </div>
                                )}
                              <div className={styles.price_real}>
                                <div>¥</div>
                                <div className={styles.price_big}>
                                  {Math.trunc(item.skuCurrentPriceInfo?.marketingPrice)}
                                </div>
                                {getDecimal(item.skuCurrentPriceInfo?.marketingPrice)}
                              </div>
                              {item.skuCurrentPriceInfo.promotionType !== "SECKILL" &&
                                item.skuCurrentPriceInfo.promotionType !== "FIXED_PRICE" && (
                                  <div className={styles.price_old}>
                                    ¥
                                    <span style={{ paddingLeft: pxtorem(2) }}>
                                      {item.skuCurrentPriceInfo?.tagPrice}
                                    </span>
                                  </div>
                                )}
                            </div>
                            {!identity() && item.activityStatus !== 1 && (
                              <div className={styles.btn} onClick={e => forwardProduct(e, item)}>
                                <Image className={styles.btn_img} src={moneyPng}></Image>
                                <div className={styles.btn_font}>立即转发</div>
                              </div>
                            )}
                            {!identity() && item.activityStatus === 1 && (
                              <div className={styles.btn_save} onClick={e => forwardProduct(e, item)}>
                                <Image className={styles.btn_img} src={moneyPng}></Image>
                                <div className={styles.btn_font}>立即转发</div>
                              </div>
                            )}
                            {identity() && item.activityStatus === 1 && (
                              <div
                                className={styles.btn_buy}
                                onClick={() => goDetail(item, index, null, "btn")}>
                                <Image className={styles.btn_img} src={buyPng}></Image>
                              </div>
                            )}
                            {identity() && item.activityStatus !== 1 && (
                              <div
                                className={styles.buy_btn}
                                onClick={() => goDetail(item, index, null, "btn")}>
                                <Image className={styles.btn_img} src={buyNowPng}></Image>
                                <div className={styles.buy_text}>立即购买</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {(isEmpty(dataInfo.layoutType) ||
              dataInfo.layoutType === "12" ||
              dataInfo.layoutType === null ||
              dataInfo.layoutType === undefined) && (
                <div
                  className={props.small ? styles.small_one2 : styles.one2}
                  id={
                    props.small
                      ? `small_one2_${props.propData.componentCode}`
                      : `one2_${props.navStyle === "HORIZONTAL"
                        ? props.propData.componentDetailCode
                        : props.propData.componentCode
                      }`
                  }
                // style={{ visibility: masonryFlag ? "visible" : "hidden" }}
                >
                  {dataInfo.dataInfo?.result.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "#ffff",
                          borderRadius: pxtorem(6),
                          width: props?.navigationType === "VERTICAL" ? pxtorem(147) : pxtorem(173),
                        }}
                        id={`${props.propData.componentCode || props.propData.componentDetailCode}-${item.activitySpuId
                          }`}
                        data-param={JSON.stringify({
                          type: props.navType
                            ? props.navType === "normal"
                              ? "普通导航"
                              : "电梯导航"
                            : "商品",
                          content: "商品",
                          name: item.title,
                          id: item.activitySpuId,
                          picture: true,
                          hasSpecially: false,
                          activitySpuId: item.activitySpuId,
                          activityId: item.activityId,
                          resourceRank:
                            props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
                          relativeRank: index,
                          ...props.trackInfo,
                        })}
                        data-eventid={
                          (props.propData.componentCode || props.propData.componentDetailCode) +
                          "-" +
                          item.activitySpuId
                        }
                        onClick={() => goDetail(item, index)}>
                        <div
                          className={styles.img}
                          style={{
                            width: "100%",
                            height: props?.navigationType === "VERTICAL" ? pxtorem(145) : pxtorem(171),
                          }}>
                          {item.spuSellOut && (
                            <div className={styles.sell_out} onClick={() => goDetail(item, index)}>
                              <div className={styles.sell_out_circle}>已售罄</div>
                            </div>
                          )}
                          {!item.spuSellOut &&
                            item.skuQuantity > 0 &&
                            item?.skuCurrentPriceInfo?.commission <= 0 && (
                              <div className={styles.quantity}>仅剩{item.skuQuantity}件</div>
                            )}
                          {formatLabel(
                            item?.labelLocationVOList,
                            project() === 2
                              ? "H5_Shop_DecorateSales_TwoColumnProductPicRight"
                              : "H5_SuperMarketing_ProductStyleNo1_ProductPicRight",
                          ).map((label, labelIndex) => {
                            if (label.type === "image") {
                              return (
                                <Image
                                  key={labelIndex}
                                  src={label.value}
                                  style={{
                                    height: pxtorem(40),
                                    width: pxtorem(40),
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                  }}
                                  fit="contain"></Image>
                              );
                            }
                            if (label.type === "text") {
                              return (
                                <div
                                  key={labelIndex}
                                  style={{
                                    height: pxtorem(16),
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                  }}>
                                  {label.value}
                                </div>
                              );
                            }
                          })}
                          <Image
                            width="100%"
                            height={props?.navigationType === "VERTICAL" ? pxtorem(145) : pxtorem(171)}
                            style={{
                              borderRadius: `${pxtorem(6)} ${pxtorem(6)} 0 0`,
                            }}
                            src={
                              item?.skuMainImageUrls?.[0]
                                ? formatImgUrl(item?.skuMainImageUrls?.[0], 171, 171)
                                : nullPng
                            }
                            onClick={() => goDetail(item, index)}></Image>
                        </div>
                        <div className={styles.content}>
                          {item?.salePropertyFirstList?.length > 1 && item?.showColorPanel && (
                            <div className={styles.style_list} onClick={e => skuPreview(e, item)}>
                              {item?.salePropertyFirstList
                                .slice(0, props?.navigationType === "VERTICAL" ? 3 : 4)
                                .map((style, styleIndex) => {
                                  return (
                                    <div className={styles.style_list_item} key={styleIndex}>
                                      <Image
                                        className={styles.item_img}
                                        src={
                                          style.picList?.[0]
                                            ? formatImgUrl(style.picList?.[0], 28, 28)
                                            : nullPng
                                        }
                                        style={{
                                          opacity: style.sellOut ? "0.1" : "1",
                                        }}></Image>
                                      {style.sellOut && (
                                        <div className={styles.item_sell_out}>售罄</div>
                                      )}
                                    </div>
                                  );
                                })}
                              {item?.salePropertyFirstList?.slice(0, 3)?.length > 0 && (
                                <div className={styles.style_list_item}>
                                  <div className={styles.item_font}>
                                    <div>{item?.salePropertyFirstList?.length}款</div>
                                    可选
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          <div
                            className={styles.price}
                            style={{
                              paddingTop:
                                item?.salePropertyFirstList?.length > 1 && item?.showColorPanel
                                  ? "0.16rem"
                                  : "0",
                            }}>
                            {(item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                              item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                              item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE") && (
                                <div className={styles.sec_kill}>
                                  {item?.skuCurrentPriceInfo?.promotionTypeName}
                                </div>
                              )}
                            <div className={styles.price_content}>
                              <div className={styles.price_real}>
                                <span className={styles.oldP}>¥</span>
                                <span className={styles.price_big}>
                                  {Math.trunc(item.skuCurrentPriceInfo?.marketingPrice)}
                                </span>
                                {getDecimal(item.skuCurrentPriceInfo?.marketingPrice)}
                                {/* <div
                              className={styles.decimal + " " + styles.oldP}
                              style={{ paddingBottom: pxtorem(1) }}
                            >
                              .
                              {Math.round(
                                (item.skuCurrentPriceInfo?.marketingPrice % 1) *
                                10
                              )}
                            </div> */}
                              </div>
                              {(item.skuCurrentPriceInfo?.tagPrice + "").length < 8 && (
                                <div className={styles.price_old}>
                                  <div className={styles.oldP}>¥</div>
                                  <div className={styles.oldP} style={{ paddingLeft: pxtorem(2) }}>
                                    {item.skuCurrentPriceInfo?.tagPrice}
                                  </div>
                                </div>
                              )}
                            </div>
                            {item?.skuCurrentPriceInfo?.promotionTimeStatus === 1 && (
                              <Image className={styles.advancePng} src={advancePng}></Image>
                            )}
                          </div>
                          <div className={styles.content_title}>
                            <div
                              className={styles.title_text}
                              style={{
                                textIndent:
                                  formatLabel(
                                    item?.labelLocationVOList,
                                    project() === 2
                                      ? "H5_Shop_DecorateSales_TwoColumnProductNameFront"
                                      : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                  )?.length > 0
                                    ? pxtorem(
                                      formatLabel(
                                        item?.labelLocationVOList,
                                        project() === 2
                                          ? "H5_Shop_DecorateSales_TwoColumnProductNameFront"
                                          : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                      )[0]?.width + 1 || 33,
                                    )
                                    : 0,
                              }}>
                              {item.title}
                            </div>
                            {formatLabel(
                              item?.labelLocationVOList,
                              project() === 2
                                ? "H5_Shop_DecorateSales_TwoColumnProductNameFront"
                                : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                            ).map((label, labelIndex) => {
                              if (label.type === "image") {
                                return (
                                  <Image
                                    key={labelIndex}
                                    src={label.value}
                                    className={styles.label_img}
                                    width={label.width}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                    }}
                                    fit="contain"></Image>
                                );
                              }
                              if (label.type === "text") {
                                return (
                                  <div
                                    key={labelIndex}
                                    style={{
                                      paddingRight: pxtorem(4),
                                    }}>
                                    {label.value}
                                  </div>
                                );
                              }
                            })}
                          </div>
                          {item?.skuExtendInfoVO?.categoryHotRankVO && (
                            <div className={styles.rank_label_wrapper + " " + styles.rank_label_wrapper_one2} onClick={(e) => { e.stopPropagation(); goRank(item); }}>
                              <div className={styles.rank_label_main}>
                                {item.skuExtendInfoVO.categoryHotRankVO.rankName}第 {item.skuExtendInfoVO.categoryHotRankVO.rankIng} 名
                              </div>
                            </div>
                          )}
                          {formatLabel(
                            item?.labelLocationVOList,
                            project() === 2
                              ? "H5_Shop_DecorateSales_TwoColumnProductNameDown"
                              : "H5_SuperMarketing_ProductStyleNo1_TitleDown",
                          )?.length > 0 && (
                              <div className={styles.content_label}>
                                <div className={styles.discount_label + " " + styles.overHidden}>
                                  {formatLabel(
                                    item?.labelLocationVOList,
                                    project() === 2
                                      ? "H5_Shop_DecorateSales_TwoColumnProductNameDown"
                                      : "H5_SuperMarketing_ProductStyleNo1_TitleDown",
                                  ).map((label, labelIndex) => {
                                    if (label.type === "image") {
                                      return (
                                        <Image
                                          className={styles.label_image}
                                          key={labelIndex}
                                          src={label.value}
                                          style={{
                                            height: pxtorem(16),
                                            width: pxtorem(label.width),
                                            paddingRight: pxtorem(4),
                                          }}></Image>
                                      );
                                    }
                                    if (label.type === "text") {
                                      return (
                                        <div
                                          key={labelIndex}
                                          style={{
                                            paddingRight: pxtorem(4),
                                          }}>
                                          {label.value}
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                                {item?.skuExtendInfoVO?.rewardCreditAmount > 0 && (
                                  <div className={styles.score}>
                                    <div className={styles.score_text}>
                                      至多积
                                      {item?.skuExtendInfoVO?.rewardCreditAmount}
                                    </div>
                                    <Image className={styles.img_score} src={scorePng}></Image>
                                  </div>
                                )}
                              </div>
                            )}
                          {item?.skuCurrentPriceInfo?.commission > 0 && (
                            <div className={styles.content_save_money}>
                              <div className={styles.price_real} style={{ alignItems: "baseline" }}>
                                <div>
                                  {platform.isApp
                                    ? "省赚"
                                    : platform.isTuan
                                      ? "推广费"
                                      : platform.isMini && !platform.isSaas
                                        ? "饷"
                                        : "赚"}
                                </div>
                                <div className={styles.symbol}>¥</div>
                                <div className={styles.price_big}>
                                  {Math.trunc(item?.skuCurrentPriceInfo?.commission)}
                                </div>
                                <div className={styles.decimal}>
                                  {getDecimal(item?.skuCurrentPriceInfo?.commission)}
                                </div>
                                {item?.skuCurrentPriceInfo?.commissionAddition > 0 && (
                                  <div className={styles.add}>
                                    +{item?.skuCurrentPriceInfo?.commissionAddition}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {!identity() && item.activityStatus !== 1 && (
                            <div className={styles.normal}>
                              <div
                                className={styles.buy_btn_normal}
                                onClick={e => forwardProduct(e, item)}
                                style={{ width: props.isNav ? "90%" : "" }}>
                                <Image src={moneyPng} className={styles.normal_img}></Image>
                                <div className={styles.buy_text}>立即转发</div>
                              </div>
                            </div>
                          )}
                          {!identity() && item.activityStatus === 1 && (
                            <div className={styles.normal}>
                              <div
                                className={styles.buy_btn_normal}
                                style={{
                                  background: "linear-gradient(270deg, #FD7921 0%, #FF6600 100%)",
                                }}
                                onClick={e => forwardProduct(e, item)}>
                                <Image src={moneyPng} className={styles.normal_img}></Image>
                                <div className={styles.buy_text}>立即转发</div>
                              </div>
                            </div>
                          )}
                          {identity() && item.activityStatus !== 1 && (
                            <div
                              className={styles.buy_btn}
                              onClick={() => goDetail(item, index, null, "btn")}>
                              <Image src={buyNowPng} className={styles.buy_now}></Image>
                              <div className={styles.buy_text}>立即购买</div>
                            </div>
                          )}
                          {identity() && item.activityStatus === 1 && (
                            <div
                              className={styles.buy_btn}
                              onClick={() => goDetail(item, index, null, "btn")}>
                              <Image src={buyNowPng} className={styles.buy_now}></Image>
                              <div className={styles.buy_text}>立即购买</div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            {dataInfo.layoutType === "13" && (
              <div className={styles.one3}>
                <Grid columns={3} gap={8}>
                  {dataInfo.dataInfo?.result.map((item, index) => {
                    return (
                      <Grid.Item key={index}>
                        <div
                          className={styles.one3_item}
                          id={`${props.propData.componentCode || props.propData.componentDetailCode
                            }-${item.activitySpuId}`}
                          data-param={JSON.stringify({
                            type: props.navType
                              ? props.navType === "normal"
                                ? "普通导航"
                                : "电梯导航"
                              : "商品",
                            content: "商品",
                            name: item.title,
                            id: item.activitySpuId,
                            picture: true,
                            hasSpecially: false,
                            activitySpuId: item.activitySpuId,
                            activityId: item.activityId,
                            resourceRank:
                              props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
                            relativeRank: index,
                            ...props.trackInfo,
                          })}
                          data-eventid={
                            (props.propData.componentCode || props.propData.componentDetailCode) +
                            "-" +
                            item.activitySpuId
                          }
                          onClick={() => goDetail(item, index)}>
                          <div className={styles.img}>
                            <Image
                              style={{
                                borderRadius: `${pxtorem(6)} ${pxtorem(6)} 0 0`,
                              }}
                              src={
                                item?.skuMainImageUrls?.[0]
                                  ? formatImgUrl(item?.skuMainImageUrls?.[0], 113, 113)
                                  : nullPng
                              }
                              className={styles.img_item}
                              onClick={() => goDetail(item, index)}></Image>
                            {item.spuSellOut && (
                              <div className={styles.sell_out} onClick={() => goDetail(item, index)}>
                                <div className={styles.sell_out_circle}>已售罄</div>
                              </div>
                            )}
                            {!item.spuSellOut &&
                              item.skuQuantity > 0 &&
                              item?.skuCurrentPriceInfo?.commission <= 0 && (
                                <div className={styles.quantity}>仅剩{item.skuQuantity}件</div>
                              )}
                            {item?.skuCurrentPriceInfo?.commission > 0 && (
                              <SaveMoney
                                platform={platform}
                                skuCurrentPriceInfo={item?.skuCurrentPriceInfo}
                              />
                            )}
                            {item?.skuExtendInfoVO?.rewardCreditAmount > 0 && (
                              <div className={styles.score}>
                                <div className={styles.score_text}>
                                  至多积
                                  {item?.skuExtendInfoVO?.rewardCreditAmount}
                                </div>
                                <Image className={styles.img_score} src={scorePng}></Image>
                              </div>
                            )}
                          </div>
                          <div className={styles.content}>
                            <div className={styles.price} style={{ position: "relative" }}>
                              {(item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                                item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                                item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE") && (
                                  <div className={styles.sec_kill}>
                                    {item?.skuCurrentPriceInfo?.promotionTypeName}
                                  </div>
                                )}
                              <div
                                className={styles.price_content}
                                style={{
                                  maxWidth:
                                    (item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                                      item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                                      item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE") &&
                                      Number(item.activityStatus) === 1
                                      ? pxtorem(48)
                                      : "unset",
                                }}>
                                <PriceCom skuCurrentPriceInfo={item?.skuCurrentPriceInfo} />
                                {(item?.skuCurrentPriceInfo?.promotionTimeStatus !== 1 ||
                                  !(
                                    item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                                    item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                                    item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE"
                                  )) && (
                                    <div className={styles.price_old}>
                                      ¥
                                      <span style={{ paddingLeft: pxtorem(2) }}>
                                        {item?.skuCurrentPriceInfo?.tagPrice}
                                      </span>
                                    </div>
                                  )}
                              </div>
                              {item?.skuCurrentPriceInfo?.promotionTimeStatus === 1 && (
                                <Image
                                  style={{ position: "absolute", right: 0 }}
                                  className={styles.advancePng13}
                                  src={advancePng13}></Image>
                              )}
                            </div>
                            <div className={styles.content_title}>
                              <div
                                className={styles.title_text}
                                style={{
                                  textIndent:
                                    formatLabel(
                                      item?.labelLocationVOList,
                                      project() === 2
                                        ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                        : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                    )?.length > 0
                                      ? pxtorem(
                                        formatLabel(
                                          item?.labelLocationVOList,
                                          project() === 2
                                            ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                            : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                        )[0]?.width + 1 || 33,
                                      )
                                      : 0,
                                  WebkitLineClamp:
                                    formatLabel(
                                      item?.labelLocationVOList,
                                      project() === 2
                                        ? "H5_Shop_DecorateSales_TwoColumnProductNameDown"
                                        : "H5_SuperMarketing_ProductStyleNo1_TitleDown",
                                    )?.length > 0
                                      ? 2
                                      : 3,
                                }}>
                                {item.title}
                              </div>
                              {formatLabel(
                                item?.labelLocationVOList,
                                project() === 2
                                  ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                  : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                              ).map((label, labelIndex) => {
                                if (label.type === "image") {
                                  return (
                                    <Image
                                      key={labelIndex}
                                      src={label.value}
                                      className={styles.label_img}
                                      style={{
                                        width: pxtorem(label.width),
                                        position: "absolute",
                                        top: pxtorem(0),
                                      }}
                                      fit="contain"></Image>
                                  );
                                }
                                if (label.type === "text") {
                                  return (
                                    <div
                                      key={labelIndex}
                                      style={{
                                        paddingRight: pxtorem(4),
                                      }}>
                                      {label.value}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <div
                              style={{
                                marginTop: pxtorem(6),
                                display: "flex",
                                flexWrap: "wrap",
                                height: pxtorem(16),
                                overflow: "hidden",
                              }}>
                              {formatLabel(
                                item?.labelLocationVOList,
                                project() === 2
                                  ? "H5_Shop_DecorateSales_ColumnProductNameDown"
                                  : "H5_SuperMarketing_ProductStyleNo1_TitleDown",
                              ).map((label, labelIndex) => {
                                if (label.type === "image") {
                                  return (
                                    <Image
                                      className={styles.label_image}
                                      key={labelIndex}
                                      src={label.value}
                                      style={{
                                        height: pxtorem(16),
                                        width: pxtorem(label.width),
                                        paddingRight: pxtorem(4),
                                      }}></Image>
                                  );
                                }
                                if (label.type === "text") {
                                  return (
                                    <div
                                      key={labelIndex}
                                      style={{
                                        paddingRight: pxtorem(4),
                                      }}>
                                      {label.value}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </Grid.Item>
                    );
                  })}
                </Grid>
              </div>
            )}
            {dataInfo.layoutType === "10" && dataInfo?.dataInfo?.result?.length > 0 && (
              <div
                className={styles.oneMore}
                style={{
                  background: dataInfo.backgroundColor,
                  flexDirection: dataInfo.imgStyle === "left" ? "row" : "column",
                }}>
                {dataInfo.hasBackgroundImg === 1 && (
                  <Image
                    className={dataInfo.imgStyle === "left" ? styles.back_img : styles.back_img_top}
                    src={formatImgUrl(dataInfo.backgroundImgUrl, 90, 169)}
                    fit="cover"></Image>
                )}
                <div
                  className={styles.oneMore_content}
                  style={{
                    padding: `${pxtorem(6)} 0 ${pxtorem(6)} 0`,
                  }}
                  ref={myRef}
                  onScroll={onScroll}>
                  <div className={styles.content_body}>
                    {dataInfo?.dataInfo?.result?.map((item, index) => {
                      return (
                        <div
                          className={styles.oneMore_content_item}
                          style={{
                            width: pxtorem(112),
                            marginLeft:
                              index === 0
                                ? dataInfo.hasBackgroundImg === 1
                                  ? pxtorem(6)
                                  : 0
                                : pxtorem(6),
                          }}
                          key={index}
                          id={`${props.propData.componentCode || props.propData.componentDetailCode
                            }-${item.activitySpuId}`}
                          data-param={JSON.stringify({
                            type: props.navType
                              ? props.navType === "normal"
                                ? "普通导航"
                                : "电梯导航"
                              : "商品",
                            content: "商品",
                            name: item.title,
                            id: item.activitySpuId,
                            picture: true,
                            hasSpecially: false,
                            activitySpuId: item.activitySpuId,
                            activityId: item.activityId,
                            resourceRank:
                              props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
                            relativeRank: index,
                            ...props.trackInfo,
                          })}
                          data-eventid={
                            (props.propData.componentCode || props.propData.componentDetailCode) +
                            "-" +
                            item.activitySpuId
                          }
                          onClick={() => goDetail(item, index)}>
                          <div
                            style={{
                              position: "relative",
                              width: pxtorem(112),
                              height: pxtorem(112),
                            }}>
                            <Image
                              style={{
                                borderRadius: `${pxtorem(6)} ${pxtorem(6)} 0 0`,
                              }}
                              src={
                                item?.skuMainImageUrls?.[0]
                                  ? formatImgUrl(item?.skuMainImageUrls?.[0], 112, 112)
                                  : nullPng
                              }
                              width={pxtorem(112)}
                              height={pxtorem(112)}
                              className={styles.img_item}
                              onClick={() => goDetail(item, index)}></Image>
                            {item.spuSellOut && (
                              <div className={styles.sell_out} onClick={() => goDetail(item, index)}>
                                <div className={styles.sell_out_circle}>已售罄</div>
                              </div>
                            )}
                            {!item.spuSellOut &&
                              item.skuQuantity > 0 &&
                              item?.skuCurrentPriceInfo?.commission <= 0 && (
                                <div className={styles.quantity}>仅剩{item.skuQuantity}件</div>
                              )}
                            {item?.skuCurrentPriceInfo?.commission > 0 && (
                              <SaveMoney
                                platform={platform}
                                skuCurrentPriceInfo={item?.skuCurrentPriceInfo}
                              />
                            )}
                            {item?.skuExtendInfoVO?.rewardCreditAmount > 0 && (
                              <div className={styles.score}>
                                <div className={styles.score_text}>
                                  至多积
                                  {item?.skuExtendInfoVO?.rewardCreditAmount}
                                </div>
                                <Image className={styles.img_score} src={scorePng}></Image>
                              </div>
                            )}
                          </div>
                          <div className={styles.content}>
                            <div className={styles.price} style={{ position: "relative" }}>
                              {(item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                                item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                                item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE") && (
                                  <div className={styles.sec_kill}>
                                    {item?.skuCurrentPriceInfo?.promotionTypeName}
                                  </div>
                                )}
                              <div
                                className={styles.price_content}
                                style={{
                                  maxWidth:
                                    (item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                                      item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                                      item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE") &&
                                      Number(item.activityStatus) === 1
                                      ? pxtorem(48)
                                      : "unset",
                                }}>
                                <PriceCom skuCurrentPriceInfo={item?.skuCurrentPriceInfo} />
                                {(item?.skuCurrentPriceInfo?.promotionTimeStatus !== 1 ||
                                  !(
                                    item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                                    item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                                    item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE"
                                  )) && (
                                    <div className={styles.price_old}>
                                      ¥
                                      <span style={{ paddingLeft: pxtorem(2) }}>
                                        {item?.skuCurrentPriceInfo?.tagPrice}
                                      </span>
                                    </div>
                                  )}
                              </div>
                              {item?.skuCurrentPriceInfo?.promotionTimeStatus === 1 && (
                                <Image
                                  style={{ position: "absolute", right: 0 }}
                                  className={styles.advancePng13}
                                  src={advancePng13}></Image>
                              )}
                            </div>
                            <div className={styles.content_title}>
                              <div
                                className={styles.title_text}
                                style={{
                                  textIndent:
                                    formatLabel(
                                      item?.labelLocationVOList,
                                      project() === 2
                                        ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                        : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                    )?.length > 0
                                      ? pxtorem(
                                        formatLabel(
                                          item?.labelLocationVOList,
                                          project() === 2
                                            ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                            : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                        )[0]?.width + 1 || 33,
                                      )
                                      : 0,
                                  WebkitLineClamp:
                                    formatLabel(
                                      item?.labelLocationVOList,
                                      project() === 2
                                        ? "H5_Shop_DecorateSales_ColumnProductNameDown"
                                        : "H5_SuperMarketing_ProductStyleNo1_TitleDown",
                                    ).length > 0
                                      ? 2
                                      : 3,
                                }}>
                                {item.title}
                              </div>
                              {formatLabel(
                                item?.labelLocationVOList,
                                project() === 2
                                  ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                  : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                              ).map((label, labelIndex) => {
                                if (label.type === "image") {
                                  return (
                                    <Image
                                      key={labelIndex}
                                      src={label.value}
                                      className={styles.label_img}
                                      style={{
                                        width: pxtorem(label.width),
                                        position: "absolute",
                                        top: pxtorem(0),
                                      }}
                                      fit="contain"></Image>
                                  );
                                }
                                if (label.type === "text") {
                                  return (
                                    <div
                                      key={labelIndex}
                                      style={{
                                        paddingRight: pxtorem(4),
                                      }}>
                                      {label.value}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <div
                              style={{
                                marginTop: pxtorem(6),
                                display: "flex",
                                flexWrap: "wrap",
                                height: pxtorem(16),
                                overflow: "hidden",
                              }}>
                              {formatLabel(
                                item?.labelLocationVOList,
                                project() === 2
                                  ? "H5_Shop_DecorateSales_ColumnProductNameDown"
                                  : "H5_SuperMarketing_ProductStyleNo1_TitleDown",
                              ).map((label, labelIndex) => {
                                if (label.type === "image") {
                                  return (
                                    <Image
                                      className={styles.label_image}
                                      key={labelIndex}
                                      src={label.value}
                                      style={{
                                        height: pxtorem(16),
                                        width: pxtorem(label.width),
                                        paddingRight: pxtorem(4),
                                      }}></Image>
                                  );
                                }
                                if (label.type === "text") {
                                  return (
                                    <div
                                      key={labelIndex}
                                      style={{
                                        paddingRight: pxtorem(4),
                                      }}>
                                      {label.value}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <InfiniteScrollContent hasMore={dataInfo.dataInfo?.hasNext} />
                </div>
              </div>
            )}
            {dataInfo.layoutType === "20" && dataInfo?.dataInfo?.result?.length > 0 && (
              <div
                className={
                  styles.twoMore + " " + (dataInfo.imgStyle === "top" ? styles.topHeight : "")
                }
                style={{
                  background: dataInfo.backgroundColor,
                  flexDirection: dataInfo.imgStyle === "left" ? "row" : "column",
                }}>
                {dataInfo.hasBackgroundImg === 1 && (
                  <Image
                    className={dataInfo.imgStyle === "left" ? styles.back_img : styles.back_img_top}
                    src={formatImgUrl(dataInfo.backgroundImgUrl, 90, 331)}
                    fit="cover"></Image>
                )}
                <div className={styles.twoMore_content} ref={myRef} onScroll={onScroll}>
                  {dataInfo?.dataInfo?.result?.map((item, index) => {
                    return (
                      <div
                        className={styles.twoMore_content_item}
                        key={index}
                        id={`${props.propData.componentCode || props.propData.componentDetailCode}-${item.activitySpuId
                          }`}
                        data-param={JSON.stringify({
                          type: props.navType
                            ? props.navType === "normal"
                              ? "普通导航"
                              : "电梯导航"
                            : "商品",
                          content: "商品",
                          name: item.title,
                          id: item.activitySpuId,
                          picture: true,
                          hasSpecially: true,
                          activitySpuId: item.activitySpuId,
                          activityId: item.activityId,
                          resourceRank:
                            props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
                          relativeRank: index,
                          ...props.trackInfo,
                        })}
                        data-eventid={
                          (props.propData.componentCode || props.propData.componentDetailCode) +
                          "-" +
                          item.activitySpuId
                        }
                        onClick={() => goDetail(item, index)}>
                        <div
                          style={{
                            position: "relative",
                            width: pxtorem(112),
                            height: pxtorem(112),
                          }}>
                          <Image
                            style={{
                              borderRadius: `${pxtorem(6)} ${pxtorem(6)} 0 0`,
                            }}
                            src={item?.skuMainImageUrls?.[0] || nullPng}
                            className={styles.img_item}
                            onClick={() => goDetail(item, index)}></Image>
                          {item.spuSellOut && (
                            <div className={styles.sell_out} onClick={() => goDetail(item, index)}>
                              <div className={styles.sell_out_circle}>已售罄</div>
                            </div>
                          )}
                          {!item.spuSellOut &&
                            item.skuQuantity > 0 &&
                            item?.skuCurrentPriceInfo?.commission <= 0 && (
                              <div className={styles.quantity}>仅剩{item.skuQuantity}件</div>
                            )}
                          {item?.skuCurrentPriceInfo?.commission > 0 && (
                            <SaveMoney
                              platform={platform}
                              skuCurrentPriceInfo={item?.skuCurrentPriceInfo}
                            />
                          )}
                          {item?.skuExtendInfoVO?.rewardCreditAmount > 0 && (
                            <div className={styles.score}>
                              <div className={styles.score_text}>
                                至多积
                                {item?.skuExtendInfoVO?.rewardCreditAmount}
                              </div>
                              <Image className={styles.img_score} src={scorePng}></Image>
                            </div>
                          )}
                        </div>
                        <div className={styles.content}>
                          <div className={styles.price} style={{ position: "relative" }}>
                            {(item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                              item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                              item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE") && (
                                <div className={styles.sec_kill}>
                                  {item?.skuCurrentPriceInfo?.promotionTypeName}
                                </div>
                              )}
                            <div
                              className={styles.price_content}
                              style={{
                                maxWidth:
                                  (item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                                    item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                                    item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE") &&
                                    Number(item.activityStatus) === 1
                                    ? pxtorem(48)
                                    : "unset",
                              }}>
                              <PriceCom skuCurrentPriceInfo={item?.skuCurrentPriceInfo} />
                              {(item?.skuCurrentPriceInfo?.promotionTimeStatus !== 1 ||
                                !(
                                  item?.skuCurrentPriceInfo?.promotionType === "SECKILL" ||
                                  item?.skuCurrentPriceInfo?.promotionType === "FIXED_PRICE" ||
                                  item?.skuCurrentPriceInfo?.promotionType === "SCHEDULE"
                                )) && (
                                  <div
                                    className={styles.price_old}
                                    style={{ paddingBottom: pxtorem(1) }}>
                                    ¥
                                    <span style={{ paddingLeft: pxtorem(2) }}>
                                      {item?.skuCurrentPriceInfo?.tagPrice}
                                    </span>
                                  </div>
                                )}
                            </div>
                            {item?.skuCurrentPriceInfo?.promotionTimeStatus === 1 && (
                              <Image
                                style={{ position: "absolute", right: 0 }}
                                className={styles.advancePng}
                                src={advancePng}></Image>
                            )}
                          </div>
                          <div className={styles.content_title}>
                            <div
                              className={styles.title_text}
                              style={{
                                textIndent:
                                  formatLabel(
                                    item?.labelLocationVOList,
                                    project() === 2
                                      ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                      : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                  )?.length > 0
                                    ? pxtorem(
                                      formatLabel(
                                        item?.labelLocationVOList,
                                        project() === 2
                                          ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                          : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                                      )[0]?.width + 1 || 33,
                                    )
                                    : 0,
                                WebkitLineClamp:
                                  formatLabel(
                                    item?.labelLocationVOList,
                                    project() === 2
                                      ? "H5_Shop_DecorateSales_ColumnProductNameDown"
                                      : "H5_SuperMarketing_ProductStyleNo1_TitleDown",
                                  ).length > 0
                                    ? 2
                                    : 3,
                              }}>
                              {item.title}
                            </div>
                            {formatLabel(
                              item?.labelLocationVOList,
                              project() === 2
                                ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                : "H5_SuperMarketing_ProductStyleNo1_TitleFront",
                            ).map((label, labelIndex) => {
                              if (label.type === "image") {
                                return (
                                  <Image
                                    key={labelIndex}
                                    src={label.value}
                                    className={styles.label_img}
                                    style={{
                                      width: pxtorem(label.width),
                                      position: "absolute",
                                      top: pxtorem(0),
                                    }}
                                    fit="contain"></Image>
                                );
                              }
                              if (label.type === "text") {
                                return (
                                  <div
                                    key={labelIndex}
                                    style={{
                                      paddingRight: pxtorem(4),
                                    }}>
                                    {label.value}
                                  </div>
                                );
                              }
                            })}
                          </div>
                          <div
                            style={{
                              marginTop: pxtorem(6),
                              display: "flex",
                              flexWrap: "wrap",
                              height: pxtorem(16),
                              overflow: "hidden",
                            }}>
                            {formatLabel(
                              item?.labelLocationVOList,
                              project() === 2
                                ? "H5_Shop_DecorateSales_ColumnProductNameDown"
                                : "H5_SuperMarketing_ProductStyleNo1_TitleDown",
                            ).map((label, labelIndex) => {
                              if (label.type === "image") {
                                return (
                                  <Image
                                    className={styles.label_image}
                                    key={labelIndex}
                                    src={label.value}
                                    style={{
                                      height: pxtorem(16),
                                      width: pxtorem(label.width),
                                      paddingRight: pxtorem(4),
                                    }}></Image>
                                );
                              }
                              if (label.type === "text") {
                                return (
                                  <div
                                    key={labelIndex}
                                    style={{
                                      paddingRight: pxtorem(3),
                                    }}>
                                    {label.value}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <InfiniteScrollContent hasMore={dataInfo.dataInfo?.hasNext} />
                </div>
              </div>
            )}
          </div>
        )}
      {dataInfo?.animationType === "2" && dataInfo?.dataInfo?.result?.length > 4 && (
        <div
          className="swiper_pro"
          style={{
            margin: `${pxtorem(dataInfo.paddingTop)} 0 ${pxtorem(dataInfo.paddingBottom)} 0`,
          }}>
          <Swiper
            spaceBetween={10}
            slidesPerView={
              (dataInfo?.dataInfo?.result?.length ?? 0) >= 4
                ? 4
                : (dataInfo?.dataInfo?.result?.length ?? 0)
            }
            loop
            modules={[Autoplay]}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            centeredSlides={true}
            centeredSlidesBounds={true}
            onSlideChange={slideChange}
            id={dataInfo.componentCode}>
            {dataInfo?.dataInfo?.result?.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="swiper_pro_item"
                  style={{
                    background: colorList[index % 5],
                    // translate: pxtorem(index * 0),
                  }}>
                  <div
                    className="item_img"
                    style={{
                      background: `url(${item?.skuMainImageUrls?.[0]
                        ? formatImgUrl(item?.skuMainImageUrls?.[0], 120, 120)
                        : nullPng
                        }) no-repeat`,
                      backgroundSize: "cover",
                    }}
                    id={`${props.propData.componentCode || props.propData.componentDetailCode}-${item.activitySpuId
                      }`}
                    data-param={JSON.stringify({
                      type: props.navType
                        ? props.navType === "normal"
                          ? "普通导航"
                          : "电梯导航"
                        : "商品",
                      content: "商品",
                      name: item.title,
                      id: item.activitySpuId,
                      picture: true,
                      hasSpecially: true,
                      activitySpuId: item.activitySpuId,
                      activityId: item.activityId,
                      resourceRank:
                        props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
                      relativeRank: index,
                      ...props.trackInfo,
                    })}
                    data-eventid={
                      (props.propData.componentCode || props.propData.componentDetailCode) +
                      "-" +
                      item.activitySpuId
                    }
                    onClick={() => goDetail(item, index)}></div>
                  <div className="price_real">
                    <div className="symbol">¥</div>
                    <div className="price_big" style={{ paddingBottom: pxtorem(1) }}>
                      {Math.trunc(item?.skuCurrentPriceInfo?.marketingPrice)}
                    </div>
                    {getDecimal(item.skuCurrentPriceInfo?.marketingPrice)}
                  </div>
                </SwiperSlide>
              );
            })}
            {sliderSupplement(dataInfo.dataInfo)}
          </Swiper>
        </div>
      )}
      {dataInfo.dataInfo?.hasNext &&
        dataInfo.layoutType !== "10" &&
        dataInfo.layoutType !== "20" &&
        !props.last &&
        (isEmpty(dataInfo.animationType) ||
          dataInfo.animationType === null ||
          dataInfo.animationType === undefined ||
          dataInfo.animationType === "1") && (
          <div className={styles.more}>
            <div className={styles.content} onClick={props.loadMore}>
              <div className={styles.text}>查看更多</div>
              <Image src={morePng} width={pxtorem(10)} height={pxtorem(10)} fit="fill" />
            </div>
          </div>
        )}
      {/* 海报 */}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        customEditData={customEditData}
        isOTC={isOTC}
        shareType="PRODUCT"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}></PosterSwipe>
      {/* 选色卡 */}
      <SizePopup
        product={sizePopupProduct}
        show={showSizePopup}
        close={onCloseSizePopup}
        toDetailByFirstProp={(product, salePropertyFirstListPicItem) =>
          goDetail(product, proIndex, salePropertyFirstListPicItem, "size")
        }
      />
    </>
  );
};

export default inject("appStore")(observer(ProductCom));
