import { getCartTotal } from "@/api";
import { SkinInfo } from "@/api/decorate";
import { queryBindCheck, UserVisitInfo, visitorInfo } from "@/api/member";
import { exchangeUserRole, queryUserInfo } from "@/api/user";
import { getGZHInfo, GZHInfo } from "@/api/wechat";
import { SubAppEventName, useSubAppMessage } from "@/utils/hooks/useSubAppMessage";
import Track from "@/utils/track";
import Cookie from "js-cookie";
import React from "react";
import { createContext, useActivate } from "react-activation";
import EventManager from "../event/eventManager";
import { getUserRole } from "../identity";
import { platform } from "../platform";
import { urlQueryObject } from "../url-parse";

interface TrackProps {
  init?(config: any, superProperties: any): void;
  registerSuperProperty?(superProperties: any): void;
  alias?(user: any): void;
  pageName?(): string | undefined;
  track?(eventName: any, props?: any): void;
  pageView?(pageName: string, props?: any): void;
}

export type Role = "R" | "E" | "C" | "A";
export interface ContextProps {
  track: TrackProps;
  eventManager: EventManager;
  isExternal: boolean; // 是否是外部场景(三方 App 嵌入场景)
  userVisitInfo?: UserVisitInfo;
  reloadUserVisitInfo: () => void;
  skin: SkinInfo;
  currentRole: Role;
  gzhInfo?: GZHInfo;
  cartNum?: number;
  setCartNum: (num?: number) => Promise<number>;
  setGzhInfo: (info?: GZHInfo) => void;
  setSkin: (info: any) => void;
}
export const CommonContext = createContext<ContextProps>({
  track: {},
  eventManager: new EventManager(),
  isExternal: false,
  userVisitInfo: undefined,
  reloadUserVisitInfo: () => undefined,
  skin: {},
  currentRole: getUserRole(),
  gzhInfo: {},
  cartNum: 0,
  setCartNum: () => Promise.resolve(0),
  setGzhInfo: () => undefined,
  setSkin: () => undefined,
});

export const useCommonContext = () => React.useContext(CommonContext);

const track = Track();
const eventManager = new EventManager();
const config = {
  appkey: process.env.REACT_APP_ANALYS_APPKEY,
  debugMode: parseInt(process.env.REACT_APP_ANALYS_DEBUGMODE as any, 10),
  uploadURL: process.env.REACT_APP_ANALYS_UPLOAD_URL,
  ABkey: process.env.REACT_APP_AB_APPKEY,
};
const urlParams: any = urlQueryObject();
const msUserCode = Cookie.get("msUserCode");
const AliasCache = localStorage.getItem("ALIASCACHE");
const AliasCacheCookie = Cookie.get("ALIASCACHE");
const visitorStatus: any = Cookie.get("visitorStatus");
const visitorId = Cookie.get("visitorId");
let userId = msUserCode || AliasCache || AliasCacheCookie;
if (Number(visitorStatus) !== 0 && visitorId) {
  // 游客
  userId = visitorId;
}
const {
  shopId,
  distributorId,
  ud: shareId,
  ut: shareRole,
  t,
  channel,
  page_name,
  shortCode,
  sessionId,
} = urlParams || {};

if (!shopId || shopId === "null" || shopId === "undefined") {
  // TODO 跳到404页面/兜底逻辑
} else {
  localStorage.setItem("shopId", shopId);
}

if (!distributorId || distributorId === "null" || distributorId === "undefined") {
  localStorage.removeItem("distributorId");
} else {
  localStorage.setItem("distributorId", distributorId);
}

const superProperties = {
  $s_code: Cookie.get("shortCode") || shortCode,
  previous_page_name: page_name ? decodeURIComponent(page_name) : page_name,
  shop_id: shopId, // 店铺ID
  serverId: distributorId, // 服务者ID
  serverRole: distributorId ? "3" : "2", // 店长3 店主2
  shareId, // 分享者ID
  shareRole, // 分享者角色
  t,
  source: channel,
  common_id: new Date().getTime().toString(), // 通用属性的唯一id（结合urerId作为唯一标识）
  auth_state: visitorStatus ? Number(visitorStatus) : undefined,
  userID: userId,
  user_id_source: (msUserCode && "0") || (AliasCache && "1") || (AliasCacheCookie && "2") || "-1",
};
if (
  msUserCode &&
  (!(AliasCache && AliasCacheCookie) || ![AliasCache, AliasCacheCookie].includes(msUserCode))
) {
  localStorage.setItem("ALIASCACHE", msUserCode);
  Cookie.set("ALIASCACHE", msUserCode, { expires: 365 });
  track.alias(userId);
}
if (platform.isH5) {
  superProperties["client"] = "h5";
}
if (platform.isMini) {
  track.alias(urlParams.userId);
  superProperties["client"] = "miniapp";
  superProperties["$session_id"] = sessionId;
}
if (platform.isSaas) {
  track.alias(urlParams.userId);
  superProperties["client"] = "other_fm";
  superProperties["$session_id"] = sessionId;
  config.appkey = process.env.REACT_APP_SAAS_ANALYS_APPKEY;
  config.ABkey = process.env.REACT_APP_SAAS_AB_APPKEY;
}

track.init(config, superProperties);

export const CommonContextProvider = ({ children }: { children: React.ReactNode; }) => {
  const [isExternal, setExternal] = React.useState(false);
  const [userVisitInfo, setUserVisitInfo] = React.useState<UserVisitInfo>();
  const [skin, setSkin] = React.useState<any>({});
  const [gzhInfo, setGzhInfo] = React.useState<GZHInfo>();
  const [currentRole, setCurrentRole] = React.useState<Role>("C");
  const [cartNum, setTotalNum] = React.useState(0);

  const setCartNum = async (num?: number) => {
    if (typeof num === "number") {
      setTotalNum(num);
      return num;
    }
    try {
      const res = await getCartTotal({ shopBizCode: shopId });
      const { cartSkuNum = 0 } = res?.data || {};
      setTotalNum(cartSkuNum);
      return cartSkuNum;
    } catch (e) {
      console.error(e);
      setTotalNum(0);
      return 0;
    }
  };

  const reloadUserVisitInfo = () => {
    queryBindCheck().then(data => {
      setUserVisitInfo(data);
    });
  };
  // 监听子应用修改购物车数量
  useSubAppMessage(SubAppEventName.updateCartNum, setCartNum);
  // 初始化数据
  React.useEffect(() => {
    const shopId = localStorage.getItem("shopId");
    if (!shopId) {
      return;
    }
    // 初始化用户信息
    queryUserInfo().then(data => {
      setCurrentRole(exchangeUserRole(data?.currentRole));
    });
    // 初始化公众号信息
    getGZHInfo().then(data => {
      setGzhInfo(data);
    });
    // 初始化 visitorInfo
    visitorInfo().then((data) => {
      // 第三方内嵌饷店场景不支持分享
      setExternal(data?.thirdLoginScene === 2);
    });
    reloadUserVisitInfo();
  }, []);

  useActivate(() => {
    reloadUserVisitInfo();
  });

  return (
    <CommonContext.Provider
      value={{
        track,
        eventManager,
        isExternal,
        userVisitInfo,
        reloadUserVisitInfo,
        skin,
        currentRole,
        gzhInfo,
        cartNum,
        setCartNum: setCartNum,
        setGzhInfo: setGzhInfo,
        setSkin,
      }}>
      {children}
    </CommonContext.Provider>
  );
};
