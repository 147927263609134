import {
  logicalDeleteServ,
  queryGiftAddrPaginationServ,
  saveOrUpdateAddressServ,
} from "@/api/appH5";
import { generateSharePoster } from "@/api/scode/poster";
import loadingPng from "@/base64";
import Bottom from "@/components/bottom/index";
import PosterSwipe from "@/components/poster-swiper";
import { sharePosterParams4H5B2RGift, useActions } from "@/utils/actions";
import { countDown } from "@/utils/date-format";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { Image, InfiniteScroll, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import AddressItem from "./AddressItem";
import styles from "./AddressList.module.less";
import { showPromoterDialog } from "./Follow/toFollow";

interface Props {
  redemptionDeadlineTime: string;
  expectedAwardAmount: number;
  giftImageUrl: string;
  giftName: string;
}

const AddressList: React.FC<Props> = ({
  redemptionDeadlineTime, // 兑换截止时间
  expectedAwardAmount, // 预估奖励金额、奖品数量
  giftImageUrl,
  giftName,
}) => {
  const { isApp, isH5 } = platform;

  const track = Track();

  const [search] = useSearchParams();
  const marketingId = search.get("actId") || "";

  const [collectedQuantity, setCollectedQuantity] = useState(0);
  const [addressList, setAddressList] = useState<any[]>([]);
  const [addressActiveIndex, setAddressActiveIndex] = useState(0);
  let [pageIndex, setPageIndex] = useState(1);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  // 转发卡片（修改文案）需要的参数
  const [sharePosterData, setSharePosterData] = useState<any>({});
  // 倒计时对象
  const [countDownObj, setCountDownObj] = useState<any>({});
  const [timeId, setTimeId] = useState<any>("");
  const { shareB2RGiftPosterAction } = useActions();

  const trackResourceClick = trackData => {
    track.track("resource_click", {
      page_name: "满赠活动详情",
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
      tab_name: "中奖查询",
      resource_type: "中奖地址",
      // resource_name: "",
      // resource_content: "",
      // resource_rank: 0,
      resource_id: search.get("actId"),
      // merchant_shop_code: "",
      // activity_id: "",
      // activity_spu_code: "",
      btn_name: "添加收货地址",
      ...(trackData || {}),
    });
  };

  const getAddressList = async (obj = {}) => {
    setLoading(true);
    let params = {
      marketingId,
      pageIndex,
      pageSize: 10,
      ...obj,
    };
    const res = await queryGiftAddrPaginationServ(params);
    if (res) {
      const { result, extra, pages } = res || {};
      setCollectedQuantity(extra?.collectedQuantity);
      // eslint-disable-next-line no-underscore-dangle
      const _result = (result || []).map(item => {
        item.addressId = item.id;
        return item;
      });
      // eslint-disable-next-line no-underscore-dangle
      const _list = params.pageIndex === 1 ? _result : addressList.concat(_result);
      setAddressList(_list);
      setPageIndex(params.pageIndex + 1);
      setHasNext(pages > params.pageIndex);
      setLoading(false);
      if (params.pageIndex === 1 && _result.length === 0 && expectedAwardAmount > 0) {
        const res = await saveOrUpdateAddressServ({
          marketingId,
          awardQuantity: expectedAwardAmount || 1,
          isApp,
          isListPageSubmit: true,
        });
        const { code, success, message = "" } = res || {};
        if (code === 0 && success) {
          getAddressList({ pageIndex: 1 });
        } else {
          Toast.show({
            content: message,
            maskClickable: false,
            maskStyle: { zIndex: 9999 },
          });
        }
      }
    } else {
      setLoading(false);
    }
  };

  const onUpdateAddress = ({ item, index }) => {
    const { addressId, baseAddressId, awardQuantity } = item;
    if (!(marketingId && addressId && awardQuantity)) {
      return;
    }
    saveOrUpdateAddressServ({
      marketingId,
      addressId,
      baseAddressId,
      awardQuantity, // 奖品数量
      isApp,
    }).then(res => {
      const { code, success, data, message = "" } = res || {};
      if (code === 0 && success) {
        setCollectedQuantity(data?.collectedQuantity || 0);
        setAddressList(
          addressList.map(item =>
            item.addressId === addressId ? { ...data, addressId: data.id } : item,
          ),
        );
      } else {
        Toast.show({
          content: message,
          maskClickable: false,
          maskStyle: { zIndex: 9999 },
        });
      }
    });
  };

  const onDeleteAddress = ({ item, index }) => {
    trackResourceClick({
      btn_name: "删除地址",
    });
    const { addressId } = item || {};
    if (!addressId) {
      return;
    }
    logicalDeleteServ({
      addressId,
    }).then(res => {
      const { code, success, message = "" } = res || {};
      if (code === 0 && success) {
        Toast.show({
          content: "删除成功",
          maskClickable: false,
          maskStyle: { zIndex: 9999 },
        });
        getAddressList({ pageIndex: 1 });
      } else {
        Toast.show({
          content: message,
          maskClickable: false,
          maskStyle: { zIndex: 9999 },
        });
      }
    });
  };

  const onRefreshAddress = async ({ item, index }) => {
    const { addressId } = item || {};
    const res = await queryGiftAddrPaginationServ({
      marketingId,
      addressId,
    });
    const { result, extra } = res || {};
    if (result?.length) {
      setAddressList(
        addressList.map(ite =>
          ite.addressId === addressId ? { ...result[0], addressId: result[0].id } : ite,
        ),
      );
    }
    setCollectedQuantity(extra?.collectedQuantity);
  };

  const onChangeQuantity = ({ item, index }) => {
    const { addressId, awardQuantity, oldAwardQuantity } = item || {};
    saveOrUpdateAddressServ({
      marketingId,
      addressId,
      awardQuantity,
      isApp,
    }).then(res => {
      const { code, success, data, message = "" } = res || {};
      if (code === 0 && success) {
        setCollectedQuantity(data?.collectedQuantity || 0);
        const list = addressList.map(item =>
          item.id === addressId ? { ...item, awardQuantity: data.awardQuantity } : item,
        );
        setAddressList(list);
        Toast.show({
          content: "修改成功",
          maskClickable: false,
          maskStyle: { zIndex: 9999 },
        });
      } else {
        const list = addressList.map(item =>
          item.id === addressId ? { ...item, awardQuantity: oldAwardQuantity } : item,
        );
        setAddressList(list);
        Toast.show({
          content: message,
          maskClickable: false,
          maskStyle: { zIndex: 9999 },
        });
      }
    });
  };

  const onAddAddress = async awardQuantity => {
    trackResourceClick({
      btn_name: "添加收货地址",
    });
    const res = await saveOrUpdateAddressServ({
      marketingId,
      awardQuantity: awardQuantity || 1,
      isApp,
      isListPageSubmit: true,
    });
    const { code, success, data, message = "" } = res || {};
    if (code === 0 && success) {
      // setAddressList([
      //   {
      //     ...data,
      //     addressId: data.id,
      //   },
      //   ...addressList,
      // ]);
      getAddressList({ pageIndex: 1 });
    } else {
      Toast.show({
        content: message,
        maskClickable: false,
        maskStyle: { zIndex: 9999 },
      });
    }
  };

  const onSelectAddress = async ({ item, index }) => {
    trackResourceClick({
      btn_name: "选择收货地址",
    });
  };

  const loadMore = async () => {
    if (hasNext && !loading) {
      getAddressList();
    }
  };

  const getAppAddressPoster = async posterData => {
    const res = await generateSharePoster(posterData);
    return res?.data?.posterInfos?.[0]?.imageUrl;
  };

  const onShare = async ({ item, index }) => {
    trackResourceClick({
      btn_name: "分享赠品",
    });
    if (isH5 || isApp) {
      const posterData = await sharePosterParams4H5B2RGift({
        contentId: marketingId,
        contentDetailId: item.id,
        awardName: giftName,
        awardImage: giftImageUrl,
      });

      if (isH5) {
        setSharePosterData(posterData);
        setShowPoster(true);
      } else {
        const imgUrl = await getAppAddressPoster(posterData);

        shareB2RGiftPosterAction({
          contentId: marketingId,
          contentDetailId: item.id,
          awardName: giftName,
          awardImage: giftImageUrl,
          imgUrl,
        });
      }
    } else {
      shareB2RGiftPosterAction({
        contentId: marketingId,
        contentDetailId: item.id,
        awardName: giftName,
        awardImage: giftImageUrl,
      });
    }
  };

  const onClosePoster = () => {
    setShowPoster(false);
    document.body.style.overflow = "auto";
  };

  const countDownDom = () => {
    if (countDownObj.day) {
      return (
        <>
          <span className={styles["block"]}>{countDownObj.day}</span>天
          <span className={styles["block"]}>{countDownObj.hou}</span>时
        </>
      );
    } else if (countDownObj.hou >= 1 && countDownObj.hou <= 24) {
      return (
        <>
          <span className={styles["block"]}>{countDownObj.hou}</span>时
          <span className={styles["block"]}>{countDownObj.min}</span>分
        </>
      );
    } else if (countDownObj.hou <= 1) {
      return (
        <>
          <span className={styles["block"]}>{countDownObj.min}</span>:
          <span className={styles["block"]}>{countDownObj.ms}</span>
        </>
      );
    }
  };

  const handleReplaceState = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = url.searchParams;
    searchParams.set("addressId", "");
    const newUrl = url.toString();
    history.replaceState(null, document.title, newUrl);
  };

  const handleInitUpdateAddress = async () => {
    const res = await saveOrUpdateAddressServ({
      marketingId: search.get("actId"),
      addressId: search.get("addressOnlyId"),
      baseAddressId: search.get("addressId"),
      awardQuantity: Number(search.get("awardQuantity") || 1),
    });
    const { code, success, message = "" } = res || {};
    if (code === 0 && success) {
      Toast.show({
        content: "地址已更新",
        maskClickable: false,
        maskStyle: { zIndex: 9999 },
      });
    } else {
      Toast.show({
        content: message,
        maskClickable: false,
        maskStyle: { zIndex: 9999 },
      });
    }
    getAddressList({ pageIndex: 1 });
    handleReplaceState();
  };

  useEffect(() => {
    if (search.get("addressId")) {
      handleInitUpdateAddress();
    } else {
      getAddressList({ pageIndex: 1 });
    }
    if (timeId) {
      clearInterval(timeId);
    }
    setTimeId(
      setInterval(() => {
        // 活动状态 [NOT_BEGIN-未开始, ADVANCE-预告中，BEGIN-已开始，END-已结束，CANCEL-已作废，DRAFT-草稿，AWAITING_DRAW-待开奖 ]
        let obj = countDown(redemptionDeadlineTime);
        setCountDownObj(obj);
      }, 1000),
    );
  }, []);

  useEffect(() => {
    if (addressList?.length && addressList.some(item => item.address)) {
      //推广员弹框
      showPromoterDialog({ actId: marketingId });
    }
  }, [addressList]);

  useActivate(() => {
    // console.error("useActivate");
  });

  return (
    <>
      <div className={styles.address_list}>
        {(countDownObj.day > 0 || countDownObj.hou > 0 || countDownObj.min > 0) && (
          <div className={styles.count_down}>
            填写时间剩余<span className={styles.time}>{countDownDom()}</span>
          </div>
        )}
        <div className={styles.tips}>
          <p className={styles.tips__item}>
            *您可设置数量后通过分享按钮，将对应数量的奖品分享给顾客填写地址
          </p>
          <p className={styles.tips__item}>
            *地址填写完成后无需手动提交，待填写时间截止系统将自动读取页面内所有地址
          </p>
        </div>
        {expectedAwardAmount > 0 && (
          <div className={styles.title}>
            <p className={styles.title__left}>
              已填写地址{collectedQuantity || 0}件/共计{expectedAwardAmount}件
            </p>
            <div className={styles.title__right} onClick={() => onAddAddress(1)}>
              添加收货地址
            </div>
          </div>
        )}
        {addressList?.length > 0 && (
          <div className={styles.list}>
            {addressList.map((item, index) => {
              return (
                <AddressItem
                  key={item.id}
                  index={index}
                  item={item}
                  expectedAwardAmount={expectedAwardAmount}
                  onUpdateAddress={({ index, item }) => onUpdateAddress({ index, item })}
                  onDeleteAddress={({ index, item }) => onDeleteAddress({ index, item })}
                  onRefreshAddress={({ index, item }) => onRefreshAddress({ index, item })}
                  onChangeQuantity={({ index, item }) => onChangeQuantity({ index, item })}
                  onShare={({ index, item }) => onShare({ index, item })}
                  onSelectAddress={({ index, item }) => onSelectAddress({ index, item })}
                />
              );
            })}
            <InfiniteScroll loadMore={loadMore} hasMore={hasNext} threshold={0}>
              {loading && (
                <div className={styles.loading}>
                  <Image src={loadingPng} width={pxtorem(30)}></Image>
                </div>
              )}
              <Bottom
                loading={loading}
                hasMore={hasNext}
                reachBottom={addressList && addressList?.length > 0 ? true : false}
              />
            </InfiniteScroll>
          </div>
        )}
      </div>
      {/* 海报*/}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        shareType="B2R_GIFT"
        btnConfig={[]}
        close={onClosePoster}
      />
    </>
  );
};

export default AddressList;
