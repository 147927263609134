import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { Slider } from "antd-mobile";
import React from "react";
import { ReactComponent as ArrowIcon } from "./images/ArrowIcon.svg";
import { ReactComponent as RedpackIcon } from "./images/RedpackIcon.svg";
import styles from "./index.module.less";

interface IProps {
  type?: string;
  index?: number;
  info?: any;
}

const RedPackSummary: React.FC<IProps> = ({ type, index, info }) => {
  const { navigate } = useNavigation();

  const handleToWallet = () => {
    if (platform.isApp) {
      AKJS.action.dispatch("event.page.open", {
        url: `${process.env.REACT_APP_PAGE_URL_4_APP}/v2/shop/wallet`,
      });
    } else if (platform.isMini) {
      // 小程序分享海报逻辑
      navigate({
        miniOptions: {
          url: "/income/pages/wallet/wallet",
          query: {},
        },
      });
    }
  };
  return (
    <>
      <div className={styles.redpackSummary}>
        <div className={styles.redpackTitle}>
          <div>
            你今日已领<span className={styles.unit}>￥</span>
            <span>{info?.myReward || 0}</span>
          </div>
          <div onClick={handleToWallet}>
            查看已领取奖励 <ArrowIcon style={{ marginLeft: 4 }} />
          </div>
        </div>
        <div className={styles.redpackProgress}>
          <Slider
            value={
              Number((Number(info?.overage || 0) / Number(info?.totalReward || 0)).toFixed(0)) * 100
            }
            style={{ "--fill-color": "#FF4747" }}
            disabled
            icon={<RedpackIcon />}
          />
        </div>
        <div className={styles.redpackInfo}>
          <div>
            红包总额<span>￥{info?.totalReward || 0}</span>
          </div>
          <div>
            红包剩余<span>￥{info?.overage || 0}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default RedPackSummary;
