import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { Image, Mask } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

interface IProps {
  visible: boolean;
  visibleChange: (visible: boolean) => void;
  data?: any;
}

const RedpacketModal: React.FC<IProps> = ({ visible, visibleChange, data }) => {
  const [open, setOpen] = useState(false);
  const { navigate } = useNavigation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleToWallet = () => {
    if (platform.isApp) {
      AKJS.action.dispatch("event.page.open", {
        url: `${process.env.REACT_APP_PAGE_URL_4_APP}/v2/shop/wallet`,
      });
    } else if (platform.isMini) {
      // 小程序分享海报逻辑
      navigate({
        miniOptions: {
          url: "/income/pages/wallet/wallet",
          query: {},
        },
      });
    }
    visibleChange(false);
  };

  useEffect(() => {
    if (visible) {
      setOpen(false);
    }
  }, [visible]);

  return (
    <Mask visible={visible} onMaskClick={() => visibleChange(false)}>
      <div
        className={`${styles.redpackModal} ${open ? styles["redpackModal__open"] : ""}`}
        onClick={handleOpen}>
        {open && (
          <>
            <div className={styles.redpackModal__content}>
              <div className={styles.redpackModal__brandLogo}>
                <Image src={data?.brandLogo}></Image>
              </div>
              <div className={styles.redpackModal__brandName}>
                {data?.brandName || "品牌"}赠送您惊喜红包
              </div>
              <div className={styles.redpackModal__amount}>
                {data?.redPacketAmount || 0}
                <span>元</span>
              </div>
              <div className={styles.redpackModal__tips}>奖励已放入“奖励金”中</div>
            </div>
            <div className={styles.redpackModal__btn} onClick={handleToWallet}></div>
          </>
        )}
      </div>
    </Mask>
  );
};
export default RedpacketModal;
