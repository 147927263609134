import { queryFrontSettingsServ } from "@/api";
import { queryJumpTypeServ } from "@/api/activity";
import { buyMore } from "@/api/add-to-cart";
import { queryAtmosphereData } from "@/api/newPerson";
import {
  convertProIdToSpuId,
  fetchMaterialProductSwitch,
  getConverterInfo,
  gqueryCGoldCoinAmount,
  queryActivityCardForProductDetail,
  queryBannerInfo,
  queryFavioriteStatus,
  queryMaterialList,
  queryMerchantShopCardForProductDetail,
  queryProductDetail,
  queryProductDetailMaterialInfo,
} from "@/api/product";
import { SharePosterParams } from "@/api/scode/poster";
import { SourceScene } from "@/api/scode/scode_params";
import { AddPurchasePanelActionType } from "@/components/add-purchase-panel";
import { CustomEditData } from "@/components/poster-swiper";
import { RouteName } from "@/router/extra";
import { sharePosterParams4H5Product } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { getCookie, MICRO_APP_BASEURL } from "@/utils/platform";
import { getUrlParam } from "@/utils/url-parse";
import useNavigation from "@/utils/useNavigate";
import {
  ActivityModel,
  AIMaterialMediaModel,
  CloudAlbumExtInfoDTO,
  CouponModel,
  LabelInfoModel,
  MaterialCombinationModel,
  PDSizeRecommandMemberInfoModel,
  PDSizeRecommandModel,
  ProductDetailAtmosphereModel,
  ProductDetailModel,
  ResourceBannerModel,
  ShopCardModel,
  SkuInfoMappingModel,
} from "@akc/biz-api";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { MultiImageViewerRef, SwiperRef, Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React, { useContext } from "react";
import { useActivate, useUnactivate } from "react-activation";
import { MarketingServicesType } from "../../components/promotion-dialog";
import { useAddressList } from "../../hook/useAddressList";
import { PageType, useBaseProductDetail } from "../../hook/useBaseProductDetail";
import { useCouponList } from "../../hook/useCouponList";
import useRecommendProductList from "../../hook/useRecommendProductList";
import { MaterialListModel } from "../../model/MaterialListModel";
import { TopTabModel } from "../../model/TopTabModel";
import { PDUtils } from "../../utils/PDUtils";
import { ProductVideoImageItem } from "../model/Media";
import { default as useCoupon, default as useMarketPop } from "./useMarketPop";
import useSoldOutRecommendProductList from "./useSoldOutRecommendProductList";
import { useTrack } from "./useTrack";

interface ExtActivityModel extends ActivityModel {
  searchRequestId?: string;
}

interface ExtShopCardModel extends ShopCardModel {
  searchRequestId?: string;
}

const pageName = "商品详情";
export const useProductDetail = () => {
  const {
    activityIdRef,
    selectSkuId,
    setSelectSkuId,
    firstSalePropertyValue,
    setFirstSalePropertyValue,
    secondSalePropertyValue,
    activitySpuIdRef,
    pageCode,
    liveNo,
    productDetail,
    setProductDetail,
    onServiceAction,
    skcId,
    ud,
    needConvert,
    productNum,
    converterMsg,
    showTopTabs,
    activeKey,
    tabItems,
    setTabItems,
    showFloatMenu,
    getProductSysTemShareInfo,
    bindPhonePopupVisible,
    setBindPhonePopupVisible,
    toAuth,
    bindPhone,
  } = useBaseProductDetail(PageType.normal);

  const { cartNum, currentRole, setCartNum, userVisitInfo } = useCommonContext();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);
  const currentRoleIsC = currentRole === "C";
  const {
    navigate2Activity,
    navigate2GoodsOfNpieceXfold,
    navigate,
    navigate2H5,
    navigate2NxProductList,
  } = useNavigation();

  const headerDomRef = React.useRef<HTMLDivElement>(null);
  const previewRef = React.useRef<MultiImageViewerRef>(null);
  // 收藏是否展示
  const [showFavoriteIcon, setShowFavoriteIcon] = React.useState<boolean>(false);
  // 档期卡片
  const [activityItem, setActivityItem] = React.useState<ExtActivityModel>();
  // 店铺卡片
  const [shopItem, setShopItem] = React.useState<ExtShopCardModel>();
  // 素材卡片
  const [materialItem, setMaterialItem] = React.useState<MaterialCombinationModel>();
  // 素材卡片预览数据（预览采用新字段，需要映射一下）
  const [materialListNew, setMaterialListNew] = React.useState<AIMaterialMediaModel[]>([]);
  // 图片/视频
  const [videoImage, setVideoImage] = React.useState<ProductVideoImageItem[]>([]);
  // 资源选择的index
  const [mediaSelectIndex, setMediaSelectIndex] = React.useState<number>(0);
  // 多媒体预览组件
  const [showMediaPreview, setShowMediaPreview] = React.useState<boolean>(false);
  // 氛围及购买记录
  const [atmosphereModel, setAtmosphereModel] = React.useState<ProductDetailAtmosphereModel>();
  // 实拍专区
  const [materialListModel, setMaterialListModel] = React.useState<MaterialListModel>();
  // 营销弹窗显示状态
  const [promotionDialogVisible, setPromotionDialogVisible] = React.useState<boolean>(false);
  // 营销弹窗类型
  const [promotionDialogType, setPromotionDialogType] = React.useState<MarketingServicesType>();
  // 加购面板显示
  const [cartModalVisible, setCartModalVisible] = React.useState<boolean>(false);
  // 加购面板所需模型
  const [modalProduct, setModalProduct] = React.useState<ProductDetailModel>();
  //是否是礼物
  const [isGiftPurchase, setIsGiftPurchase] = React.useState<boolean>(false);
  // 加购面板按钮类型
  const [cartPannelButtonType, setCartPannelButtonType] = React.useState<number>();
  // 购物车数量
  const [cartTotal, setCartTotal] = React.useState<number>();
  // 转发
  const [sharePosterData, setSharePosterData] = React.useState<SharePosterParams>();
  const [customEditData, setCustomEditData] = React.useState<CustomEditData>();
  const [showPoster, setShowPoster] = React.useState(false);
  const [isOTC, setIsOTC] = React.useState(false);

  //图片预览
  const [previewPicList, setPreviewPicList] = React.useState<string[]>([]);
  //图片预览是否显示
  const [showPicPreview, setShowPicPreview] = React.useState<boolean>(false);
  //图片预览默认 index
  const [previewIndex, setPreviewIndex] = React.useState<number>(0);
  //banner数据集
  const [bannerList, setBannerList] = React.useState<ResourceBannerModel[]>();
  //用药指导弹窗
  const [showMedicateDialog, setShowMedicateDialog] = React.useState<boolean>(false);
  //是否显示推广费弹窗
  const [showPromotionFeeDialog, setShowPromotionFeeDialog] = React.useState<boolean>(false);
  // 是否预告佣金(区分：佣金 || 预告佣金)
  const [isAdvanceProfit, setIsAdvanceProfit] = React.useState<boolean>(false);
  //c积分
  const [coinAmount, setCoinAmount] = React.useState<number>(0);
  //是否显示骨架屏
  const [showSkeleton, setShowSkeleton] = React.useState<boolean>(true);
  //预告缺货文案
  const [noWrapText, setNoWrapText] = React.useState<string>();
  //是否显示送礼弹窗
  const [showGiveGiftDialog, setShowGiveGiftDialog] = React.useState<boolean>(false);
  // 选择尺码数据弹窗
  const [chooseSizeDialogVisible, setChooseSizeDialogVisible] = React.useState<boolean>(false);
  // 新增角色尺码数据弹窗
  const [addRoleSizeDialogVisible, setAddRoleSizeDialogVisible] = React.useState<boolean>(false);

  //是否显示ai图文popup
  const [showAiPopup, setShowAiPopup] = React.useState<boolean>(false);
  // 埋点
  const { trackPageView, trackGiveFriend } = useTrack(pageName);

  // 刷新商详时间戳(仅用于尺码角色信息发生变化，刷新商详)
  const [refreshDetailRoleTime, setRefreshDetailRoleTime] = React.useState<number>();
  // 尺码角色id 用于编辑角色
  const [sizeRoleId, setSizeRoleId] = React.useState<string>();
  // 选择尺码数据列表刷新时间戳
  const [refreshRoleListTime, setRefreshRoleListTime] = React.useState<number>();
  // 操作成功过的尺码角色id,适用于角色列表-编辑角色-关闭按钮场景下，刷新商详
  const operateSuccessedSizeRoleId = React.useRef<string>();
  // 是否第一次添加尺码角色
  const isFirstAddRole = React.useRef<boolean>();

  useActivate(() => {
    document.body.style.overflow = "unset";
  });

  //地址
  const {
    addressList,
    selectAddressId,
    updateSelectAddress,
    fetchAddressList,
    isLoadAddress,
    currentAddressId,
  } = useAddressList();

  //推荐商品列表
  const { hasNext, recommendProductList, onLoadNextPage } = useRecommendProductList({
    product: productDetail,
    scene: "product_detail",
  });

  //售罄关联商品弹框
  const { soldOutRecommendProductList, showRecommendProductDialog, setShowRecommendProductDialog } =
    useSoldOutRecommendProductList({
      currentActivitySpuId: activitySpuIdRef.current ?? undefined,
      product: productDetail,
    });

  // 新人专区/营销pop
  const {
    couponInfo,
    isShowMarketingPop,
    showPopCoupon,
    setShowPopCoupon,
    marketingPopInfo,
    setIsShowMarketingPop,
    search,
  } = useMarketPop({
    product: productDetail,
  });

  //优惠券
  const { couponList, receiveCoupon } = useCouponList(productDetail);

  const dismissAllDialogs = () => {
    if (promotionDialogVisible) {
      setPromotionDialogVisible(false);
    }
    if (showPicPreview) {
      setShowPicPreview(false);
    }
    if (cartModalVisible) {
      setCartModalVisible(false);
    }
    if (showPromotionFeeDialog) {
      setShowPromotionFeeDialog(false);
    }
    if (showMedicateDialog) {
      setShowMedicateDialog(false);
    }
    if (showPoster) {
      setShowPoster(false);
    }
    if (showRecommendProductDialog) {
      setShowRecommendProductDialog(false);
    }
    if (isShowMarketingPop) {
      setIsShowMarketingPop(false);
    }
    if (showPopCoupon) {
      setShowPopCoupon(false);
    }
  };

  useActivate(() => {
    document.title = "商品详情";
  });

  // 页面卸载时
  useUnactivate(() => {
    dismissAllDialogs();
  });

  React.useEffect(() => {
    const fetchFavioriteStatus = async () => {
      queryFavioriteStatus({
        businessCode: "H5_GUIDE_COLLECT_WINDOW_CODE",
      }).then(res => {
        setShowFavoriteIcon(res ?? false);
      });
    };
    fetchFavioriteStatus();
  }, []);

  //获取购物车数量
  const changeCartNum = () => {
    setCartNum().then(num => {
      console.log("购物车数量", num);
      setCartTotal(num);
    });
  };

  //替换当前页面路径参数
  const getReplaceUrlParam = (params?: {
    activitySpuId?: string | null;
    activityId?: string | null;
  }) => {
    let href = location.href;
    href = PDUtils.updateUrlParameter(
      href,
      "activitySpuId",
      params?.activitySpuId ?? activitySpuIdRef.current ?? "",
    );
    href = PDUtils.updateUrlParameter(
      href,
      "activityId",
      params?.activityId ?? activityIdRef.current ?? "",
    );
    return href.split("?")[1];
  };

  // 请求商详
  const fetchProductDetail = (params?: { selectSkuId?: string | null }) => {
    queryProductDetail({
      activityId: activityIdRef.current,
      activitySpuId: activitySpuIdRef.current,
      selectSkuId: params?.selectSkuId ?? selectSkuId,
      firstSalePropertyValue,
      secondSalePropertyValue,
      pageId: "H5_ProductDetail",
      addressId: currentAddressId.current,
    })
      .then(res => {
        setShowSkeleton(false);
        setProductDetail(res);
        getProductSysTemShareInfo(res);
        activityIdRef.current = res?.activityId;
        setTrackData(data => ({
          ...data,
          page_name: pageName,
          previous_page_name:
            search.get("previous_page_name") ?? search.get("page_name") ?? pageName,
          activity_id: res?.activityId,
          activity_spu_code: res?.activitySpuId,
          sku_id: res?.selectSkuId ?? selectSkuId,
          merchant_shop_code: res?.activitySimpleVO?.merchantShopCode,
          product_model: res?.activityType === 4 ? "普通商品" : "档期商品",
          brand_id: res?.brandInfo?.brandId,
          price: res?.skuCurrentPriceInfo?.marketingPrice,
          product_name: res?.skuBaseInfoVO?.title,
        }));
        trackPageView({
          previousPageName: search.get("previous_page_name") ?? search.get("page_name") ?? pageName,
          merchantShopCode: res?.activitySimpleVO?.merchantShopCode,
          activitySpuCode: res?.activitySpuId,
          skuId: res?.selectSkuId ?? selectSkuId,
          spuId: res?.originActivitySpuId,
          productModel: res?.activityType === 4 ? "普通商品" : "档期商品",
          activityId: activityIdRef.current,
        });
      })
      .catch(e => {
        setShowSkeleton(false);
        if (e?.message) {
          Toast.show(e?.message);
        }
      });
  };

  const getFrontSettings = async () => {
    const res = await queryFrontSettingsServ();
    if (res?.data?.soldOutForAdvance) {
      setNoWrapText(res?.data?.soldOutForAdvance?.noWrapText);
    }
  };

  //页面初始化请求
  const fetchPageStart = async (params?: {
    activitySpuId?: string;
    activityId?: string;
    selectSkuId?: string;
  }) => {
    let initActivitySpuId = params?.activitySpuId ?? activitySpuIdRef.current;
    let initActivityId = params?.activityId ?? activityIdRef.current;
    let initSelectSkuId = params?.selectSkuId ?? selectSkuId;
    console.log("fetchPageStart,", "start");
    //商品信息置换请求,productId(skcId) 置换 spuId
    if (!activitySpuIdRef.current && skcId) {
      console.log("fetchPageStart,", "skcId置换spuId");
      try {
        const res = await convertProIdToSpuId({
          convertType: 1,
          skcIds: [skcId],
        });
        if (res && res.length) {
          initActivitySpuId = res?.[0]?.activitySpuId;
          initActivityId = res?.[0]?.activityId;
          initSelectSkuId = res?.[0]?.selectSkuId;
        }
      } catch (error) {
        console.log("请求skcId置换activitySpuId,error=", error);
      }
    }

    // 商品详情转换逻辑
    if (ud || needConvert) {
      console.log("fetchPageStart,", "商品详情转换逻辑");
      try {
        const res = await getConverterInfo({
          activityId: initActivityId,
          activitySpuId: initActivitySpuId,
          productNum: productNum,
        });
        if (res && res.isConverter) {
          navigate2H5(RouteName.ProductDetail, {
            query: {
              activitySpuId: res.activitySpuId,
              activityId: res.activityId,
              converterMsg: res.converterMsg,
            },
            replace: true,
          });
          return;
        }
      } catch (error) {
        console.log("请求商品详情转换逻辑,error=", error);
      }
    }
    //请求素材商详开关
    const mps = await fetchMaterialProductSwitch(initActivitySpuId);
    if (mps?.switchStatus === 1 && mps.materialNo?.length) {
      console.log("fetchPageStart,", "符合素材商品详情条件");
      navigate2H5(RouteName.ProductDetailMaterial, {
        query: {
          materialNo: mps?.materialNo,
          activitySpuId: initActivitySpuId,
          activityId: initActivityId,
        },
        replace: true,
      });
      return;
    }
    console.log("fetchPageStart,", "不符合素材商品详情条件");
    await fetchAddressList();
    activitySpuIdRef.current = initActivitySpuId;
    activityIdRef.current = initActivityId;
    fetchProductDetail({ selectSkuId: initSelectSkuId });
    console.log("fetchPageStart,", "end");
  };

  React.useEffect(() => {
    document.title = "商品详情";
    fetchPageStart();
    getFrontSettings();
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (!productDetail) {
      return;
    }
    if (!isLoadAddress) {
      return;
    }
    console.log("监听属性值变化，请求商详接口");
    //请求商品详情
    fetchProductDetail();
  }, [selectSkuId, firstSalePropertyValue, secondSalePropertyValue, refreshDetailRoleTime]);

  React.useEffect(() => {
    if (!productDetail) {
      return;
    }
    //请求购物车数量
    changeCartNum();
    // 请求气氛数据
    queryAtmosphereData({
      activitySpuId: productDetail.activitySpuId,
    }).then(res => {
      setAtmosphereModel(res);
    });
    //请求banner
    queryBannerInfo().then(res => {
      setBannerList(res || []);
    });

    if (currentRoleIsC) {
      //实拍专区
      queryMaterialList({
        pageId: "H5_MaterialList",
        objectIds: productDetail.activitySpuId ? [productDetail.activitySpuId] : [],
        baseProductIds: productDetail?.spuInfoVO?.spuId ? [productDetail?.spuInfoVO?.spuId] : [],
      }).then(res => {
        setMaterialListModel(res);
      });
    } else {
      //素材三合一
      let params = {
        pageId: "H5_MaterialList",
        activityId: productDetail.activityId,
        activitySpuId: productDetail?.activitySpuId,
        selectSkuId: productDetail?.selectSkuId,
        itemCode: productDetail?.spuInfoVO?.spuId,
      };
      queryProductDetailMaterialInfo(params).then(res => {
        setMaterialItem(res);
      });
    }
    // 是否积分商品
    const isIntegralProduct =
      productDetail?.pricePanelType === 13 || productDetail?.pricePanelType2 === 7;
    if (!isIntegralProduct) {
      switch (productDetail?.cardEntranceVO?.cardType) {
        case "ACTIVITY": {
          // 请求档期卡片
          let searchRequestId = getCookie("mstoken") || "" + Date.now();
          let params = {
            pageId: "H5_ScheduleList",
            activityId: (productDetail?.skuAdvancePriceInfo ?? productDetail?.skuCurrentPriceInfo)
              ?.activityId,
            brandId: productDetail?.brandInfo?.brandId || "",
            spuId: productDetail?.spuInfoVO?.spuId || "",
            categoryCode: productDetail?.spuCategoryInfoVO?.categoryCode || "",
            merchantShopCode: productDetail?.activitySimpleVO?.merchantShopCode || "",
            searchRequestId,
          };
          queryActivityCardForProductDetail(params).then(res => {
            setActivityItem({
              ...res,
              searchRequestId: searchRequestId,
            });
          });
          break;
        }
        case "MERCHANT_SHOP": {
          // 请求店铺卡片
          let searchRequestId = getCookie("mstoken") || "" + Date.now();
          let params = {
            pageId: "H5_ScheduleList",
            activityId: (productDetail?.skuAdvancePriceInfo ?? productDetail?.skuCurrentPriceInfo)
              ?.activityId,
            brandId: productDetail?.brandInfo?.brandId || "",
            spuId: productDetail?.spuInfoVO?.spuId || "",
            categoryCode: productDetail?.spuCategoryInfoVO?.categoryCode || "",
            merchantShopCode: productDetail?.activitySimpleVO?.merchantShopCode || "",
            searchRequestId,
          };
          queryMerchantShopCardForProductDetail(params).then(res => {
            setShopItem({
              ...res,
              searchRequestId: searchRequestId,
            });
          });
          break;
        }
        default:
          break;
      }
    } else {
      //获取积分
      gqueryCGoldCoinAmount({})
        .then(res => {
          if (res && res.integralCoinCenter) {
            const amount = res.integralCoinCenter?.coinAccount?.amount || 0;
            setCoinAmount(amount);
          } else {
            console.log("获取积分失败");
          }
        })
        .catch(error => {
          Toast.show("服务异常，请稍后再试");
        });
    }
  }, [productDetail]);

  React.useMemo(() => {
    if (!productDetail) {
      return;
    }
    let tabs: TopTabModel[] = tabItems;
    if (tabItems.findIndex(e => e.key === "1") === -1) {
      tabs.push({ key: "1", title: "宝贝" });
    }

    if (materialItem || materialListModel?.materialPictureVOList?.length) {
      if (tabItems.findIndex(e => e.key === "2") === -1) {
        tabs.push({ key: "2", title: "素材" });
      }
    }
    if (tabItems.findIndex(e => e.key === "3") === -1) {
      tabs.push({ key: "3", title: "详情" });
    }

    if (recommendProductList.length > 0) {
      if (tabItems.findIndex(e => e.key === "4") === -1) {
        tabs.push({ key: "4", title: "推荐" });
      }
    }
    setTabItems(tabs.sort((a, b) => parseInt(a.key) - parseInt(b.key)));
  }, [materialItem, materialListModel, recommendProductList]);

  //显示营销标签弹窗
  const showPromotionDialog = (type: MarketingServicesType) => {
    setPromotionDialogType(type);
    setPromotionDialogVisible(true);
  };

  //立即购买
  const onBuyAction = () => {
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    if (
      productDetail?.skuCurrentPriceInfo?.promotionTimeStatus === 1 &&
      !productDetail?.skuAdvancePriceInfo
    ) {
      Toast.show("该场活动还未开始，暂不支持购买");
      return;
    }
    if (!productDetail?.skuExtendInfoVO?.allowDeliver) {
      Toast.show("此商品当前收货地址无法配送，请更换收货地址再进行购买");
      return;
    }
    if (!productDetail?.activityId || !productDetail?.activitySpuId) {
      console.log("活动id和商品id不能为空");
      return;
    }
    setIsGiftPurchase(false);
    setCartPannelButtonType(3);
    setModalProduct(productDetail);
    setTimeout(() => {
      setCartModalVisible(true);
    }, 80);
  };

  //送给朋友
  const onSendGiftAction = () => {
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    setIsGiftPurchase(true);
    setCartPannelButtonType(3);
    setModalProduct(productDetail);
    setTimeout(() => {
      setCartModalVisible(true);
    }, 80);
  };

  //加入购物车
  const onAddCartAction = () => {
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    //该商品不允许发货
    if (!productDetail?.skuExtendInfoVO?.allowDeliver) {
      Toast.show("此商品当前收货地址无法配送，请更换收货地址再进行购买");
      return;
    }
    // 不允许加购
    if (!productDetail?.skuExtendInfoVO?.buyShoppingCartModel) {
      return;
    }
    if (productDetail?.skuExtendInfoVO?.isVirtual) {
      Toast.show("亲~该场活动还未开始，暂不支持购买");
      return;
    }
    if (!productDetail?.activityId || !productDetail?.activitySpuId) {
      return;
    }
    setIsGiftPurchase(false);
    setCartPannelButtonType(2);
    setModalProduct(productDetail);
    setTimeout(() => {
      setCartModalVisible(true);
    }, 80);
  };

  //积分兑换
  const onExchangeAction = () => {
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    if (coinAmount < (productDetail?.skuExtendInfoVO?.creditAmount ?? 0)) {
      Toast.show("积分不足，无法兑换");
      return;
    }
    setIsGiftPurchase(false);
    setCartPannelButtonType(7);
    setModalProduct(productDetail);
    setTimeout(() => {
      setCartModalVisible(true);
    }, 80);
  };

  //处理价格区域sku点击
  const handleSelectSku = () => {
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    if (productDetail?.pricePanelType === 13) {
      onBuyAction();
      return;
    }
    setIsGiftPurchase(false);
    setCartPannelButtonType(6);
    setModalProduct(productDetail);
    setTimeout(() => {
      setCartModalVisible(true);
    }, 80);
  };

  //去使用优惠券
  const onUseCoupon = (coupon?: CouponModel) => {
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    const { isOldCoupon, couponId, voucherNo } =
      coupon ?? productDetail?.skuExtendInfoVO?.newcomerCouponVO ?? {};
    const params: { couponId?: string; voucherNo?: string } = {};
    if (isOldCoupon) {
      params.couponId = couponId;
    } else {
      params.voucherNo = voucherNo;
    }

    queryJumpTypeServ(params).then(res => {
      const { success, data, message } = res || {};
      const { couponId: _couponId, voucherNo: _voucherNo, activityId } = data || {};
      const originParams = getReplaceUrlParam();
      const mxShopPath = `/mx-shop-micro/joint-bill?couponId=${_couponId}&voucherNo=${_voucherNo}&${originParams}`;
      if (success) {
        switch (data.jumpType) {
          case "productDetail":
            onBuyAction();
            break;
          case "productList":
            navigate2GoodsOfNpieceXfold({
              h5Query: {
                couponId: data.couponId,
                voucherNo: data.voucherNo,
                awdInstanceId: data.awdInstanceId,
              },
            });
            break;
          case "activityDetail":
            navigate2Activity({
              activityNo: activityId ?? "",
            });
            break;
          case "activityList":
            navigate({
              h5Options: {
                url: mxShopPath,
              },
            });
            break;
          default:
            break;
        }
      } else {
        Toast.show(message || "服务异常，请稍后重试！");
      }
    });
  };

  //跳转商品尺码详情页面
  const goToProductFitSize = () => {
    if (productDetail?.productRecommendSizeVO?.sizeRecommendationInfo?.simpleContent) {
      // 尺码助手中的更多尺码参考
      navigate2H5(RouteName.SizeInfo, {
        query: {
          activitySpuId: productDetail?.activitySpuId,
        },
      });
      return;
    }
    const attrValueRes = productDetail?.productSizeTableSimpleInfo?.attrValueRes || [];
    const sizeUrl = productDetail?.spuInfoVO?.sizeUrl;
    const fitSizeCode = productDetail?.spuInfoVO?.fitSizeCode;
    if (attrValueRes.length > 0) {
      if (!fitSizeCode) {
        return;
      }
      navigate({
        h5Options: {
          url: `${MICRO_APP_BASEURL}/#/productFitSize?fitSizeCode=${productDetail?.productSizeTableSimpleInfo?.fitSizeCode}`,
        },
      });
      return;
    }

    if (sizeUrl) {
      setPreviewIndex(0);
      previewRef?.current?.swipeTo(0);
      setShowPicPreview(true);
      setPreviewPicList([sizeUrl]);
    }
  };

  //店铺
  const onB2RShopAction = (productType = 1) => {
    let merchantShopCode = productDetail?.activitySimpleVO?.merchantShopCode;
    const originParams = getReplaceUrlParam();
    let mxShopPath = `/mx-shop-micro/decorate?merchantShopCode=${merchantShopCode}&productType=${productType}&${originParams}`;
    const { skuCurrentPriceInfo, skuAdvancePriceInfo } = productDetail || {};
    if (skuCurrentPriceInfo?.promotionType) {
      mxShopPath += `&currentPromotionType=${skuCurrentPriceInfo.promotionType}`;
    }
    if (skuAdvancePriceInfo?.promotionType) {
      mxShopPath += `&advancePromotionType=${skuAdvancePriceInfo.promotionType}`;
    }
    navigate({
      h5Options: {
        url: mxShopPath,
      },
    });
  };

  //转发赚钱(详情转发)
  const onForwardAction = () => {
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    if (!productDetail?.activitySpuId) {
      return;
    }
    setSharePosterData(
      sharePosterParams4H5Product({
        pageCode: pageCode as string,
        sourceScene: SourceScene.productMaterial,
        activitySpuId: productDetail?.activitySpuId,
        selectSkuId: productDetail?.selectSkuId,
      }),
    );
    setCustomEditData({
      activitySpuId: productDetail?.activitySpuId,
      activityId: productDetail?.activityId,
      selectSkuId: productDetail?.selectSkuId,
    });
    setIsOTC(productDetail?.skuExtendInfoVO?.isOTC ?? false);
    setShowPoster(true);
  };

  // 还想买
  const stillBuy = () => {
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    buyMore({
      activityId: productDetail?.activityId,
      activitySpuId: productDetail?.activitySpuId,
    }).then(res => {
      if (res) {
        Toast.show("你的购买意愿已通知商家");
      }
    });
  };

  //关闭营销标签弹窗
  const diamissPromotionDialog = () => {
    setPromotionDialogVisible(false);
  };

  //多买优惠
  const goPromoActivity = (label?: LabelInfoModel) => {
    if (label?.skipUrl && label?.drillDown) {
      let skipUrl = label.skipUrl;
      if (skipUrl.indexOf("/#/goodsOfNpieceXfold?") > 0) {
        const promoActivityId = getUrlParam("promoActivityId", skipUrl);
        navigate2NxProductList(promoActivityId);
      } else {
        navigate2H5(skipUrl);
      }
    }
  };

  //规格选择-sku点击
  const onPropertyAction = (skuInfo?: SkuInfoMappingModel) => {
    if (skuInfo) {
      setSelectSkuId(skuInfo?.skuId);
      setFirstSalePropertyValue(skuInfo?.firstPropertyValue);
    }
  };

  const showVideoImage = () => {
    return !currentRoleIsC && productDetail?.pricePanelType !== 13 && materialItem;
  };

  //地址切换
  const onAddressSwitch = (addressId?: string, index?: number) => {
    currentAddressId.current = addressId;
    fetchProductDetail();
    updateSelectAddress(addressId, index);
  };

  //商品主图预览
  const onSkuMainImagePreview = (params: { index?: number; isSizeImage?: boolean }) => {
    let swipIndex = params?.index ?? 0;
    let imgList = productDetail?.skuMainImageUrls ?? [];
    const sizeUrl = productDetail?.spuInfoVO?.sizeUrl;
    if (sizeUrl) {
      imgList = imgList.concat(sizeUrl);
    }
    if (params?.isSizeImage) {
      swipIndex = imgList.length - 1;
    }
    setShowPicPreview(true);
    setPreviewPicList(imgList);
    setPreviewIndex(swipIndex);
    previewRef?.current?.swipeTo(swipIndex);
  };
  // 跳转到更多素材
  const onMoreMaterialAction = () => {
    const originParams = location.href.split("?")[1];
    const shopPath = `/mx-shop-micro/material?activitySpuId=${productDetail?.activitySpuId}&baseProductId=${productDetail?.spuInfoVO?.spuId}&useType=1&queryType=14${originParams}`;
    navigate({
      h5Options: {
        url: shopPath,
      },
    });
  };
  // 跳转到图/视频页
  const onMediaMoreAction = () => {
    const {
      activitySpuId,
      skuCurrentPriceInfo,
      selectSkuId,
      activityId,
      spuInfoVO = {},
    } = productDetail || {};
    navigate({
      h5Options: {
        url: `/mx-shop-micro/media-list?activityId=${skuCurrentPriceInfo?.activityId || activityId}&&activitySpuId=${activitySpuId}&&itemCode=${spuInfoVO?.spuId}&&selectSkuId=${selectSkuId}`,
      },
    });
  };
  // 文案复制
  const onAiMaterialCopyAction = (text: string) => {
    if (!text || text.length === 0) {
      return;
    }
    copy(text);
    Toast.show("复制成功");
  };

  //关闭手机号绑定弹窗
  const onFinishBindPhone = () => {
    setBindPhonePopupVisible(false);
  };

  // 添加尺码角色信息
  const onAddSizeRole = (isFirstAdd: boolean) => {
    console.log("onAddSizeRole isFirstAdd:", isFirstAdd);
    isFirstAddRole.current = isFirstAdd;
    setSizeRoleId(undefined);
    setAddRoleSizeDialogVisible(true);
  };

  // 编辑角色
  const onEditSizeRole = (model?: PDSizeRecommandMemberInfoModel) => {
    console.log("onEditSizeRole params:", model);
    if (model?.roleId && model?.roleId.length > 0) {
      setSizeRoleId(model?.roleId);
      setAddRoleSizeDialogVisible(true);
    }
  };

  // 选择尺码角色
  const onChooseSizeRole = (model?: PDSizeRecommandModel) => {
    console.log("onChooseSizeRole model:", model);
    setChooseSizeDialogVisible(true);
  };

  // 刷新尺码角色信息
  const onRefreshDetailRoleInfo = () => {
    console.log("onRefreshDetailRoleInfo");
    setRefreshDetailRoleTime(Date.now());
  };

  // 刷新尺码角色信息
  const onRefreshRoleListInfo = () => {
    console.log("onRefreshRoleListInfo");
    setRefreshRoleListTime(Date.now());
  };

  // 处理尺码助手相关操作 10:修改角色尺码 11:新建角色尺码
  const onHandleRoleSize = (type: AddPurchasePanelActionType) => {
    switch (type) {
      case 10: {
        // 修改角色尺码
        onChooseSizeRole();
        break;
      }
      case 11: {
        // 新建角色尺码
        onAddSizeRole(true);
        break;
      }
    }
  };

  return {
    currentRoleIsC,
    showFavoriteIcon,
    productDetail,
    cartNum,
    currentRole,
    materialListModel,
    videoImage,
    headerDomRef,
    showPromotionDialog,
    onUseCoupon,
    onBuyAction,
    onAddCartAction,
    atmosphereModel,
    goToProductFitSize,
    recommendProductList,
    hasNext,
    onLoadNextPage,
    cartTotal,
    onB2RShopAction,
    onServiceAction,
    onForwardAction,
    stillBuy,
    showFloatMenu,
    promotionDialogVisible,
    promotionDialogType,
    cartModalVisible,
    modalProduct,
    cartPannelButtonType,
    diamissPromotionDialog,
    addressList,
    selectAddressId,
    couponList,
    receiveCoupon,
    marketingPopInfo,
    setIsShowMarketingPop,
    goPromoActivity,
    setFirstSalePropertyValue,
    sharePosterData,
    customEditData,
    showPoster,
    isOTC,
    setShowPoster,
    setCartModalVisible,
    changeCartNum,
    previewRef,
    previewPicList,
    showPicPreview,
    previewIndex,
    setShowPicPreview,
    bannerList,
    setPreviewPicList,
    setPreviewIndex,
    isShowMarketingPop,
    shopItem,
    activityItem,
    materialItem,
    materialListNew,
    setMaterialListNew,
    liveNo,
    showMedicateDialog,
    setShowMedicateDialog,
    handleSelectSku,
    onPropertyAction,
    showPromotionFeeDialog,
    showRecommendProductDialog,
    soldOutRecommendProductList,
    setShowPromotionFeeDialog,
    setShowRecommendProductDialog,
    isAdvanceProfit,
    setIsAdvanceProfit,
    onExchangeAction,
    setShowPopCoupon,
    showTopTabs,
    activeKey,
    tabItems,
    converterMsg,
    setSelectSkuId,
    fetchProductDetail,
    getReplaceUrlParam,
    couponInfo,
    showPopCoupon,
    showSkeleton,
    mediaSelectIndex,
    setVideoImage,
    setMediaSelectIndex,
    showMediaPreview,
    setShowMediaPreview,
    showVideoImage,
    onAddressSwitch,
    onSkuMainImagePreview,
    noWrapText,
    showGiveGiftDialog,
    setShowGiveGiftDialog,
    isGiftPurchase,
    showAiPopup,
    setShowAiPopup,
    onMoreMaterialAction,
    onMediaMoreAction,
    onAiMaterialCopyAction,
    trackGiveFriend,
    onFinishBindPhone,
    bindPhonePopupVisible,
    setBindPhonePopupVisible,
    toAuth,
    bindPhone,
    onSendGiftAction,
    chooseSizeDialogVisible,
    setChooseSizeDialogVisible,
    addRoleSizeDialogVisible,
    setAddRoleSizeDialogVisible,
    onChooseSizeRole,
    onAddSizeRole,
    onHandleRoleSize,
    onRefreshDetailRoleInfo,
    refreshDetailRoleTime,
    onEditSizeRole,
    sizeRoleId,
    refreshRoleListTime,
    onRefreshRoleListInfo,
    operateSuccessedSizeRoleId,
    isFirstAddRole,
  };
};
