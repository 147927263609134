import Clickable from "@/components/clickable";
import { pxtorem } from "@/utils/pxtorem";
import { ActivityBrandModel } from "@akc/biz-api";
import React, { useEffect, useMemo, useRef } from "react";
import {
  useSelectedActivity,
  useSetFilterInfo,
  useSetSelectedActivity,
} from "../hooks/useActivityProduct";
import styles from "./ActivitySelector.module.less";

interface Props {
  brandData: ActivityBrandModel;
  borderRadis?: number;
  bgColor?: string;
  small?: boolean;
  initialSelectedActivityId?: string;
  previous_page_name?: string;
  onSelectionChange?: (id: any) => void;
}

const ActivitySelector: React.FC<Props> = ({
  brandData,
  borderRadis,
  bgColor,
  small,
  initialSelectedActivityId,
  previous_page_name = "",
  onSelectionChange,
}) => {
  const selectedActivity = useSelectedActivity();
  const setSelectedActivity = useSetSelectedActivity();
  const setFilterInfo = useSetFilterInfo();

  useEffect(() => {
    const dom = document.getElementById(`activity-${initialSelectedActivityId ?? -1}`);
    dom?.scrollIntoView({ inline: "center", block: "end" });
  }, [initialSelectedActivityId]);

  const hasActivityBizImg = brandData.activityList?.some(item => !!item.activityBizImg);

  const listHeight = pxtorem((small ? 30 : 60) + (hasActivityBizImg ? 10 : 0));

  return (
    <div
      className={styles.container}
      style={{
        borderTopLeftRadius: borderRadis !== undefined ? borderRadis + "px" : undefined,
        borderTopRightRadius: borderRadis !== undefined ? borderRadis + "px" : undefined,
        background: bgColor,
      }}>
      <div
        className={styles.activitySelectorList}
        style={{ height: listHeight }}>
        {brandData.activityList?.map(item => {
          return (
            <Clickable
              onClick={() => {
                if (selectedActivity?.activityId === item.activityId) {
                  return;
                }
                const dom = document.getElementById(`activity-${item.activityId ?? -1}`);
                dom?.scrollIntoView({
                  behavior: "smooth",
                  inline: "center",
                  block: "nearest",
                });
                setSelectedActivity(item);
                onSelectionChange?.(item.activityId);
                setFilterInfo(undefined);
              }}
              key={item.activityId ?? "-1"}
              trackData={{
                tab_id: item.activityId
                  ? item.activityId
                  : brandData.activityList?.map(item => item.activityId).join(","),
                activity_id: item.activityId
                  ? item.activityId
                  : brandData.activityList?.map(item => item.activityId).join(","),
                resource_type: "TAB",
                tab_name: item.activityAggrTitle,
                tab_rank: (item.rank ?? 0) + 1,
                merchant_shop_code: item.merchantShopCode,
                previous_page_name,
                page_name: document.title,
              }}
              id={`activity-${item.activityId ?? -1}`}
              className={`${styles.activitySelector} ${selectedActivity?.activityId === item.activityId
                ? styles.selected
                : styles.unselected
                }`}
              style={{
                height: small ? pxtorem(30) : pxtorem(48),
                marginTop: hasActivityBizImg ? pxtorem(10) : 0,
              }}>

              {item.activityBizImg && <img className={styles.activityLT} src={item.activityBizImg} />}
              {/* <img className={styles.activityLT} src="https://akim-oss.aikucun.com/04ccdbee0143ade65b72e905a7baf325e7f58ad7_1739529313968_57.png" /> */}

              <div className={styles.category}>
                {item.activityHotImg && selectedActivity?.activityId === item.activityId && (
                  <img src={item.activityHotImg} alt="火爆" className={styles.tagImg} />
                )}
                {item.noSelectActivityHotImg &&
                  selectedActivity?.activityId !== item.activityId && (
                    <img src={item.noSelectActivityHotImg} alt="火爆" className={styles.tagImg} />
                  )}
                <span>{item.activityAggrTitle}</span>
                {item.activityStatus === 1 && <span className={styles.tagForecast}>预</span>}
              </div>
              {!small && (
                <div className={styles.highlight}>
                  {item.activityAggrSubTitleList
                    ? item.activityAggrSubTitleList.map((tag, subIndex: number) => {
                      return (
                        <div className={styles.highlightPair} key={subIndex}>
                          <div className={styles.tag}>{tag}</div>
                          {item.activityAggrSubTitleList &&
                            subIndex < item.activityAggrSubTitleList?.length - 1 && (
                              <div className={styles.gapLine}>|</div>
                            )}
                        </div>
                      );
                    })
                    : item.activityAggrSubTitle}
                </div>
              )}
            </Clickable>
          );
        })}
      </div>
    </div>
  );
};

export default ActivitySelector;
