import _ from "lodash";
import React from "react";
import { PriceProps } from "../../expand_tab/types/basics/price";
import styles from "./index.module.less";

/**
 * 价格组件
 */

const PriceComp: React.FC<PriceProps> = props => {
  const int = _.floor(props.price);
  const dec =
    props.price && props.price % 1 !== 0 ? props.price?.toString()?.split(".")?.[1] : undefined;
  return (
    <div className={styles.price_container} style={props.style}>
      <div
        className={`${styles.price_container_symbol} ${props?.decimalsClassName ?? props.symbolClassName}`}>
        ¥
      </div>
      <div className={`${styles.price_container_int} ${props.intClassName}`}>{int}</div>
      {dec && (
        <div
          className={`${styles.price_container_decimals} ${props?.decimalsClassName ?? props.symbolClassName}`}>
          .{dec}
        </div>
      )}
    </div>
  );
};

export default PriceComp;
