import type { CartData, CartLine } from "@/api/cart/types.d";
import { Toast } from "antd-mobile";
import { useRef, useState } from "react";
import { SkuItem } from "./useCartData";

export const useItemSelection = () => {
  const [selectedSku, setSelectedSku] = useState<SkuItem[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [isHasGoods, setIsHasGoods] = useState(false);
  const showPrevGoodsToast = useRef(false);

  // 判断是否为预告商品不可购买
  const isPrevGoods = (item: any) => {
    return !!item.advancePriceInfo && !item.allowBuy;
  };

  // 提示预告商品信息
  const toastPrevGoods = () => {
    showPrevGoodsToast.current = true;
    Toast.show({
      content: "该商品为预告商品仅支持加购",
      duration: 3000,
      afterClose: () => {
        showPrevGoodsToast.current = false;
      },
    });
  };

  const getShowPrevGoodsToast = () => {
    return showPrevGoodsToast.current;
  };

  // 检查是否所有商品都被选中
  const checkAllSelected = (data: CartData | null) => {
    if (!data?.effectiveCommodityList?.length) {
      return false;
    }

    let allSelected = true;
    let hasItems = false;

    for (const group of data.effectiveCommodityList) {
      if (group.cartLines?.length) {
        hasItems = true;
        for (const line of group.cartLines) {
          if (!line.selected) {
            allSelected = false;
            break;
          }
        }
        if (!allSelected) {
          break;
        }
      }
    }

    return hasItems && allSelected;
  };

  // 检查是否有预告商品
  const checkPrevGoods = (data: CartData | null) => {
    if (!data?.effectiveCommodityList?.length) {
      return false;
    }

    for (const group of data.effectiveCommodityList) {
      if (group.cartLines?.length) {
        for (const line of group.cartLines) {
          if (isPrevGoods(line) && line.selected) {
            return true;
          }
        }
      }
    }
    return false;
  };

  // 更新购物车数据
  const updateCartData = (newData: CartData) => {
    setIsAllSelected(checkAllSelected(newData));
    setIsHasGoods(checkPrevGoods(newData));
  };

  // 更新选中的SKU列表
  const updateSelectedSku = (item: any, isSelected: boolean) => {
    setSelectedSku(prev => {
      const newSelectedSku = [...prev];

      if (isSelected) {
        // 添加到选中列表
        newSelectedSku.push({
          activityId: item.activityNo,
          skuId: item.externalSkuCode,
          spuId: item.spuId,
        });
        if (isPrevGoods(item)) {
          toastPrevGoods();
        }
      } else {
        // 从选中列表中移除
        const delIndex = newSelectedSku.findIndex(
          sku =>
            sku.activityId === item.activityNo &&
            sku.skuId === item.externalSkuCode &&
            sku.spuId === item.spuId,
        );

        if (delIndex !== -1) {
          newSelectedSku.splice(delIndex, 1);
        }
      }

      return newSelectedSku;
    });
  };

  // 批量更新选中的SKU列表
  const batchUpdateSelectedSku = (items: any[], isSelected: boolean) => {
    setSelectedSku(prev => {
      const newSelectedSku = [...prev];

      if (isSelected) {
        // 批量添加到选中列表
        const itemsToAdd = items.map(item => ({
          activityId: item.activityNo,
          skuId: item.externalSkuCode,
          spuId: item.spuId,
        }));

        // 添加新项目，避免重复
        itemsToAdd.forEach(newItem => {
          const exists = newSelectedSku.some(
            existingItem =>
              existingItem.activityId === newItem.activityId &&
              existingItem.skuId === newItem.skuId &&
              existingItem.spuId === newItem.spuId,
          );

          if (!exists) {
            newSelectedSku.push(newItem);
          }
        });

        // 检查是否有预告商品
        const hasPrevGoods = items.some(isPrevGoods);
        if (hasPrevGoods) {
          toastPrevGoods();
        }
      } else {
        // 批量从选中列表中移除
        items.forEach(item => {
          const delIndex = newSelectedSku.findIndex(
            sku =>
              sku.activityId === item.activityNo &&
              sku.skuId === item.externalSkuCode &&
              sku.spuId === item.spuId,
          );

          if (delIndex !== -1) {
            newSelectedSku.splice(delIndex, 1);
          }
        });
      }

      return newSelectedSku;
    });
  };

  // 获取选中的商品
  const getSelectedItems = (cartData: CartData | null) => {
    if (!cartData?.effectiveCommodityList?.length) {
      return [];
    }

    const selectedItems: CartLine[] = [];

    cartData.effectiveCommodityList.forEach(group => {
      if (group.cartLines?.length) {
        group.cartLines.forEach(line => {
          if (line.selected) {
            selectedItems.push(line);
          }
        });
      }
    });

    return selectedItems;
  };

  return {
    selectedSku,
    setSelectedSku,
    isAllSelected,
    isHasGoods,
    updateCartData,
    updateSelectedSku,
    batchUpdateSelectedSku,
    getSelectedItems,
    getShowPrevGoodsToast,
    isPrevGoods,
    toastPrevGoods,
  };
};
