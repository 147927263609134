import { getCookie, platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import moment from "moment";
import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./UserCard.module.less";

interface UserCardProps {
  userInfo: any;
  index: number;
  onResourceClick: (index, obj) => void;
}

const UserCard: React.FC<UserCardProps> = ({ userInfo, index, onResourceClick }) => {
  const [search] = useSearchParams();
  const role = search.get("role");

  const { navigate } = useNavigation();

  const onCopy = (e, text: string) => {
    e.stopPropagation();
    copy(text);
    Toast.show({
      content: "复制成功",
      position: "center",
      maskClassName: styles["poster-unify-toast"],
    });
    onResourceClick(index, {
      btnName: "复制",
    });
  };

  const navigate2H5 = (path: string, queries?: { [key in string]: any }) => {
    navigate({
      appOptions: {
        url: `${process.env.REACT_APP_PAGE_URL_4_APP}/v2/${path}`,
        query: queries,
      },
      miniOptions: {
        url: `/pages/web-view/web-view?url=${encodeURIComponent(`${origin}/v2/${path}`)}`,
        query: queries,
      },
      h5Options: {
        url: `#/${path}`,
        query: queries,
      },
    });
  };

  const toCustomerDetailPage = () => {
    onResourceClick(index, {
      btnName: "查看顾客档案",
    });
    // eslint-disable-next-line no-underscore-dangle
    let _role = role || (platform?.isApp ? "b" : "e");
    navigate2H5("analysis/customer/archieve", {
      role: _role,
      customerId: userInfo.userId,
      searchType: "visit",
    });
  };

  const toCustomerFootPrintPage = () => {
    onResourceClick(index, {
      btnName: "TA的足迹",
    });
    // eslint-disable-next-line no-underscore-dangle
    let _role = role || (platform?.isApp ? "b" : "e");
    navigate2H5("analysis/customer/footPrint", {
      role: _role,
      customerId: userInfo.userId,
    });
  };

  return (
    <>
      {userInfo && (
        <div className={styles.userCard}>
          <div className={styles.cardHeader}>
            <div className={styles.headerLeft} onClick={() => toCustomerDetailPage()}>
              <img
                className={styles.avatar}
                src={
                  userInfo.headPicture ||
                  "https://akim-oss.aikucun.com/mshop/a05cd5bfcbcde6e80e2c2d338670105da0106db5_1711707055021_93.png"
                }
              />
              <div className={styles.info}>
                <div className={styles.nickname}>
                  {userInfo.nickName}
                  <img
                    className={styles.iconArrow}
                    src="https://akim-oss.aikucun.com/mshop/b14f97413e78f590853ff4772d76c33925e6bfe3_1710816175034_89.png"
                  />
                </div>
                {userInfo.phone && (
                  <div className={styles.phone} onClick={e => onCopy(e, userInfo.phone)}>
                    {userInfo.phone.replace(/^(1[3-9][0-9])\d{4}(\d{4}$)/, "$1****$2")}
                    <img
                      className={styles.iconCopy}
                      src="https://akim-oss.aikucun.com/mshop/4edd1d00875d8edeec08e27ad5650fe11fad1d8d_1710816452788_81.png"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.headerRight} onClick={() => toCustomerFootPrintPage()}>
              <div className={styles.footprint}>
                TA的足迹
                <img
                  className={styles.iconArrow}
                  src="https://akim-oss.aikucun.com/mshop/b14f97413e78f590853ff4772d76c33925e6bfe3_1710815777002_35.png"
                />
              </div>
            </div>
          </div>
          <div className={styles.cardMain}>
            <div className={styles.mainAction}>
              {userInfo.ordered && <span className={styles.iconOrdered}>已下单</span>}
              {userInfo.productVO &&
                (userInfo.productVO?.skuAdvancePriceInfo ||
                  userInfo.productVO?.skuCurrentPriceInfo?.promotionTimeStatus === 1) && (
                  <span className={styles.iconAdvance}>预告中</span>
                )}
              {userInfo.shoppingMessage}
            </div>
            <div className={styles.mainDate}>
              {moment(new Date(Number(userInfo.addToCartTime))).format("YYYY-MM-DD HH:mm:ss")}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserCard;
