import { queryBindCheck } from "@/api/member";
import { useCommonContext } from "@/utils/context/common";
import { getPlatform } from "@/utils/platform";
import { useEffect, useState } from "react";

const useCheckBind = () => {
  const { userVisitInfo } = useCommonContext();
  //手机号绑定弹窗是否显示
  const [bindPhonePopupVisible, setBindPhonePopupVisible] = useState(false);
  const { isMini } = getPlatform();

  const toAuth = (hasScope = true) => {
    let url = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    // 处理微信自动添加字符串问题，替换为空
    url = url.replace(/\/\?from=(message|timeline|singlemessage|groupmessage)(\S*)#/, "/#");
    url = url.replace(/\/\?(scene|subscene|clicktime)=[\d]+(\S*)#/, "/#");
    // eslint-disable-next-line no-restricted-syntax
    window.location.href = `/api/member/auth?${
      hasScope ? "scope=snsapi_userinfo&" : ""
    }backUrl=${window.encodeURIComponent(url)}`;
  };

  const bindPhone = () => {
    if (userVisitInfo?.isBindPhone) {
      return;
    }
    if (isMini) {
      window.wx.miniProgram.navigateTo({
        url: "/pages/auth-page/auth-page",
      });
    } else {
      if (!userVisitInfo?.isAuthorized) {
        toAuth();
        return;
      }
      setBindPhonePopupVisible(true);
    }
  };

  return {
    isVisitor: userVisitInfo?.isVisitor ?? false,
    bindPhonePopupVisible,
    setBindPhonePopupVisible,
    bindPhone,
    toAuth,
  };
};

export default useCheckBind;
