import { useUnmount } from "ahooks";
import { useEffect, useRef } from "react";

export const usePageTitle = (title: string) => {
  const originalTitle = useRef(document.title);
  useEffect(() => {
    document.title = title;
  }, [title]);

  useUnmount(() => {
    document.title = originalTitle.current;
  });
};

export * from "./useCartData";
export * from "./useItemSelection";
export * from "./useCartActions";
export * from "./useProductList";
