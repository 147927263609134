import { batchGeneratePoster, forwardRecordSave } from "@/api/scode/poster";
import PosterSwipe from "@/components/poster-swiper";
import { batchPosterParams4H5Product } from "@/utils/actions";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { DotLoading, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import useSelectivePosters from "../../hooks/useSelectivePosters";
import { UseSelectiveProductContext } from "../../hooks/useSelectiveProduct";
import styles from "./index.module.less";

const Footer: React.FC = () => {
  const { navigate2H5 } = useNavigation();
  const { resourceType, shareType, sharePosterData } = useSelectivePosters();
  const [loading, setLoading] = useState(false);
  const [showPoster, setShowPoster] = useState(false);

  const track = Track();
  const [search] = useSearchParams();
  const shareIcon =
    "https://akim-oss.aikucun.com/mshop/5974e4f97ff445bfddee2535bb743230f141a582_1713843397699_87.png";
  const checkIcon =
    "https://akim-oss.aikucun.com/mshop/989d92f2bdd2f3f6ac497a9bfe076f79c8e8942e_1713843042910_80.png";
  const checkedIcon =
    "https://akim-oss.aikucun.com/mshop/698a22b68ecff5c9bca113eba3e6df70f9235e7d_1713843077562_85.png";

  const { total, forwardNum, productList, setProductList, checkedProductList, autoCheckedProduct } =
    UseSelectiveProductContext();

  const checkedAction = () => {
    // 全部勾选
    if (checkedProductList.length === 0 && productList.length > 0) {
      autoCheckedProduct();
    }
    // 全部不勾选
    if (checkedProductList.length > 0 && productList.length > 0) {
      const newProductList = productList.map(item => {
        return { ...item, isSelect: false };
      });
      setProductList(newProductList);
    }
  };

  const getSourceScene = () => {
    const sourceSceneMap = {
      ACTIVITY: "ACT_SHARELIST",
      CONFERENCE3: "B2R_SHARELIST",
      SHARE_BRAND: "BRAND_SHARELIST",
      SHARE_CATEGORY: "CATEGORY_SHARELIST",
    };
    const sourceSceneIdMap = {
      ACTIVITY: search.get("activityIdList"),
      CONFERENCE3: search.get("shopCodes"),
      SHARE_BRAND: search.get("brandIds"),
      SHARE_CATEGORY: search.get("categoryIdList"),
    };
    return {
      sourceScene: sourceSceneMap[shareType],
      sourceSceneId: sourceSceneIdMap[shareType],
    };
  };

  const getPosterImages = async () => {
    const contentIds = checkedProductList.map(item => {
      if (item.selectSkuId && item.selectSkuId.length > 0) {
        return `${item.activitySpuId ?? ""}-${item.selectSkuId}`;
      }
      return item.activitySpuId ?? "";
    });
    const activitySpuIds = checkedProductList.map(item => item.activitySpuId).join(",");
    const params = batchPosterParams4H5Product({
      contentIds: contentIds,
      envContextExtra: {
        sourceScene: getSourceScene().sourceScene,
        sourceSceneId: getSourceScene().sourceSceneId,
        shareFrom: "MANUAL",
        scodeType: "SHARE",
        targetTerminalType: "H5",
      },
      contentContextExtra: {
        contentId: activitySpuIds,
      },
      carrierContextExtra: {
        posterCode: "productPoster",
      },
    });
    setLoading(true);
    Toast.show({
      icon: "loading",
      content: "海报生成中…",
      duration: 0,
    });
    const res = await batchGeneratePoster(params);
    setLoading(false);
    Toast.clear();
    // 手动设置已转发
    if (res?.data?.posterInfos?.length) {
      const activitySpuIdList = checkedProductList.map(item => item.activitySpuId);
      const newProductList = productList.map(item => {
        if (activitySpuIdList.includes(item.activitySpuId)) {
          return {
            ...item,
            isForwarded: true,
            isSelect: false,
          };
        }
        return item;
      });
      setProductList(newProductList);
    }
    // 转发保存
    if (res?.data?.posterInfos?.length) {
      for (let i = 0; i < checkedProductList.length; i++) {
        let productItem = checkedProductList[i];
        forwardRecordSave({
          model: 2,
          srcPage: 2,
          forwardModel: 3,
          liveid: productItem.activityId,
          productList: [
            {
              markup: "0",
              id: productItem.activitySpuId,
            },
          ],
        });
      }
    }
    // 查看海报页面
    if (res?.data?.posterInfos?.length) {
      const posterInfos = res.data.posterInfos;
      const images = posterInfos.map(item => item.imageUrl);
      const scode = posterInfos[0]?.scode;
      navigate2H5(`/mx-shop-micro/selective-posters?scode=${scode}&images=${images}`);
    }
  };

  const goPostersPage = () => {
    if (loading) {
      return;
    }
    if ((checkedProductList?.length ?? 0) < 1) {
      Toast.show("至少选择一个商品");
      return;
    }
    getPosterImages();
    track.track("resource_click", {
      page_name: "选择性转发页",
      resource_type: resourceType,
      resource_name: "生成海报",
      activity_id: search.get("activityIdList"),
      brand_id: search.get("brandIds"),
      cartegory_id: search.get("categoryIdList"),
      merchant_shop_code: search.get("shopCodes"),
    });
  };

  const onShowPoster = () => {
    setShowPoster(true);
    track.track("resource_click", {
      page_name: "选择性转发页",
      resource_type: resourceType,
      resource_name: "转发全部",
      activity_id: search.get("activityIdList"),
      brand_id: search.get("brandIds"),
      cartegory_id: search.get("categoryIdList"),
      merchant_shop_code: search.get("shopCodes"),
    });
  };

  useEffect(() => {
    document.title = "选择商品";
  }, []);

  useActivate(() => {
    // 海报页面回退，自动选中商品
    if (checkedProductList.length === 0 && productList.length > 0) {
      autoCheckedProduct();
    }
  });

  return (
    <>
      <div className={styles["footer"]}>
        {productList?.length === 0 && (
          <div className={styles["footer_top"]}>
            <div className={styles["skeleton"]}></div>
            <div className={styles["skeleton"]}></div>
          </div>
        )}
        {productList?.length > 0 && (
          <div className={styles["footer_top"]}>
            <div className={styles["check-box"]} onClick={checkedAction}>
              <img src={checkedProductList?.length > 0 ? checkedIcon : checkIcon} alt="" />
              已选({checkedProductList.length}/{forwardNum})
            </div>
            <span>共{total}款商品</span>
          </div>
        )}
        <div className={styles["footer_bottom"]}>
          <div className={styles["btn_share"]} onClick={onShowPoster}>
            <img src={shareIcon} alt="" />
            <span>转发全部</span>
          </div>
          <div className={styles["btn_poster"]} onClick={goPostersPage}>
            生成海报
            {loading && <DotLoading color="currentColor" />}
          </div>
        </div>
      </div>
      {shareType && shareType === "ACTIVITY" && (
        <PosterSwipe
          show={showPoster}
          scodeSimpleParams={sharePosterData}
          shareType={shareType}
          close={() => {
            setShowPoster(false);
            document.body.style.overflow = "unset";
          }}
          btnConfig={["copy", "edit"]}
        />
      )}
      {shareType && shareType !== "ACTIVITY" && (
        <PosterSwipe
          show={showPoster}
          posterData={sharePosterData}
          shareType={shareType}
          close={() => {
            setShowPoster(false);
            document.body.style.overflow = "unset";
          }}
          btnConfig={
            ["SHARE_CATEGORY", "SHARE_BRAND"].includes(shareType) ? ["copy", "edit"] : ["copy"]
          }
        />
      )}
    </>
  );
};

export default Footer;
