import { Toast } from "antd-mobile";
import React from "react";
import styles from "./LotteryControl.module.less";

/**
 * 获取签到提示文本
 * @param activityInfo 活动信息
 * @returns 提示文本
 */
const getSignInNoticeText = (activityInfo: any): string => {
  if (!activityInfo || !activityInfo.signInTaskInfo) {
    return "";
  }

  const { signInTaskInfo, status } = activityInfo;
  const { totalDays, daysToGetAward, signedInToday, todayAwardCount, continuousDays } = signInTaskInfo;

  // 根据活动状态和签到情况生成提示文本
  if (status === "ADVANCE") {
    // 活动未开始
    return "活动暂未开始，敬请期待";
  } else if (status === "BEGIN") {
    // 活动进行中
    if (signedInToday && todayAwardCount > 0) {
      // 今日已签到且获得抽奖机会
      return `今日已获得${todayAwardCount}次抽奖机会`;
    } else if (!continuousDays) {
      // 未开始连续签到
      return `连续签到${totalDays}天，可获得抽奖机会`;
    } else {
      // 已开始连续签到
      return `再签到${daysToGetAward}天，可获得抽奖机会`;
    }
  } else {
    // 活动已结束
    return "本次活动已结束，请期待下次活动";
  }
};

/**
 * 获取积分抽奖提示文本节点
 * @param activityInfo 活动信息
 * @returns 提示文本节点
 */
const getSignInNoticeTextNode = (activityInfo: any): React.ReactNode => {
  if (!activityInfo || !activityInfo.signInTaskInfo) {
    return "";
  }

  const { signInTaskInfo, status, lotteryPointInfo } = activityInfo;
  const { totalDays, awdAmount } = signInTaskInfo;

  // 根据活动状态生成提示文本节点
  if (status === "ADVANCE") {
    // 活动未开始
    return "活动暂未开始，敬请期待";
  } else if (status === "BEGIN") {
    // 活动进行中 - 返回格式化的 React 节点
    return (
      <>
        <span>连续签到</span> <span className={styles.fontColor}>{totalDays} </span>
        <span>
          天<span>得{awdAmount}次抽奖机会</span>或使用
        </span>
        <span className={styles.fontColor}>
          {lotteryPointInfo?.exchangeRequiredPoints ?? 0}
        </span>
        <span>积分抽1次</span>
      </>
    );
  } else {
    // 活动已结束
    return "本次活动已结束，请期待下次活动";
  }
};

/**
 * 获取抽奖区域显示文案
 * @param activityInfo 活动信息
 * @returns 抽奖区域显示文案
 */
const LotteryDisplayText = ({ activityInfo }) => {
  if (!activityInfo) {
    return null;
  }

  const { status, lotteryInfo, lotteryPointInfo, canUsePointsForLottery } = activityInfo;
  const remainCount = lotteryInfo?.remainCount ?? 0;
  const exchangeRequiredPoints = lotteryPointInfo?.exchangeRequiredPoints ?? 0;
  const hasPoints = lotteryPointInfo?.availableDrawLotteryCount > 0;

  if (canUsePointsForLottery && status === "BEGIN") {
    // 可以使用积分抽奖的情况
    if (remainCount > 0) {
      // 1. 有抽奖机会时的文案
      return (
        <span>
          剩余抽奖次数：
          <span className={styles.highLight}>{remainCount}</span>
        </span>
      );
    } else if (hasPoints) {
      // 2. 没有抽奖机会，有积分文案
      return (
        <span>
          <span className={styles.highLight}>{exchangeRequiredPoints}</span>
          积分抽奖1次
        </span>
      );
    } else {
      // 3. 没有任何抽奖机会文案
      return <span>暂无抽奖机会</span>;
    }
  } else {
    // 不可以使用积分抽奖的情况
    const textMap = {
      ADVANCE: "活动暂未开始",
      BEGIN: "暂无抽奖机会",
      END: "本次活动已结束",
      CANCEL: "本次活动已结束",
    };

    if (status === "BEGIN" && remainCount > 0) {
      // 有抽奖次数
      return (
        <span>
          剩余抽奖次数：
          <span className={styles.highLight}>{remainCount}</span>
        </span>
      );
    } else {
      // 根据状态显示文案
      return <span>{textMap[status] || ""}</span>;
    }
  }
};

interface LotteryControlProps {
  activityInfo: any
  callback: (val: any) => void
}

const LotteryControl = (props: LotteryControlProps) => {
  const { callback, activityInfo } = props;
  const noticeText = activityInfo?.canUsePointsForLottery ? getSignInNoticeTextNode(activityInfo) : getSignInNoticeText(activityInfo);

  // 判断是否有抽奖机会
  const remainCount = activityInfo?.lotteryInfo?.remainCount ?? 0;
  const canUsePointsForLottery = activityInfo?.canUsePointsForLottery;
  const hasPoints = activityInfo?.lotteryPointInfo?.availableDrawLotteryCount > 0;
  const canLottery = activityInfo?.status === "BEGIN" && (remainCount > 0 || (canUsePointsForLottery && hasPoints));

  // 判断是否显示无抽奖机会样式（活动已开始但无抽奖机会）
  const showNoChanceStyle = activityInfo?.status === "BEGIN" && !canLottery;

  // 判断活动是否未开始
  const isActivityNotStarted = activityInfo?.status === "ADVANCE";

  // 判断活动是否已结束
  const isActivityEnded = activityInfo?.status === "END" || activityInfo?.status === "CANCEL";

  // 处理抽奖点击事件
  const handleLotteryClick = () => {
    if (isActivityNotStarted) {
      Toast.show({
        content: "活动暂未开始～",
        position: "top",
      });
      return;
    }
    if (!canLottery) {
      Toast.show({
        content: "您暂时没有抽奖机会了哦",
        position: "top",
      });
      return;
    }
    callback && callback(activityInfo);
  };

  // 根据活动状态渲染不同的UI
  if (isActivityEnded) {
    return (
      <div className={styles.container}>
        <div className={`${styles.activityEndedButton}`}>
          <span>活动已结束</span>
        </div>
        <div className={styles.prompt}>
          {noticeText}
        </div>
      </div>
    );
  }

  if (isActivityNotStarted) {
    return (
      <div className={styles.container}>
        <div className={styles.lotteryBtn}>
          <div className={styles.innerLayer} onClick={handleLotteryClick}>
            <div className={styles.lotteryTextNotStart}>未开始</div>
          </div>
        </div>
      </div>
    );
  }

  // 无抽奖机会时使用图片展示
  if (showNoChanceStyle) {
    return (
      <div className={styles.container}>
        <div className={styles.noChanceImageContainer} onClick={handleLotteryClick}>
          <img
            src="https://akim-oss.aikucun.com/d85ae9cecb417aeb572b87c08417513278d8a31a_1742269431156_15.png"
            alt="暂无抽奖机会"
            className={styles.noChanceImage}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.lotteryBtn}>
        <div className={styles.innerLayer} onClick={handleLotteryClick}>
          <div className={styles.lotteryText}>参与抽奖</div>
          <div className={styles.lotteryCountInfo}>
            <LotteryDisplayText activityInfo={activityInfo} />
          </div>
        </div>
      </div>
      <div className={styles.prompt}>
        {noticeText}
      </div>
    </div>
  );
};

export default LotteryControl;