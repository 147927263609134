import { addShoppingCart, batchReceiveCoupon, checkCartList } from "@/api/cart";
import type { CartData, CartLine } from "@/api/cart/types.d";
import { showCaptcha } from "@/utils/captcha/captcha";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import { Dialog, Toast } from "antd-mobile";
import Cookie from "js-cookie";
import React, { useRef } from "react";
import PurchaseLimitContent from "../components/PurchaseLimitContent";

export const useCartActions = (
  cartData: CartData | null,
  cartShareNo: string | null,
  shopBizCode: string | null,
  searchParams: URLSearchParams,
  updateSelectedSku: (item: any, isSelected: boolean) => void,
  batchUpdateSelectedSku: (items: any[], isSelected: boolean) => void,
  getSelectedItems: (cartData: CartData | null) => CartLine[],
  createBindPhoneTask: () => Promise<void>,
  trackBtnClick: (button_name: string, extra?: Record<string, any>) => void,
) => {
  const { navigate2H5 } = useNavigation();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const rcsTraceIdRef = useRef<string>("");

  // 单个商品选择状态改变
  const handleItemSelect = (groupIndex: number, itemIndex: number) => {
    const group = cartData?.effectiveCommodityList?.[groupIndex];
    if (group?.cartLines?.[itemIndex]) {
      const item = group.cartLines[itemIndex];
      trackBtnClick(item.selected ? "取消勾选" : "选中商品", { skuId: item.externalSkuCode });
      updateSelectedSku(item, !item.selected);
    }
  };

  // 商品组选择状态改变
  const handleGroupSelect = (groupIndex: number, selected: boolean) => {
    const group = cartData?.effectiveCommodityList?.[groupIndex];
    if (group?.cartLines?.length) {
      // 使用批量更新函数，一次性更新所有商品
      batchUpdateSelectedSku(group.cartLines, !selected);
    }
  };

  // 全选/取消全选
  const handleSelectAll = (selected: boolean) => {
    if (cartData?.effectiveCommodityList?.length) {
      // 收集所有商品
      const allItems: any[] = [];
      cartData.effectiveCommodityList.forEach(group => {
        if (group.cartLines?.length) {
          allItems.push(...group.cartLines);
        }
      });

      // 使用批量更新函数，一次性更新所有商品
      batchUpdateSelectedSku(allItems, selected);
    }
  };

  const initCaptcha = (tpc: string, callback: (token: string) => void) => {
    showCaptcha(tpc).then(token => {
      callback(token ?? "");
    });
  };

  const autoReceiveCoupon = async (captchaToken = "") => {
    if (cartData?.toDrawAwardList?.length) {
      try {
        const data = await batchReceiveCoupon({
          couponByIdReqList: cartData?.toDrawAwardList,
          rcsTraceId: "",
          captchaToken,
          pageIndex: window.location.href,
          protocolVersion: "",
        });
        if (data?.rcsTraceId) {
          rcsTraceIdRef.current = data.rcsTraceId || "";
        }
        Toast.show({
          content: `已帮您自动领取${data}张优惠券，下单更省钱`,
          duration: 2000,
        });
      } catch (error: any) {
        // 处理验证码情况
        if (error.code === "666B00004") {
          initCaptcha(error.data && error.data.tpc, token => {
            autoReceiveCoupon(token);
          });
          return;
        }
        Toast.show({
          content: error?.message || "优惠券被领光啦",
          duration: 2000,
        });
      }
    }
  };

  // 一键购买处理函数
  const handleBuyAll = async () => {
    if (!cartShareNo) {
      Toast.show({
        content: "未找到有效的购物车分享信息",
        duration: 2000,
      });
      return;
    }

    const selectedItems = getSelectedItems(cartData);

    if (selectedItems.length === 0) {
      Toast.show({
        content: "请先选择商品",
        duration: 2000,
      });
      return;
    }

    // 检查是否需要绑定手机号
    await createBindPhoneTask();

    Toast.show({
      content: "加载中...",
      icon: "loading",
      duration: 0,
    });

    // 自动领券
    await autoReceiveCoupon();

    const data = await checkCartList({
      cartShareNo,
      shopBizCode: shopBizCode || "",
      cartProducts: getSelectedItems(cartData).map(item => ({
        activityId: item.activityNo,
        skuId: item.externalSkuCode,
        spuId: item.spuId,
      })),
    });

    const { invalidCommodityList, purchaseLimitCheckVO } = data;
    if (purchaseLimitCheckVO) {
      Toast.clear();
      const content = <PurchaseLimitContent info={purchaseLimitCheckVO} />;
      Dialog.alert({
        title: "提示",
        content,
      });
      return;
    }

    if (invalidCommodityList.length > 0) {
      Toast.clear();
      Dialog.alert({
        title: "提示",
        content: "哎呀，检测到存在失效商品，请核对之后重新下单～",
        onConfirm: () => {
          window.location.reload();
        }
      });
      return;
    }

    if (!data.isJumpConfirmPage) {
      Toast.clear();
      return;
    }

    trackBtnClick("一键购买");

    // 执行结算逻辑，跳转到确认订单页面
    const skuNoList = selectedItems.map(item => ({
      activityId: item.activityNo,
      skuId: item.externalSkuCode,
      spuId: item.spuId,
    }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      Toast.clear();
      navigate2H5(`${MICRO_APP_BASEURL}/#/confirmationOrder`, {
        query: {
          skuNoList: JSON.stringify(skuNoList),
          fromCart: 1,
          cartShareNo: cartShareNo,
        },
      });
    }, 2000);
  };

  // 一键加购处理函数
  const handleAddToCart = async () => {
    if (!cartShareNo) {
      Toast.show({
        content: "未找到有效的购物车分享信息",
        duration: 2000,
      });
      return;
    }

    // 检查是否需要绑定手机号
    await createBindPhoneTask();

    const selectedItems = getSelectedItems(cartData);

    if (selectedItems.length === 0) {
      Toast.show({
        content: "请先选择商品",
        duration: 2000,
      });
      return;
    }

    trackBtnClick("一键加购");

    Toast.show({
      content: "加载中...",
      icon: "loading",
      duration: 0,
    });

    const skus = selectedItems.map(item => ({
      skuNo: item.externalSkuCode,
      spuId: item.spuId,
      activityNo: item.activityNo,
      quantity: item.quantity,
    }));

    try {
      const response = (await addShoppingCart({
        shopBizCode: shopBizCode || undefined,
        skus,
        cartShareNo: cartShareNo,
      })) as any;

      const { successSkuNum, successMsg, failSkuNum, cartShareList = [], failMsg } = response;

      if (successSkuNum > 0) {
        Toast.show({
          content: successMsg,
          duration: 2000,
        });

        // 购物车的商品缓存逻辑
        if (cartShareList.length) {
          const skuNoList = cartShareList.map((item: any) => ({
            activityId: item.activityNo,
            skuId: item.externalSkuCode,
            spuId: item.spuId,
          }));

          const openId = Cookie.get("msOpenId");
          const shopId = searchParams.get("shopId");

          if (shopId && openId) {
            localStorage.setItem(
              `${shopId}:${openId}:selectedSkuObjList`,
              JSON.stringify(skuNoList),
            );
          }
        }

        // Toast结束之后再跳转到购物车
        // 保存定时器引用以便清除
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          Toast.clear();
          navigate2H5(`${MICRO_APP_BASEURL}/#/cart`);
        }, 2000);
      } else {
        Toast.show({
          content: failMsg,
          duration: 2000,
        });
        // Toast结束之后需要刷新页面
        // 保存定时器引用以便清除
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          Toast.clear();
          window.location.reload();
        }, 2000);
      }
    } catch (error: any) {
      Toast.show({
        content: error?.message || "一键加购失败",
        duration: 2000,
      });
    }
  };

  return {
    handleItemSelect,
    handleGroupSelect,
    handleSelectAll,
    handleBuyAll,
    handleAddToCart,
    timerRef,
  };
};
