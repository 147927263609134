import React from "react";
import clsx from "clsx";
import styles from "../index.module.less";
import iconLink from "@/assets/icon_link.png";
import iconCard from "@/assets/icon_card.png";
import iconWritting from "@/assets/icon_writting.png";

interface ActionRenderProps {
  btnConfig: string[];
  contentType: string;
  currentRole: string;
  onCopy: () => void;
  onEdit: () => void;
  onCopyWriting: () => void;
}

const ActionRender: React.FC<ActionRenderProps> = ({
  btnConfig,
  contentType,
  currentRole,
  onCopy,
  onEdit,
  onCopyWriting,
}) => {
  return (
    <div className={clsx(styles["poster__action"], styles["safe--bottom"])}>
      <div className={styles["poster__action__list"]}>
        {btnConfig.length > 0 && btnConfig.includes("copy") && (
          <div className={styles["poster__action__item"]} onClick={onCopy}>
            <div className={styles["item-wrapper"]}>
              <img className={styles["icon"]} src={iconLink} alt="" />
              <span className={styles["text"]}>复制链接</span>
            </div>
          </div>
        )}
        {btnConfig.length > 0 && btnConfig.includes("edit") && (
          <div className={styles["poster__action__item"]} onClick={onEdit}>
            <div className={styles["item-wrapper"]}>
              <img className={styles["icon"]} src={iconCard} alt="" />
              <span className={styles["text"]}>转发卡片</span>
            </div>
          </div>
        )}
        {["ACTIVITY", "SPU_PRODUCT"].includes(contentType) && currentRole !== "C" && (
          <div className={styles["poster__action__item"]} onClick={onCopyWriting}>
            <div className={styles["item-wrapper"]}>
              <img className={styles["icon"]} src={iconWritting} alt="" />
              <span className={styles["text"]}>复制文案</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionRender;
