import React from "react";
import styles from "./index.module.less";

interface Props {
  text?: any;
  anotherStyle?: any;
}

const EmptyView: React.FC<Props> = (props: Props) => {
  const { text } = props;

  return (
    <div className={`${styles.container} ${props.anotherStyle} `}>
      <img src={"https://akim-oss.aikucun.com/9c4c787222f9989045c7519daef042d2f833c84f_1740993722532_16.png"} alt="empty" className={styles.errorImg} />

      <div className={styles.errorText}>{text}</div>

    </div>
  );
};

export default EmptyView;
