import { MICRO_APP_BASEURL, platform } from "@/utils/platform";
import Track from "@/utils/track";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./TopBackSticky.module.less";

const TopBackSticky = ({ isAbsolute = true }) => {
  const navigater = useNavigate();
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const track = Track();
  const goBackOrHome = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          if (search.get("distributorId")) {
            navigater(
              `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
                "distributorId",
              )}`,
            );
          } else {
            navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
          }
        }
      }, 500);
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  const goHome = () => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: "首页",
        btn_name: "首页",
        btn_text: "首页",
      });
    }
    /* eslint-enable */
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  };
  if (!platform.isH5) {
    return null;
  }

  return (
    <div className={`${styles.wrap} ${!isAbsolute && styles.wrap_relative}`}>
      <img
        onClick={() => goBackOrHome()}
        className={styles.left_back}
        src="https://akim-oss.aikucun.com/1d513c0bcbe33b2e7440e5e14d0b22ef95c9d673_1723720440170_73.png"
      />
      <div className={styles.line}></div>
      <img
        onClick={() => goHome()}
        className={styles.left_home}
        src="https://akim-oss.aikucun.com/76546f9a641ede2beab506b96df1688d889e629a_1723720440458_3.png"
      />
      {/* <div onClick={() => goHome()} className={styles.text}>
        首页
      </div> */}
    </div>
  );
};

export default TopBackSticky;
