import { ILabelItem, IMaterialRsp, queryCloudAlbumLabelTable, queryMaterialContentPage } from "@/api/material";
import MediaPreview from "@/components/media-preview";
import { onShareAppMessageByBrandMaterial } from "@/utils/actions";
import Track from "@/utils/track";
import { AIMaterialMediaModel, CloudAlbumExtInfoDTO, } from "@akc/biz-api";
// import { MaterialMediaModel } from "@akc/biz-api";
import { Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
// import { StickyPosition } from "react-vant";
import styles from "./index.module.less";




interface Props {
  activityId?: string;
  activitySpuId?: string;
  selectSkuId?: string;
  itemCode?: string;
}

const MediaList: React.FC<Props> = () => {
  const [search] = useSearchParams();
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [showEmpty, setShowEmpty] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaList, setMediaList] = useState<AIMaterialMediaModel[]>([]);
  const [labels, setLabels] = useState<ILabelItem[]>([]);
  const [labelLoaded, setLabelLoaded] = useState(false);
  const [activeLabelId, setactiveLabelId] = useState<string>("");
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const mediaGridRef = useRef<HTMLDivElement>(null);
  const track = Track();

  // 预览媒体内容
  const onPreviewMedia = (index: number) => {
    setCurrentIndex(index);
    // tran
    setPreviewVisible(true);
    track.track("resource_click", {
      page_name: "更多图/视频页",
      resource_type: "内容",
      resource_name: "预览",
      activity_spu_code: search.get("activitySpuId"),
      resource_rank: index + 1
    });
  };

  // 查询标签列表
  const getLabels = async () => {
    try {
      const params = {
        userId: "",
        itemCode: search.get("itemCode") || "",
        publish: 1
      };
      const res = await queryCloudAlbumLabelTable(params);
      if (res?.length) {
        // 设置标签列表
        setLabels(res || []);
        // 默认选中第一个标签
        if (res[0]?.labelId !== undefined) {
          setactiveLabelId(String(res[0]?.labelId || ""));
        }
      }
    } catch (error: any) {
      Toast.show(`获取标签失败: ${error?.message ?? "未知错误"}`);
    };
    setLabelLoaded(true);
  };

  /**
   * 获取图文内容列表
   * @param reset 是否重置列表
   * @returns 
   */
  const getMediaContentList = async (reset = false) => {
    if (loading || !hasMore) { return; }
    setLoading(true);

    try {
      const currentPage = reset ? 1 : pageIndex;
      const params = {
        contentType: 2, // 内容类型：1 文章 2 图片 3 视频
        itemCode: search.get("itemCode") || "",
        activitySpuCode: search.get("activitySpuId") || "",
        labelId: activeLabelId,
        publish: 1, // 发布状态：0、不发布；1、发布
        pageIndex: currentPage,
        pageSize: 20
      };

      const pageData = await queryMaterialContentPage(params);

      if (pageData?.result?.length) {
        if (reset) {
          setMediaList(pageData.result);
        } else {
          setMediaList(prev => [...prev, ...pageData.result]);
        }

        setPageIndex(currentPage + 1);
        setHasMore(pageData.hasNext || false);
        setShowEmpty(false);
      } else if (reset || currentPage === 1) {
        setMediaList([]);
        setShowEmpty(true);
        Toast.show("暂无数据哦~");
      } else {
        setHasMore(false);
      }
    } catch (error: any) {
      if (reset || pageIndex === 1) {
        setShowEmpty(true);
        setMediaList([]);
      }
      Toast.show(`${error?.message ?? "获取数据失败"} `);
    } finally {
      setLoading(false);
    }
  };

  // 处理标签点击
  const handleLabelClick = (labelId: number | string) => {
    // 如果点击的是当前选中的标签，不做处理
    if (labelId === activeLabelId) { return; }

    // 设置选中的标签ID
    setactiveLabelId(String(labelId || ""));
    setPageIndex(1);
    setHasMore(true);
  };

  useEffect(() => {
    // 获取标签列表
    getLabels();
    onShareAppMessageByBrandMaterial();
  }, []);
  // 在标签加载完成后获取媒体列表
  useEffect(() => {
    if (labelLoaded) {
      getMediaContentList(true);
    }
  }, [labelLoaded, activeLabelId]);

  // 处理滚动加载更多
  const handleScroll = () => {
    if (!mediaGridRef.current || loading) { return; }

    const { scrollTop, clientHeight, scrollHeight } = mediaGridRef.current;
    // 滚动到底部时加载更多
    if (scrollTop + clientHeight >= scrollHeight - 50) {
      getMediaContentList();
    }
  };



  useEffect(() => {
    // 添加滚动事件监听
    const mediaGrid = mediaGridRef.current;
    if (mediaGrid) {
      mediaGrid.addEventListener("scroll", handleScroll);
    }

    return () => {
      // 移除滚动事件监听
      if (mediaGrid) {
        mediaGrid.removeEventListener("scroll", handleScroll);
      }
    };
  });
  const mediaListCpnt = (
    <>
      <div className={styles.mediaGrid} ref={mediaGridRef}>
        {mediaList?.map((item, index) => (
          <div className={styles.mediaItem} key={item.id}>
            {item.cloudAlbumExtInfoDTOList?.length && (
              <div className={styles.mediaText}>文案</div>
            )}
            <div className={styles.mediaContent} onClick={() => onPreviewMedia(index)} data-index={index}>
              <img
                className={styles.mediaImage}
                src={item.coverImgUrl || item.content || ""}
                alt=""
                style={{ objectFit: "cover" }}
              />
              {item.contentType === 3 && (
                <div className={styles.videoIcon} data-index={index}>
                  <img
                    className={styles.videoIconImage}
                    src="https://akim-oss.aikucun.com/ffbaf58f1231628f9ac2a583f038b51719006ec6_1731379484590_43.png"
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        ))}
        {loading && (
          <div className={styles.loading}>加载中...</div>
        )}
      </div>
      <div className={styles.btmPlaceholder}></div>
    </>
  );

  const emptyCpnt = (
    <div className={styles.empty}>
      <img src="https://akim-oss.aikucun.com/27eb0c6ff79db3c21d671e006e26e3ce031d3751_1736577935723_41.png" />
      <div>暂无数据哦~</div>
    </div>
  );

  return (
    <div className={styles.container}>
      {/* 标签tabs */}
      <div className={styles.tabsContainer}>
        <div className={styles.tabsScroll}>
          {labels.map((label) => (
            <div
              key={label.labelId}
              className={`${styles.tabItem} ${activeLabelId === String(label.labelId) ? styles.activeTab : ""}`}
              onClick={() => handleLabelClick(label.labelId || "")}
            >
              {label.labelName}
            </div>
          ))}
        </div>
      </div>

      {/* 图文列表 */}
      {!showEmpty ? mediaListCpnt : emptyCpnt}

      {isPreviewVisible && (
        <MediaPreview
          mediaList={mediaList || []}
          currentIndex={currentIndex}
          onClose={() => setPreviewVisible(false)}
        />
      )}
    </div>
  );
};

export default MediaList;
