// 1. React 相关导入
import { useCallback, useRef, useState } from "react";

/**
 * 创建一个可以通过 Promise 控制的弹窗函数
 * @returns 包含控制弹窗显示的函数和状态
 */
export const useNoticeControl = () => {
  // 是否自动播放
  const [autoplay, setAutoplay] = useState(false);
  // 解决函数引用
  const resolveRef = useRef<(() => void) | null>(null);

  /**
   * 开始显示通知
   * @returns 返回一个 Promise，当通知关闭时 resolve
   */
  const start = useCallback(() => {
    return new Promise<void>(resolve => {
      // 保存 resolve 函数以便后续调用
      resolveRef.current = resolve;
      // 设置自动播放为 true，触发组件检查
      setAutoplay(true);
    });
  }, []);

  /**
   * 通知检查完成的回调
   * @param hasNotice 是否有通知需要显示
   */
  const onCheckComplete = useCallback((hasNoticeValue: boolean) => {
    // 如果没有通知，直接完成 Promise
    if (!hasNoticeValue && resolveRef.current) {
      resolveRef.current();
      resolveRef.current = null;
    }
  }, []);

  /**
   * 通知关闭的回调
   */
  const onClose = useCallback(() => {
    // 通知关闭时，完成 Promise
    if (resolveRef.current) {
      resolveRef.current();
      resolveRef.current = null;
    }
    // 重置自动播放状态
    setAutoplay(false);
  }, []);

  return {
    autoplay,
    start,
    onCheckComplete,
    onClose,
  };
};
