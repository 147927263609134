import PosterSwipe from "@/components/poster-swiper";
import { sharePosterParams4H5Material } from "@/utils/actions";
import React, { useState } from "react";
import styles from "./index.module.less";

const ShopDispatchMaterial: React.FC<any> = props => {
  const { cardInfo } = props;
  const { materialVO: materialInfo, ...mainInfo } = cardInfo || {};

  const [showPoster, setShowPoster] = useState(false);
  const [sharePosterData, setSharePosterData] = useState<any>({});
  // const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);

  // 类型 2：图片 3：视频
  const coverVOItem =
    (materialInfo?.coverVOList || []).find(item => [2, 3].includes(item?.coverType)) ?? {};
  const coverUrl = coverVOItem?.coverUrl ?? "";

  const onShareMaterial = async () => {
    setSharePosterData(
      await sharePosterParams4H5Material({
        materialId: materialInfo?.materialNo,
        activitySpuId: materialInfo?.activitySpuId,
      }),
    );
    // setCustomEditData({
    //   activitySpuId: mainInfo?.spuId,
    //   activityId: mainInfo?.activityId,
    //   selectSkuId: mainInfo?.skuId,
    //   materialNo: materialInfo?.materialNo,
    // });
    setShowPoster(true);
  };

  const onClosePoster = () => {
    setSharePosterData({} as any);
    setShowPoster(false);
    document.body.style.overflow = "unset";
  };

  return (
    <>
      <div className={styles["material-card"]}>
        <div className={styles["card__left"]}>
          <img className={styles["left__cover"]} src={coverUrl} />
        </div>
        <div className={styles["card__right"]}>
          <div className={styles["right__up"]}>
            <div className={styles["product-title"]}>
              {materialInfo.name || materialInfo.groupBuyDescribe}
            </div>
            <div className={styles["material__data"]}>
              <div className={styles["data__buyer"]}>
                {materialInfo.groupHeaders &&
                  materialInfo.groupHeaders.map((item, index) => (
                    <div className={styles["buyer__avatars"]} key={index}>
                      <img
                        className={`${styles["avatar"]} ${index > 0 ? styles["avatar--negative"] : ""}`}
                        src={item}
                      />
                    </div>
                  ))}
                <span className={styles["buyer__desc"]}>{materialInfo.groupPerson}</span>
              </div>
              <div
                className={`${styles["data__browse-person"]} ${materialInfo.groupHeaders ? styles["data__browse-person--both"] : ""}`}>
                {materialInfo.browsePerson}
              </div>
            </div>
          </div>
          <div className={styles["right__down"]}>
            <div className={styles["price-box"]}>
              <span className={styles["price"]}>{materialInfo.priceRange}</span>
              <span className={styles["commission"]}>省赚¥{materialInfo.commissionRange}</span>
            </div>
            <div className={styles["btn__share"]} onClick={onShareMaterial}>
              立即转发
            </div>
          </div>
        </div>
      </div>
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        // customEditData={customEditData}
        shareType="MATERIAL3"
        // btnConfig={["copy", "edit"]}
        close={onClosePoster}
      />
    </>
  );
};

export default ShopDispatchMaterial;
