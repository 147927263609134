import microApp from "@micro-zoe/micro-app";
import "core-js/features/queue-microtask"; // framer-motion 使用了 queueMicrotask (iOS12.1 及以下版本不支持)
import eruda from "eruda";
import React from "react";
import { AliveScope } from "react-activation";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "./utils/flexible";
import { initSentry } from "./utils/sentry";

initSentry();

microApp.start();

try {
  if (
    window.location.href.indexOf("AkcDebugger") > 0 ||
    process.env.REACT_APP_ENV === "local" ||
    process.env.REACT_APP_ENV === "stable" ||
    process.env.REACT_APP_ENV === "release"
  ) {
    eruda.init();
  }
} catch (err) {
  console.warn(err);
}

ReactDOM.render(
  <AliveScope>
    <App />
  </AliveScope>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from "./reportWebVitals";
// reportWebVitals(console.warn);
