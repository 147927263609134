import {
  changePhone as changePhoneApi,
  checkChangeMobileTimesFlag,
  checkCode as checkCodeApi,
  queryDefaultPhone,
  verifyCode2 as sendNewPhoneVerifyCodeApi,
  verifyCode as sendVerifyCodeApi
} from "@/api/my/changePhone";
import useNavigation from "@/utils/useNavigate";
import { Dialog, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";
const ChangePhone: React.FC = () => {
  const { navigateBack } = useNavigation();
  const [currentPhone, setCurrentPhone] = useState<string>("");
  const [countdown, setCountdown] = useState<number>(0);
  const [newPhoneCountdown, setNewPhoneCountdown] = useState<number>(0);
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [newPhoneVerifyCode, setNewPhoneVerifyCode] = useState<string>("");
  const [newPhone, setNewPhone] = useState<string>("");
  const [step, setStep] = useState<number>(1); // 1: 初始状态, 2: 验证原手机号, 3: 输入新手机号
  const [loading, setLoading] = useState<boolean>(false);


  // 获取默认手机号
  const fetchDefaultPhone = async () => {
    try {
      setLoading(true);
      const response = await queryDefaultPhone() || "";
      setCurrentPhone(response);
    } catch (error) {
      console.error("获取手机号失败", error);
      Toast.show({
        content: "获取手机号失败，请稍后重试",
        position: "center",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "修改手机号";
    // 从接口获取默认手机号
    fetchDefaultPhone();
  }, []);



  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => {
      if (timer) { clearTimeout(timer); }
    };
  }, [countdown]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (newPhoneCountdown > 0) {
      timer = setTimeout(() => {
        setNewPhoneCountdown(newPhoneCountdown - 1);
      }, 1000);
    }
    return () => {
      if (timer) { clearTimeout(timer); }
    };
  }, [newPhoneCountdown]);

  // 发送验证码
  const sendVerifyCode = async () => {
    try {
      setLoading(true);
      await sendVerifyCodeApi();
      setCountdown(60);
      Toast.show({
        content: "验证码已发送",
        position: "center",
      });
    } catch (error) {
      console.error("发送验证码失败", error);
      Toast.show({
        content: "发送验证码失败，请稍后重试",
        position: "center",
      });
    } finally {
      setLoading(false);
    }
  };

  // 发送新手机号验证码
  const sendNewPhoneVerifyCode = async () => {
    if (!newPhone) {
      Toast.show({
        content: "请输入新手机号",
        position: "center",
      });
      return;
    }

    if (!/^1[1-9]\d{9}$/.test(newPhone)) {
      Toast.show({
        content: "请输入正确的手机号码",
        position: "center",
      });
      return;
    }

    try {
      setLoading(true);
      await sendNewPhoneVerifyCodeApi({ phone: newPhone });
      setNewPhoneCountdown(60);
      Toast.show({
        content: "验证码已发送",
        position: "center",
      });
    } catch (error: any) {
      console.error("发送验证码失败", error);
      // 处理手机号已注册的情况
      if (error?.message?.includes("该手机已注册")) {
        Dialog.show({
          content: (
            <div className={styles.dialogContent}>
              <h3>提示</h3>
              <div className={styles.agreementText}>
                {error?.data?.headPicture && (
                  <div className={styles.headPictureContainer}>
                    <img
                      src={error.data.headPicture}
                      alt="头像"
                      className={styles.headPicture}
                    />
                  </div>
                )}
                <p>该手机号已绑定过其他微信账号，请选择其他手机号</p>
              </div>
              <div className={styles.dialogButtons}>
                <button
                  className={styles.confirmBtn}
                  onClick={() => Dialog.clear()}
                >
                  我知道了
                </button>
              </div>
            </div>
          ),
          closeOnMaskClick: true,
        });
      } else {
        Toast.show({
          content: error?.message || "发送验证码失败，请稍后重试",
          position: "center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // 处理同意并继续
  const handleAgreeAndContinue = async () => {
    try {
      setLoading(true);
      await checkChangeMobileTimesFlag();
      Dialog.clear();
      await sendVerifyCode();
      setStep(2);
    } catch (error: any) {
      console.error("校验失败", error);
      Dialog.clear();
      Toast.show({
        content: error?.message || "操作失败，请稍后重试",
        position: "center",
      });
    } finally {
      setLoading(false);
    }
  };

  // 显示协议确认弹窗
  const handleChangePhone = () => {
    Dialog.show({
      content: (
        <div className={styles.dialogContent}>
          <h3>用户协议确认</h3>
          <div className={styles.agreementText}>
            <p>为了保障您的账户安全，在修改手机号前请仔细阅读并确认同意：</p>
            <p>手机号用于您的账户登录、安全验证、接收信息。手机号修改成功后，原手机号将无法再用于登录、安全验证、接收信息，与其他关联产品的手机号将同步更新。若修改后的手机号非您本人持有，您可能会无法正常使用相关联产品提供的功能或服务。</p>
          </div>
          <div className={styles.dialogButtons}>
            <button className={styles.cancelBtn} onClick={() => Dialog.clear()}>
              取消
            </button>
            <button
              className={styles.confirmBtn}
              onClick={handleAgreeAndContinue}
            >
              同意并继续
            </button>
          </div>
        </div>
      ),
      closeOnMaskClick: true,
    });
  };



  // 验证原手机号验证码
  const handleNextStep = async () => {
    if (!verifyCode) {
      Toast.show({
        content: "请输入验证码",
        position: "center",
      });
      return;
    }

    try {
      setLoading(true);
      await checkCodeApi({ code: verifyCode });
      setStep(3);
      setVerifyCode("");
    } catch (error: any) {
      console.error("验证码校验失败", error);
      Toast.show({
        content: error?.message || "验证码校验失败，请重新输入",
        position: "center",
      });
    } finally {
      setLoading(false);
    }
  };

  // 提交新手机号和验证码
  const handleConfirmChange = async () => {
    if (!newPhone) {
      Toast.show({
        content: "请输入新手机号",
        position: "center",
      });
      return;
    }

    if (!/^1[1-9]\d{9}$/.test(newPhone)) {
      Toast.show({
        content: "请输入正确的手机号码",
        position: "center",
      });
      return;
    }

    if (!newPhoneVerifyCode) {
      Toast.show({
        content: "请输入验证码",
        position: "center",
      });
      return;
    }

    try {
      setLoading(true);
      await changePhoneApi({ phone: newPhone, code: newPhoneVerifyCode });
      Toast.show({
        content: "修改成功",
        position: "center",
        afterClose: () => {
          // 返回上一页
          setTimeout(() => {
            navigateBack();
          }, 500);
        },
      });
    } catch (error: any) {
      console.error("修改手机号失败", error);
      Toast.show({
        content: error?.message || "修改手机号失败，请稍后重试",
        position: "center",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {step === 1 && (
        <div className={styles.stepOne}>
          <div className={styles.header}>
            <div className={styles.currentPhoneLabel}>当前手机号码</div>
            <div className={styles.currentPhone}>{currentPhone}</div>
          </div>
          <button className={styles.changeBtn} onClick={handleChangePhone} disabled={loading}>
            更换手机号
          </button>
        </div>
      )}

      {step === 2 && (
        <div className={styles.stepTwo}>
          <div className={styles.verifyTitle}>
            验证码已发送至 {currentPhone}，请输入验证码
          </div>
          <div className={styles.inputGroup}>
            <input
              type="text"
              maxLength={6}
              className={styles.verifyInput}
              placeholder="请输入验证码"
              value={verifyCode}
              onChange={(e) => {
                // 只允许输入数字
                const value = e.target.value.replace(/\D/g, "");
                setVerifyCode(value);
              }}
            />
            <button
              className={styles.sendCodeBtn}
              disabled={countdown > 0 || loading}
              onClick={sendVerifyCode}
            >
              {countdown > 0 ? `重新发送(${countdown})` : "重新发送"}
            </button>
          </div>
          <button className={styles.nextBtn} onClick={handleNextStep} disabled={loading}>
            下一步
          </button>
        </div>
      )}

      {step === 3 && (
        <div className={styles.stepThree}>
          <div className={styles.inputTitle}>请输入新手机号码</div>
          <div className={styles.phoneInputGroup}>
            <input
              type="text"
              className={styles.phoneInput}
              placeholder="请输入新手机号码"
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
              maxLength={11}
            />
          </div>
          <div className={styles.inputGroup}>
            <input
              type="text"
              maxLength={6}
              className={styles.verifyInput}
              placeholder="请输入验证码"
              value={newPhoneVerifyCode}
              onChange={(e) => {
                // 只允许输入数字
                const value = e.target.value.replace(/\D/g, "");
                setNewPhoneVerifyCode(value);
              }}
            />
            <button
              className={styles.sendCodeBtn}
              disabled={newPhoneCountdown > 0 || loading}
              onClick={sendNewPhoneVerifyCode}
            >
              {newPhoneCountdown > 0 ? `重新发送(${newPhoneCountdown})` : "获取验证码"}
            </button>
          </div>
          <button className={styles.confirmBtn} onClick={handleConfirmChange} disabled={loading}>
            确认更换
          </button>
        </div>
      )}
    </div>
  );
};

export default ChangePhone;