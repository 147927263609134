import { platform } from "@/utils/platform";
import AKJS from "@akc/akjs";

const getUserId = async () => {
  if (platform.isApp) {
    const res: any = await AKJS.ready().then(() => {
      // account() 返回结构体：http://git.akcops.com/bfe-infra/jsbridge/docs/-/blob/master/akc/User.md
      return AKJS.info.account();
    });
    return res?.data?.userID || "anonymous";
  }
  try {
    // 从 localStorage 获取 userinfo 字符串
    const userInfoStr = localStorage.getItem("userinfo");

    // 如果存在 userinfo，解析 JSON 并获取 openId
    if (userInfoStr) {
      const userInfo = JSON.parse(userInfoStr);
      if (userInfo && userInfo.openId) {
        return userInfo.openId;
      }
    }

    // 如果没有找到有效的 openId，返回默认值
    return "anonymous";
  } catch (error) {
    // 处理 JSON 解析错误或其他异常
    console.error("获取用户ID失败:", error);
    return "anonymous";
  }
};

const shouldShowHighValueOther = async () => {
  const today = new Date().toISOString().split("T")[0];
  const userId = await getUserId();
  const storageKey = `highValueOtherLastShow_${userId}`;

  const lastShowDate = localStorage.getItem(storageKey);

  if (!lastShowDate || lastShowDate !== today) {
    return true;
  }

  return false;
};

const updateShowHighValueOther = async () => {
  const today = new Date().toISOString().split("T")[0];
  const userId = await getUserId();
  const storageKey = `highValueOtherLastShow_${userId}`;

  const lastShowDate = localStorage.getItem(storageKey);

  if (!lastShowDate || lastShowDate !== today) {
    localStorage.setItem(storageKey, today);
  }
};

export { getUserId, shouldShowHighValueOther, updateShowHighValueOther };
