import { queryCartData, querySelectedSku, selectSku } from "@/api/cart";
import type { CartData } from "@/api/cart/types.d";
import { checkIdentity } from "@/utils/identity";
import { Toast } from "antd-mobile";
import { useEffect, useRef, useState } from "react";

// 定义类型
export interface SkuItem {
  activityId: string;
  skuId: string;
  spuId: string;
}

export const useCartData = (
  cartShareNo: string | null,
  shopBizCode: string | null,
  selectedSku: SkuItem[],
  setSelectedSku: (newSku: SkuItem[]) => void,
  updateCartData: (newData: CartData) => void,
  getShowPrevGoodsToast: () => boolean,
) => {
  // 购物车数据状态
  const isInit = useRef(false);
  const [loading, setLoading] = useState(false);
  const [cartData, setCartData] = useState<CartData | null>(null);
  const [goodsCount, setGoodsCount] = useState(0);

  // 加载选中的商品
  const loadSelectedSku = async (shareNo: string) => {
    setLoading(true);
    try {
      const response = (await querySelectedSku({ cartShareNo: shareNo })) as any;

      if (response?.number) {
        setGoodsCount(response.number);
      }

      if (response?.shareProductList?.length) {
        const mappedSkus = response.shareProductList.map((item: any) => ({
          activityId: item.activityNo,
          skuId: item.externalSkuCode,
          spuId: item.spuId,
        }));
        return mappedSkus;
      } else {
        return [];
      }
    } catch (error: any) {
      // 只有在有数据的情况下才显示Toast，第一次加载失败时使用空态组件展示
      Toast.clear();
      if (error?.message && error?.message.includes("未登录")) {
        checkIdentity();
        return;
      }
      Toast.show({
        content: error?.message || "获取选中的商品数据失败",
        duration: 2000,
      });
      console.error("获取选中的商品数据失败:", error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  // 加载购物车分享数据
  const loadCartData = async (shareNo: string) => {
    // 只有第一次加载时显示空态页loading
    if (!cartData) {
      setLoading(true);
    } else if (!getShowPrevGoodsToast()) {
      Toast.show({
        content: "加载中...",
        icon: "loading",
        duration: 0,
      });
    }

    try {
      const response = await queryCartData({
        shopBizCode: shopBizCode || undefined,
        cartShareNo: shareNo,
        cartProducts: selectedSku,
      });
      if (response) {
        setCartData(response);
        updateCartData(response);
      }
      if (!getShowPrevGoodsToast()) {
        Toast.clear();
      }
      console.log("获取到购物车分享数据:", response);
    } catch (error: any) {
      // 只有在有数据的情况下才显示Toast，第一次加载失败时使用空态组件展示
      Toast.clear();
      if (error?.message && error?.message.includes("未登录")) {
        checkIdentity();
        return;
      }
      Toast.show({
        content: error?.message || "获取购物车数据失败",
        duration: 2000,
      });
      console.error("获取购物车数据失败:", error);
    } finally {
      setLoading(false);
    }
  };

  // 初始加载选中的商品
  useEffect(() => {
    if (cartShareNo) {
      loadSelectedSku(cartShareNo).then(res => {
        isInit.current = true;
        setSelectedSku(res);
      });
    }
  }, [cartShareNo]);

  // 当选中的商品变化时，更新购物车数据
  useEffect(() => {
    if (cartShareNo && isInit.current) {
      // 先调用 API 更新选中状态
      selectSku({ cartShareNo, cartProducts: selectedSku });
      // 然后加载最新的购物车数据
      loadCartData(cartShareNo);
    }
  }, [cartShareNo, selectedSku]);

  return {
    loading,
    cartData,
    goodsCount,
    loadCartData,
    loadSelectedSku,
    setCartData,
  };
};
