import { useCommonContext } from "@/utils/context/common";
import { platform } from "@/utils/platform";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { Badge } from "antd-mobile";
import React, { useEffect, useRef } from "react";
import styles from "./index.module.less";

interface CardTopActionProps {
  onBackClick?: () => void;
  onHomeClick?: () => void;
  onCartClick?: () => void;
}

const CardTopAction: React.FC<CardTopActionProps> = ({
  onBackClick,
  onHomeClick,
  onCartClick,
}) => {
  const { navigate2Home, navigate2Cart } = useNavigation();
  const track = Track();
  const timerRef = useRef<number | null>(null);
  const { cartNum } = useCommonContext();

  // 清理定时器的副作用
  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        window.clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  // 记录埋点的通用函数
  const trackEvent = (btnName: string, btnText: string, moduleName = "首页") => {
    try {
      /* eslint-disable */
      if (track.track) {
        track.track("btn_click", {
          page_name: document.title,
          module: moduleName,
          btn_name: btnName,
          btn_text: btnText,
        });
      }
      /* eslint-enable */
    } catch (error) {
      console.error("埋点记录失败:", error);
    }
  };

  // 处理返回按钮点击
  const handleBackClick = () => {
    // 记录返回按钮点击埋点
    trackEvent("返回", "返回", "导航");

    // 执行自定义回调
    if (onBackClick) {
      onBackClick();
      return;
    }

    try {
      if (history.length > 1) {
        const url = location.href;
        history.back();

        // 使用 ref 保存定时器 ID，以便在组件卸载时清除
        if (timerRef.current !== null) {
          window.clearTimeout(timerRef.current);
        }

        timerRef.current = window.setTimeout(() => {
          if (location.href === url) {
            navigate2Home();
          }
          timerRef.current = null;
        }, 500);
      } else {
        navigate2Home();
      }
    } catch (error) {
      console.error("返回操作失败:", error);
      navigate2Home();
    }
  };

  // 处理首页按钮点击
  const handleHomeClick = () => {
    // 无论是否提供回调，都记录埋点
    trackEvent("首页", "首页");

    // 执行自定义回调
    if (onHomeClick) {
      onHomeClick();
      return;
    }

    navigate2Home();
  };

  // 处理购物车按钮点击
  const handleCartClick = () => {
    // 记录购物车按钮点击埋点
    trackEvent("购物车", "购物车", "购物");

    if (onCartClick) {
      onCartClick();
    }
    navigate2Cart();
  };

  if (!platform.isH5) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftActions}>
        <div className={styles.actionItem} onClick={handleBackClick}>
          <img
            src="https://akim-oss.aikucun.com/3ee1715f76666e884f0396abe62bab8071651dd5_1742551640201_44.png"
            alt="返回"
            className={styles.icon}
          />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.actionItem} onClick={handleHomeClick}>
          <img
            src="https://akim-oss.aikucun.com/5567dbd1fac515819e463c101397b36afc3068c1_1742551630555_75.png"
            alt="首页"
            className={styles.icon}
          />
        </div>
      </div>
      <div className={styles.rightActions}>
        <div className={styles.actionItem} onClick={handleCartClick}>
          <Badge content={!!cartNum && cartNum > 0 ? cartNum.toString() : null} className={styles.badge}>
            <img
              src="https://akim-oss.aikucun.com/66bf094b93699bf88cf9a80e97e192a19901cace_1742551630556_21.png"
              alt="购物车"
              className={styles.icon}
            />
          </Badge>
        </div>
      </div>
    </div>
  );
};

export default CardTopAction;