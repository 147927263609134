import {
  AIMaterialItem,
} from "@akc/biz-api";
import { Popup, Toast } from "antd-mobile"; // 引入 Popup 和 Button
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import styles from "./index.module.less";

type Props = {
  moduleList?: AIMaterialItem[];
  showAiPopup: boolean;
  onClose: () => void;
};

const AiContentPopup: React.FC<Props> = ({ moduleList = [], showAiPopup, onClose }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (index: number) => {
    setCurrentTab(index);
  };

  const currentList = moduleList[currentTab]?.materialContextRecommendDTOS || [];

  // 复制功能
  const handleCopy = (text: string) => {
    copy(text);
    Toast.show({
      content: "复制成功",
      maskStyle: { zIndex: 100001 },
    });
  };

  return (
    <Popup visible={showAiPopup} onMaskClick={onClose} position="bottom" style={{ height: "85%" }}>
      <div className={styles.popupContainer}>
        <div className={styles.popupHeader}>
          <div className={styles.title}>AI文案</div>
          <div className={styles.closeIcon} onClick={onClose}></div>
        </div>

        {Array.isArray(moduleList) && moduleList.length > 1 && (
          <div className={styles.tabWrapper}>
            {moduleList.map((item, index) => (
              <div
                key={index}
                className={`${styles.tabItem} ${currentTab === index ? styles.active : ""}`}
                onClick={() => handleTabChange(index)}
              >
                {item.moduleName}
              </div>
            ))}
          </div>
        )}

        <div className={styles.contentList}>
          {currentList.map((item, index) => (
            <div key={index} className={styles.contentItem}>
              <pre className={styles.contentText}>{item.context}</pre>
              <div className={styles.contentBottom}>
                {typeof item.cnt === "number" && item.cnt > 0 && (
                  <div className={styles.usageCount}>
                    <img
                      className={styles.usageIcon}
                      src="https://akim-oss.aikucun.com/74f529b607aafbf5841c91c38eb57a41b8be30c6_1731921628600_90.png"
                      alt="usage"
                    />
                    <span>{item.forwardCondition || ""}</span>
                  </div>
                )}
                <div className={styles.copyBox} onClick={() => { handleCopy(item.context || ""); }}>
                  <img className={styles.copyIcon} src="https://xiangdian-oss.aikucun.com/app_akc_ios/4edd1d00875d8edeec08e27ad5650fe11fad1d8d_1711721527908_10.png" />
                  <div>复制</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Popup>
  );
};



export default AiContentPopup;