import CLOSE from "@/assets/icon_close_gray.png";
import { Popup, Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import styles from "./index.module.less";

type Props = {
  logisticsItem: any;
};
const LogisticsCard: React.FC<Props> = ({ logisticsItem }) => {
  const [visible, setVisible] = useState(false);
  const onCopy = (str: string) => {
    copy(str);
    Toast.show("复制成功");
  };

  useActivate(() => {
    setVisible(false);
  });

  useUnactivate(() => {
    setVisible(false);
  });

  return (
    <>
      <div className={styles["express-card"]}>
        <div className={styles["express-card__header"]}>
          快递走件、催派等物流信息，请联系物流公司，如需平台接入请联系客服
        </div>
        <div className={styles["express-card__content"]}>
          <div className={styles["left"]}>
            <div className={styles["company"]}>{logisticsItem.logisticsCompany}</div>
            <div className={styles["order"]}>
              <span>快递单号：{logisticsItem.logisticsNo}</span>
              {
                logisticsItem.logisticsNo && (
                  <span className={styles["copy"]} onClick={() => onCopy(logisticsItem.logisticsNo)}>
                    复制
                  </span>
                )
              }
            </div>
          </div>
          <div className={styles["right"]}>
            <a href={`tel:${logisticsItem.phone}`}>
              <img
                src="https://akim-oss.aikucun.com/mshop/88728495c3ab96971d824e94318e64f18a5eacff_1722245073509_93.png"
                alt=""
              />
            </a>
            <span>快递客服</span>
          </div>
        </div>
        <div className={styles["express-card__bottom"]}>
          <div className={styles["product-img"]}>
            {logisticsItem.deliverProducts.map(product => (
              <img src={product.goodsUrl} key={product.skuId} alt="" />
            ))}
          </div>
          <div className={styles["product-count"]} onClick={() => setVisible(true)}>
            共<span>{logisticsItem.deliverProducts.length}</span>种商品
            <img
              src="https://akim-oss.aikucun.com/mshop/a562a323aa46c131288790a104d81662f6f37501_1722247582609_14.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <Popup
        visible={visible}
        onMaskClick={() => setVisible(false)}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}>
        <div className={styles["product-popup"]}>
          <div className={styles["product-popup__header"]}>
            <div className={styles["title"]}>
              包裹中的商品({logisticsItem.deliverProducts.length})
            </div>
            <div className={styles["desc"]}>{logisticsItem.shipDesc}</div>
            <img className={styles["close"]} src={CLOSE} onClick={() => setVisible(false)} />
          </div>
          <div className={styles["product-popup__content"]}>
            {logisticsItem.deliverProducts.map(product => (
              <div className={styles["product-item"]} key={product.skuId}>
                <img className={styles["product-img"]} src={product.goodsUrl} alt="" />
                <div className={styles["product-info"]}>
                  <div className={styles["product-name"]}>{product.goodsName}</div>
                  <div className={styles["product-desc"]}>
                    <div className={styles["size"]}>{product.chima}</div>
                    <div className={styles["count"]}>共{product.quantity}件</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default LogisticsCard;
