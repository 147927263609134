import { fetchHistoryInfo } from "@/api/youxue";
import useNavigation from "@/utils/useNavigate";
import { useEffect, useRef, useState } from "react";
import { StudyTourMonthHistory, StudyTourProfileVO } from "../../model";

const useStudyTourHistory = () => {
  const { navigate } = useNavigation();
  const [list, setList] = useState<StudyTourMonthHistory[]>([]);
  const lastMonth = useRef<string>("");
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [visiblePicker, setVisiblePicker] = useState(false);
  const selectValue = useRef<string[]>(["全部", ""]);

  const selectPickerTitle = () => {
    if (selectValue.current[1]) {
      return `${selectValue.current[0]}-${selectValue.current[1]}`;
    }
    return selectValue.current[0];
  };

  const selectPickerData = () => selectPickerTitle().replace(/年/g, "").replace(/月/g, "");

  const isSelectAll = () => selectPickerTitle() === "全部";

  const queryType = () => (isSelectAll() ? 2 : 1);

  const updateHistory = async (isSelect?: boolean) => {
    setLoading(true);
    let res = await fetchHistoryInfo({
      lastMonth: isSelectAll() ? lastMonth.current : selectPickerData(),
      queryType: queryType(),
    });
    if (!res) {
      return;
    }
    setLoading(false);

    if (isSelect) {
      setList(res?.studyTourProfileMonthListVOS || []);
    } else {
      setList([...list, ...(res?.studyTourProfileMonthListVOS || [])]);
    }
    lastMonth.current = res?.lastMonth;
    setHasMore(res?.hasMore ?? false);
  };

  const onResetAndRefresh = () => {
    lastMonth.current = "";
    updateHistory(true);
  };

  const loadMore = async () => {
    if (hasMore && !loading) {
      updateHistory();
    }
  };

  useEffect(() => {
    document.title = "我的游学";
    updateHistory();
  }, []);

  (window as any).akc_onAppear = () => {
    onResetAndRefresh();
  };

  const onClosePickerAction = () => setVisiblePicker(false);

  const onConfirmAction = (select: string[]) => {
    selectValue.current = select;
    onResetAndRefresh();
  };

  const onStudyTourActivityDetail = (profile?: StudyTourProfileVO) => {
    const query = {
      studyTourId: profile?.studyTourId,
    };
    navigate({
      h5Options: {
        url: "/mx-shop-micro/yx/travelConfirm",
        query: query,
      },
      appOptions: {
        url: `${origin}/mx-shop-micro/yx/travelConfirm?studyTourId=${profile?.studyTourId}`,
      },
      miniOptions: {
        url: `${origin}/mx-shop-micro/yx/travelConfirm`,
        query: query,
      },
    });
  };
  const onSelectAction = () => {
    setVisiblePicker(true);
  };

  return {
    list,
    lastMonth,
    loading,
    visiblePicker,
    selectValue,
    hasMore,
    loadMore,
    onClosePickerAction,
    onConfirmAction,
    onSelectAction,
    selectPickerTitle,
    onStudyTourActivityDetail,
  };
};

export default useStudyTourHistory;
