import { ContentType } from "@/api/scode/scode_params";
import { usePosterSwipePro } from "@/components/poster-swiper/pro";
import { onShareAppMessageByRanking } from "@/utils/actions";
import { useShare } from "@/utils/share/share";
import { useEffect, useRef } from "react";

/**
 * 榜单分享Hook
 * 用于处理榜单页面的分享逻辑，包括榜单分享和商品分享
 */
export interface RankShareConfig {
  // 榜单分享配置
  rankShare: {
    contentType: ContentType; // 内容类型：榜单中心或类目榜单
    contentId: string; // 内容ID：榜单ID或类目ID
    title?: string; // 分享标题
    marketName: string; // 市场名称：榜单中心或榜单详情
  };
  // 依赖项数组，当这些值变化时更新分享配置
  dependencies: any[];
}

/**
 * 榜单分享Hook
 * @param config 榜单分享配置
 * @returns 分享相关的控制器和方法
 */
export const useRankShare = (config: RankShareConfig) => {
  const { rankShare, dependencies } = config;
  const productRef = useRef<any>(null);
  const { shareProduct, shareRank } = useShare();

  // 获取分享类型和海报代码
  const getShareTypeAndPosterCode = (contentType: ContentType) => {
    if (contentType === ContentType.categoryRank) {
      return {
        shareType: "HOT_LIST_CATEGORY",
        posterCode: "CategoryHotListPoster",
        src: "HOT_LIST_CATEGORY",
        pageName: "榜单页",
        marketName: "榜单详情",
      };
    } else {
      return {
        shareType: "HOT_LIST_TOTAL",
        posterCode: "TotalHotListPoster",
        src: "HOT_LIST_TOTAL",
        pageName: "榜单中心页",
        marketName: "榜单中心",
      };
    }
  };

  // 榜单分享控制器
  const swiperControl = usePosterSwipePro({
    defaultBizInfo: {
      contentType: rankShare.contentType,
      contentId: rankShare.contentId || "",
      contentExtra: {
        posterCode:
          rankShare.contentType === ContentType.categoryRank
            ? "CategoryHotListPoster"
            : "TotalHotListPoster",
      },
    },
    defaultEnvContext: {
      sourceScene: rankShare.contentType,
      sourceSceneId: rankShare.contentId || "",
      shareFrom: "MANUAL",
      scodeType: "SHARE",
      targetTerminalType: "H5",
    },
    defaultCarrierContext: {
      carrierElements: ["h5POSTER"],
      posterInfo: {
        posterCode:
          rankShare.contentType === ContentType.categoryRank
            ? "CategoryHotListPoster"
            : "TotalHotListPoster",
      },
    },
    defaultUIConfig: {
      btnConfig: ["copy", "edit"],
    },
    onNonH5Open: options => {
      const { shareType, posterCode, src, pageName, marketName } = getShareTypeAndPosterCode(
        rankShare.contentType,
      );

      // 确保contentId有值
      if (!rankShare.contentId) {
        console.warn("榜单分享失败：contentId为空");
        return;
      }

      shareRank({
        miniOptions: {
          contentId: rankShare.contentId,
          contentType: rankShare.contentType,
          pageName: marketName,
          shareType: shareType,
          posterCode: posterCode,
        },
        appOptions: {
          contentType: shareType,
          contentId: rankShare.contentId,
          pageName: pageName,
          src: src,
          sid: rankShare.contentId,
          shareType: shareType,
          posterCode: posterCode,
          imgType: posterCode,
        },
      });
    },
  });

  // 商品分享控制器
  const productControl = usePosterSwipePro({
    defaultBizInfo: {
      contentType: ContentType.spuProduct,
      contentId: "",
    },
    onNonH5Open: options => {
      if (!productRef.current) {
        return;
      }
      if (options.platform.isMini) {
        shareProduct({
          miniOptions: {
            ...productRef.current,
          },
        });
      } else if (options.platform.isApp) {
        const {
          activityId,
          activitySpuId,
          selectSkuId,
          spuId,
          skuExtendInfoVO = {},
        } = productRef.current;
        shareProduct({
          appOptions: {
            activityNo: activityId,
            productId: activitySpuId,
            selectSkuId: selectSkuId,
            spuId: spuId,
            src: "HOT_LIST_CATEGORY",
            sid: "",
            marketName: rankShare.marketName,
            marketId: "",
            isOTC: !!skuExtendInfoVO?.isOTC,
          },
        });
      }
    },
  });

  // 更新分享配置
  useEffect(() => {
    // 确保contentId有值再更新配置
    if (!rankShare.contentId) {
      console.warn("榜单分享配置更新失败：contentId为空");
      return;
    }

    // 更新榜单分享配置
    swiperControl.updateConfig({
      bizInfo: {
        contentType: rankShare.contentType,
        contentId: rankShare.contentId,
      },
      envContext: {
        sourceScene: rankShare.contentType,
        sourceSceneId: rankShare.contentId,
      },
    });

    // 设置页面右上角分享
    if (rankShare.contentId) {
      const { shareType, posterCode, marketName } = getShareTypeAndPosterCode(
        rankShare.contentType,
      );
      onShareAppMessageByRanking({
        contentType: rankShare.contentType,
        contentId: rankShare.contentId,
        title: rankShare.title,
        shareType,
        posterCode,
        pageName: marketName,
      });
    }
  }, dependencies);

  // 处理榜单分享
  const handleShareRank = () => {
    // 确保contentId有值再打开分享
    if (!rankShare.contentId) {
      console.warn("榜单分享失败：contentId为空");
      return;
    }
    swiperControl.open();
  };

  // 处理商品分享
  const handleShareProduct = (product: any) => {
    if (!product) {
      return;
    }
    productRef.current = product;
    // 打开分享海报
    productControl.open({
      bizInfo: {
        contentType: ContentType.spuProduct,
        contentId: product.activitySpuId,
        contentDetailId: product.selectSkuId || undefined,
      },
      uiConfig: {
        btnConfig: ["copy", "edit"],
      },
    });
  };

  return {
    swiperControl,
    productControl,
    handleShareRank,
    handleShareProduct,
    productRef,
  };
};
