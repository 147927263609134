import { launchConsultation } from "@/api/my";
import { getCSBase } from "@/utils/service";
import Cookie from "js-cookie";
const jumpToCS = async (queryObj, from, shopId, distributorId, sourceQuery) => {
  const webChannel = 3;
  const query = {
    ...queryObj,
    webChannel,
    shopId,
    distributorId,
  };
  const queryStr = Object.keys(query).reduce((res, i) => `${res}${i}=${query[i]}&`, "?");
  let sourceQueryStr;
  if (sourceQuery) {
    sourceQueryStr = Object.keys(sourceQuery).reduce((res, i) => {
      if (["channel", "shareChannel", "spm", "refSpm"].includes(i)) {
        return res ? `${res}&${i}=${sourceQuery[i]}` : `${i}=${sourceQuery[i]}`;
      }
      return res;
    }, "");
  }
  let auditChannel = 2;
  sourceQueryStr +=
    (sourceQueryStr ? "&" : "") +
    `source=3&auditChannel=${auditChannel}&shopId=${shopId}&distributorId=${distributorId}`;
  // 传入userId, 易观埋点用
  const msUserCode = Cookie.get("msUserCode");
  if (msUserCode) {
    sourceQueryStr += `&userId=${msUserCode}`;
  }

  const base = await getCSBase({
    webChannel,
    locationType: queryObj.locationType,
    merchantCode: queryObj.merchantCode,
  });
  // add au
  // 兼容从新的‘我的’过来的逻辑
  if (from === "1") {
    // eslint-disable-next-line no-restricted-syntax
    window.location.replace(
      `${base}${queryStr}sourceOrigin=${window.encodeURIComponent(
        window.location.origin,
      )}&sourceQueryStr=${window.encodeURIComponent(sourceQueryStr)}`,
    );
    return;
  }
  // eslint-disable-next-line no-restricted-syntax
  window.location.href = `${base}${queryStr}sourceOrigin=${window.encodeURIComponent(
    window.location.origin,
  )}&sourceQueryStr=${window.encodeURIComponent(sourceQueryStr)}`;
};

const checkLocationType = async (
  locationType,
  params,
  from,
  shopId,
  distributorId,
  sourceQuery,
) => {
  /* locationType：2，商品详情页；3，订单详情页；6，列表页 */
  const query: { locationType: number; activityExternalCode: string } = {
    locationType: locationType,
    activityExternalCode: "",
  };
  ["productNo", "activityNo", "activitySpuId", "merchantShopCode"].forEach(it => {
    if (params && !params[it]) {
      params[it] = "";
    }
  });
  const queryObj = { locationType, ...params };
  if (params.externalCode) {
    query.activityExternalCode = params.externalCode;
    delete params.externalCode;
  }
  try {
    const res: any = await launchConsultation(query);
    if (res && res.success && res.data) {
      const { merchantCode, model, consultQrUrl } = res.data;
      console.log(model);
      console.log(consultQrUrl);
      if (merchantCode) {
        queryObj.merchantCode = merchantCode;
      }
      jumpToCS(queryObj, from, shopId, distributorId, sourceQuery);
    }
  } catch (error) {
    console.error(error);
  }
};

export const CSUtils = {
  checkLocationType,
};
