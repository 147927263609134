import { fetchConfigInfo, fetchStudyCategoryList } from "@/api/youxue";
import { setMarkPageId } from "@/utils/page_utils";
import { setTitle } from "@/utils/set-title";
import useNavigation from "@/utils/useNavigate";
import { useThrottleFn } from "ahooks";
import { Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";

import {
  CategoryInfo,
  MonthTab,
  StudyTourConfigInfo,
  StudyTourSelectInfo,
  StudyTourSignUpDayListInfo,
  YouXueSignUp,
} from "../../model";

/// 最大选择数量
export const MAX_SELECT_COUNT = 3;
/// 偏移位置
const STICKY_HEIFGT = 100;
const useStudyTour = () => {
  const { navigate } = useNavigation();
  const [visible, setVisible] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<MonthTab>();
  const tabList = useRef<MonthTab[]>();
  const [instructVisible, setInstructVisible] = useState<boolean>(false);
  const [registrationVisible, setRegistrationVisible] = useState<boolean>(false);
  const selectStudyTourDetail = useRef<YouXueSignUp>();
  const selectListMap = useRef<StudyTourSelectInfo[]>([]);
  const [checkCount, setCheckCount] = useState<number>(0);
  const [activityImages, setActivityImages] = useState<string[]>([]);
  const selectStudyIds = useRef<string[]>([]);
  const [configInfo, setConfigInfo] = useState<StudyTourConfigInfo>({});
  const [categoryList, setCategoryList] = useState<CategoryInfo[]>([]); // 类目
  const [totalCount, setTotalCount] = useState<number>(0);
  const pageRef = useRef<HTMLDivElement | null>(null);
  const [tabSticky, setTabSticky] = useState<boolean>(false);
  const [cateorySelect, setCategorySelect] = useState({});

  /// 请求配置信息
  const queryPageTab = async () => {
    const [configData, cList] = await Promise.all([fetchConfigInfo(), fetchStudyCategoryList()]);
    if (!configData && !cList) {
      return;
    }
    setConfigInfo(configData);
    setCategoryList(cList);
    const selectCList = {};
    configData?.monthTabs?.map((item: MonthTab) => {
      if (item?.monthTab) {
        selectCList[item?.monthTab] = "";
      }
    });
    setCategorySelect(selectCList);
    tabList.current = configData?.monthTabs;
    if ((tabList.current?.length || 0) <= 2) {
      const selectList = tabList.current?.filter(item => item.choseFlag);
      if (selectList !== undefined) {
        setSelectTab(selectList[0]);
      }
      tabList.current?.forEach(item => {
        selectListMap.current?.push({
          tab: `${item?.month}`,
        });
      });
    }
  };

  const updateCheckedCount = () => {
    let number = 0;
    selectListMap.current?.map(item => {
      if (item?.tab === selectTab?.month) {
        item.list?.forEach(i => {
          i.studyTourSignUpList?.forEach(i => {
            if (i?.isSelect) {
              number += 1;
            }
          });
        });
      }
    });
    setCheckCount(number);
  };

  const updateSelectStudyIds = () => {
    const studyIds: string[] = [];
    selectListMap.current?.map(item => {
      if (item.tab === selectTab?.month) {
        item?.list?.forEach(i => {
          const selectedIds =
            i?.studyTourSignUpList?.filter(t => t.isSelect === true)?.map(s => s.studyTourId) ?? [];
          studyIds.push(...selectedIds.map(String));
        });
        selectStudyIds.current = studyIds;
      }
    });
  };

  //滚动监听
  const { run: handleScroll } = useThrottleFn(
    () => {
      const scrollTop =
        pageRef.current?.scrollTop ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

      console.log("scrollTop=", scrollTop);
      setTabSticky(scrollTop >= STICKY_HEIFGT);
    },
    {
      leading: true,
      trailing: true,
      wait: 0,
    },
  );

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  useEffect(() => {
    if (!selectTab) {
      return;
    }
    selectListMap.current?.map(item => {
      if (item?.tab === selectTab?.month) {
        setActivityImages(item.brandLogos || []);
        setTotalCount(item?.total || 0);
      }
    });
    updateSelectStudyIds();
    updateCheckedCount();
  }, [selectTab]);

  const onChangeTabIndex = (e, index: number) => {
    e.stopPropagation();
    setSelectTab(tabList.current?.[index]);
    const dom = document?.getElementById("page");
    if (dom) {
      dom.scrollTop = 0;
    }
  };

  useEffect(() => {
    setTitle({ appOptions: { title: "游学报名" }, h5Options: { title: "游学报名" } });
    queryPageTab();
    setMarkPageId({ appOptions: { markId: "youxue_calendar" } });
  }, []);

  const onSelectedDialogVisible = (show?: boolean) => setVisible(show ?? false);

  const onInstructAction = (show?: boolean) => setInstructVisible(show ?? false);

  const onRegistrationAction = (show?: boolean, isSure?: boolean) => {
    if (selectStudyIds.current.length <= 0) {
      Toast.show("请选择游学活动～");
      return;
    }

    setRegistrationVisible(show ?? false);

    const query = {
      youxueIds: selectStudyIds.current,
      activityImages: activityImages,
    };

    if (isSure) {
      navigate({
        h5Options: {
          url: "/mx-shop-micro/yx/signup",
          query: query,
        },
        appOptions: {
          url: `${origin}/mx-shop-micro/yx/signup?youxueIds=${selectStudyIds.current}&activityImages=${activityImages}`,
        },
        miniOptions: {
          url: `${origin}/mx-shop-micro/yx/signup`,
          query: query,
        },
      });
    }
  };

  const updateListDataAndStatus = () => {
    updateCheckedCount();
    selectListMap.current?.map(item => {
      if (item?.tab === selectTab?.month) {
        setActivityImages(item.brandLogos || []);
        setTotalCount(item.total || 0);
      }
    });
    updateSelectStudyIds();
  };

  const onUpdateSelectStatusAction = (
    tabKey?: string,
    detail?: YouXueSignUp,
    showSelect?: boolean,
  ) => {
    if (!detail || !tabKey) {
      return;
    }
    const index = selectListMap.current?.findIndex(item => item.tab === tabKey);
    if (index !== -1) {
      const list = selectListMap.current[index]?.list;
      list?.map(t => {
        // 选中的是已选的 取消勾选
        t?.studyTourSignUpList?.forEach(item => {
          if (item?.studyTourId === detail?.studyTourId) {
            if (showSelect) {
              item.isSelect = true;
              return;
            }
            item.isSelect = !item?.isSelect || false;
          }
        });
      });
    }
    updateListDataAndStatus();
  };

  const onStudyTourListAction = (
    tabKey?: string,
    list?: StudyTourSignUpDayListInfo[],
    brandLogos?: string[],
    total?: number,
  ) => {
    if (!list) {
      return;
    }
    const index = selectListMap.current?.findIndex(item => item.tab === tabKey);
    if (index !== -1) {
      const sinUp = selectListMap.current[index];
      if (sinUp) {
        sinUp.list = list;
        sinUp.brandLogos = brandLogos;
        sinUp.total = total;
      }
    }
    updateListDataAndStatus();
  };

  const onDeleteAction = () => {
    updateSelectStudyIds();
    updateCheckedCount();
  };

  const onCategoryShowOrHideAction = (isShow?: boolean) => {
    if (!isShow || !pageRef.current) {
      return;
    }
    pageRef.current.scrollTo({ top: STICKY_HEIFGT, behavior: "smooth" });
  };

  const onCategorySelectAction = (category?: CategoryInfo) => {
    if (selectTab?.monthTab === undefined) {
      return;
    }
    setCategorySelect(pre => {
      return {
        ...pre,
        [selectTab?.monthTab as string]: category?.categoryCode,
      };
    });
  };

  return {
    selectTab,
    visible,
    activityImages,
    tabList,
    instructVisible,
    registrationVisible,
    selectStudyTourDetail,
    selectListMap,
    checkCount,
    configInfo,
    totalCount,
    pageRef,
    tabSticky,
    categoryList,
    cateorySelect,
    selectStudyIds,
    onChangeTabIndex,
    onSelectedDialogVisible,
    onInstructAction,
    onDeleteAction,
    onRegistrationAction,
    onStudyTourListAction,
    onUpdateSelectStatusAction,
    onCategoryShowOrHideAction,
    onCategorySelectAction,
  };
};

export default useStudyTour;
