import { RouteName } from "@/router/extra";
import useNavigation from "@/utils/useNavigate";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

interface IProps {
  shopInfo: any;
}

const ShopEmpty: React.FC<IProps> = ({ shopInfo }) => {
  const { navigate2H5 } = useNavigation();

  const emptyIcon =
    "https://akim-oss.aikucun.com/mshop/f859b7a9bb9b9bcb8ea82fbd7c7aab7b10efd418_1721296630529_77.png";

  const goHome = () => {
    navigate2H5(RouteName.Home, { replace: true });
  };

  return (
    <div className={styles["content-empty"]}>
      <Image src={emptyIcon} className={styles["icon"]}></Image>
      {/* {shopInfo.shopStatusCode === "PAUSED" && <span className={styles["text"]}>{!identity() ? "该店铺已暂停经营":"该品牌会场已暂停经营"}</span>} */}
      {/* {shopInfo.shopStatusCode === "CLOSED" && <span className={styles["text"]}>{!identity() ? "该店铺已下架":"该品牌会场已下架"}</span>} */}
      <span className={styles["text"]}>品牌特卖已结束</span>
      <div className={styles["btn"]} onClick={goHome}>
        看看其他
      </div>
    </div>
  );
};

export default ShopEmpty;
