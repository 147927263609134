import { fetchProductMaterialDetail } from "@/api/product";
import { hotRankDetail, HotRankDetailModel, hotRankList } from "@/api/rank";
import { useAddressList } from "@/pages/product-detail/hook/useAddressList";
import useCheckBind from "@/pages/product-detail/normal/hook/useCheckBind";
import { useCommonContext } from "@/utils/context/common";
import exposure from "@/utils/expose/exposure";
import useQueryAllPlatRole from "@/utils/hooks/useQueryAllPlatRole";
import { getPlatform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import {
  PriceInfoModel,
  ProductDetailModel,
  ProductMaterialModel,
  ProductModel,
} from "@akc/biz-api";
import { Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

const pageName = "榜单详情";
export const useRankScale = () => {
  const { navigate2RankAll, navigate2Product, navigate2RankScale } = useNavigation();
  const { userVisitInfo, track, setCartNum } = useCommonContext();
  const [search] = useSearchParams();

  //榜单详情
  const [rankDetail, setRankDetail] = useState<HotRankDetailModel>();
  //底部榜单列表
  const [rankDataList, setRankDataList] = useState<HotRankDetailModel[]>();

  const pageNum = useRef<number>(1);
  const [hasMore, setHasMore] = useState(true);

  const [params] = useSearchParams();

  let rankNo = params.get("rankNo") ?? "H2502258612721";

  // 购物车数量
  const [cartTotal, setCartTotal] = useState<number>();
  //活动id
  const [activityId, setActivityId] = React.useState<string | undefined | null>(
    search.get("activityId"),
  );
  //skuId
  const [selectSkuId, setSelectSkuId] = React.useState<string | undefined | null>(
    search.get("selectSkuId"),
  );

  const promoActivityId = search.get("promoActivityId");
  const awdInstanceId = search.get("awdInstanceId");
  //一级属性value
  const [firstSalePropertyValue, setFirstSalePropertyValue] = React.useState<
    string | undefined | null
  >(search.get("firstSalePropertyValue"));
  //商品id
  const activitySpuId = search.get("activitySpuId");
  //素材id
  const materialNo = search.get("materialNo");

  // 加购面板显示
  const [cartModalVisible, setCartModalVisible] = React.useState<boolean>(false);
  // 加购面板所需模型
  const [modalProduct, setModalProduct] = React.useState<ProductDetailModel>();
  // 加购面板按钮类型
  const [cartPannelButtonType, setCartPannelButtonType] = React.useState<number>();
  const [loading, setLoading] = useState(true);
  const { toAuth, bindPhone } = useCheckBind();

  //地址
  const { currentAddressId } = useAddressList();

  const { getRole } = useQueryAllPlatRole();
  const role = getRole();
  const commonContext = useCommonContext();

  const getRankDataList = async () => {
    try {
      // 开始加载时设置加载状态为true
      const pageNo = pageNum.current;
      const res = await hotRankList("H5_RankDetail", pageNo, 5, rankNo, "", "same_list");
      setHasMore(res?.hasNext ?? false);

      if (!res) {
        setRankDataList([]);
        return;
      }
      if (pageNo === 1 && (res?.result?.length || 0) === 0) {
        setRankDataList([]);
        return;
      }
      if (res.pageIndex) {
        pageNum.current = res.pageIndex + 1;
      }

      if (res?.result && res?.result.length > 0) {
        const flatResult = Array.isArray(res.result[0]) ? res.result.flat() : res.result;

        // 如果是第一页（首次加载），则重置数据列表
        if (pageNo === 1) {
          setRankDataList(flatResult as HotRankDetailModel[]);
        } else {
          // 如果是加载更多（下一页），则追加数据
          setRankDataList(prevData => [
            ...(prevData || []),
            ...(flatResult as HotRankDetailModel[]),
          ]);
        }
      }
    } catch (error) {
      setHasMore(false);
      console.log("getRankDataList", error);
    }
  };

  const getRankDetailData = async () => {
    try {
      const res = await hotRankDetail(rankNo);
      setLoading(false);
      if (res) {
        if (res.rankNo) {
          rankNo = res.rankNo;
        }
        setRankDetail(res);

        if (track.track) {
          track.track("resource_expose", {
            page_name: pageName,
            page_code: rankNo,
            previous_page_name: search.get("page_name"),
            domain: "榜单详情",
            module: "模块：TOP 商品",
            resource_type: "新榜单",
            resource_content: "商品",
            resource_name: rankDetail?.rankName,
            resource_id: rankNo,
            resource_rank: 1,
            activity_id: rankDetail?.productList?.[0]?.activityId,
            activity_spu_code: rankDetail?.productList?.[0]?.activitySpuId,
            sku_id: rankDetail?.productList?.[0]?.selectSkuId,
            product_model:
              rankDetail?.productList?.[0]?.activityType === 4 ? "普通商品" : "档期商品",
          });
        }
      } else {
        setRankDetail(undefined);
      }
    } catch (error) {
      setLoading(false);
      console.log("getRankDetailData", error);
    }
  };

  const fetchFirstPage = async () => {
    setLoading(true);
    await getRankDetailData();

    // 重置到第一页
    pageNum.current = 1;
    // 加载第一页数据
    getRankDataList();
  };

  React.useEffect(() => {
    // rankNo="H2502253933524";
    fetchFirstPage();
  }, [rankNo]);

  const goToRankAll = () => {
    const query = {
      categoryOneId: 0,
    };
    navigate2RankAll(query);
  };

  const goToRankScale = (no?: string, index?: number) => {
    const query = {
      rankNo: no,
    };
    navigate2RankScale(query);
  };

  const goToProductDetail = (product?: ProductModel | ProductDetailModel | any) => {
    navigate2Product({
      activitySpuId: product.activitySpuId ?? "",
      activityId: product.activityId,
      selectSkuId: product.selectSkuId,
    });
  };

  const onBuyAction = (product?: any) => {
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    if (product?.skuCurrentPriceInfo?.promotionTimeStatus === 1 && !product?.skuAdvancePriceInfo) {
      Toast.show("该场活动还未开始，暂不支持购买");
      return;
    }
    if (!product?.skuExtendInfoVO?.allowDeliver) {
      Toast.show("此商品当前收货地址无法配送，请更换收货地址再进行购买");
      return;
    }
    if (!product?.activityId || !product?.activitySpuId) {
      console.log("活动id和商品id不能为空");
      return;
    }
    setCartModalVisible(true);
    setCartPannelButtonType(3);
    setModalProduct(product);
  };

  //页面初始化请求
  const initPageRequest = (params?: { selectSkuId?: string; firstSalePropertyValue?: string }) => {
    // 请求商品详情数据
    fetchProductMaterialDetail({
      activityId: activityId ?? "",
      activitySpuId: activitySpuId ?? "",
      pageId: "H5_ProductDetail",
      selectSkuId: params?.selectSkuId ?? selectSkuId,
      materialNo: materialNo,
      firstSalePropertyValue: params?.firstSalePropertyValue ?? firstSalePropertyValue,
      addressId: currentAddressId.current,
      promoActivityIds: promoActivityId ? [promoActivityId] : undefined,
      sourceScene: promoActivityId ? "JOIN_ORDER_PAGE" : undefined,
      awdInstanceId: awdInstanceId,
    })
      .then(res => {
        setSelectSkuId(res?.productDetailVO?.selectSkuId);
        setFirstSalePropertyValue(res?.productDetailVO?.skuBaseInfoVO?.firstSalePropertyValue);
        setActivityId(res?.productDetailVO?.activityId);

        // getProductSysTemShareInfo(res?.productDetailVO);
      })
      .catch(e => {
        console.log(e);
      });
  };

  //获取购物车数量
  const changeCartNum = () => {
    setCartNum().then(num => {
      console.log("购物车数量", num);
      setCartTotal(num);
    });
  };

  return {
    hasMore,
    getRankDataList,
    goToRankAll,
    goToProductDetail,
    goToRankScale,
    rankDetail,
    rankDataList,
    // 商品卡片相关操作
    onBuyAction,
    cartModalVisible,
    modalProduct,
    cartPannelButtonType,
    initPageRequest,
    selectSkuId,
    setCartModalVisible,
    cartTotal,
    changeCartNum,
    role,
    loading,
    track,
    search,
    pageName,
    commonContext,
  };
};
