const config = {
  0: {
    // 萌芽会员
    label: "https://akim-oss.aikucun.com/da4b9237bacccdf19c0760cab7aec4a8359010b0_1730800256778_84.png",
    bg: "https://akim-oss.aikucun.com/356a192b7913b04c54574d18c28d46e6395428ab_1730883500383_3.png",
    icon: "https://akim-oss.aikucun.com/f1385e0ef8c747042d0557bd5001e8cfd987ab45_1742452217829_66.png",
    name: "萌芽会员"
  },
  1: {
    // 一星会员
    label: "https://akim-oss.aikucun.com/77de68daecd823babbb58edb1c8e14d7106e83bb_1730800257008_16.png",
    bg: "https://akim-oss.aikucun.com/b6589fc6ab0dc82cf12099d1c2d40ab994e8410c_1730883500076_5.png",
    icon: "https://akim-oss.aikucun.com/0779f0cefe3e0955d704235f710733545a2b2929_1742452334257_92.png",
    name: "一星会员"
  },
  2: {
    // 二星会员
    label: "https://akim-oss.aikucun.com/1b6453892473a467d07372d45eb05abc2031647a_1730800257175_27.png",
    bg: "https://akim-oss.aikucun.com/da4b9237bacccdf19c0760cab7aec4a8359010b0_1730883500645_92.png",
    icon: "https://akim-oss.aikucun.com/323822cf9316a4d74a41f22016b6cc3b4a3fb143_1742452334254_26.png",
    name: "二星会员"
  },
  3: {
    // 三星会员
    label: "https://akim-oss.aikucun.com/ac3478d69a3c81fa62e60f5c3696165a4e5e6ac4_1730800257408_34.png",
    bg: "https://akim-oss.aikucun.com/77de68daecd823babbb58edb1c8e14d7106e83bb_1730884073921_16.png",
    icon: "https://akim-oss.aikucun.com/b7477cbca83256075578bee957e87ac81aca5d81_1742452334256_72.png",
    name: "三星会员"
  },
  4: {
    // 四星会员
    label: "https://akim-oss.aikucun.com/c1dfd96eea8cc2b62785275bca38ac261256e278_1730800257620_21.png",
    bg: "https://akim-oss.aikucun.com/1b6453892473a467d07372d45eb05abc2031647a_1730884074260_90.png",
    icon: "https://akim-oss.aikucun.com/7975ba2a4d2e09c9b2f8fe12871d67da0fb5cc2a_1742452334250_78.png",
    name: "四星会员"
  },
  5: {
    // 五星会员
    label: "https://akim-oss.aikucun.com/902ba3cda1883801594b6e1b452790cc53948fda_1730800257828_72.png",
    bg: "https://akim-oss.aikucun.com/ac3478d69a3c81fa62e60f5c3696165a4e5e6ac4_1730884074779_18.png",
    icon: "https://akim-oss.aikucun.com/7dd955654c90a30fbf524a3773a25a66cb92c56f_1742452334236_56.png",
    name: "五星会员"
  },
  100: {
    // 新秀会员
    label: "https://akim-oss.aikucun.com/356a192b7913b04c54574d18c28d46e6395428ab_1730800256624_39.png",
    bg: "https://akim-oss.aikucun.com/b6589fc6ab0dc82cf12099d1c2d40ab994e8410c_1730883500076_5.png",
    icon: "https://akim-oss.aikucun.com/d94eecc8121b70bd2f88689bc147012dc959a0fd_1742452334254_91.png",
    name: "新秀会员"
  }
};

export { config };
