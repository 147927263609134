import { useCommonContext } from "@/utils/context/common";
import { getPlatform } from "@/utils/platform";
import { default as useNavigation } from "@/utils/useNavigate";
import { default as React, useEffect } from "react";
import styles from "./FixedGuide.module.less";

type Props = {
  isShowToTopBtn: boolean;
  isVisitor: boolean;
  toBind: () => void;
};

const scrollToTop = () => {
  const newPersionContainer = document.getElementById("newPersionContainer");
  newPersionContainer && (newPersionContainer.scrollTop = 0);
};

const FixedGuide: React.FC<Props> = ({ isShowToTopBtn, isVisitor, toBind }) => {
  const { cartNum, setCartNum } = useCommonContext();
  useEffect(() => {
    setCartNum();
  }, []);

  const { navigate2H5 } = useNavigation();

  const goCart = () => {
    const { isMini } = getPlatform();
    if (isMini) {
      window.wx.miniProgram.navigateTo({
        url: "/buyFlow/pages/cart-product/cart-product",
      });
      return;
    }
    if (isVisitor) {
      return toBind();
    }
    navigate2H5("/#/cart");
  };

  return (
    <div className={styles.wrap}>
      {Number(cartNum) > 0 && (
        <div className={styles.cartIconWrap}>
          <img
            src="https://akim-oss.aikucun.com/b7eb6c689c037217079766fdb77c3bac3e51cb4c_1706772425441_93.png"
            className={styles.cartIcon}
            onClick={goCart}
          />
          <div className={styles.cornerNum}>{cartNum}</div>
        </div>
      )}
      {isShowToTopBtn && (
        <div>
          <img
            src="https://akim-oss.aikucun.com/a9334987ece78b6fe8bf130ef00b74847c1d3da6_1706772425659_3.png"
            className={styles.icon}
            onClick={scrollToTop}
          />
        </div>
      )}
    </div>
  );
};

export default FixedGuide;
