import { getPlatform } from "@/utils/platform";
import { PriceInfoModel } from "@akc/biz-api";
import React from "react";
import styles from "./index.module.less";

interface Props {
  item?: PriceInfoModel;
}

const PriceView: React.FC<Props> = (props: Props) => {
  const { item } = props;

  const platform = getPlatform();

  if (item?.commission) {
    return (
      <div className={styles.proMoney}>
        <div className={styles.save_money}>
          {platform.isApp
            ? "省赚"
            : platform.isTuan
              ? "推广费"
              : platform.isMini && !platform.isSaas
                ? "饷"
                : "赚"}
          ¥{item?.commission}
        </div>
        {item?.commissionAddition && item?.commissionAddition > 0 && (
          <div className={styles.add}>+{item?.commissionAddition}</div>
        )}
      </div>
    );
  } else {
    return <></>;
  }

};

export default PriceView;
