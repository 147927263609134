import {
  CategoryHotRankTabVO,
  hotRankCategoryList,
  HotRankDetailModel,
  hotRankList,
} from "@/api/rank";
import { useCommonContext } from "@/utils/context/common";
import useNavigation from "@/utils/useNavigate";
import React, { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useRankAll = () => {
  const [tabsData, setTabsData] = React.useState<CategoryHotRankTabVO[]>([]);

  const { navigate2RankScale } = useNavigation();

  // 初始化为空数组，避免空值引起的错误
  const { skin, track } = useCommonContext();
  const containerDom = useRef<HTMLDivElement>(null);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const [params] = useSearchParams();
  const [search] = useSearchParams();
  let categoryOneId = params.get("categoryOneId") ?? "0";

  const activityTagNo = useRef<string>(categoryOneId ?? "0");

  const [rankDataList, setRankDataList] = useState<HotRankDetailModel[]>();

  const pageNum = useRef<number>(1);
  const [hasMore, setHasMore] = useState(true);

  const [showTabPop, setShowTabPop] = useState<boolean>(false);
  const [showView, setShowView] = useState<boolean>(false);
  const timer = useRef<any>(null);

  const commonContext = useCommonContext();

  const [loading, setLoading] = useState(true);

  const [showEmptyShowTip, setShowEmptyShowTip] = useState(false);

  const getCategoryList = async () => {
    try {
      const res = await hotRankCategoryList();
      if (res) {
        setTabsData(res);

        //传过来的值
        const findIndex = res?.findIndex(item => item.categoryId === categoryOneId) ?? 0;
        // console.log("tangshangfindIndex--", findIndex);

        if (findIndex !== -1) {
          setSelectedTabIndex(findIndex);
          // 尝试获取当前选中的Tab元素
          const tabElement = document.getElementById(`category-${findIndex}`);
          if (tabElement) {
            // 如果找到元素，滚动到该元素
            tabElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
          }

          activityTagNo.current = res[findIndex].categoryId ?? "0";

          if (track.track) {
            track.track("$pageview", {
              page_name: "榜单中心",
              tab_name: res[findIndex].categoryName,
              tab_id: res[findIndex].categoryId,
              previous_page_name: search.get("page_name"),
            });
          }
        } else {
          setSelectedTabIndex(0);
          // 尝试获取当前选中的Tab元素
          const tabElement = document.getElementById("category-0");
          if (tabElement) {
            // 如果找到元素，滚动到该元素
            tabElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
          }

          activityTagNo.current = res[0].categoryId ?? "0";

          if (track.track) {
            track.track("$pageview", {
              page_name: "榜单中心",
              tab_name: res[0].categoryName,
              tab_id: res[0].categoryId,
              previous_page_name: search.get("page_name"),
            });
          }
        }
      } else {
        setTabsData([]);
      }
    } catch (error) {
      setTabsData([]);
      console.log("getCategoryList", error);
    }
  };

  const getRankDataList = async (index = 0) => {
    try {
      // 开始加载时设置加载状态为true
      const pageNo = pageNum.current;

      const res = await hotRankList(
        "H5_RankDetail",
        pageNo,
        5,
        "",
        activityTagNo.current,
        "class_1",
      );
      setLoading(false);
      setHasMore(res?.hasNext ?? false);

      if (!res) {
        setRankDataList([]);
        return;
      }
      if (pageNo === 1 && (res?.result?.length || 0) === 0) {
        if (index === 0) {
          setRankDataList([]);
        } else {
          setShowEmptyShowTip(true);
          setTimeout(async () => {
            setShowEmptyShowTip(false);

            await getCategoryList();
            setLoading(true);
            pageNum.current = 1;
            getRankDataList(0);
          }, 3000);
        }
        return;
      }
      if (res.pageIndex) {
        pageNum.current = res.pageIndex + 1;
      }

      if (res?.result && res?.result.length > 0) {
        const flatResult = Array.isArray(res.result[0]) ? res.result.flat() : res.result;

        // 如果是第一页（tab切换或首次加载），则重置数据列表
        if (pageNo === 1) {
          setRankDataList(flatResult as HotRankDetailModel[]);
        } else {
          // 如果是加载更多（下一页），则追加数据
          setRankDataList(prevData => [
            ...(prevData || []),
            ...(flatResult as HotRankDetailModel[]),
          ]);
        }
      }
    } catch (error) {
      setRankDataList([]);
      setLoading(false);
      setHasMore(false);
      console.log("getRankDataList", error);
    }
  };

  const fetchFirstPage = async () => {
    await getCategoryList();
    setLoading(true);
    pageNum.current = 1;
    getRankDataList(0);
  };

  React.useEffect(() => {
    fetchFirstPage();
  }, []);

  const onClickTab = (index = 0) => {
    if (track.track) {
      track.track("btn_click", {
        page_name: "榜单中心",
        previous_page_name: search.get("page_name"),
        btn_name: tabsData?.[index].categoryName,
        request_id: tabsData?.[index].categoryId,
      });
    }

    setShowTabPop(false);
    // 设置选中的Tab索引
    setSelectedTabIndex(index);

    const selectedTab = tabsData?.[index];
    activityTagNo.current = (selectedTab && selectedTab.categoryId) || "0";
    setLoading(true);

    pageNum.current = 1;
    getRankDataList(index);

    // 尝试获取当前选中的Tab元素
    const tabElement = document.getElementById(`category-${index}`);

    if (tabElement) {
      // 如果找到元素，滚动到该元素
      tabElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    }
  };

  const goToRankScale = (no?: string, index?: number) => {
    // if (track.track) {
    //   track.track("resource_click", {
    //     page_name: "榜单中心",
    //     previous_page_name: search.get("page_name"),
    //     tab_name: tabsData?.[selectedTabIndex]?.categoryName,
    //     domain: "榜单中心",
    //     module: "榜单中心",
    //     btn_name: "查看榜单",
    //     resource_type: "榜单中心",
    //     resource_content: "榜单",
    //     resource_name: rankDataList?.[index ?? 0]?.rankName,
    //     resource_id: no,
    //     resource_rank: index ?? 0 + 1,
    //     request_id: no,
    //   });
    // }
    const query = {
      rankNo: no,
      fromAll: true,
    };
    navigate2RankScale(query);
  };

  const onShowTabPop = isShow => {
    if (isShow) {
      clearTimeout(timer.current);
      setShowTabPop(isShow);
    } else {
      setShowTabPop(isShow);
    }
  };

  return {
    hasMore,
    selectedTabIndex,
    onClickTab,
    onShowTabPop,
    containerDom,
    skin,
    tabsData,
    showTabPop,
    setShowView,
    showView,
    rankDataList,
    getRankDataList,
    goToRankScale,
    commonContext,
    track,
    search,
    loading,
    showEmptyShowTip,
  };
};
