import { useCommonContext } from "@/utils/context/common";
import { useSearchParams } from "react-router-dom";

export const useTrack = () => {
  const { track } = useCommonContext();
  const [search] = useSearchParams();
  // 送礼场景PV
  const trackPVForGiveFriend = () => {
    try {
      if (track.track) {
        track.track("$pageview", {
          page_name: "送礼购买半弹窗",
          previous_page_name: search.get("previous_page_name") ?? search.get("page_name"),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    trackPVForGiveFriend,
  };
};
