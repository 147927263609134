import { useCallback, useMemo, useRef } from "react";

// 任务优先级定义
export enum TaskPriority {
  HIGH = "high",
  NORMAL = "normal",
}

interface AsyncTask<T = any> {
  task: () => T | Promise<T> | void;
  completeFn?: () => void;
  priority: TaskPriority; // 添加优先级字段
}

export const useAsyncTask = () => {
  // 使用 ref 存储队列和运行状态
  const queueRef = useRef<AsyncTask[]>([]);
  const isRunningRef = useRef<boolean>(false);
  // 记录当前正在运行的task
  const currentTaskRef = useRef<AsyncTask | null>(null);
  // 标记是否有待处理的队列处理
  const pendingProcessQueueRef = useRef<boolean>(false);

  // 获取下一个要执行的任务，优先返回高优先级任务
  const getNextTask = useCallback(() => {
    const currentQueue = [...queueRef.current];

    // 先查找高优先级任务
    const highPriorityIndex = currentQueue.findIndex(task => task.priority === TaskPriority.HIGH);

    if (highPriorityIndex !== -1) {
      // 找到高优先级任务，从队列中移除并返回
      const highPriorityTask = currentQueue[highPriorityIndex];
      currentQueue.splice(highPriorityIndex, 1);
      queueRef.current = currentQueue;
      return highPriorityTask;
    }

    // 没有高优先级任务，取第一个任务
    if (currentQueue.length > 0) {
      const normalTask = currentQueue.shift();
      queueRef.current = currentQueue;
      return normalTask;
    }

    return null;
  }, []);

  const processQueue = useCallback(async () => {
    // 重置待处理标记
    pendingProcessQueueRef.current = false;

    // 使用 ref 获取最新队列
    const currentQueue = queueRef.current;
    console.log("执行任务开始", currentQueue, isRunningRef.current);

    if (currentQueue.length === 0) {
      return;
    }

    // 检查是否已经在运行
    if (isRunningRef.current) {
      console.log("已有任务在运行，跳过");
      return;
    }

    isRunningRef.current = true;
    try {
      // 获取下一个要执行的任务（考虑优先级）
      const currentTask = getNextTask();
      if (currentTask) {
        currentTaskRef.current = currentTask;
        console.log("执行任务", currentTask.priority);
      }

      // 执行任务
      await Promise.resolve(currentTask?.task());
    } finally {
      isRunningRef.current = false;
      currentTaskRef.current = null;
      console.log("执行任务结束", isRunningRef.current, queueRef.current.length);
      // 检查是否还有任务
      if (queueRef.current.length > 0) {
        // 使用 Promise.resolve().then 替代 setTimeout，减少时序问题
        Promise.resolve().then(() => processQueue());
      }
    }
  }, [getNextTask]);

  const addTask = useCallback(
    (task: AsyncTask["task"], options?: { completeFn?: () => void; priority?: TaskPriority }) => {
      const { completeFn, priority = TaskPriority.NORMAL } = options || {};

      // 创建新任务
      const newTask = { task, completeFn, priority };
      console.log("添加任务", priority, queueRef.current.length, isRunningRef.current);

      // 更新队列
      queueRef.current = [...queueRef.current, newTask];

      // 如果已经有一个待处理的队列处理，则不再安排新的
      if (pendingProcessQueueRef.current) {
        console.log("已有队列处理被安排，跳过");
        return;
      }

      // 检查是否需要启动队列处理
      pendingProcessQueueRef.current = true;

      // 使用 Promise.resolve().then 替代 setTimeout，减少时序问题
      Promise.resolve().then(() => {
        console.log("执行任务入口-addTask", isRunningRef.current);
        if (!isRunningRef.current) {
          processQueue();
        }
      });
    },
    [processQueue],
  );

  const clearTasks = useCallback(() => {
    if (isRunningRef.current && currentTaskRef.current?.completeFn) {
      currentTaskRef.current.completeFn();
    }
    queueRef.current = [];
  }, []);

  // 返回不变的对象引用，避免组件重新渲染
  const returnValue = useMemo(
    () => ({
      addTask,
      clearTasks,
      isRunning: () => isRunningRef.current,
    }),
    [addTask, clearTasks],
  );

  return returnValue;
};

/**
 * 创建一个可以通过 Promise 控制的任务
 * @param options 任务控制选项
 * @returns 包含执行任务的函数和完成任务的函数
 */
export const createAsyncControl = <T = any, P = any>(options?: {
  onStart?: (param?: P) => void;
  onComplete?: (value: T) => void;
}) => {
  const { onStart, onComplete } = options || {};
  let resolveRef: ((value: T) => void) | null = null;

  return {
    /**
     * 开始任务并返回一个 Promise
     * @param param 可选的参数
     * @returns 返回一个 Promise，当任务完成时 resolve
     */
    start: (param?: P) => {
      return new Promise<T>(resolve => {
        resolveRef = resolve;
        onStart?.(param);
      });
    },

    /**
     * 完成任务并解析 Promise
     * @param value 可选的返回值
     */
    complete: (value?: T) => {
      if (resolveRef) {
        const result = value as T;
        resolveRef(result);
        onComplete?.(result);
        resolveRef = null;
      }
    },

    /**
     * 检查任务是否正在进行中
     */
    isRunning: () => {
      return resolveRef !== null;
    },
  };
};
