import { Swiper } from "antd-mobile";
import React from "react";

import useNavigation from "@/utils/useNavigate";
import style from "./Banner.module.less";

type Props = {
  dataList: Array<any>;
};

const Banner: React.FC<Props> = ({ dataList }) => {
  const { navigate2H5 } = useNavigation();

  return (
    <Swiper
      loop={!!(dataList.length > 1)}
      rubberband={!!(dataList.length > 1)}
      autoplay
      indicatorProps={{
        style: {
          display: dataList.length > 1 ? "unset" : "none",
          "--dot-color": "rgba(235, 237, 240, 0.3)",
          "--active-dot-color": "white",
          "--dot-size": "6px",
          "--active-dot-size": "6px",
          "--dot-border-radius": "50%",
          "--active-dot-border-radius": "50%",
          "--dot-spacing": "8px",
          marginBottom: "10px",
        },
      }}>
      {dataList.map((item, index) => (
        <Swiper.Item key={index}>
          <img
            key={index}
            className={style.img}
            src={item.imgUrl}
            onClick={() => navigate2H5(item.ringshopLinkUrl)}
          />
        </Swiper.Item>
      ))}
    </Swiper>
  );
};

export default Banner;
