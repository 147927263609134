import type { CartData } from "@/api/cart/types.d";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import { CartGroupComp } from "@akc/mx-taro-h5-components";
import React, { ReactNode, useMemo } from "react";
import Empty from "../components/Empty";
import ProductList from "../components/ProductList";
import styles from "../index.module.less";

/**
 * 商品列表渲染Hook
 * 根据购物车数据和状态生成对应的商品列表JSX
 */
export const useProductList = (
  loading: boolean,
  cartData: CartData | null,
  cartShareNo: string | null,
  goodsCount: number,
  handleItemSelect: (groupIndex: number, itemIndex: number) => void,
  handleGroupSelect: (groupIndex: number, selected: boolean) => void,
  trackBtnClick: (button_name: string, extra?: Record<string, any>) => void,
): ReactNode => {
  const { navigate2H5, navigate2Product, navigate2BrandDetail } = useNavigation();

  // 返回商品列表JSX
  return useMemo(() => {
    // 第一次加载时显示空态组件
    if (loading && !cartData) {
      return <Empty text="加载中..." />;
    }

    if (!cartShareNo || !cartData || goodsCount === 0) {
      return <Empty text="暂无数据" />;
    }

    if (goodsCount > 0 && !cartData.effectiveCommodityList.length) {
      return (
        <div>
          <Empty text="商品已全部售罄/下架，看看其他好物吧～" />
          <ProductList />
        </div>
      );
    }

    return (
      <div className={styles.cartList}>
        {/* 有效可操作商品列表 */}
        {cartData.effectiveCommodityList.map((item, index) => (
          <CartGroupComp
            key={`${item.commodityId}-${index}`}
            groupInfo={item}
            scene="share"
            style={{
              marginTop: pxtorem(8),
              marginLeft: pxtorem(12),
              marginRight: pxtorem(12),
              borderRadius: pxtorem(10),
            }}
            onSelectChangeAction={(selected, item, itemIndex) => {
              handleItemSelect(index, itemIndex);
            }}
            onSelectGroupChangeAction={(selectGroup, isSelected) => {
              handleGroupSelect(index, isSelected);
            }}
            onGroupTopAction={selectGroup => {
              trackBtnClick("分组标题");
              if (selectGroup.groupType === 1) {
                navigate2BrandDetail(selectGroup.brandNo);
                return;
              }
              navigate2H5(`/mx-shop-micro/activity/${selectGroup.activityNo}?activityNo=${selectGroup.activityNo}`);
            }}
            onGroupCardAction={(selectGroup, item) => {
              trackBtnClick("商品卡片", { skuId: item.externalSkuCode });
              navigate2Product({
                activitySpuId: item.spuId,
                activityId: item.activityId ? item.activityId : undefined,
                selectSkuId: item.selectSkuId ? item.selectSkuId : undefined,
              });
            }}
          />
        ))}

        {/* 失效商品 */}
        {!!cartData?.invalidCommodityList?.length && (
          <CartGroupComp
            scene="share"
            isInvalid
            style={{
              marginTop: pxtorem(8),
              marginLeft: pxtorem(12),
              marginRight: pxtorem(12),
              borderRadius: pxtorem(10),
            }}
            groupInfo={{ cartLines: cartData.invalidCommodityList }}
          />
        )}
      </div>
    );
  }, [loading, cartShareNo, cartData, goodsCount, handleItemSelect, handleGroupSelect, navigate2H5, navigate2Product]);
};
