import useCheckBind from "@/pages/new-person/hooks/useCheckBind";
import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";
import { shareCart } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { useUnmount } from "ahooks";
import { Button, Checkbox, Toast } from "antd-mobile";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CardTopAction from "./components/CardTopAction";
import TipCard from "./components/TipCard";
import { useCartActions, useCartData, useItemSelection, usePageTitle, useProductList } from "./hooks";
import styles from "./index.module.less";

interface CartShareProps { }

const CartShare: React.FC<CartShareProps> = () => {
  // 从URL参数中获取cartShareNo
  const [searchParams] = useSearchParams();
  const cartShareNo = searchParams.get("cartShareNo");
  const shopBizCode = searchParams.get("serverShopBizCode") || searchParams.get("shopId");
  const { track } = useCommonContext();

  const {
    bindPhonePopupVisible,
    setBindPhonePopupVisible,
    createBindPhoneTask,
    completeBindPhoneTask
  } = useCheckBind();

  // 页面标题
  usePageTitle("购物车分享");

  const trackBtnClick = (button_name: string, extra?: Record<string, any>) => {
    if (track && track.track) {
      track.track("btn_click", {
        page_name: "分享购物清单页",
        button_name,
        ...extra
      });
    }
  };

  // 使用商品选择状态管理hook
  const {
    selectedSku,
    setSelectedSku,
    isAllSelected,
    isHasGoods,
    updateCartData,
    getSelectedItems,
    updateSelectedSku,
    batchUpdateSelectedSku,
    getShowPrevGoodsToast,
  } = useItemSelection();

  // 使用购物车数据管理hook
  const {
    loading,
    cartData,
    goodsCount,
  } = useCartData(cartShareNo, shopBizCode, selectedSku, setSelectedSku, updateCartData, getShowPrevGoodsToast);

  // 使用购物车操作hook
  const {
    handleItemSelect,
    handleGroupSelect,
    handleSelectAll,
    handleBuyAll,
    handleAddToCart,
    timerRef
  } = useCartActions(
    cartData,
    cartShareNo,
    shopBizCode,
    searchParams,
    updateSelectedSku,
    batchUpdateSelectedSku,
    getSelectedItems,
    createBindPhoneTask,
    trackBtnClick,
  );

  // 使用商品列表渲染hook
  const productList = useProductList(
    loading,
    cartData,
    cartShareNo,
    goodsCount,
    handleItemSelect,
    handleGroupSelect,
    trackBtnClick
  );

  useEffect(() => {
    if (!cartShareNo) { return; }
    shareCart({
      contentId: cartShareNo,
    });
    // 触发一次pageview埋点
    if (track && track.track) {
      track.track("$pageview", {
        page_name: "购物车分享清单",
      });
    }
  }, [cartShareNo]);

  // 使用 ahooks 的 useUnmount 钩子在组件卸载时清除所有副作用
  useUnmount(() => {
    // 清除所有Toast提示
    Toast.clear();

    // 清除定时器
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  });
  const isShowFooter = !!cartData && goodsCount > 0 && !!cartData.effectiveCommodityList && cartData.effectiveCommodityList.length > 0;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <CardTopAction />
      </div>

      {/* 页面内容区域 */}
      <div className={styles.content}>
        {/* 提示头部 */}
        {cartData && !loading && goodsCount > 0 && (
          <TipCard count={goodsCount} />
        )}

        {/* 商品列表内容 */}
        {productList}
      </div>

      {/* 提示信息 */}
      {
        isShowFooter && (
          <div className={styles.tipContainer}>
            <span className={styles.tipText}>加入购物车即可查看促销信息，赶快试试吧～</span>
          </div>
        )
      }

      {/* 底部操作栏 */}
      {
        isShowFooter && (
          <div className={styles.footer}>
            {/* 底部按钮区域 */}
            <div className={styles.buttonContainer}>
              <div className={styles.selectAllWrapper}>
                <Checkbox
                  checked={isAllSelected}
                  onChange={checked => handleSelectAll(checked)}
                >
                  <span className={styles.selectAllText}>全选</span>
                </Checkbox>
              </div>
              <div className={styles.actionButtons}>
                <Button
                  className={`${styles.button} ${styles.addButton}`}
                  onClick={handleAddToCart}
                >
                  一键加购
                </Button>
                <Button
                  className={`${styles.button} ${styles.buyButton}`}
                  onClick={handleBuyAll}
                  disabled={isHasGoods}
                >
                  一键购买
                </Button>
              </div>
            </div>
          </div>
        )
      }
      {/* 绑定手机号弹窗 */}
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={() => { completeBindPhoneTask(); }}
      />
    </div>
  );
};

export default CartShare;