import { getOrderStatusCount } from "@/api/my/shop";
import ArrowRight from "@/assets/arrow_right@2.png";
import { getCookie, getPlatform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import { Badge, Mask } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";

import { btn_click } from "../../track";
import styles from "./index.module.less";

interface IOrderCount {
  waitPayOrderCount?: number;
  waitDeliverOrderCount?: number;
  waitReceiveOrderCount?: number;
  completedOrderCount?: number;
  logisticExceptionOrderCount?: number;
}

const menuList = [
  {
    icon: "https://akim-oss.aikucun.com/mshop/0dc514caf9a8428c8748aff654d8af31724fc4ff_1715062228706_90.png",
    text: "待付款",
    key: "waitPayOrderCount",
    type: 0,
  },
  {
    icon: "https://akim-oss.aikucun.com/mshop/7ded5c855c84f11d18f57eb952ac3c45baefb247_1715062163235_10.png",
    text: "待发货",
    key: "waitDeliverOrderCount",
    type: 1,
  },
  {
    icon: "https://akim-oss.aikucun.com/mshop/8f4d1822000722aec5c6e5bf30b32ae3e4b26b29_1715062134390_48.png",
    text: "待收货",
    key: "waitReceiveOrderCount",
    type: 2,
  },
  {
    icon: "https://akim-oss.aikucun.com/mshop/c70b5b7957596726b064cf9f6f9cca4bf36ae766_1715062269793_46.png",
    text: "已完成",
    key: "completedOrderCount",
    type: 20,
  },
  {
    icon: "https://akim-oss.aikucun.com/mshop/c3794ca936b0ecc6726b20ab2d89c75a1e3771f5_1715062301838_16.png",
    text: "退换/售后",
    // 实际不存在该状态的数量，仅用于占位
    key: "unusualOrderCount",
    type: 11,
  },
];

type Props = {
  onAddressGuide?: () => void;
};

const OrderCenter: React.FC<Props> = ({ onAddressGuide }) => {
  const { isWxH5 } = getPlatform();
  const msUserCode = getCookie("msUserCode");
  const { navigate2H5 } = useNavigation();
  const [searchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const [orderStatusCount, setOrderStatusCount] = useState<IOrderCount>({});
  const goOrder = (type: number | undefined, text: string) => {
    if (type === 11) {
      // 跳售后
      navigate2H5("/aftersale/serviceRecord", {
        query: {
          source: 3,
          auditChannel: isWxH5 ? "2" : "6",
          type: 11,
          queryScene: 1,
          isDistributeOrder: 0,
          userId: msUserCode
        },
      });
    } else {
      navigate2H5("/#/order/list", { query: `type=${type}` });
    }
    btn_click("我的-订单", text + "订单");
  };

  const onGuide = () => {
    setVisible(false);
    onAddressGuide?.();
    localStorage.setItem("_cache_admin_guide", "1");
  };

  const queryOrderStatusCount = async () => {
    const data = await getOrderStatusCount();
    data && typeof data === "object" && setOrderStatusCount(data);
  };

  useEffect(() => {
    if (
      !localStorage.getItem("_cache_admin_guide") &&
      (JSON.parse(localStorage.getItem("userinfo") ?? "") ?? {})?.currentRole === 4
    ) {
      const dom = document.getElementById("tab-bar-mask");
      const dom2 = document.getElementById("tab-bar-bottom");
      const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (dom && dom2 && isiOS) {
        dom.style.width = "100%";
        dom.style.height = "100%";
        dom2.style.borderTop = "none";
      }
      setVisible(true);
    }
    queryOrderStatusCount();
  }, []);

  useActivate(() => {
    queryOrderStatusCount();
  });

  const handleBadgeContent = (type: string) => {
    if (!orderStatusCount[type]) {
      return "";
    }

    if (orderStatusCount[type] > 99) {
      return "99";
    }
    return orderStatusCount[type];
  };
  return (
    <>
      <div className={styles["order-center"]} style={{ zIndex: visible ? 11000 : 10 }}>
        <div className={styles["order-center-header"]}>
          <div className={styles["order-center-header__title"]}>订单中心</div>
          <div
            className={styles["order-center-header__more"]}
            onClick={() => goOrder(undefined, "查看全部")}>
            查看全部
            <img src={ArrowRight} alt="" />
          </div>
        </div>
        <div className={styles["order-center-body"]}>
          {menuList.map((item, index) => (
            <div
              className={styles["order-center-body__item"]}
              key={index}
              onClick={() => goOrder(item.type, item.text)}>
              <Badge content={handleBadgeContent(item.key)} style={{ "--right": "4px", "--top": "4px" }}>
                <img className={styles["order-center-body__item__icon"]} src={item.icon} alt="" />
              </Badge>
              <div className={styles["order-center-body__item__text"]}>{item.text}</div>
            </div>
          ))}
        </div>
        {visible && (
          <>
            <div className={styles["order-center-guide__line"]}></div>
            <div className={styles["order-center-guide__txt"]}>
              新版订单中心搬到这里啦～
              <br />
              可在此查看所有订单哦～
            </div>
            <div className={styles["order-center-guide__button"]} onClick={() => onGuide()}>
              我知道了
            </div>
          </>
        )}
      </div>
      <Mask
        visible={visible}
        disableBodyScroll={true}
        opacity={0.7}
        style={{ "--z-index": "10100" }}
        onMaskClick={() => onGuide()}
      />
    </>
  );
};
export default OrderCenter;
