import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Overlay } from "react-vant";
import styles from "./LotteryDialog.module.less";

interface infoProps {
  title?: string
  content?: string
}

export interface LotteryDialogRef {
  setVisible: (val: boolean) => any
  setInfo: (val: infoProps) => void
}

export interface LotteryDialogProps {
  callbcak: (type: string) => void
}

const LotteryDialog = forwardRef<LotteryDialogRef, LotteryDialogProps>((props, ref) => {
  const { callbcak } = props;
  const [visible, setVisible] = useState(false);
  const [info, setInfo] = useState<infoProps>({});


  const handleClickEvent = (type: string) => {
    callbcak(type);
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    setVisible,
    setInfo
  }));

  return (
    <Overlay
      className={styles.overlay}
      visible={visible}
      zIndex={120}
      onClick={() => setVisible(false)}
    >
      <div className={styles.content}>
        <div className={styles.dialog}>
          <div className={styles.title}>{info?.title}</div>
          <div className={styles.label}>{info?.content}</div>
          <div className={styles.dialogFooter}>
            <div onClick={() => handleClickEvent("cancel")}>取消</div>
            <div onClick={() => handleClickEvent("confirm")}>确定</div>
          </div>
        </div>
      </div>
    </Overlay>
  );
});

export default LotteryDialog;