import { Request, Response } from "@/api/request";
import { fetchCopyLink } from "@/api/scode/copy_link";
import { fetchCopyMessage } from "@/api/scode/copy_message";
import { generateSharePoster } from "@/api/scode/poster";
import {
  CarrierContext,
  CarrierElementType,
  ContentContext,
  ContentType,
  EnvContext,
  SourceScene,
  bizInfoParams,
  carrierContextParams,
  envContextParams,
} from "@/api/scode/scode_params";
import { fetchShareCardInfo } from "@/api/scode/share_card_info";
import { fetchForwardMode } from "@/api/share";
import Track from "@/utils/track";
import { getBaseurl, getUrlParam } from "@/utils/url-parse";
import { default as AKJS } from "@akc/akjs";
import { Toast } from "antd-mobile";
import { getAppSystem } from "./app-system";
import { showCaptcha } from "./captcha/captcha";
import { platform } from "./platform";
import { forwardPosterLink4App, shareCardByH5, useShare } from "./share/share";
import useNavigation from "./useNavigate";

const staticUrl = getBaseurl();

const track = Track();

export const bizSubCategory = () => {
  const { isApp, isMini } = platform;
  if (isApp) {
    return "akcApp";
  } else if (isMini) {
    if (getUrlParam()?.["plt"] === "saas") {
      return "SaaSApplets";
    }
    if (getUrlParam()?.["plt"] === "atuan") {
      return "tuanApplets";
    }
    return "xdApplets";
  }
  return "xdWxH5";
};
export const terminalType = async () => {
  const { isApp, isMini } = platform;
  const isAndroid = await getAppSystem().then(info => info?.systemType === "android");
  return isApp ? (isAndroid ? "android" : "iOS") : isMini ? "applets" : "h5";
};

export const conference3BizInfo = (
  pageCode: any,
  brandId?: any,
  forwardBrand?: any,
  forwardHome?: any,
  shareTabId?: any,
  shareTabType?: any,
  shareTabName?: any,
): any => {
  return {
    contentType:
      location.href.indexOf("/decorate") >= 0 ? ContentType.decorate : ContentType.conference3,
    contentId: pageCode,
    contentExtra: { share_store_brandId: brandId, shareTabId, shareTabType, shareTabName },
    shortCodeExtend: { forwardBrand: forwardBrand, productType: forwardHome ? 1 : "" },
  };
};

export const conference3EnvContext = async (pageCode: any): Promise<EnvContext> => ({
  bizCategory: "XD",
  bizSubCategory: bizSubCategory(),
  terminalType: await terminalType(),
  sourceScene:
    location.href.indexOf("/decorate") >= 0
      ? SourceScene.decorate
      : location.href.indexOf("/market") >= 0
        ? SourceScene.market
        : location.href.indexOf("/shopList") >= 0
          ? SourceScene.list
          : SourceScene.conference3,
  sourceSceneId: pageCode,
  createTime: new Date().getTime(),
});

export const conference3ContentContext = (pageCode: any): ContentContext => ({
  contentType:
    location.href.indexOf("/decorate") >= 0
      ? ContentType.decorate
      : location.href.indexOf("/market") >= 0
        ? ContentType.market
        : location.href.indexOf("/shopList") >= 0
          ? ContentType.list
          : ContentType.conference3,
  contentId: pageCode,
});

export const conference3CarrierContext = (
  carrierElements: CarrierElementType[],
  posterCode?: string,
  posterVersion?: any,
): CarrierContext => ({
  carrierElements: carrierElements,
  posterInfo: {
    posterCode,
    posterVersion,
  },
});

/**
 * 分享H5卡片/小程序卡片
 *
 * 1. 生成转发链接
 * 2. 若是App，设置右上角分享按钮并设置分享内容
 * 3. 若是小程序 xxx
 * 4. 若是H5 xxx
 */
export const configShareCard = async (pageCode: string, url?: any) => {
  const { isApp, isMini } = platform;
  const carrierElement = isMini ? CarrierElementType.miniCard : CarrierElementType.h5Link;

  const response = await fetchShareCardInfo({
    bizInfo: bizInfoParams({
      contentType: ContentType.conference3,
      contentId: pageCode,
    }),
    envContext: envContextParams({
      sourceScene: SourceScene.conference3,
      sourceSceneId: pageCode,
    }),
    carrierContext: carrierContextParams([carrierElement]),
  });
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response?.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareDesc || !urlShareUrl) {
    return;
  }
  if (isApp) {
    // App
    AKJS.ready().then(() => {
      AKJS.action.dispatch("event.navigationBar.config.rightBtns", {
        btns: [
          {
            iconUrl:
              "https://xiangdian-oss.aikucun.com/app_akc_ios/4e0c103e51627588bc2007a84fb5773b8972461f_1684812474126_96.png",
            onclick: () => {
              AKJS.action.dispatch("event.share.shareToWX", {
                imageUrl: urlShareImg,
                title: urlShareTitle,
                desc: urlShareDesc,
                shareUrl: urlShareUrl,
                type: 1,
                method: 1,
                onshare: res => {
                  const channel = JSON.parse(res)?.data?.share_channel;
                  /* eslint-disable */
                  track.track("$share", {
                    share_type: "CONFERENCE3",
                    share_method: "卡片",
                    share_channel: channel,
                    scode: scode,
                    is_default: true,
                    page_name: document.title,
                    share_t: new Date().valueOf(),
                    market_name: document.title,
                    market_id: getUrlParam()?.["customPageCode"],
                  });
                  /* eslint-enable */
                },
              });
            },
          },
        ],
      });
      return;
    });
  }
  if (isMini) {
    // 小程序
    const sysSharePosterData = {
      pageName: "mx-shop-micro",
      shareTitle: urlShareTitle,
      pageTitle: urlShareTitle,
      shareUrl: urlShareUrl,
      shareImgUrl: urlShareImg,
      scode: scode,
    };
    window.wx.miniProgram.postMessage({
      data: sysSharePosterData,
    });
    console.log("postMessage——", sysSharePosterData);
    return;
  }
  // H5
  shareCardByH5(
    {
      title: urlShareTitle,
      desc: urlShareDesc,
      link: urlShareUrl,
      imgUrl: urlShareImg,
      scode,
    },
    false,
    url,
  );
};

//品牌馆页面分享
export const shareMarket = async contentExtra => {
  const response = await fetchShareCardInfo({
    bizInfo: {
      contentType: ContentType.market,
      contentExtra: {
        share_brand_shareImageUrl: contentExtra,
      },
    },
    envContext: envContextParams({
      sourceScene: SourceScene.market,
    }),
    carrierContext: carrierContextParams([CarrierElementType.h5Link]),
  });
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareDesc || !urlShareUrl) {
    return;
  }
  shareCardByH5(
    {
      title: urlShareTitle,
      desc: urlShareDesc,
      link: urlShareUrl,
      imgUrl: urlShareImg,
      scode,
    },
    false,
  );
};

//店铺列表页面分享
export const shareList = async (shopCode, contentExtra?: any) => {
  const response = await fetchShareCardInfo({
    bizInfo: {
      contentType: ContentType.list,
      contentId: shopCode,
      contentExtra: {
        share_brand_shareImageUrl: contentExtra,
      },
    },
    envContext: envContextParams({
      sourceScene: SourceScene.list,
      sourceSceneId: shopCode,
    }),
    carrierContext: carrierContextParams([CarrierElementType.h5Link]),
  });
  console.log("列表首页分享信息-------", response);
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareDesc || !urlShareUrl) {
    return;
  }
  shareCardByH5(
    {
      title: urlShareTitle,
      desc: urlShareDesc,
      link: urlShareUrl,
      imgUrl: urlShareImg,
      scode,
    },
    false,
  );
};

// 礼物分享
export const shareGift = async (data: any) => {
  const response = await fetchShareCardInfo({
    bizInfo: data.bizInfo,
    envContext: data.envContext,
    carrierContext: carrierContextParams([CarrierElementType.h5Link]),
  });
  console.log("礼物分享信息-------", data, response);
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareDesc || !urlShareUrl) {
    return;
  }
  shareCardByH5(
    {
      title: urlShareTitle,
      desc: urlShareDesc,
      link: urlShareUrl,
      imgUrl: urlShareImg,
      scode,
    },
    false,
  );
};
// 购物车清单分享
export const shareCart = async (data: any) => {
  const response = await fetchShareCardInfo({
    bizInfo: {
      contentType: ContentType.CART_PRODUCT,
      contentId: data.contentId,
    },
    envContext: envContextParams({
      sourceScene: SourceScene.CART_PRODUCT,
      sourceSceneId: data.contentId,
    }),
    carrierContext: carrierContextParams([CarrierElementType.h5Link]),
  });
  console.log("购物车分享信息-------", data, response);
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareDesc || !urlShareUrl) {
    return;
  }
  shareCardByH5(
    {
      title: urlShareTitle,
      desc: urlShareDesc,
      link: urlShareUrl,
      imgUrl: urlShareImg,
      scode,
    },
    false,
  );
};

//店铺详情页页面分享
export const shareDecorate = async data => {
  console.trace("trace");

  let bizInfo: any = {
    contentType: ContentType.decorate,
    contentId: getUrlParam()?.["merchantShopCode"],
    contentExtra: {
      share_store_brandId: data?.brandId,
      shareTabId: data.shareTabId,
      shareTabType: data.shareTabType,
      shareTabName: data.shareTabName,
    },
  };
  if (data.forwardBrand) {
    bizInfo.shortCodeExtend = {
      forwardBrand: true,
    };
  }
  if (data.forwardHome) {
    bizInfo.shortCodeExtend = {
      productType: 1,
    };
  }
  const response = await fetchShareCardInfo({
    bizInfo,
    envContext: await conference3EnvContext(data.pageCode),
    contentContext: conference3ContentContext(data.pageCode),
    carrierContext: conference3CarrierContext([CarrierElementType.h5Link]),
  });
  console.log("店铺详情页享信息-------", response);
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response?.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareDesc || !urlShareUrl) {
    return;
  }
  shareCardByH5(
    {
      title: urlShareTitle,
      desc: urlShareDesc,
      link: urlShareUrl,
      imgUrl: urlShareImg,
      scode,
    },
    false,
  );
};

// 右上角分享-B2R
export const onShareAppMessageByB2R = async data => {
  const { isMini, isH5 } = platform;
  let bizInfo = {
    contentType: data.contentType,
    contentId: data.contentId,
    contentDetailId: data.activityId || "",
    contentExtra: {
      shareBizType: data.shareBizType,
      shareMainTitle: data.shareMainTitle,
      shareSubTitle: data.shareSubTitle,
    },
    shortCodeExtend: {
      actId: data.contentId,
      brandId: data.brandId || "",
      activityId: data.activityId || "",
    },
  };
  const response = await fetchShareCardInfo({
    bizInfo,
    envContext: {
      bizCategory: "XD",
      bizSubCategory: bizSubCategory(),
      createTime: new Date().getTime(),
      terminalType: await terminalType(),
    },
    contentContext: bizInfo,
    carrierContext: conference3CarrierContext(
      isMini ? [CarrierElementType.miniCard] : [CarrierElementType.h5Link],
    ),
  });
  console.log("fetchShareCardInfo", response);
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response?.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareUrl) {
    return;
  }
  if (isMini) {
    const sysSharePosterData = {
      pageName: "mx-shop-micro",
      shareTitle: urlShareTitle,
      pageTitle: urlShareTitle,
      shareUrl: urlShareUrl,
      shareImgUrl: urlShareImg,
      scode: scode,
    };
    window.wx.miniProgram.postMessage({
      data: sysSharePosterData,
    });
  } else if (isH5) {
    shareCardByH5(
      {
        title: urlShareTitle,
        desc: urlShareDesc,
        link: urlShareUrl,
        imgUrl: urlShareImg,
        scode,
      },
      false,
    );
  }
};
// 右上角分享-榜单
export const onShareAppMessageByRanking = async data => {
  const { isMini, isH5 } = platform;
  let bizInfo = {
    contentType: data.contentType,
    contentId: data.contentId,
  };
  const response = await fetchShareCardInfo({
    bizInfo,
    envContext: {
      bizCategory: "XD",
      bizSubCategory: bizSubCategory(),
      createTime: new Date().getTime(),
      terminalType: await terminalType(),
      targetTerminalType: "H5",
      shareFrom: "MANUAL",
      scodeType: "SHARE",
      sourceScene: data.contentType,
      sourceSceneId: data.contentId,
    },
    contentContext: bizInfo,
    carrierContext: conference3CarrierContext(
      isMini ? [CarrierElementType.miniCard] : [CarrierElementType.h5Link],
    ),
  });
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response?.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareUrl) {
    return;
  }
  if (isMini) {
    const { shareType, posterCode, pageName } = data || {};
    const sysSharePosterData = {
      pageName: pageName || "mx-shop-micro",
      shareTitle: urlShareTitle,
      pageTitle: urlShareTitle,
      shareUrl: urlShareUrl,
      shareImgUrl: urlShareImg,
      scode: scode,
      shareType,
      posterCode,
    };
    window.wx.miniProgram.postMessage({
      data: sysSharePosterData,
    });
  } else if (isH5) {
    shareCardByH5(
      {
        title: urlShareTitle,
        desc: urlShareDesc,
        link: urlShareUrl,
        imgUrl: urlShareImg,
        scode,
      },
      false,
    );
  }
};

// 右上角分享-必买日历
export const onShareAppMessageBySaleCalendar = async data => {
  const { isMini, isH5 } = platform;
  let bizInfo = {
    contentType: data.contentType,
    contentId: data.contentId,
    contentExtra: {},
  };
  const response = await fetchShareCardInfo({
    bizInfo,
    envContext: {
      bizCategory: "XD",
      bizSubCategory: bizSubCategory(),
      createTime: new Date().getTime(),
      terminalType: await terminalType(),
    },
    contentContext: bizInfo,
    carrierContext: conference3CarrierContext(
      isMini ? [CarrierElementType.miniCard] : [CarrierElementType.h5Link],
    ),
  });
  console.log("fetchShareCardInfo", response);
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response?.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareUrl) {
    return;
  }
  if (isH5) {
    shareCardByH5(
      {
        title: urlShareTitle,
        desc: urlShareDesc,
        link: urlShareUrl,
        imgUrl: urlShareImg,
        scode,
      },
      false,
    );
  }
};

// 右上角分享-首页
export const onShareAppMessageByHome = async () => {
  const { isMini, isH5 } = platform;
  let bizInfo = {
    contentType: "TAB",
    contentId: "1",
    contentExtra: {},
    shortCodeExtend: {},
  };
  const response = await fetchShareCardInfo({
    bizInfo,
    envContext: {
      bizCategory: "XD",
      bizSubCategory: bizSubCategory(),
      createTime: new Date().getTime(),
      terminalType: await terminalType(),
    },
    // contentContext: bizInfo,
    carrierContext: conference3CarrierContext(
      isMini ? [CarrierElementType.miniCard] : [CarrierElementType.h5Link],
    ),
  });
  console.log("fetchShareCardInfo", response);
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response?.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareUrl) {
    return;
  }
  if (isMini) {
    const sysSharePosterData = {
      pageName: "mx-shop-micro",
      shareTitle: urlShareTitle,
      pageTitle: urlShareTitle,
      shareUrl: urlShareUrl,
      shareImgUrl: urlShareImg,
      scode: scode,
    };
    window.wx.miniProgram.postMessage({
      data: sysSharePosterData,
    });
  } else if (isH5) {
    shareCardByH5(
      {
        title: urlShareTitle,
        desc: urlShareDesc,
        link: urlShareUrl,
        imgUrl: urlShareImg,
        scode,
      },
      false,
    );
  }
};

// 品牌素材列表分享
export const onShareAppMessageByBrandMaterial = async () => {
  const { isMini, isH5 } = platform;
  const homeShareQueryParamsStr = sessionStorage.getItem("homeShareQueryParams");
  if (homeShareQueryParamsStr) {
    try {
      const homeShareQueryParams = JSON.parse(homeShareQueryParamsStr);
      if (isH5 && typeof homeShareQueryParams === "object" && homeShareQueryParams) {
        const response = await fetchShareCardInfo(homeShareQueryParams);
        console.log("fetchShareCardInfo", response);
        const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } =
          response?.data || {};
        if (!urlShareImg || !urlShareTitle || !urlShareUrl) {
          return;
        }
        return shareCardByH5(
          {
            title: urlShareTitle,
            desc: urlShareDesc,
            link: urlShareUrl,
            imgUrl: urlShareImg,
            scode,
          },
          false,
        );
      }
      return onShareAppMessageByHome();
    } catch (error) {
      return onShareAppMessageByHome();
    }
  } else {
    return onShareAppMessageByHome();
  }
};

/**
 * 设置标题及转发卡片内容
 *
 * @param pageData
 */
export const setTitleNShareCardInfo = (pageData: any, flag?: any, url?: any) => {
  // 标题
  const { isApp } = platform;
  if (isApp) {
    AKJS.ready().then(() => {
      AKJS.action.dispatch("event.navigationBar.config.title", {
        title: pageData.name,
      });
    });
  } else {
    if (!flag) {
      document.title = pageData.name;
    }
  }

  // 设置右上角转发按钮
  const shareConfig = pageData.shareConfig;
  if (flag) {
    configShareCard(getUrlParam()?.["merchantShopCode"], url);
  }
  if (shareConfig && shareConfig.shareSwitch) {
    return configShareCard(pageData.pageCode);
  }
};

const autoShareCountKey = info => `conference3_auto_share_count_key_${info.pageCode}`;
/**
 * 是否需要自动弹出会场海报
 */
export const shouldAutoShareConferencePoster = info => {
  if (info.shareConfig?.openPoster === 1) {
    const count = info.shareConfig?.openPosterCount;
    if (!count || 0 === count) {
      return false;
    }
    const key = autoShareCountKey(info);
    try {
      const autoCount = parseInt(localStorage.getItem(key) || "0");
      return autoCount < count;
    } catch (e) {
      return true;
    }
  }
  return false;
};

/**
 * 自增
 */
export const autoShareConferencePosterIncrement = info => {
  const key = autoShareCountKey(info);
  try {
    const autoCount = parseInt(localStorage.getItem(key) || "0");
    localStorage.setItem(key, (autoCount + 1).toString());
  } catch (e) {
    // console.error("存储自动转发数量报错", e);
  }
};

export const useShareConferencePoster = () => {
  const { navigate } = useNavigation();
  /**
   * 转发/分享会场-分享海报
   */
  const shareConferencePosterAction = async (props: {
    pageCode?: any;
    name?: string;
    merchantShopCode?: any;
  }) => {
    const { isApp, isMini } = platform;
    // const { navigate } = useNavigate();
    if (isMini) {
      if (props.merchantShopCode) {
        navigate({
          miniOptions: {
            url: "/pages/poster-page/poster-page",
            query: {
              merchantShopCode: props.merchantShopCode,
              shareType: "STORE",
            },
          },
        });
      } else {
        navigate({
          miniOptions: {
            url: "/pages/poster-page/poster-page",
            query: {
              conferenceId: props.pageCode,
              shareType: "CONFERENCE3",
            },
          },
        });
      }
      return undefined;
    }
    if (isApp) {
      // App
      Toast.show({
        icon: "loading",
        content: "加载中…",
        duration: 0,
      });
      // 1. 获取转发配置 & 获取视频号灰度状态
      const ress = await Promise.all([
        fetchForwardMode(),
        // isBizHit("B-VIDEO-LINK"), 暂时不支持视频号短链
        new Promise<boolean>(resolve => resolve(false)),
      ]);
      const forwardMode = ress[0];
      const isHit = ress[1];
      const posterCarrierElement =
        forwardMode === "h5" ? CarrierElementType.h5Poster : CarrierElementType.miniPoster;
      const linkCarrierElement =
        forwardMode === "h5" ? CarrierElementType.linkUrl : CarrierElementType.copyMiniLink;

      // 2. 根据转发配置生成海报 & 复制文案 & 复制链接
      const bizInfo = bizInfoParams({
        contentType: ContentType.conference3,
        contentId: props.pageCode,
      });
      const envContext = envContextParams({
        sourceScene: SourceScene.conference3,
        sourceSceneId: props.pageCode,
      });
      const results = await Promise.all([
        generateSharePoster({
          bizInfo: bizInfo,
          envContext: envContext,
          carrierContext: carrierContextParams([posterCarrierElement]),
        }),
        fetchCopyLink({
          bizInfo: bizInfo,
          envContext: envContext,
          carrierContext: carrierContextParams([linkCarrierElement]),
        }),
        fetchCopyMessage({
          bizInfo: bizInfo,
        }),
      ]);
      const posterInfos = results[0].data.posterInfos;
      if (!posterInfos || 0 === posterInfos.length) {
        Toast.clear();
        return;
      }
      const posterInfo = posterInfos[0];
      const copyLink = results[1].data;
      const copyMessages = results[2].data;
      const shareMessage = copyMessages?.[0];
      /* eslint-disable */
      const onShare = (channel?: string) => {
        if (!channel) return;
        track.track("$share", {
          share_type: "CONFERENCE3",
          share_method: "二维码",
          share_channel: channel,
          scode: posterInfo.scode,
          is_default: false,
          page_name: document.title,
          share_t: new Date().valueOf(),
          market_id: getUrlParam()?.["customPageCode"],
          market_name: document.title,
        });
      };
      /* eslint-disable */
      await forwardPosterLink4App({
        imageUrl: posterInfo.imageUrl,
        copyUrl: copyLink.copyMessage,
        shareMessage: shareMessage,
        toastMessage: "已为您自动复制分享文案，可直接粘贴哦~",
        pageCode: props.pageCode,
        pageName: props.name,
        isShowVideoLink: isHit,
        onShare: onShare,
      });
      Toast.clear();
      return undefined;
    }
    // H5 外面处理
  };
  return {
    shareConferencePosterAction,
  };
};

/**
 * H5 选择性转发-批量下载商品海报
 */
export const batchPosterParams4H5Product = (props: {
  contentIds: Array<string>;
  sourceSceneId?: string;
  envContextExtra?: Record<string, any>;
  contentContextExtra?: Record<string, any>;
  carrierContextExtra?: Record<string, any>;
}) => {
  return {
    bizInfo: {
      contentType: ContentType.spuProduct,
      contentIds: props.contentIds,
      contentTypeConfig: "1",
      contentExtra: {
        returnOneTemplate: 1,
      },
    },
    envContext: {
      ...envContextParams({
        sourceScene: SourceScene.selective,
        sourceSceneId: props.sourceSceneId,
      }),
      ...props.envContextExtra,
    },
    contentContext: {
      contentType: ContentType.spuProduct,
      contentExtra: {},
      ...props.contentContextExtra,
    },
    carrierContext: {
      ...carrierContextParams([CarrierElementType.h5Poster]),
      ...props.carrierContextExtra,
    },
  };
};

/**
 * H5 绘制商品海报
 */
export const sharePosterParams4H5Product = (props: {
  pageCode: string;
  activitySpuId: string;
  selectSkuId?: string;
  sourceScene?: SourceScene;
}) => {
  const content = {
    contentType: ContentType.spuProduct,
    contentId: props.activitySpuId,
    contentDetailId: props.selectSkuId || undefined,
  };
  return {
    bizInfo: { ...content, contentTypeConfig: "1" },
    envContext: envContextParams({
      sourceScene: props?.sourceScene ?? SourceScene.conference3,
      sourceSceneId: props.pageCode,
    }),
    carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
  };
};

/**
 * H5 绘制档期海报
 */
export const sharePosterParams4H5Activity = (props: { pageCode: string; activityId: string }) => {
  const content = {
    contentType: ContentType.activity,
    contentId: props.activityId,
  };
  return {
    bizInfo: { ...content, contentTypeConfig: "1" },
    envContext: envContextParams({
      sourceScene: SourceScene.conference3,
      sourceSceneId: props.pageCode,
    }),
    contentContext: content,
    carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
  };
};

/**
 * H5 绘制素材海报
 */
export const sharePosterParams4H5Material = async (props: {
  materialId: string;
  activitySpuId?: string;
}) => {
  const content = {
    contentType: "MATERIAL3",
    contentId: props.materialId,
    contentExtra: { material_id: props.materialId },
  };

  return {
    bizInfo: { ...content, shortCodeExtend: { activitySpuId: props.activitySpuId } },
    envContext: {
      bizCategory: "XD",
      bizSubCategory: "xdWxH5",
      createTime: new Date().getTime(),
      sourceScene: "MATERIAL_LIST_ACT",
    },
    contentContext: content,
    carrierContext: conference3CarrierContext([CarrierElementType.h5Poster]),
  };
};

/**
 * H5 绘制 爱豆满返/pk/爱豆满赠 海报
 */
export const sharePosterParams4H5B2R = async (props: {
  contentId: string;
  shareBizType: string;
  shareMainTitle: string;
  shareSubTitle: string;
  brandId?: string;
  contentDetailId?: string;
  sourceScene?: string;
}) => {
  const content = {
    contentType: "B2R",
    contentId: props.contentId,
    contentDetailId: props.contentDetailId || "",
    contentExtra: {
      shareBizType: props.shareBizType,
      shareMainTitle: props.shareMainTitle,
      shareSubTitle: props.shareSubTitle,
      page_type: props.sourceScene,
    },
    shortCodeExtend: {
      actId: props.contentId,
      brandId: props.brandId || "",
      activityId: props.contentDetailId || "",
    },
  };

  return {
    bizInfo: { ...content },
    envContext: {
      bizCategory: "XD",
      bizSubCategory: "xdWxH5",
      createTime: new Date().getTime(),
      scodeType: "SHARE",
      sourceScene: props.sourceScene,
      sourceSceneId: props.contentId,
      targetTerminalType: "H5",
    },
    contentContext: content,
    carrierContext: conference3CarrierContext(
      [CarrierElementType.h5Poster],
      "IncentiveActivityPoster",
      0,
    ),
  };
};

/**
 * H5 绘制二维码海报
 */
export const sharePosterParams4H5Coupon = (props: { pageCode: string; voucherNo: string }) => {
  const content = {
    contentType: ContentType.shopCoupon,
    contentId: props.voucherNo,
  };
  return {
    bizInfo: { ...content, contentTypeConfig: "1" },
    envContext: envContextParams({
      sourceScene: SourceScene.conference3,
      sourceSceneId: props.pageCode,
    }),
    carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
  };
};

/**
 *
 * H5 店铺海报
 */
export const sharePosterParams4H5Shop = (props: { shopId: string; mainPic: string }) => {
  const content = {
    contentType: "H5_SHOP",
    contentId: props.shopId,
  };
  return {
    bizInfo: { ...content, contentExtra: { activityListImageUrl: props.mainPic } },
    contentContext: content,
    envContext: envContextParams({
      sourceScene: SourceScene.h5shop,
      sourceSceneId: props.shopId,
    }),
    carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
  };
};

/**
 *
 * H5 店铺海报(通用版)
 */
export const sharePosterParams4Shop = (props: { shopId: string }) => {
  const content = {
    contentType: "SHOP",
    contentId: props.shopId,
  };
  return {
    bizInfo: { ...content },
    contentContext: content,
    envContext: envContextParams({
      sourceSceneId: props.shopId,
    }),
    carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
  };
};

/**
 * 领取优惠券
 */
export const fetchCounpon = async (coupon, rcsTraceId?: string, captchaToken?: string) => {
  const awdId = coupon.awdId;
  const promoActivityId = coupon.promoActivityId;
  const voucherNo = coupon.voucherNo;
  const res: Response<any> = await Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/v2/coupon/batchReceiveCouponV2`,
    method: "post",
    data: {
      couponByIdReqList: [
        {
          awdId,
          promoActivityId,
          voucherNo,
        },
      ],
      rcsTraceId,
      captchaToken,
    },
  });
  const { code, success, data, message } = res || {};
  if (code === "666B00004") {
    const tcp = data.tcp;
    return new Promise<boolean>(resolve => {
      showCaptcha(tcp).then(token => {
        resolve(fetchCounpon(coupon, rcsTraceId || data.rcsTraceId, token));
      });
    });
  }
  if (code === "00000" && success && data instanceof Array && data.length >= 1) {
    coupon.voucherNo = data[0];
    Toast.show("优惠券领取成功");
    return true;
  }
  Toast.show(message || "优惠券被领光啦");
  return false;
};

/**
 * 预告档期-提醒
 */
export const activityAddCalendar = activityModel => {
  AKJS.ready().then(() => {
    AKJS.action.dispatch("natCalendarAddEvent", {
      title: activityModel.activityName,
      startDate: activityModel.beginTimeStr,
      endDate: activityModel.endTimeStr,
      url: `akapp://aikucun.com/activity?id=${activityModel.activityId}`,
    });
  });
};

/**
 * 优惠券去使用
 */
export const toUseCoupon = async data => {
  const res: Response<any> = await Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/v1/coupon/queryJumpType`,
    method: "post",
    data,
  });
  return res;
};

/**
 * H5 绘制[爱豆满赠-收货地址]海报
 */
export const sharePosterParams4H5B2RGift = async (props: {
  contentId: string;
  contentDetailId: string;
  awardName: string;
  awardImage: string;
}) => {
  const content = {
    contentType: "B2R_GIFT",
    contentId: props.contentId,
    contentDetailId: props.contentDetailId,
    contentExtra: {
      awardName: props.awardName,
      awardImage: props.awardImage,
    },
  };

  return {
    bizInfo: { ...content },
    envContext: {
      bizCategory: "XD",
      bizSubCategory: "xdWxH5",
      createTime: new Date().getTime(),
      sourceScene: "MATERIAL_LIST_ACT",
    },
    contentContext: content,
    carrierContext: conference3CarrierContext([CarrierElementType.h5Poster]),
  };
};

// H5 绘制[必买日历]海报
export const sharePosterParams4H5SaleCalendar = async (props: { contentId: string }) => {
  const content = {
    contentType: "SALE_CALENDAR",
    contentId: props.contentId,
  };

  return {
    bizInfo: { ...content },
    envContext: {
      bizCategory: "XD",
      bizSubCategory: "xdWxH5",
      createTime: new Date().getTime(),
      sourceScene: "SALE_CALENDAR",
    },
    contentContext: content,
    carrierContext: conference3CarrierContext([CarrierElementType.h5Poster]),
  };
};
// 分享卡片
export const shareCard = async ({ contentType, contentId }) => {
  const { isApp, isMini, isH5 } = platform;
  if (isApp) return;
  let carrierContext = carrierContextParams([CarrierElementType.h5Link]);
  if (isMini) {
    // miniCard
    carrierContext = carrierContextParams([CarrierElementType.miniCard]);
  }

  const response = await fetchShareCardInfo({
    bizInfo: {
      contentType: ContentType[contentType],
      contentId,
    },
    envContext: envContextParams({
      sourceScene: SourceScene[contentType],
    }),
    carrierContext: carrierContext,
  });
  const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = response.data || {};
  if (!urlShareImg || !urlShareTitle || !urlShareUrl) {
    return;
  }
  if (isMini) {
    const sysSharePosterData = {
      pageName: "mx-shop-micro",
      shareTitle: urlShareTitle,
      pageTitle: urlShareTitle,
      shareUrl: urlShareUrl,
      shareImgUrl: urlShareImg,
      scode: scode,
    };
    window.wx.miniProgram.postMessage({
      data: sysSharePosterData,
    });
  } else {
    shareCardByH5(
      {
        title: urlShareTitle,
        desc: urlShareDesc,
        link: urlShareUrl,
        imgUrl: urlShareImg,
        scode,
      },
      false,
    );
  }
};

/**
 * [年终奖-品牌伯乐奖]-转发参数
 */
export const sharePosterParams4H5AnnualBouns = async (props: {
  contentId: string;
  sourceScene?: string;
}) => {
  const content = {
    contentType: "ANNUAL_BONUS",
    contentId: props.contentId,
    contentExtra: {
      page_type: props.sourceScene,
    },
  };

  return {
    bizInfo: { ...content },
    envContext: {
      bizCategory: "XD",
      bizSubCategory: "xdWxH5",
      createTime: new Date().getTime(),
      scodeType: "SHARE",
      sourceScene: props.sourceScene,
      sourceSceneId: props.contentId,
      targetTerminalType: "H5",
    },
    contentContext: content,
    carrierContext: conference3CarrierContext(
      [CarrierElementType.h5Poster],
      "AnnualBonusActivityPoster",
      0,
    ),
  };
};

export const useActions = () => {
  const {
    shareProduct,
    shareMaterial,
    shareB2R,
    shareAnnualBouns,
    shareSaleCalendar,
    shareB2RGift,
  } = useShare();

  /**
   * 转发/分享商品-分享海报
   */
  const shareProductPosterAction = (props: {
    product: any;
    pageCode: string;
    pageName?: string;
  }) => {
    const { isApp, isMini } = platform;
    const activityNo = props.product.activityId;
    const productNo = props.product.activitySpuId;
    const selectSkuId = props.product.selectSkuId;
    const spuId = props.product.spuId;
    if (isMini) {
      props.product.pageCode = props.pageCode;
      // 小程序
      shareProduct({
        miniOptions: props.product,
      });
      return undefined;
    }
    if (isApp) {
      // App
      shareProduct({
        appOptions: {
          activityNo: activityNo,
          productId: productNo,
          selectSkuId: selectSkuId,
          spuId: spuId,
          src: SourceScene.conference3,
          sid: props.pageCode || "",
          marketName: props.pageName || "",
          marketId: props.pageCode || "",
          isOTC: !!props.product?.skuExtendInfoVO?.isOTC,
        },
      });
      return undefined;
    }
    // H5 外面处理
  };

  /**
   * 转发/分享素材-分享海报
   */
  const shareMaterialPosterAction = (props: {
    material: any;
    pageCode: string;
    pageName?: string;
  }) => {
    const { isApp, isMini } = platform;
    const materialId = props.material.materialNo;
    const activitySpuId = props.material.activitySpuId;
    if (isApp) {
      // App
      shareMaterial({
        appOptions: {
          materialId: materialId,
          activitySpuId: activitySpuId,
          src: SourceScene.conference3,
          sid: props.pageCode,
        },
      });
      return undefined;
    } else if (isMini) {
      props.material.pageCode = props.pageCode;

      // 小程序
      shareMaterial({
        miniOptions: {
          ...props.material,
          contentId: materialId,
          contentType: "MATERIAL3",
        },
      });
      return undefined;
    }
    // H5 外面处理
  };

  /**
   * 转发分享-[爱豆满返, 爱豆满赠, pk]-海报
   */
  const shareB2RPosterAction = (props: {
    actId: string;
    bizType: string;
    shareMainTitle?: string;
    shareSubTitle?: string;
    brandId?: string;
    contentDetailId?: string;
    sourceScene?: string;
  }) => {
    const { isApp, isMini } = platform;
    if (isApp) {
      // App
      shareB2R({
        appOptions: {
          ...props,
          mainTitle: props.shareMainTitle,
          subTitle: props.shareSubTitle,
          id: props.actId,
          sourceScene: props.sourceScene,
        },
      });
      return undefined;
    } else if (isMini) {
      // 小程序
      shareB2R({
        miniOptions: {
          ...props,
          contentId: props.actId,
          contentType: "B2R",
          sourceScene: props.sourceScene,
        },
      });
      return undefined;
    }
    // H5 外面处理
  };

  /**
   * 年终奖落地页-海报
   */
  const shareAnnualBounsPosterAction = (props: {
    contentId: string;
    contentType?: string;
    pageName?: string;
    src?: string; // sourceScene
  }) => {
    const { isApp, isMini } = platform;
    if (isApp) {
      // App
      shareAnnualBouns({
        appOptions: {
          ...props,
        },
      });
      return undefined;
    } else if (isMini) {
      // 小程序
      shareAnnualBouns({
        miniOptions: {
          ...props,
        },
      });
      return undefined;
    }
    // H5 外面处理
  };

  // 转发分享-[必买日历]-海报
  const shareSaleCalendarPosterAction = (props: { contentId: string; pageName: string }) => {
    const { isApp } = platform;
    if (isApp) {
      // App
      shareSaleCalendar({
        appOptions: {
          ...props,
        },
      });
      return undefined;
    }
    // H5 外面处理
    // 小程序 外面处理
  };

  /**
   * 转发分享-[爱豆满赠-收货地址]-海报
   */
  const shareB2RGiftPosterAction = (props: {
    contentId: string;
    contentDetailId: string;
    awardName: string;
    awardImage: string;
    imgUrl?: string;
  }) => {
    const { isApp, isMini } = platform;
    if (isApp) {
      // App
      shareB2RGift({
        appOptions: {
          ...props,
        },
      });
      return undefined;
    } else if (isMini) {
      // 小程序
      shareB2RGift({
        miniOptions: {
          ...props,
        },
      });
      return undefined;
    }
    // H5 外面处理
  };

  return {
    shareProductPosterAction,
    shareMaterialPosterAction,
    shareB2RPosterAction,
    shareAnnualBounsPosterAction,
    shareSaleCalendarPosterAction,
    shareB2RGiftPosterAction,
  };
};
