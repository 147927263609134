import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";
import { config } from "./jifenConfig";

type Props = {
  pointsInfo: any;
};

type Info = {
  freeBalance?: string;
  level?: number;
};
const JifenBoard: React.FC<Props> = ({ pointsInfo }) => {
  const { navigate2H5 } = useNavigation();

  // useEffect(() => {}, []);
  const toCenter = () => {
    // 跳转到积分中心
    navigate2H5("/#/membercenter");
  };
  const openMore = () => {
    const levelMap = {
      0: "zero",
      1: "one",
      2: "two",
      3: "three",
      4: "four",
      5: "five",
    };
    let query = `rankequity=${levelMap[pointsInfo.level]}`;
    if (pointsInfo.returnRatio !== undefined) {
      query += `&returnRatio=${pointsInfo.returnRatio}`;
    }
    // 跳转到更多权益
    navigate2H5("/#/rankequity", { query });
  };

  const NoGrouthValue = () => {
    const [isShowTip, setIsShowTip] = useState(false);
    useEffect(() => {
      document.addEventListener("click", () => {
        setIsShowTip(false);
      });
    }, []);
    return (
      <div
        className={styles.noGrouthValue}
        onClick={e => {
          e.stopPropagation();
          setIsShowTip(!isShowTip);
        }}>
        <span className={styles.qaIcon}>--</span>
        {isShowTip && (
          <div className={styles.tip}>
            您的会员成长值目前低于0，您可以通过改善消费行为、售后行为、邀请新用户成功下单等行为逐步提升成长值，享受更多会员特权~
          </div>
        )}
      </div>
    );
  };

  const OnlyCBoard = () => {
    return (
      <>
        <div
          className={styles.wrap}
          style={{ backgroundImage: `url(${config[pointsInfo.level].bg})` }}>
          <div className={styles.header} onClick={toCenter}>
            <span className={styles.title}>可用积分：</span>
            <span className={styles.hl}>{pointsInfo.freeBalance || 0}</span>
            <img
              src="https://akim-oss.aikucun.com/84a516841ba77a5b4648de2cd0dfcb30ea46dbb4_1730877963916_80.png"
              className={styles.coin}
            />
          </div>
          <div className={styles.main}>
            <img src={config[pointsInfo.level].label} className={styles.label} />
            <span className={styles.pd8}>当前成长值</span>
            {+pointsInfo.growthValue < 0 ? (
              <NoGrouthValue />
            ) : (
              <span className={styles.hl}>{pointsInfo.growthValue}</span>
            )}
            <img
              src="https://akim-oss.aikucun.com/e9d71f5ee7c92d6dc9e92ffdad17b8bd49418f98_1730800258194_91.png"
              className={styles["aside-btn"]}
              onClick={openMore}
            />
          </div>
        </div>
      </>
    );
  };
  const Board = () => {
    return (
      <>
        <div className={styles.wrap2}>
          <div className={styles.main}>
            <img src={config[pointsInfo.level].label} className={styles.label} />
            <span className={styles.pd8}>当前成长值</span>
            {+pointsInfo.growthValue < 0 ? (
              <NoGrouthValue />
            ) : (
              <span className={styles.hl}>{pointsInfo.growthValue}</span>
            )}
          </div>
          <div className={styles.aside} onClick={openMore}>
            查看更多权益
          </div>
        </div>
      </>
    );
  };
  return pointsInfo?.showPoint ? <OnlyCBoard /> : <Board />;
};

export default JifenBoard;
