import { queryUserInfo } from "@/api/user";
import ReplaceSwiperComp from "@/components/replaceSwiperComp";
import Track from "@/utils/track";
import { getBaseurl } from "@/utils/url-parse";
import { API } from "@akc/biz-api";
import { startFoundation } from "@akc/foundation";
import { CommonContext as TaroCommonContext } from "@akc/mx-taro-h5-components";
import TaroUI, { TaroContextConfig, TaroRole } from "@akc/taro-service";
import { Toast } from "antd-mobile";
import React from "react";
import { Request } from "../../api/request";
import { platform } from "../platform";
import useNavigation from "../useNavigate";

const configFoundation = () => {
  console.log("配置 Foundation");
  const ua = navigator.userAgent;
  const match = ua.match(/OS (\d+)_/);
  const osVersion = match?.[1]?.replaceAll("_", ".");
  startFoundation({
    systemInfo: {
      iOS: !!ua.match(/(iPhone|iPad|iPod|iOS)/i),
      android: !!ua.match(/(Android)/i),
      h5: true,
      weapp: false,
      app: false,
      osVersion,
      scale: 3,
    },
  });
};

const configAPI = () => {
  console.log("启动 API 服务");
  API.startService({
    request: props => {
      return Request({
        url: props.url,
        method: props.method,
        params: props.method === "GET" ? props.body : undefined,
        data: props.method === "POST" || props.method === "PUT" ? props.body : undefined,
        headers: props.headers,
        withCredentials:
          props.withCredentials !== undefined && props.withCredentials !== null
            ? props.withCredentials
            : platform.isMini
              ? false
              : true,
      });
    },
    getHostname: id => {
      let hostname = getBaseurl();
      switch (id) {
        case "h5shop":
          hostname = getBaseurl();
          break;
      }
      return hostname ?? "";
    },
  });
};
// 不可以放在 useEffect 中，因为 useEffect 的执行顺序是子组件先执行，就会出现为初始化的情况
configFoundation();
configAPI();

const useBasicService = () => {
  const {
    navigate2Activity,
    navigate2Product,
    navigate2LiveRoom,
    navigate2Venue,
    navigate2SuperVenue,
    navigate2Shop,
    navigate,
    navigate2H5,
    navigate2BrandDetail,
  } = useNavigation();

  const [taroRole, setTaroRole] = React.useState<TaroRole>(platform.isApp ? "R" : "C");

  const data: {
    profitPrefix: string;
    terminal: TaroContextConfig["terminal"];
  } = {
    profitPrefix: "赚",
    terminal: "xdh5",
  };
  if (platform.isApp) {
    data.profitPrefix = "省赚";
    data.terminal = "akcapp";
  }
  if (platform.isMini) {
    data.profitPrefix = "饷";
    data.terminal = "xdweapp";
  }
  if (platform.isSaas) {
    data.profitPrefix = "赚";
    data.terminal = "saasweapp";
  }

  console.log("----------- taro-service", taroRole, data);

  React.useEffect(() => {
    TaroUI.startService({
      profitPrefix: data.profitPrefix,
      terminal: data.terminal,
      track: {
        pageView: (props: { pageName: string; properties: Record<string, any>; }) => {
          const track = Track();
          track.pageView(props.pageName, props.properties);
        },
        action: (props: { actionName: string; properties: Record<string, any>; }) => {
          const track = Track();
          track.track(props.actionName, props.properties);
        },
      },
      navigate: {
        toProduct: props =>
          navigate2Product({
            activitySpuId: props.activitySpuId,
            activityId: props.activityId || "",
            firstSalePropertyValue: props.firstSalePropertyValue,
            selectSkuId: props.selectSkuId,
            marketName: props.marketName,
          }),
        toActivity: props => {
          if (platform.isApp || platform.isMini) {
            navigate2Activity({
              activityNo: props.activityId,
            });
            return;
          }
          navigate2H5(`/mx-shop-micro/activity/${props.activityId}`);
        },
        toLive: props =>
          navigate2LiveRoom({
            liveId: props.liveId,
          }),
        toVenue: props =>
          navigate2Venue({
            miniQuery: { id: props.venueId },
            h5Query: { conferenceId: props.venueId },
          }),
        toSuperVenue: props =>
          navigate2SuperVenue({
            miniQuery: { shareOnlyId: props.venueId },
            h5Query: { hotSaleId: props.venueId },
          }),
        toConference3: props => {
          navigate({
            appOptions: {
              url: `${origin}/mx-shop-micro?customPageCode=${props.pageCode}`,
            },
            miniOptions: {
              url: `${origin}/mx-shop-micro`,
              query: {
                customPageCode: props.pageCode,
              },
            },
            h5Options: {
              url: `/mx-shop-micro?customPageCode=${props.pageCode}`,
            },
          });
        },
        toShopDetail: props =>
          navigate2Shop({
            shopCode: props.shopCode,
          }),
        toBrandDetail: props => navigate2BrandDetail(props.brandId),
        toH5: props => navigate2H5(props.url),
      },
      ui: {
        showToast: props =>
          Toast.show({
            content: props.message,
            duration: (props.duration ?? 2) * 1000,
            maskStyle: { zIndex: 100001 },
          }),
        hideToast: () => Toast.clear(),
      },
      replaceSwiperComp: () => ReplaceSwiperComp as any,
    });

    queryUserInfo().then(userInfo => {
      if (platform.isApp) {
        setTaroRole("R");
        return;
      }
      console.log("----------- taro-service userInfo", userInfo);
      if (userInfo?.currentRole === 4) {
        setTaroRole(userInfo.shopOwner ? "R" : userInfo.shopDistributor ? "E" : "C");
      } else if (userInfo?.currentRole === 3) {
        setTaroRole("E");
      } else if (userInfo?.currentRole === 2) {
        setTaroRole("R");
      } else if (userInfo?.currentRole === 1) {
        setTaroRole("C");
      }
    });
  }, []);

  return {
    ...data,
    taroRole,
  };
};

export const TaroCommonContextProvider = ({ children }: { children: React.ReactNode; }) => {
  const context = useBasicService();
  return (
    <TaroCommonContext.Provider
      value={{
        role: context.taroRole,
        profitPrefix: context.profitPrefix,
      }}>
      {children}
    </TaroCommonContext.Provider>
  );
};
