import useNavigation from "@/utils/useNavigate";
import { PDSizeRecommandMemberInfoModel, ProductDetailModel, ProductModel } from "@akc/biz-api";
import { CartPanelComp } from "@akc/mx-taro-h5-components";
import { ImageViewer, Popup } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTrack } from "./hook/useTrack";
import styles from "./index.module.less";

export type AddPurchasePanelActionType =
  /** 关闭 */
  | 0
  /** 预览 */
  | 1
  /** 跳转虚拟直购 */
  | 3
  /** 跳转虚拟直购 */
  | 4
  /** 加入购物车成功 */
  | 5
  /** 提醒 */
  | 6
  /** 修改规格尺码成功 */
  | 7
  /** 跳转订单确认页面 */
  | 9
  /** 修改角色尺码 */
  | 10
  /** 新建角色尺码 */
  | 11;

type AddPurchasePanelProps = {
  //是否显示
  visible?: boolean;
  product?: ProductModel | ProductDetailModel | any;
  //按钮类型 1:还想买 2:加入购物车 3:立即购买  6:加入购物车和立即购买 7:立即兑换
  buttonType?: number;
  //某些场景下需要显示传入的文案，例如:商详底部点击加入购物车和立即购买，面板底部按钮都显示确认
  buttonName?: string;
  //是否loading
  needLoading?: boolean;
  //是否送礼
  isGiftPurchase?: boolean;
  //是否修改规格尺码
  isModifySku?: boolean;
  /** 是否可以选择全部sku属性，isModifySku= true生效，默认情况下，修改规格尺码只显示最后一级sku属性 */
  canSelectAllSkuFromModifySku?: boolean;
  /** 是否直接返回sku属性，isModifySku= true生效，默认情况下，修改尺码规格会调用订单修改尺码接口 */
  canDirectReturnSku?: boolean;
  //当前可用的skuId列表
  availableSkuIdList?: string[];
  //埋点
  trackData?: Record<string, any>;
  //关闭回调
  close?: (params?: { skuId?: string; }) => void;
  //确认回调
  confirm?: (params: {
    type?: PDSizeRecommandMemberInfoModel;
    skuId?: string;
    firstSalePropertyName?: string;
    firstSalePropertyValue?: string;
    secondSalePropertyName?: string;
    secondSalePropertyValue?: string;
    activityId?: string;
  }) => void;
  // 10:修改角色尺码 11:新建角色尺码
  onHandleRoleSize?: (type: AddPurchasePanelActionType, roleId?: string) => void;
  // 刷新尺码信息
  sizeRefreshTime?: number;
};

//加购面板
const AddPurchasePanel: React.FC<AddPurchasePanelProps> = props => {
  const [search] = useSearchParams();
  const { navigate2OrderConfrim } = useNavigation();
  const product = props.product;
  const [preViewProductImgVisible, setPreViewProductImgVisible] = useState<boolean>(false);
  const [productPicList, setProductPicList] = useState<string[]>();
  const [cartPanelParams, setCartPanelParams] = useState<any>();
  // 业务回调参数，用于点击阴影部分时回调
  const bizResult = useRef<any>();

  const { trackPVForGiveFriend } = useTrack();

  //关闭
  const onClose = () => {
    console.log("onClose", bizResult.current?.selectSkuIds?.[0]);
    props.close && props.close({ skuId: bizResult.current?.selectSkuIds?.[0] });
  };

  useEffect(() => {
    //按钮类型
    const getButtonType = () => {
      if (props?.buttonType) {
        return props?.buttonType;
      }
      if (props.isModifySku) {
        return 0;
      }
      if (product?.activitySimpleVO?.status === 1) {
        return 2;
      }
      if (product?.pricePanelType === 13 || product?.pricePanelType2 === 7) {
        return 7;
      }
      if (
        product?.skuExtendInfoVO?.buyShoppingCartModel &&
        product?.skuExtendInfoVO?.buyDirectModel
      ) {
        return 6;
      }
      if (
        !product?.skuExtendInfoVO?.buyShoppingCartModel &&
        product?.skuExtendInfoVO?.buyDirectModel
      ) {
        return 3;
      }
      if (
        product?.skuExtendInfoVO?.buyShoppingCartModel &&
        !product?.skuExtendInfoVO?.buyDirectModel
      ) {
        return 2;
      }
      return 0;
    };

    //是否可以购买
    const getBuyEnable = () => {
      if (props.isGiftPurchase) {
        return true;
      }
      return product?.skuExtendInfoVO?.allowDeliver;
    };

    //是否不可以加购
    const getCartDisabled = () => {
      return !product?.skuExtendInfoVO?.buyShoppingCartModel;
    };

    if (props.visible && props.product) {
      console.log(
        "组装面板参数",
        product?.firstPropertyValue || product?.skuBaseInfoVO?.firstSalePropertyValue,
        product?.skuMainImageUrls?.[0],
      );
      if (props.isGiftPurchase) {
        trackPVForGiveFriend();
      }
      const useInfo = JSON.parse(localStorage.getItem("userinfo") ?? "") ?? {};
      setCartPanelParams({
        activitySpuId: product?.activitySpuId,
        activityId: product?.activityId,
        url: product?.skuMainImageUrls?.[0],
        firstSalePropertyValue:
          product?.firstPropertyValue || product?.skuBaseInfoVO?.firstSalePropertyValue,
        secondSalePropertyValue:
          product?.secondPropertyValue || product?.skuBaseInfoVO?.secondSalePropertyValue,
        skuId: product?.selectSkuId,
        pricePanelType: product?.pricePanelType,
        pricePanelType2: product?.pricePanelType2,
        page_name: props?.trackData?.pageName,
        domain: props?.trackData?.domain,
        brandId: product?.brandInfo?.brandId,
        sizeUrl: product?.spuInfoVO?.sizeUrl,
        buttonType: getButtonType(),
        buttonName: props?.buttonName,
        buyEnable: getBuyEnable(),
        cartDisabled: getCartDisabled(),
        isBuyDirectModel: product?.skuExtendInfoVO?.onlyDirectModel,
        liveNo: search.get("liveNo") || "",
        shopId: search.get("shopId") || "",
        awdInstanceId: search.get("awdInstanceId") || "",
        promoActivityId: search.get("promoActivityId") || "",
        isVirtual: product?.skuExtendInfoVO?.isVirtual,
        needLoading: props?.needLoading ?? false,
        isGiftPurchase: props.isGiftPurchase ?? false,
        availableSkuIdList: props?.availableSkuIdList,
        isModifySku: props?.isModifySku,
        canSelectAllSkuFromModifySku: props?.canSelectAllSkuFromModifySku,
        canDirectReturnSku: props?.canDirectReturnSku,
        sizeRefreshTime: props?.sizeRefreshTime,
        trackData: {
          ...props.trackData,
          merchant_shop_code: product?.activitySimpleVO?.merchantShopCode,
          unionid: useInfo?.unionId,
          spu_id: product?.spuId || product?.spuInfoVO?.spuId,
        }
      });
    }
  }, [props.product, props.buttonType, props.visible, props.isGiftPurchase, props.sizeRefreshTime]);

  // 事件回调类型, 0:关闭 1:预览 3:跳转虚拟直购 4:跳转虚拟直购 5:加入购物车成功 6:提醒 7:修改规格尺码成功 8:暂存,sku选中的数据 9:跳转订单确认页面  10:修改角色尺码 11:设置我的尺码 12:新建角色尺码
  const doActionCallback = async (params?: any) => {
    switch (params?.type) {
      case 0: {
        onClose();
        break;
      }
      case 8: {
        bizResult.current = params?.bizResult;
        break;
      }
      case 1: {
        setProductPicList(params?.skipResult?.urls ?? []);
        setPreViewProductImgVisible(true);
        break;
      }
      case 5: {
        props?.confirm?.({
          type: params?.bizResult?.buttonType,
          skuId: params?.bizResult?.selectSkuIds?.[0],
        });
        onClose();
        break;
      }
      case 7: {
        props?.confirm?.({
          type: params?.bizResult?.buttonType,
          skuId: params?.bizResult?.selectSkuIds?.[0],
          firstSalePropertyName: params?.bizResult?.firstSalePropertyName,
          firstSalePropertyValue: params?.bizResult?.firstSalePropertyValue,
          secondSalePropertyName: params?.bizResult?.secondSalePropertyName,
          secondSalePropertyValue: params?.bizResult?.secondSalePropertyValue,
          activityId: params?.bizResult?.activityId,
        });
        onClose();
        break;
      }
      case 9: {
        const skuNo = params?.skipResult?.selectSkuId;
        const quantity = params?.skipResult?.productCount;
        const activityNo = product.activityId;
        const spuId = product.activitySpuId;
        const virtualRechargeType = product?.skuExtendInfoVO?.virtualRechargeType || "";
        const isGiftPurchase = params?.skipResult?.isGiftPurchase ?? false;
        props?.confirm?.({
          type: params?.bizResult?.buttonType,
          skuId: params?.bizResult?.selectSkuIds?.[0],
        });
        onClose();
        setTimeout(() => {
          navigate2OrderConfrim({
            miniQuery: {
              skuNo: skuNo,
              quantity,
              virtualRechargeType: virtualRechargeType,
              activityNo: activityNo,
              spuId: spuId,
              isGift: isGiftPurchase ? 1 : undefined,
            },
            h5Query: {
              skuNo,
              num: quantity,
              directcChargeType: virtualRechargeType,
              activityNo,
              spuId,
              liveRoomNo: search.get("liveNo") || "",
              isBusinessVenue: search.get("isBusinessVenue") || "",
              isGift: isGiftPurchase ? 1 : undefined,
            },
          });
        }, 800);
        break;
      }
      case 10:
      case 11: {
        // 修改尺码角色相关
        props?.onHandleRoleSize?.(params?.type, params?.skipResult?.productRecommendSizeVO?.recommendMemberInfos?.roleId);
        break;
      }
    }
  };

  //底部安全区域高度
  function getSafeAreaInsetBottom() {
    const bodyStyle = window.getComputedStyle(document.body);
    return parseFloat(bodyStyle.getPropertyValue("--safe-area-inset-bottom")) || 10;
  }

  return (
    <React.Fragment>
      <Popup
        style={{ "--z-index": "2000" }}
        maskStyle={{ zIndex: "1999" }}
        visible={props.visible}
        onMaskClick={onClose}
        bodyStyle={{ backgroundColor: "transparent" }}>
        <div className={styles.addPurchasePanel}>
          <CartPanelComp
            style={{ "padding-bottom": getSafeAreaInsetBottom() }}
            {...cartPanelParams}
            onPurchaseCallback={(params?: any) => {
              doActionCallback(params);
            }}
          />
        </div>
      </Popup>
      <ImageViewer.Multi
        classNames={{ mask: styles.imageMask }}
        getContainer={document.body}
        images={productPicList}
        visible={preViewProductImgVisible}
        defaultIndex={0}
        onClose={() => {
          setPreViewProductImgVisible(false);
        }}
      />
    </React.Fragment>
  );
};

export default AddPurchasePanel;
