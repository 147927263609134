import { currentUserInfoServ } from "@/api/my";
import { exchangeUserRole, UserInfo } from "@/api/user";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { ErrorInfo } from "react-dom/client";

const enableSentry =
  process.env.REACT_APP_MODE === "production" || process.env.REACT_APP_MODE === "release";

// "https://7256477206ba4e3b9482dbe94beb6e93@sentrynew.aikucun.com/32"

/**
 * 初始化 Sentry
 */
export const initSentry = async () => {
  if (enableSentry) {
    const dsn = process.env.REACT_APP_GRAY
      ? "https://a6d573350bfe4cbc8e612a662c7cb406@sentrynew.aikucun.com/31"
      : "https://07fd415169c3420fb1935f3294fcfa45@sentrynew.aikucun.com/29";

    Sentry.init({
      dsn,
      release: process.env.REACT_APP_VERSION,
      environment: process.env.REACT_APP_MODE,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 1.0,
      beforeSend: (event, hint) => {
        // 过滤掉 _mergeOptions 相关错误
        const mergeOptionsError =
          event.message?.toLowerCase().includes("_mergeoptions") ||
          event.exception?.values?.some(
            v =>
              v.value?.toString().toLowerCase().includes("_mergeoptions") ||
              v.stacktrace?.frames?.some(
                frame =>
                  frame.filename?.toLowerCase().includes("_mergeoptions") ||
                  frame.function?.toLowerCase().includes("_mergeoptions"),
              ),
          ) ||
          hint?.originalException?.toString().toLowerCase().includes("_mergeoptions");
        if (mergeOptionsError) {
          return null;
        }
        // 仅上报 TypeError
        if (
          event.exception?.values?.[0]?.type === "TypeError" ||
          event.exception?.values?.[0]?.type === "Error"
        ) {
          return event;
        }
        if (!event.exception) {
          // 非报错场景，应该是自定义信息，直接上报
          return event;
        }
        return null;
      },
    });
  }
};

/**
 * 设置用户
 */
export const setUser = (user?: UserInfo & { [key: string]: any }) => {
  if (enableSentry) {
    currentUserInfoServ().then(res => {
      let id = user?.openId;
      const info = res?.data;
      if (info) {
        // 如果有爱豆编号，优先使用爱豆编号
        id = info.userCode;
      }
      Sentry.setUser(
        user
          ? {
              ...user,
              id, // user.id = 爱豆编号 ?? openId
              username: user.shopId, // user.username = shopId
              email: user.phone, // user.email = phone
              distributorId: user.distributorId,
              role: exchangeUserRole(user.currentRole),
            }
          : null,
      );
    });
  }
};

/**
 * 捕获异常
 */
export const captureException = (error: Error | unknown, errorInfo?: ErrorInfo) => {
  if (enableSentry) {
    if (axios.isAxiosError(error)) {
      Sentry.captureException(error, {
        extra: {
          errorInfo,
          response: error.response?.data,
          status: error.response?.status,
          statusText: error.response?.statusText,
          request: error.request,
          config: error.config,
        },
      });
      return;
    }
    Sentry.captureException(error, {
      extra: {
        errorInfo,
      },
    });
  }
};

/**
 * 捕获异常
 */
export const captureMessage = (message: string, level: "info" | "error" | "warning" = "info") => {
  if (enableSentry) {
    Sentry.captureMessage(message, level);
  }
};
