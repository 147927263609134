import React from "react";
import styles from "./index.module.less";

interface TipCardProps {
  count?: number;
}

const TipCard: React.FC<TipCardProps> = ({ count = 0 }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles.titleRed}>TA分享了 </span>
        <span className={styles.titleBlack}>{count}</span>
        <span className={styles.titleRed}> 个商品给你</span>
      </div>
      <div className={styles.subtitle}>
        不知道怎么买比较划算？给你看一下我的购物车哦！
      </div>
    </div>
  );
};

export default TipCard;