import { querySaleCalendarInfoServ } from "@/api/brand";
import { subscribe, unSubscribe } from "@/api/market";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import TopNavigation from "@/components/top-navigation";
import { RouteName } from "@/router/extra";
import {
  onShareAppMessageBySaleCalendar,
  sharePosterParams4H5SaleCalendar,
  useActions,
} from "@/utils/actions";
import { getAppSystem } from "@/utils/app-system";
import { getUserRole } from "@/utils/identity";
import { platform } from "@/utils/platform";
import { ButtonOptions, setTopRightButtons } from "@/utils/set-top-right-buttons";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { versionsCompare } from "@/utils/version-compare";
import AKJS from "@akc/akjs";
import { SellCalendarCardComp, TrackDataContext } from "@akc/mx-taro-h5-components";
import { Dialog, Toast } from "antd-mobile";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import TabBasic from "./components/tab-basic";
import styles from "./index.module.less";

const SaleCalendar: React.FC = () => {
  const track = Track();
  // 埋点上下文数据设置
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);
  const [search] = useSearchParams();
  const isPreview = ["1", "true"].includes(search.get("isPreview") || "");
  const scode = search.get("scode") || "";

  const { navigate2H5, navigate2Activity } = useNavigation();

  const [activeIndex, setActiveIndex] = useState(0); // 当前选中的日期索引
  const [list, setList] = useState<any[]>([]); // 日历数据
  const sysTimeRef = useRef<any>(new Date().getTime()); // 系统时间
  const [isLoading, setIsLoading] = useState(false); // 加载状态
  const offsetTopListRef = useRef<any>([]); // 每个tab的offsetTop
  const offWatchScroll = useRef<any>(false); // 是否关闭滚动监听
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  const shareParamRef = useRef<any>({});
  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<any>({});
  const { shareSaleCalendarPosterAction } = useActions();
  const handleClickSetActiveIndex = index => {
    offWatchScroll.current = true;
    setActiveIndex(index);
  };

  const getTimestamp2Week = timestamp => {
    const week = timestamp ? new Date(parseInt(timestamp)).getDay() : new Date().getDay();
    const weekEnum = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    return weekEnum[week];
  };

  const handleUpdateSubscribeStatus = indexObj => {
    const { floorIndex, relativeRank } = indexObj || {};
    const isSubscribe = list[floorIndex].brandVOList[relativeRank].isSubscribe;
    setList(
      list.map((item, index) => {
        if (index === floorIndex) {
          return {
            ...item,
            brandVOList: item.brandVOList.map((brand, rank) => {
              if (rank === relativeRank) {
                return {
                  ...brand,
                  isSubscribe: !isSubscribe,
                };
              }
              return brand;
            }),
          };
        } else {
          return item;
        }
      }),
    );
  };

  const handleCancelSubscribe = (subscribeServParams, indexObj) => {
    Dialog.confirm({
      title: "取消订阅",
      content: "取消订阅将无法接收活动提醒，请确认是否取消订阅？",
      bodyStyle: { textAlign: "center" },
      onConfirm: async () => {
        unSubscribe(subscribeServParams).then(res => {
          console.error("取消订阅——", res);
          Toast.show({
            content: "取消订阅成功",
            maskClickable: true,
            position: "center",
            maskStyle: { zIndex: 9999 },
          });
          handleUpdateSubscribeStatus(indexObj);
        });
      },
    });
  };

  const handleSubscribe = (subscribeServParams, indexObj) => {
    subscribe(subscribeServParams).then(res => {
      console.error("订阅——", res);
      Toast.show({
        content: "订阅成功",
        maskClickable: false,
        position: "center",
        maskStyle: { zIndex: 9999 },
      });
      handleUpdateSubscribeStatus(indexObj);
    });
  };

  const handleConfirmSubscribe = async (subscribeServParams, indexObj) => {
    if (platform.isApp) {
      const appVersion = (await getAppSystem())?.appVersion;
      if (appVersion && versionsCompare(appVersion, "7.52.0") >= 0) {
        AKJS.ready().then(() => {
          AKJS.action
            .dispatch("natPermissions", {
              type: "notification",
            })
            .then(res => {
              console.log(11111, res.data.granted);
              if (res?.data?.granted) {
                handleSubscribe(subscribeServParams, indexObj);
              }
            });
        });
      } else {
        handleSubscribe(subscribeServParams, indexObj);
      }
    }
  };

  const onSubscribePress = currentItem => {
    console.error("订阅——", currentItem);
    track.track("resource_click", {
      page_name: "今日必卖日历页",
      resource_type: "品牌卡片",
      resource_name: currentItem?.isSubscribe ? "取消订阅" : "订阅",
      btn_name: currentItem?.isSubscribe ? "取消订阅" : "订阅",
      resource_rank: currentItem.floorIndex + 1,
      brnad_id: currentItem.brandId,
      activity_id: currentItem.jumpType === "ACTIVITY" ? currentItem.jumpTargetId : "",
      market_id: currentItem.jumpType === "CUSTOM_PAGE_30" ? currentItem.jumpTargetId : "",
      page_desc: moment(Number(currentItem.startTime)).format("M月D日"),
    });
    if (!currentItem) {
      return;
    }
    const { floorIndex, relativeRank } = currentItem;
    let calendarDayStr = list[floorIndex]?.calendarDayStr;
    // 订阅 || 取消订阅接口 必传参数，没有直接return
    if (!(currentItem.brandId && calendarDayStr)) {
      return;
    }
    let subscribeServParams = {
      bizType: "TODAY_SELL_BRAND",
      bizNo: `${currentItem.brandId}_${calendarDayStr}`,
    };
    let indexObj = {
      floorIndex,
      relativeRank,
    };
    currentItem.isSubscribe
      ? handleCancelSubscribe(subscribeServParams, indexObj)
      : handleConfirmSubscribe(subscribeServParams, indexObj);
  };

  const onBrandCardPress = async currentItem => {
    console.error("点击——", currentItem);
    track.track("resource_click", {
      page_name: "今日必卖日历页",
      resource_type: "品牌卡片",
      resource_name: "详情",
      btn_name: "详情",
      resource_rank: currentItem.floorIndex + 1,
      brnad_id: currentItem.brandId,
      activity_id: currentItem.jumpType === "ACTIVITY" ? currentItem.jumpTargetId : "",
      market_id: currentItem.jumpType === "CUSTOM_PAGE_30" ? currentItem.jumpTargetId : "",
      page_desc: moment(Number(currentItem.startTime)).format("M月D日"),
    });
    if (!currentItem) {
      return;
    }
    const { jumpType, jumpTargetId, floorIndex, relativeRank } = currentItem;
    if (!(jumpType && jumpTargetId)) {
      Toast.show({
        content: "活动准备中，敬请期待~",
        maskClickable: false,
        position: "center",
        maskStyle: { zIndex: 9999 },
      });
      return;
    }
    /**
     * sysTime: 系统时间戳
     * calendarDay: 日历时间戳
     * 如果非今天，需要把日历时间带在地址栏，用来在会场3.0标题前追加显示
     */
    const todayDate = new Date(parseInt(sysTimeRef.current));
    const todayDateStr = `${todayDate.getMonth() + 1}.${todayDate.getDate()}`;
    const { calendarDay } = list[floorIndex] || {};
    let calendarDayStr = "";
    const calendarDayDate = new Date(parseInt(calendarDay));
    const calendarDayStr1 = `${calendarDayDate.getMonth() + 1}.${calendarDayDate.getDate()}`;
    if (todayDateStr !== calendarDayStr1 && calendarDay > sysTimeRef.current) {
      calendarDayStr = calendarDayStr1;
    }

    switch (jumpType) {
      case "ACTIVITY":
        navigate2Activity({ activityNo: jumpTargetId });
        break;
      case "CUSTOM_PAGE_30":
        if (platform.isApp) {
          const appVersion = (await getAppSystem())?.appVersion;
          if (appVersion && versionsCompare(appVersion, "7.54.0") >= 0) {
            const shopId = search.get("shopId");
            const distributorId = search.get("distributorId");
            let url = `${process.env.REACT_APP_PAGE_URL}/mx-shop-micro?customPageCode=${jumpTargetId}&calendarDay=${calendarDayStr}&env_identity=${search.get("env_identity") || ""}`;
            if (shopId) {
              url += `&shopId=${shopId}`;
            }
            if (distributorId) {
              url += `&distributorId=${distributorId}`;
            }
            AKJS.ready().then(() => {
              AKJS.action.dispatch("event.page.open", { url });
            });
          }
        } else {
          navigate2H5(RouteName.Index, {
            query: `customPageCode=${jumpTargetId}&calendarDay=${calendarDayStr}&env_identity=${search.get("env_identity") || ""}`,
          });
        }
        break;
      default:
        break;
    }
  };

  const initActiveIndex = (list, sysTime) => {
    const matchingRule1 = list.findIndex(item => {
      return item.title === "今日";
    });
    if (matchingRule1 !== -1) {
      return matchingRule1;
    }
    const matchingRule2 = list.findIndex(item => {
      return item.calendarDay >= sysTime;
    });
    if (matchingRule2 !== -1) {
      return matchingRule2;
    }
    return 0;
  };

  const initShareParam = (list, startIndex) => {
    const endIndex = startIndex + 2;
    const contentId = list
      .slice(startIndex, endIndex)
      .map(item => item.calendarDayStr)
      .join();
    return {
      contentId,
    };
  };

  const isNotStart = (timestamp, sysTime) => {
    const itemTimestamp = Number(timestamp);
    const sysTimestamp = Number(sysTime);
    return itemTimestamp > sysTimestamp;
  };
  const getList = () => {
    let params = {};
    if (isPreview) {
      params = {
        isPreview: true,
        isQuerySubscript: false,
      };
    } else {
      params = {
        isPreview: false,
        isQuerySubscript: true,
      };
    }
    setIsLoading(true);
    querySaleCalendarInfoServ(params)
      .then((res: any) => {
        const { sysTime, dayList } = res ?? {};
        if (sysTime) {
          sysTimeRef.current = sysTime;
        }
        const list = (dayList || []).map(item => {
          return {
            ...item,
            brandVOList: item.brandVOList.map(brand => {
              return {
                ...brand,
                showSubscribeBtn:
                  platform.isApp && !isPreview && isNotStart(brand.startTime, sysTime),
              };
            }),
            week: getTimestamp2Week(item.calendarDay),
          };
        });
        setList(list);
        handleClickSetActiveIndex(initActiveIndex(list, sysTime));
        shareParamRef.current = initShareParam(list, initActiveIndex(list, sysTime));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickShare = async () => {
    const { contentId } = shareParamRef.current || {};
    track.track("resource_click", {
      page_name: "今日必卖日历页",
      resource_type: "分享",
      resource_name: "分享",
      btn_name: "分享",
    });
    if (platform.isH5) {
      const sharePosterData = await sharePosterParams4H5SaleCalendar({
        contentId,
      });
      setCustomEditData({
        contentType: "SALE_CALENDAR",
        contentId,
      });
      setSharePosterData(sharePosterData);
      setShowPoster(true);
    } else {
      shareSaleCalendarPosterAction({
        contentId,
        pageName: "今日必卖日历页",
      });
    }
  };

  const initTitleConfig = () => {
    let title = ["A", "C"].includes(getUserRole()) ? "必买日历" : "必卖日历";
    console.log("getUserRole()——", getUserRole());
    document.title = title + (isPreview ? "（预览）" : "");
    const params: ButtonOptions[] = [
      {
        iconUrl:
          "https://akim-oss.aikucun.com/mshop/eeaaa563822b7c0c875f3c49e65c48e966db9716_1723717603263_33.png",
        onclick: () => {
          onClickShare();
        },
      },
    ];
    setTopRightButtons(params);
  };

  const onClosePoster = () => {
    setShowPoster(false);
    document.body.style.overflow = "auto";
  };

  const onScroll = (e: any) => {
    if (offWatchScroll.current) {
      return;
    }
    const scrollTop = e.target.scrollTop;
    const index = offsetTopListRef.current.findIndex(item => item >= scrollTop);
    setActiveIndex(index === -1 ? 0 : index);
  };

  useEffect(() => {
    const { contentId } = shareParamRef.current || {};
    onShareAppMessageBySaleCalendar({
      contentType: "SALE_CALENDAR",
      contentId,
    });
  }, [shareParamRef.current]);

  useEffect(() => {
    if (offWatchScroll.current) {
      document.getElementById("card" + activeIndex)?.scrollIntoView({ behavior: "auto" });
      setTimeout(() => {
        offWatchScroll.current = false;
      }, 100);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (isLoading || !list.length) {
      return;
    }
    const cardDomList = document.getElementsByClassName("card");
    let clientHeightList: any = [];
    for (let i = 0; i < cardDomList.length; i++) {
      if (cardDomList?.[i] instanceof HTMLElement) {
        clientHeightList.push(cardDomList?.[i].clientHeight);
      }
    }
    // 累加高度用作offsetTop，原offsetTop是针对视口的，不是针对滚动容器的
    clientHeightList = clientHeightList.reduce((accumulator: any, currentValue, currentIndex) => {
      const sum = clientHeightList.slice(0, currentIndex + 1).reduce((acc, val) => acc + val, 0);
      accumulator.push(sum);
      return accumulator;
    }, []);
    offsetTopListRef.current = clientHeightList;
  }, [isLoading]);

  useEffect(() => {
    initTitleConfig();
    getList();
    setTrackData({
      page_name: "今日必卖日历页",
      previous_page_name: search.get("page_name") || "",
    });
    track.track("$pageview", {
      page_name: "今日必卖日历页",
      scode,
    });
  }, []);

  useActivate(() => {
    initTitleConfig();
  });

  return (
    <div id="page" className={styles.page}>
      <div className={styles["page__header"]}>
        {platform.isH5 && (
          <TopNavigation
            hideSearchIcon={true}
            hideCartIcon={true}
            hideFavoriteIcon={true}
            showShareIcon={true}
            onClickShare={onClickShare}
          />
        )}
        <TabBasic
          list={list}
          activeIndex={activeIndex}
          setActiveIndex={handleClickSetActiveIndex}
        />
      </div>
      <div className={styles["page__body"]} onScroll={onScroll}>
        {list.map((item, index) => {
          return (
            <div id={"card" + index} className="card" key={index}>
              <SellCalendarCardComp
                sellCalendar={item}
                index={index}
                showSubscribeBtn={item.showSubscribeBtn}
                onSubscribePress={(obj, relativeRank) => {
                  onSubscribePress({ ...obj, floorIndex: index, relativeRank });
                }}
                onBrandCardPress={(obj, relativeRank) => {
                  onBrandCardPress({ ...obj, floorIndex: index, relativeRank });
                }}
                trackData={{
                  page_name: "今日必卖日历页",
                  previous_page_name: search.get("page_name") || "",
                }}
              />
            </div>
          );
        })}
      </div>

      {/* 海报*/}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        customEditData={customEditData}
        shareType="SALE_CALENDAR"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}
      />
    </div>
  );
};

export default SaleCalendar;
