import React from "react";
import { useActivate } from "react-activation";
import styles from "./index.module.less";

const BottomNotice = ({ isActivity = false }: { isActivity?: boolean; }) => {
  const [isShow, setIsShow] = React.useState(false);
  const className = isActivity ? `${styles.bottomNotice} ${styles.isActivity}` : `${styles.bottomNotice}`;

  const checkVisible = React.useCallback(() => {
    const now = new Date();
    if (now.getTime() >= new Date("2025-01-27").getTime()) {
      // 2025-01-27 之后不再显示
      setIsShow(false);
      return;
    }
    if (sessionStorage.getItem("bottomNoticeShow") === "false") {
      // 本 session 中已经关闭过
      setIsShow(false);
      return;
    }
    setIsShow(true);
  }, []);

  React.useEffect(() => {
    checkVisible();
  }, []);

  useActivate(() => {
    checkVisible();
  });

  const handleClose = React.useCallback(() => {
    sessionStorage.setItem("bottomNoticeShow", "false");
    setIsShow(false);
  }, []);

  if (!isShow) {
    return null;
  }
  return (
    <div className={className}>
      <img
        src="https://akim-oss.aikucun.com/d819f6810d1b3b4388715718a1dcb77968818ed9_1737367706062_97.png"
        alt=" notice-icon"
        className={styles.noticeIcon}
      />
      <span className={styles.noticeText}>
        部分商品<span className={styles.hightLight}>物流马上停运</span>，请尽快下单
      </span>
      <div className={styles.noticeClose}
        onClick={handleClose}>
        <img
          src="https://akim-oss.aikucun.com/4a1a0e5ed29c0ea4cf8742c01f98270f5bebf689_1737367705735_93.png"
          alt="notice-close"
          className={styles.closeIcon}
        />
      </div>
    </div>
  );
};

export default BottomNotice;

