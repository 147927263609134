import React from "react";
import styles from "./index.module.less";

interface EmptyProps {
  text?: string;
}

const Empty: React.FC<EmptyProps> = ({ text = "暂无数据" }) => {
  return (
    <div className={styles.container}>
      <img
        src="https://akim-oss.aikucun.com/004922066efd0f47bcb6e8caace8db91817298ba_1742892087704_10.png"
        alt="空状态"
        className={styles.icon}
      />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default Empty;