import { batchReceiveCoupon } from "@/api/coupon";
import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";
import { useCommonContext } from "@/utils/context/common";
import Track from "@/utils/track";
import { Mask, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import CouponItemOne from "./couponItem/one";
import CouponItemThree from "./couponItem/three";
import CouponItemTwo from "./couponItem/two";
import styles from "./index.module.less";

interface IProps {
  couponInfo: any;
  pageName?: string;
  shopCode?: string;
  activityId?: string;
  activitySpuId?: string;
  update?: () => void;
}

const CouponForVenue: React.FC<IProps> = ({
  couponInfo,
  pageName,
  shopCode,
  activityId,
  activitySpuId,
  update,
}) => {
  const [visible, setVisible] = useState<boolean>(true);
  const [thirdCustomerCoupons, setThirdCustomerCoupons] = useState<any>([]);
  const [spCouponActivities, setSpCouponActivities] = useState<any>([]);
  const [couponsId, setCouponsId] = useState<any>([]);
  const [bindPhonePopupVisible, setBindPhonePopupVisible] = useState(false);
  const track = Track();
  const { userVisitInfo } = useCommonContext();

  const toAuth = (hasScope = true) => {
    let url = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    // 处理微信自动添加字符串问题，替换为空
    url = url.replace(/\/\?from=(message|timeline|singlemessage|groupmessage)(\S*)#/, "/#");
    url = url.replace(/\/\?(scene|subscene|clicktime)=[\d]+(\S*)#/, "/#");
    // eslint-disable-next-line no-restricted-syntax
    window.location.href = `/api/member/auth?${hasScope ? "scope=snsapi_userinfo&" : ""
      }backUrl=${window.encodeURIComponent(url)}`;
  };

  const onClose = () => {
    setVisible(false);
    const trackParams = {
      page_name: pageName,
      btn_name: "关闭",
      resource_name: "品牌新客券",
      resource_type: "自动弹窗",
      resource_content: "优惠券",
      merchant_shop_code: shopCode,
      activity_id: activityId,
      activity_spu_code: activitySpuId,
      coupons_id: couponsId.join(","),
    };
    track.track("resource_click", trackParams);
  };

  const getDayDiff = (time1: number, time2: number) => {
    if (Number(time2) <= Number(time1)) {
      return 0;
    }
    const timeDiff = Math.abs(Number(time2) - Number(time1));
    const diffDays = timeDiff / (1000 * 3600 * 24);
    return diffDays;
  };

  const getFormatTime = timestamp => {
    let dateTime = new Date(Number(timestamp));
    let mon = dateTime.getMonth() + 1;
    let day = dateTime.getDate();
    return mon + "月" + day + "日";
  };

  const getCountdown = (time1, time2) => {
    const time = Math.ceil((time2 - time1) / 1000);
    let h = Math.trunc(time / 3600);
    let m = Math.trunc((time % 3600) / 60);
    return h + "时" + m + "分";
  };

  const handleCouponList = couponList => {
    if (!couponList || !couponList.length) {
      return [];
    }
    const newList = couponList.map(item => {
      const time1 = item.systemTime || Date.now();
      const time2 = item.endTime || Date.now();
      const diffDays = getDayDiff(time1, time2);
      const diffDaysInteger = Math.floor(diffDays);
      const endTimeDesc = getFormatTime(time2);
      let countdownDesc = "";
      if (diffDays > 0 && diffDays < 1) {
        countdownDesc = getCountdown(time1, time2);
      }
      return {
        ...item,
        diffDays,
        diffDaysInteger,
        endTimeDesc,
        countdownDesc,
      };
    });
    return newList;
  };

  useEffect(() => {
    let couponsId = [];
    if (couponInfo?.thirdCustomerCoupons?.length) {
      const couponList = handleCouponList(couponInfo.thirdCustomerCoupons);
      const ids = couponList.map((item: any) => item.ticketId);
      couponsId = couponsId.concat(ids);
      setThirdCustomerCoupons(couponList);
    }
    if (couponInfo?.spCouponActivities?.length) {
      const couponList = handleCouponList(couponInfo.spCouponActivities);
      const ids = couponList.map((item: any) => item.ticketId);
      couponsId = couponsId.concat(ids);
      setSpCouponActivities(couponList);
    }

    if (couponsId.length) {
      setCouponsId(couponsId);
      const trackParams = {
        page_name: pageName,
        resource_name: "品牌新客券",
        resource_type: "自动弹窗",
        resource_content: "优惠券",
        merchant_shop_code: shopCode,
        activity_id: activityId,
        activity_spu_code: activitySpuId,
        coupons_id: couponsId.join(","),
      };
      track.track("resource_expose", trackParams);
    }
  }, []);

  const ThirdCouponsComp = () => {
    return (
      <div
        className={`${styles.couponNewcomer} ${thirdCustomerCoupons.length === 1 ? styles.single : null
          } ${thirdCustomerCoupons.length === 2 ? styles.couple : null}`}>
        {thirdCustomerCoupons.map((item, index) => {
          if (thirdCustomerCoupons.length === 1) {
            return <CouponItemOne key={index} coupon={item} type="newcomer" />;
          }
          if (thirdCustomerCoupons.length === 2) {
            return <CouponItemTwo key={index} coupon={item} type="newcomer" />;
          }
          if (thirdCustomerCoupons.length === 3) {
            return <CouponItemThree key={index} coupon={item} type="newcomer" />;
          }
          return null;
        })}
      </div>
    );
  };

  const VunueCouponsComp = () => {
    return (
      <div
        className={`${styles.couponVenue} ${spCouponActivities.length === 1 ? styles.single : null
          } ${spCouponActivities.length === 2 ? styles.couple : null} ${spCouponActivities.length > 2 ? styles.triple : null
          }`}>
        <div className={`${spCouponActivities.length > 3 ? styles.couponVenueList : styles.flex}`}>
          {spCouponActivities.map((item, index) => {
            if (spCouponActivities.length === 1) {
              return <CouponItemOne key={index} coupon={item} type="venue" />;
            }
            if (spCouponActivities.length === 2) {
              return <CouponItemTwo key={index} coupon={item} type="venue" />;
            }
            if (spCouponActivities.length >= 3) {
              return <CouponItemThree key={index} coupon={item} type="venue" />;
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  const onReceiveCoupon = async () => {
    const trackParams = {
      page_name: pageName,
      btn_name: "立即领券",
      resource_name: "品牌新客券",
      resource_type: "自动弹窗",
      resource_content: "优惠券",
      merchant_shop_code: shopCode,
      activity_id: activityId,
      activity_spu_code: activitySpuId,
      coupons_id: couponsId.join(","),
    };
    track.track("resource_click", trackParams);
    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      setBindPhonePopupVisible(true);
      return;
    }
    let couponByIdReqList = [];
    if (spCouponActivities && spCouponActivities.length) {
      couponByIdReqList =
        spCouponActivities.map(item => ({
          awdId: item.ticketId,
          promoActivityId: item.promoActivityId,
        })) || [];
    }
    if (thirdCustomerCoupons && thirdCustomerCoupons.length) {
      couponByIdReqList = couponByIdReqList.concat(
        thirdCustomerCoupons.map(item => ({
          awdId: item.ticketId,
          promoActivityId: item.promoActivityId,
        })),
      );
    }
    const res = await batchReceiveCoupon({
      couponByIdReqList,
      rcsTraceId: "",
      captchaToken: "",
    });
    const { code, success, data, message } = res || {};
    if (code === "00000" && success && data) {
      Toast.show("优惠券领取成功");
      setVisible(false);
      update && update();
      return;
    }
    Toast.show(message || "优惠券被领光啦");
  };

  const onFinishBindPhone = () => {
    setBindPhonePopupVisible(false);
    onReceiveCoupon();
  };

  return (
    <>
      <Mask visible={visible} onMaskClick={() => onClose()} opacity={0.75}>
        <div className={styles.couponContent}>
          <i className={styles.iconClose} onClick={() => onClose()}></i>
          <div className={styles.couponTitle}>
            <i className={styles.iconTitle1}></i>
            <span className={styles.amount}>{couponInfo.totalCouponAmount}元</span>
            <i className={styles.iconTitle2}></i>
          </div>
          <div className={styles.couponLabel}></div>
          {!!thirdCustomerCoupons.length && <ThirdCouponsComp />}
          {!!spCouponActivities.length && <VunueCouponsComp />}
          <div className={styles.couponBtn} onClick={onReceiveCoupon}>
            {userVisitInfo?.isVisitor ? "登录领券" : "领券去使用"}
          </div>
          <i className={styles.couponIcon}></i>
        </div>
      </Mask>
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={onFinishBindPhone}
      />
    </>
  );
};

export default CouponForVenue;
