import ARROW_RIGHT from "@/assets/arrow_right@3.png";
import TopNavigation from "@/components/top-navigation";
import { RouteName } from "@/router/extra";
import { useCommonContext } from "@/utils/context/common";
import { formatDate } from "@/utils/date-format";
import useQueryAllPlatRole from "@/utils/hooks/useQueryAllPlatRole";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { Toast } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  createChannelSharerPoster,
  queryAchievementData,
  queryChannelAnchorInfo,
  queryChannelSharerStatus,
} from "../../api/videoShare";
import InvitedShareModal from "./components/invitedShareModal";
import LiveList from "./components/liveList";
import { UseVideoShareContext, UseVideoShareProvider } from "./hooks/useVideoShare";
import styles from "./index.module.less";

interface Props {
  appStore?: any;
  [key: string]: any;
}

// 1、 queryData 为接口返回的数据
// 2、在当前页面显示文案 + 二维码 还是走3
//  2.1 弹框显示二维码
//  2.2 分不同的端， app、小程序（长按识别）、h5 保存不同的图片
// 3、需要跳转一个页面fix
const VideoShare: React.FC<Props> = ({ appStore }) => {
  const { getRole } = useQueryAllPlatRole();
  const role = getRole();
  const {
    setAnchorDataList,
    setQrImage,
    qrImage,
    setIsOpenModal,
    isOpenModal,
    pollingActive,
    setPollingActive,
    accountId,
    setAccountId,
    queryAcceptInvite,
    dataProcess,
  } = UseVideoShareContext();
  const [search] = useSearchParams();
  const [isSharer, setIsSharer] = useState<number | undefined>(undefined);
  const [rotationCount, setRotationCount] = useState(0);
  const { navigate2H5 } = useNavigation();
  const { track } = useCommonContext();
  const [showNotice, setShowNotice] = useState<boolean>(true);
  const [achievementCommission, setAchievementCommission] = useState<any>(null);

  const openModal = () => {
    if (isSharer === 0) {
      if (qrImage) {
        setIsOpenModal(true);
      } else if (!qrImage) {
        Toast.show("二维码海报暂未生成~请稍后重试");
      }
      if (track.track) {
        track.track("btn_click", {
          previous_page_name: "我的",
          btn_name: "成为视频号分享员",
          page_name: "视频号分享员",
        });
      }
    } else {
      return;
    }
  };

  const reqAchievementDatas = async () => {
    const beginTime = formatDate(new Date()).concat(" ", "00:00:00");
    const endTime = formatDate(new Date()).concat(" ", "23:59:59");
    const res = (await queryAchievementData({
      beginTime,
      endTime,
    })) as any;
    setAchievementCommission(dataProcess(res?.data?.list[0]?.commission));
  };
  //请求二维码图片
  const queryQrCode = async (accountid?: string) => {
    try {
      const res: any = await createChannelSharerPoster({
        accountId: platform.isApp ? accountid : search.get("accountId"),
      });
      if (res?.success) {
        setQrImage(res?.data?.posterImage);
      } else {
        Toast.show(res?.message || "请求异常");
      }
    } catch {
      console.log("请求二维码图片失败");
    }
  };
  const queryBoundChannelInfo = async (accountid?: string) => {
    const res: any = await queryChannelAnchorInfo({
      accountId: platform.isApp ? accountid : search.get("accountId"),
    });
    if (res?.success) {
      //   setAnchorInfo({ nickName: res.data?.nickName, headImgUrl: res.data?.headImgUrl });
      setAnchorDataList(res?.data?.anchorDataList);
      const anchorInfo = {
        nickName: res?.data?.nickName || "未设置昵称",
        headImgUrl: res?.data?.headImgUrl,
      };
      localStorage.setItem("anchorInfo", JSON.stringify(anchorInfo));
    } else {
      Toast.show(res?.message || "请求异常");
    }
    if (track.track) {
      track.track("$pageview", {
        page_name: "分享员详情页",
        // previous_page_name: "我的",
      });
    }
  };

  const registerStatus = async ({ showToast }) => {
    const res: any = await queryChannelSharerStatus({ accountId: accountId });
    if (res?.success) {
      setIsSharer(res?.data?.registerStatus);
      if (res?.data?.registerStatus === 1) {
        // queryBoundChannelInfo();
      } else if (showToast) {
        Toast.show("未查询到已绑定账号，绑定微信需要与当前账号一致");
      }
    } else {
      Toast.show(res.message || "请求异常");
    }
  };
  //轮询
  useEffect(() => {
    let intervalId;
    let counter = 0;
    if (pollingActive) {
      intervalId = setInterval(() => {
        if (counter >= rotationCount || isSharer === 1) {
          clearInterval(intervalId); // 达到终止条件时清除定时器
          setPollingActive(false);
          // if (isSharer === 1) {
          //   goConference();
          // }
          return;
        }
        let showToast = false;
        if (counter === rotationCount - 1) {
          showToast = true;
        }
        Toast.show("查询中，请稍等");
        registerStatus({ showToast: showToast });
        counter++;
      }, 2000);

      return () => {
        clearInterval(intervalId); // 组件卸载时清除定时器
      };
    }
  }, [pollingActive, isSharer]);

  //获取分享员身份和二维码图片
  const queryPage = async () => {
    try {
      const res: any = await queryChannelSharerStatus({ accountId: search.get("accountId") });
      if (res?.success) {
        setIsSharer(res?.data?.registerStatus);
        if (platform.isApp) {
          setAccountId(res?.data?.accountId);
        }
        setRotationCount(res?.data?.rotationCount);
        if (res?.data?.registerStatus === 1) {
          if (platform.isApp) {
            queryBoundChannelInfo(res?.data?.accountId);
          } else {
            queryBoundChannelInfo(); //是分享员时请求绑定的视频号信息
          }
        } else {
          if (platform.isApp) {
            queryQrCode(res?.data?.accountId);
          } else {
            queryQrCode(); //不是分享员时请求海报
          }
          if (track.track) {
            track.track("$pageview", {
              page_name: "视频号分享员",
              previous_page_name: "我的",
            });
          }
        }
      } else {
        // setIsShow(true);
        Toast.show(res?.message || "请求异常");
      }
    } catch (error) {
      // setIsShow(true);
      Toast.show("请求异常");
    }
  };
  //获取token/shopId/distributorId
  const initData = async () => {
    setAccountId(search.get("accountId"));
    if (platform.isApp) {
      const res: any = await AKJS.info.account();
      appStore.token = res.data.token;
      appStore.channel = "akcApp";
    } else if (platform.isMini) {
      appStore.token = null;
      appStore.channel = "xdApplets";
      if (search.get("plt") === "saas") {
        appStore.channel = "SaaSApplets";
      }
      appStore.token = search.get("token");
    }
    queryPage();
  };
  const goPerformanceBorad = () => {
    navigate2H5(RouteName.PerformanceBoard, {
      query: `token=${search.get("token")}&currrentRole=${search.get("currrentRole")}`,
    });
  };

  useEffect(() => {
    initData();
    reqAchievementDatas();
    document.title = "分享员";
    console.log(role, "role");
  }, []);

  (window as any).akc_onAppear = () => {
    if (accountId) {
      queryAcceptInvite();
    }
  };

  return (
    <div style={{ overflow: "scroll", height: "100vh" }}>
      {isSharer === 1 && (
        <>
          {(platform.isH5 || platform.isWxH5) && (
            <div>
              <TopNavigation hideSearchIcon={true} hideCartIcon={true} hideFavoriteIcon={true} />
            </div>
          )}
          {showNotice && role === "R" && (
            <div className={styles["notice"]}>
              您可以在爱库存App店铺设置里为店长打开分享员开关
              <div className={styles["notice__btn"]} onClick={() => setShowNotice(false)}>
                知道了
              </div>
            </div>
          )}
          <div className={styles.container}>
            <div className={styles["performance-intro"]} onClick={goPerformanceBorad}>
              <div className={styles["intro__title"]}>业绩看板</div>
              <div className={styles["intro__right"]}>
                {achievementCommission &&
                  (Number(achievementCommission.integer) > 0 ||
                    Number(achievementCommission.decimal) > 0) && (
                    <>
                      <div className={styles["pre__price"]}>预估收入(元)</div>
                      <div className={styles["price"]}>
                        <span className={styles["price__integer"]}>
                          {achievementCommission.integer}
                        </span>
                        {achievementCommission.decimal !== "" && (
                          <span className={styles["price__decimal"]}>
                            .{achievementCommission.decimal}
                          </span>
                        )}
                      </div>
                    </>
                  )}

                <img className={styles["intro__arrow__icon"]} src={ARROW_RIGHT} alt="" />
              </div>
            </div>

            <LiveList />
          </div>
        </>
      )}
      {isSharer === 0 && (
        <div className={styles.showWrapper}>
          <div
            className={styles.wrapper}
            style={{
              height: "100vh",
              overflow: "scroll",
            }}>
            <div className={styles.topTitle}>
              <img
                src="https://akim-oss.aikucun.com/9d43fecc42b50ec34129cff4365dcadfed307e30_1699327626657_98.png"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div
              className={styles.content}
              style={{
                paddingLeft: pxtorem(16),
                paddingRight: pxtorem(16),
                paddingBottom: pxtorem(90),
              }}>
              <div className={styles.container1} style={{ paddingBottom: pxtorem(9) }}>
                <img
                  src="https://akim-oss.aikucun.com/4008cfa6eedc41ba634995770c8a44dbab077b7e_1699327940289_98.png"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className={styles.container2} style={{ paddingBottom: pxtorem(9) }}>
                <img
                  src="https://akim-oss.aikucun.com/c1ee475a0be3148d06338b1e67db031a7a6fe5d9_1699328396836_74.png"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className={styles.container3} style={{ paddingBottom: pxtorem(9) }}>
                <img
                  src="https://akim-oss.aikucun.com/d711a99b72cc456639bcdcc609aed2b8e126129b_1699328434304_99.png"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className={styles.container4} style={{ paddingBottom: pxtorem(9) }}>
                <img
                  src="https://akim-oss.aikucun.com/25d08b0027db56c3a9c21ca5292b961fe5071096_1699328462809_8.png"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </div>
            <div
              className={styles.footerWrap}
              style={{
                width: "100%",
                height: pxtorem(56),
                paddingBottom: pxtorem(34),
              }}>
              <div
                className={styles.bottom_btn}
                style={{
                  height: pxtorem(44),
                  width: pxtorem(355),
                  borderRadius: pxtorem(6),
                  fontSize: pxtorem(16),
                  lineHeight: pxtorem(22),
                  color: "#fff",
                  background: "#ff4747",
                }}
                onClick={() => {
                  openModal();
                }}>
                成为视频号分享员
              </div>
            </div>
          </div>
          {isOpenModal && <InvitedShareModal />}
        </div>
      )}
    </div>
  );
};

const Container: React.FC<Props> = ({ appStore }) => {
  return (
    <UseVideoShareProvider>
      <VideoShare appStore={appStore} />
    </UseVideoShareProvider>
  );
};

export default inject("appStore")(observer(Container));
