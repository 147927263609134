import { useCommonContext } from "@/utils/context/common";

export const useTrack = (pageName?: string) => {
  const { track } = useCommonContext();

  // PV
  const trackPageView = (params?: {
    previousPageName?: string | null;
    activitySpuCode?: string | null;
  }) => {
    try {
      if (track.track) {
        track.track("$pageview", {
          page_name: pageName,
          previous_page_name: params?.previousPageName,
          activity_spu_code: params?.activitySpuCode,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    trackPageView,
  };
};
