import type { HighValue, LotteryAwardResponse } from "@/api/dailyReward";
import useNavigation from "@/utils/useNavigate";
import React, { forwardRef, useImperativeHandle } from "react";
import { Overlay } from "react-vant";
import Certificate from "../components/Certificate";
import GiftPanel from "../components/GiftPanel";
import useCelebrateModule from "../hook/useCelebrateModule";
import "./CelebrateModule.css";
import styles from "./CelebrateModule.module.less";


export interface CelebrateModuleRef {
  type?: string,
  setType: (val: string) => any
  setPanelType: (val: string) => void
  setVisible: (val: boolean) => any
}

interface CelebrateModuleProps {
  dataSource?: LotteryAwardResponse
  highDataSource?: HighValue | null
  onAfterClose?: (props: {
    type: string,
    panelType: string,
    isButtonClose?: boolean
  }) => void
}


const CelebrateModule = forwardRef<CelebrateModuleRef, CelebrateModuleProps>((props, ref) => {
  const { dataSource, highDataSource } = props;
  const {
    visible,
    type,
    panelType,
    setType,
    setPanelType,
    setVisible,
  } = useCelebrateModule();
  const { navigate, navigate2Store } = useNavigation();

  useImperativeHandle(ref, () => ({
    setType,
    setPanelType,
    setVisible
  }));

  // 通知外部关闭事件
  const notifyAfterClose = (isButtonClose = false) => {
    if (props.onAfterClose) {
      props.onAfterClose({
        type,
        panelType,
        isButtonClose
      });
    }
  };

  // 处理导航逻辑
  const handleNavigation = () => {
    // 当是证书面板且是自己的奖品时，根据 pageType 执行不同的逻辑
    if (type === "default" && panelType === "certificate") {
      const result = dataSource;

      // 如果是市场类型，跳转到自定义页面
      if (result?.redPacketAward?.pageType === "MARKET") {
        // 跳转档期页面
        navigate({
          h5Options: {
            url: `/mx-shop-micro?customPageCode=${result.redPacketAward?.customPageCode}`,
          },
          appOptions: {
            url: `${origin}/mx-shop-micro?customPageCode=${result.redPacketAward?.customPageCode}`,
          },
          miniOptions: {
            url: `${origin}/mx-shop-micro`,
            query: {
              customPageCode: result.redPacketAward?.customPageCode,
            },
          },
        });
      } else if (result?.awardType === "RED_PACKET" && result?.merchantShopList?.[0]) {
        // 如果是红包类型且有商店信息，跳转到商店
        navigate2Store({
          merchantShopCode: result.merchantShopList[0].shopCode,
          shopCode: result.merchantShopList[0].shopCode,
          page_name: encodeURIComponent("日日奖"),
          productType: 1,
        });
      }
    }
  };

  // 点击遮罩层关闭 - 执行所有逻辑
  const handleClickToClose = () => {
    setVisible(false);
    notifyAfterClose(false);
    handleNavigation();
  };

  // 点击关闭按钮关闭 - 只触发关闭事件，不执行导航逻辑
  const handleCloseButtonClick = () => {
    setVisible(false);
    notifyAfterClose(true);
  };

  return (
    <Overlay
      className={styles.overlay}
      visible={visible}
      zIndex={120}
      onClick={handleClickToClose}
    >
      <div className={styles.content}>
        {
          panelType === "certificate"
            ? <Certificate type={type} dataSource={dataSource} highDataSource={highDataSource ?? {}} onClose={handleClickToClose} />
            : <GiftPanel type={type} dataSource={dataSource} highDataSource={highDataSource ?? {}} onClose={handleClickToClose} />
        }
        <img
          className={styles.close}
          src="https://akim-oss.aikucun.com/a4d1a23a4e960b293ebc5d3d175a8d0948f32cba_1740043019739_0.png"
          alt=""
          onClick={handleCloseButtonClick}
        />
      </div>

      <div className={styles.footer} >
        <img
          src="https://akim-oss.aikucun.com/0db68b0d3213d10ac21be40684dc257371782f39_1740040374720_66.png"
          alt=""
        />
      </div>
    </Overlay>
  );
});

export default CelebrateModule;