import { queryRecommendList } from "@/api/product";
import { ProductDetailModel, ProductModel } from "@akc/biz-api";
import { useEffect, useRef, useState } from "react";

const useRecommendProductList = ({
  product,
  scene,
}: {
  product?: ProductDetailModel;
  scene?: string;
}) => {
  // 当前页（必须在1~500之间！）
  const pageNum = useRef<number>(1);
  // 是否有下一页
  const [hasNext, setHasNext] = useState(false);
  // 商品列表
  const [recommendProductList, setRecommendProductList] = useState<ProductModel[]>([]);

  const fetchProductList = async () => {
    if (!product) {
      return;
    }
    const res = await queryRecommendList({
      pageId: "H5_Search",
      activitySpuIds: [product.activitySpuId ?? ""],
      pageIndex: pageNum.current,
      pageSize: 10,
      scene: scene,
      categoryCode: product.spuCategoryInfoVO?.categoryCode,
      voConfig: {
        showType: 2,
      },
    });
    const result = res?.result ?? [];
    setRecommendProductList(productList => {
      return [
        ...productList,
        ...(result?.map((item, index) => ({
          ...item,
          rank: productList.length + index,
          trackData: {
            recommend_requestid: `${res?.extra?.searchRequestId ?? Date.now()}`,
            domain: "商品详情",
            module: "好货推荐",
            resource_type: "大家都在买",
            resource_content: "商品",
            resource_name: "大家都在买",
            click_activity_id: item.activityId,
            click_activity_spu_code: item.activitySpuId,
            click_sku_id: item.selectSkuId,
            btn_name: "推荐商品",
            resource_id: item.activitySpuId,
            resource_rank: productList.length + index + 1,
            product_model: item.activityType === 4 ? "普通商品" : "档期商品",
            activity_spu_code: product?.activitySpuId,
            sku_id: product?.selectSkuId,
            activity_id: product?.activityId,
          },
        })) ?? []),
      ];
    });
    setHasNext(res?.hasNext ?? false);
    pageNum.current = (res?.pageIndex ?? 1) + 1;
  };

  // 加载更多
  const onLoadNextPage = async () => {
    await fetchProductList();
  };

  useEffect(() => {
    if (!product) {
      return;
    }
    setRecommendProductList([]);
    pageNum.current = 1;
    fetchProductList();
  }, [product]);

  return {
    hasNext,
    recommendProductList,
    onLoadNextPage,
  };
};

export default useRecommendProductList;
