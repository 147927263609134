import { adminLogout } from "@/api/member";
import { RouteName } from "@/router/extra";
import { getPlatform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import { Dialog } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

const Logout: React.FC = () => {
  const { isWxH5 } = getPlatform();
  const { navigate2H5 } = useNavigation();

  const onLogout = () => {
    Dialog.confirm({
      title: "退出登录",
      content: "确认退出当前账号吗?",
      bodyClassName: "dialog_logout",
      onConfirm: async () => {
        await adminLogout();
        navigate2H5(RouteName.Home, {
          replace: true,
        });
      },
    });
  };

  return (
    <>
      {!isWxH5 && (
        <div className={styles.logout} onClick={onLogout}>
          退出登录
        </div>
      )}
    </>
  );
};

export default Logout;
