import { getCouponWindowForVenue } from "@/api/coupon";
import {
  getShop,
  getShopPage,
  searchActivity,
  searchCoupon,
  searchMaterial,
  searchMaterialV2,
  searchNavigationActivity,
  searchNavigationProduct,
  searchPageInfo,
  searchProduct,
  searchSecKill,
  searchShopInfo,
  searchTopList,
  subscribe,
} from "@/api/decorate";
import { SharePosterParams } from "@/api/scode/poster";
import { CarrierElementType } from "@/api/scode/scode_params";
import { getProfitadditional } from "@/api/shopPlus";
import loadingPng from "@/assets/loading2.png";
import noSecKill from "@/assets/no_seckill.png";
import CouponForVenue from "@/components/couponForVenue";
import DailyRewardNotice from "@/components/daily-reward-notice";
import { CustomEditData, default as PosterSwipe } from "@/components/poster-swiper";
import RedPacketNotice from "@/components/red-packet-notice";
import TopBar from "@/components/top-bar";
import { RouteName } from "@/router/extra";
import {
  autoShareConferencePosterIncrement,
  conference3BizInfo,
  conference3CarrierContext,
  conference3ContentContext,
  conference3EnvContext,
  shareDecorate,
  sharePosterParams4H5Product,
  shouldAutoShareConferencePoster,
  useActions,
  useShareConferencePoster,
} from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { initTime } from "@/utils/countdown";
import { getUserRole, identity } from "@/utils/identity";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { getBaseurl, getUrlParam } from "@/utils/url-parse";
import useNavigation from "@/utils/useNavigate";
import { default as AKJS } from "@akc/akjs";
import { Image, Toast } from "antd-mobile";
import { isEmpty, throttle } from "lodash";
import { default as React, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import AllPanel from "./components/all-panel";
import DecorateEquitiesCard from "./components/decorate-equities-card";
import Home from "./components/home";
import ShopEmpty from "./components/shopEmpty";
import ShopInfo from "./components/shopInfo";
import ActivityV2Com from "./components/special-sale";
import { UseDecorateContext } from "./hooks/useDecorate";
import styles from "./index.module.less";

interface IProps {
  appStore?: any;
  [key: string]: any;
}

const Decorate: React.FC<IProps> = ({ appStore }) => {
  const { navigate2H5 } = useNavigation();
  const { shareProductPosterAction } = useActions();
  const { track, isExternal } = useCommonContext();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<any>();
  const [couponInfo, setCouponInfo] = useState<any>();
  const [hasMore, setHasMore] = useState(false);
  const [customPageCode] = useState<string | null>(searchParams.get("customPageCode"));
  const [merchantShopCode] = useState<string | null>(searchParams.get("merchantShopCode"));
  const [shopId] = useState<string | null>(searchParams.get("shopId"));
  const [distributorId] = useState<string | null>(searchParams.get("distributorId"));
  const [previous_page_name] = useState<string | null>(searchParams.get("previous_page_name"));
  const ratio = parseFloat(getComputedStyle(document.querySelectorAll("html")[0]).fontSize) / 50;
  const [tabList, setTabList] = useState([
    { id: 0, name: "首页", show: false },
    { id: 2, name: "特卖", show: false },
    { id: 1, name: "全部", show: false },
  ]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [shopInfo, setShopInfo] = useState<any>();
  const [showPoster, setShowPoster] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const [currentPromotionType, setCurrentPromotionType] = useState(
    getUrlParam()?.["currentPromotionType"],
  );
  const [advancePromotionType, setAdvancePromotionType] = useState(
    getUrlParam()?.["advancePromotionType"],
  );
  // 转发卡片（修改文案）需要的参数
  const [, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<SharePosterParams>(
    {} as SharePosterParams,
  );
  const [isShowInNav, setIsShowInNav] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const shopInfoRef = useRef<HTMLDivElement>(null);
  const pageCodeRef = useRef();
  const tabChangeCount = useRef(0);

  const { shareConferencePosterAction } = useShareConferencePoster();

  const {
    selectedNav,
    shareBtnText,
    setShareBtnText,
    brandId: brandIds,
    setBrandId,
  } = UseDecorateContext();
  const [materialData, setMaterialData] = useState<any>({});
  const showRef = useRef(false);
  //店铺订阅
  const subscribeShop = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isSubscript: boolean,
  ) => {
    e.stopPropagation();
    const info = { ...shopInfo };
    if (isSubscript) {
      return;
    } else {
      const res = await subscribe({
        bizType: "MERCHANT_SHOP",
        bizNo: merchantShopCode,
      });
      if (res?.data) {
        info.isSubscript = true;
      }
    }
    setShopInfo(info);
  };
  //根据shopcode查询搭建页信息
  const queryPageInfo = async (shopCode, customPageCode) => {
    let res = await getShopPage({ shopCode: shopCode, customPageCode });
    return res;
  };
  /**
   * 0:首页 1:全部 2:特卖
   * @param idList array
   */
  const setUnShowTabItem = (idList: number[]) => {
    setTabList(pre => {
      return pre.map(item => {
        if (idList.includes(item.id)) {
          return { ...item, show: true };
        }
        return item;
      });
    });
  };
  //判断最后一个组件是否是档期或者商品，并判断是否还需要加载更多
  const checkLast = (pageInfo: any) => {
    if (pageInfo?.components?.length > 0) {
      const last = pageInfo.components[pageInfo.components.length - 1];
      if (last.type === "ACTIVITY") {
        setHasMore(last?.dataInfo?.hasNext);
        return;
      }
      if (
        last.type === "PRODUCT" &&
        last.animationType !== "2" &&
        last.layoutType !== "10" &&
        last.layoutType !== "20"
      ) {
        setHasMore(last?.dataInfo?.hasNext);
        return;
      }
      setHasMore(false);
    }
  };
  //获取档期
  const queryActivity = async (item: any, pageData: any, from = 0) => {
    if (queryLoading) {
      return;
    }
    setQueryLoading(true);
    const params = {
      componentCode: item.componentCode,
      componentDetailCodes: item.activityRuleConfigDetails.map(item => item.componentDetailCode),
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      from: from,
      pageSize: from > 0 ? item.morePageSize : item.pageSize,
      lastActivityId:
        item?.dataInfo?.result?.length > 0
          ? item?.dataInfo?.extra?.lastActivityId
            ? item?.dataInfo?.extra?.lastActivityId
            : item?.dataInfo?.result[item.dataInfo?.result.length - 1]?.activityId
          : null,
      pageId: "H5_Shop",
      preview: false,
    };
    const res: any = await searchActivity(params);
    if (res) {
      if (from > 0) {
        res.data.result = res.data?.result
          ? item.dataInfo?.result.concat(res.data?.result)
          : item.dataInfo?.result;
        item.dataInfo = res?.data;
      } else {
        item.dataInfo = res?.data;
      }
      for (const itemInfo of item.dataInfo?.result || []) {
        const { day, hour, minute, seconds } = initTime({
          type: "ms",
          startTime: itemInfo?.status === 1 ? itemInfo?.beginTimeStr : itemInfo?.endTimeStr,
          serverTime: new Date().valueOf(),
        });
        itemInfo.day = day;
        itemInfo.hour = hour;
        itemInfo.minute = minute;
        itemInfo.seconds = seconds;
      }
      checkLast(pageData);
      setPageInfo(JSON.parse(JSON.stringify(pageData)));
      setQueryLoading(false);
    } else {
      setQueryLoading(false);
    }
  };

  //档期加载更多
  const loadActivity = async index => {
    const last = pageInfo?.components?.[index];
    await queryActivity(last, pageInfo, last?.dataInfo?.result?.length ?? 0);
  };

  //获取秒杀
  const querySecKill = async (item: any, pageData: any) => {
    const params = {
      componentCode: item.componentCode,
      componentDetailCode: item.seckillConfigDetails[0].componentDetailCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      pageNum: 1,
      pageSize: 6,
      pageId: "H5_Shop",
      preview: false,
    };
    const params1 = {
      ...params,
      componentDetailCode: item.seckillConfigDetails[0].componentDetailCode,
    };
    const params2 = {
      ...params,
      componentDetailCode: item.seckillConfigDetails[1].componentDetailCode,
    };
    const res1 = await searchSecKill(params1);
    const res2 = await searchSecKill(params2);
    // 自定义组件参数
    console.log("res12", res1, res2);
    item.list = res1.data.result;
    item.soonList = res2.data.result;
    item.isC = identity();
    item.text = "赚";
    item.dataInfo = res1.data.result;
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
  };

  //获取优惠券
  const queryCoupon = async (item: any, pageData: any) => {
    const params = {
      componentCode: item.componentCode,
      componentDetailCode: item.couponConfigDetails[0].componentDetailCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      preview: false,
    };
    const res = await searchCoupon(params);
    item.dataInfo = res?.data;
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
  };

  //获取素材
  const queryMaterial = async (item: any, pageData: any) => {
    const params = {
      componentCode: item.componentCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      pageNum: 1,
      pageSize: 6,
      pageId: "H5_MaterialList",
      preview: false,
    };
    const res = await searchMaterial(params);
    item.dataInfo = res?.data;
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
  };

  //获取商品
  const queryProduct = async (item: any, pageData: any, from = 0) => {
    if (queryLoading) {
      return;
    }
    setQueryLoading(true);
    const params = {
      componentCode: item.componentCode,
      componentDetailCode: item.productRuleConfigDetails[0].componentDetailCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      from: from,
      pageSize:
        item.layoutType === "10" || item.layoutType === "20" || item.animationType === "2"
          ? 50
          : from > 0
            ? item.morePageSize
            : item.pageSize,
      lastActivitySpuCode:
        item?.dataInfo?.result?.length > 0
          ? item?.dataInfo?.extra?.lastActivitySpuCode
            ? item?.dataInfo?.extra?.lastActivitySpuCode
            : item?.dataInfo?.result[(item?.dataInfo?.result?.length ?? 1) - 1]?.activitySpuId
          : null,
      pageId: "H5_Shop",
      preview: false,
    };
    const res: any = await searchProduct(params);
    if (from > 0) {
      res.data.result = res?.data?.result
        ? (item?.dataInfo?.result || []).concat(res.data.result)
        : item?.dataInfo?.result || [];
      item.dataInfo = res?.data;
    } else {
      item.dataInfo = res?.data;
    }
    checkLast(pageData);
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
    setQueryLoading(false);
  };

  //商品加载更多
  const loadProduct = async index => {
    const last = pageInfo?.components?.[index];
    await queryProduct(last, pageInfo, last?.dataInfo?.result?.length ?? 0);
  };

  //转发商品
  const forwardProduct = async (item: any) => {
    if (!platform.isApp && !platform.isMini) {
      setSharePosterData(
        sharePosterParams4H5Product({
          pageCode: pageInfo.pageCode,
          activitySpuId: item.activitySpuId,
        }),
      );
      setCustomEditData({
        activitySpuId: item.activitySpuId,
        activityId: item.activityId,
        selectSkuId: item.selectSkuId,
      });
      setShowPoster(true);
    } else {
      shareProductPosterAction({
        product: item,
        pageCode: pageInfo.pageCode,
        pageName: pageInfo.name,
      });
    }
  };

  // 获取新人券
  const queryCouponWindowForVenue = async () => {
    const params = {
      shopBizCode: shopId,
      pageCode: "merchantShop",
      shopCode: merchantShopCode,
    };
    const res: any = await getCouponWindowForVenue(params);
    if (res?.data) {
      console.log("queryCouponWindowForVenue", res.data);
      setCouponInfo(res.data);
    }
  };

  //获取榜单
  const queryTopList = async (item: any, pageData: any) => {
    if (queryLoading) {
      return;
    }
    setQueryLoading(true);
    const params = {
      componentCode: item.componentCode,
      componentDetailCode: item.topListConfigDetails[0].componentDetailCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      pageSize: 20,
      pageNum: 1,
      pageId: "H5_Shop",
      preview: false,
    };
    const res = await searchTopList(params);
    item.dataInfo = res?.data;

    checkLast(pageData);
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
    setQueryLoading(false);
  };
  //获取导航
  const queryNavigation = async (item: any, pageData: any, from = 0) => {
    //普通导航
    if (
      item.navigationConfigDetails.length > 0 &&
      (item.styleType === "DEFAULT" || item.styleType === "0")
    ) {
      for (const nav of item.navigationConfigDetails) {
        if (nav.type === "ACTIVITY") {
          const params = {
            componentCode: item.componentCode,
            componentDetailCode: nav.componentDetailCode,
            customPageCode: pageData.pageCode,
            customPageVersion: pageData.version,
            from: from,
            pageSize: from > 0 ? nav.morePageSize : nav.pageSize,
            lastActivityId:
              item?.dataInfo?.result?.length > 0
                ? item?.dataInfo?.extra?.lastActivityId
                  ? item?.dataInfo?.extra?.lastActivityId
                  : item?.dataInfo?.result[(item?.dataInfo?.result?.length ?? 1) - 1]?.activityId
                : null,
            pageId: "H5_Shop",
            preview: false,
          };
          const res = await searchNavigationActivity(params);
          nav.dataInfo = res?.data;
        } else if (nav.type === "PRODUCT") {
          const params = {
            componentCode: item.componentCode,
            componentDetailCode: nav.componentDetailCode,
            customPageCode: pageData.pageCode,
            customPageVersion: pageData.version,
            from: from,
            pageSize: from > 0 ? nav.morePageSize : nav.pageSize,
            lastActivitySpuCode:
              item?.dataInfo?.result?.length > 0
                ? item?.dataInfo?.extra?.lastActivitySpuCode
                  ? item?.dataInfo?.extra?.lastActivitySpuCode
                  : item?.dataInfo?.result[item.dataInfo?.result.length - 1]?.activitySpuId
                : null,
            pageId: "H5_Shop",
            preview: false,
          };
          const res = await searchNavigationProduct(params);
          nav.dataInfo = res?.data;
        }
      }
    }
    //电梯导航
    if (
      item.navigationConfigDetails.length > 0 &&
      (item.styleType === "ELEVATOR" || item.styleType === "1" || item.styleType === 1)
    ) {
      if ((item.components?.length ?? 0) > 0) {
        for (const com of item.components) {
          if (com.type === "PRODUCT") {
            const params = {
              componentCode: com.componentCode,
              componentDetailCode: com.productRuleConfigDetails[0].componentDetailCode,
              customPageCode: pageData.pageCode,
              customPageVersion: pageData.version,
              from: from,
              pageSize:
                com.layoutType === "10" || com.layoutType === "20" || com.animationType === "2"
                  ? 50
                  : from > 0
                    ? com.morePageSize
                    : com.pageSize,
              lastActivitySpuCode:
                com?.dataInfo?.result?.length > 0
                  ? com?.dataInfo?.extra?.lastActivitySpuCode
                    ? com?.dataInfo?.extra?.lastActivitySpuCode
                    : com?.dataInfo?.result[(com?.dataInfo?.result?.length ?? 1) - 1]?.activitySpuId
                  : null,
              pageId: "H5_Shop",
              preview: false,
            };
            const res: any = await searchProduct(params);
            com.dataInfo = res?.data;
          }
          if (com.type === "ACTIVITY") {
            const params = {
              componentCode: com.componentCode,
              componentDetailCodes: com.activityRuleConfigDetails.map(
                detail => detail.componentDetailCode,
              ),
              customPageCode: pageData.pageCode,
              customPageVersion: pageData.version,
              from: from,
              pageSize: from > 0 ? com.morePageSize : com.pageSize,
              lastActivityId:
                com?.dataInfo?.result?.length > 0
                  ? com?.dataInfo?.extra?.lastActivityId
                    ? com?.dataInfo?.extra?.lastActivityId
                    : com?.dataInfo?.result[com.dataInfo?.result.length - 1]?.activityId
                  : null,
              pageId: "H5_Shop",
              preview: false,
            };
            const res: any = await searchActivity(params);
            com.dataInfo = res?.data;
          }
        }
      }
    }
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
  };
  const queryProfitadditional = async (item, pageData) => {
    const params = {
      componentCode: item.componentCode,
      componentDetailCode: item.productRuleConfigDetails[0].componentDetailCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      pageSize: 20,
      pageNum: 1,
      pageId: "H5_Shop",
      preview: false,
    };
    const res = await getProfitadditional(params);
    item.list = res?.data?.result;
    item.isC = identity();
    item.text = "赚";
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
  };
  const goSelectiveForward = () => {
    const brandId = getUrlParam()?.["brandId"];
    const forwardBrand = getUrlParam()?.["forwardBrand"];
    const bizInfo = conference3BizInfo(
      merchantShopCode,
      brandId || brandIds,
      forwardBrand === "true" ? true : false,
    );
    if (track.track) {
      track.track("resource_click ", {
        page_name: "店铺首页",
        previous_page_name: searchParams.get("previous_page_name"),
        resource_type: "商家店铺",
        resource_name: "选择性转发",
        merchant_shop_code: merchantShopCode,
      });
    }
    navigate2H5(RouteName.SelectiveForward, {
      query: `shopCodes=${merchantShopCode}${brandIds ? "&brandIds=" + brandIds : ""}&bizInfo=${JSON.stringify(bizInfo)}&timestamp=${Date.now()}`,
    });
  };
  const forwardConferenceThr = throttle(
    async (info?: any, auto = false, forwardNav = false) => {
      /* eslint-disable */
      if (track.track) {
        track.track("btn_click", {
          page_name: "店铺首页",
          previous_page_name: searchParams.get("previous_page_name"),
          domain: "B2R旗舰店",
          merchant_shop_code: merchantShopCode,
          page_code: pageCodeRef.current,
          btn_name: forwardNav ? selectedNav?.itemName : shareBtnText,
        });
      }
      const pi = info ?? pageInfo;
      if (auto && !shouldAutoShareConferencePoster(pi)) {
        // 不需要自动弹出海报
        return;
      }
      if (!platform.isApp && !platform.isMini) {
        const brandId = getUrlParam()?.["brandId"];
        const forwardBrand = getUrlParam()?.["forwardBrand"];
        let bizInfo = null as any;
        if (!forwardNav) {
          bizInfo = conference3BizInfo(
            merchantShopCode,
            brandId || brandIds,
            forwardBrand === "true" ? true : false,
          );
        } else {
          bizInfo = conference3BizInfo(
            merchantShopCode,
            brandId || brandIds,
            forwardBrand === "true" ? true : false,
            false,
            selectedNav?.itemCode || "",
            selectedNav?.itemType || "",
            selectedNav?.itemName || "",
          );
        }
        setSharePosterData({
          bizInfo,
          envContext: await conference3EnvContext(merchantShopCode),
          contentContext: conference3ContentContext(merchantShopCode),
          carrierContext: conference3CarrierContext([CarrierElementType.h5Poster]),
        });
        setShowPoster(true);
      } else {
        shareConferencePosterAction({ merchantShopCode: merchantShopCode });
      }
      if (auto) {
        autoShareConferencePosterIncrement(pi);
      }
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );
  // 转发会场
  const forwardConference = (info?: any, auto = false, forwardNav = false) => {
    forwardConferenceThr(info, auto, forwardNav);
  };
  // 组件接口获取内容改异步调用
  const queryComponent = async (components: any, pageData: any) => {
    try {
      const promises = components?.map(async item => {
        if (item.type === "ACTIVITY") {
          return queryActivity(item, pageData, 0);
        }
        if (item.type === "SECKILL") {
          return querySecKill(item, pageData);
        }
        if (item.type === "COUPON") {
          return queryCoupon(item, pageData);
        }
        if (item.type === "MATERIAL") {
          return queryMaterial(item, pageData);
        }
        if (item.type === "PRODUCT") {
          return queryProduct(item, pageData, 0);
        }
        if (item.type === "NAVIGATION") {
          return queryNavigation(item, pageData, 0);
        }
        if (item.type === "TOPLIST") {
          return queryTopList(item, pageData);
        }
        if (item.type === "PROFITADDITIONAL") {
          //自定义组件参数
          return queryProfitadditional(item, pageData);
        }
      });
      await Promise.all(promises);
      return components;
    } catch (error) {
      // console.error("Error in queryComponent:", error);
    }
  };
  //检查最后一个组件是否是动效组件
  const checkAnimationProduct = async (components, pageData) => {
    if (components === "") {
      return [];
    }
    components = components && JSON.parse(components);
    if (components.length > 0) {
      const last = components[components.length - 1];
      if (last.type === "PRODUCT" && last.animationType === "2") {
        const params = {
          componentCode: last.componentCode,
          componentDetailCode: last.productRuleConfigDetails[0].componentDetailCode,
          customPageCode: pageData.pageCode,
          customPageVersion: pageData.version,
          from: 0,
          pageSize: 50,
          lastActivitySpuCode: null,
          pageId: "H5_Shop",
          preview: false,
        };
        const res: any = await searchProduct(params);
        if ((res?.data?.result?.length ?? 0) <= 4) {
          return components.slice(0, components.length - 1);
        } else {
          return components;
        }
      } else {
        return components;
      }
    } else {
      return components;
    }
  };
  //获取页面信息，并逐个获取组件信息
  const queryPage = async (shop?: any, info?: any) => {
    //根据路径上是否存在isPreview,来走预览
    let res: any = null;
    if (info) {
      res = info;
    } else {
      res = await searchPageInfo({
        customPageCode: customPageCode ? customPageCode : shop?.customPageCode,
      });
    }
    if (!(res?.success)) {
      Toast.show({
        content: "正为您努力搬运信息中，请您稍后再试！",
      });
      setLoading(false);
      return;
    }
    // document.title = res.data.name;
    //如果最后一个组件是商品组件，且是动效的，需要判断他是否展示，不展示就从组件里去除
    if (res?.data) {
      res.data.components = await checkAnimationProduct(res?.data?.components, res?.data);
    }
    //对组件进行修改,将电梯导航的子组件塞入电梯导航
    if (res?.data?.components?.length > 0) {
      const pageCom: any = [];
      for (const nav of res.data.components) {
        if (
          nav.type === "NAVIGATION" &&
          (nav.styleType === "1" || nav.styleType === 1) &&
          nav.componentCodes?.length > 0
        ) {
          nav.components = [];
          for (const com of res.data.components) {
            for (const navCom of nav.componentCodes) {
              if (navCom.componentCode === com.componentCode) {
                nav.components.push(com);
                com.deleteFlag = true; //删除标记
              }
            }
          }
        }
      }
      for (const com of res.data.components) {
        if (!com.deleteFlag) {
          pageCom.push(com);
        }
      }
      res.data.components = pageCom;
    }
    setPageInfo(res?.data);
    //对组件进行处理
    if ((res?.data?.components?.length ?? 0) > 3) {
      await queryComponent(res?.data?.components?.slice(0, 3), res?.data);
      queryComponent(res?.data?.components?.slice(3, res?.data?.components?.length), res?.data);
    } else {
      await queryComponent(res?.data?.components, res?.data);
    }
    //判断最后一个组件是否是档期或者商品，并判断是否还需要加载更多
    await checkLast(res?.data);
    console.log("pageinfo", res?.data);
    setPageInfo(res?.data);
    // setTitleNShareCardInfo(res.data, true);

    pageCodeRef.current = res?.data?.pageCode;
    sessionStorage.setItem("conference3_page_title", res?.data?.name);
    setLoading(false);
    forwardConference(res?.data, true);
    //页面埋点
    /* eslint-disable */
    if (track.track) {
      track.track("$pageview", {
        page_name: "店铺首页",
        previous_page_name: searchParams.get("previous_page_name"),
        domain: "B2R旗舰店",
        merchant_shop_code: merchantShopCode,
        page_code: pageCodeRef.current,
      });
    }
  };
  //获取店铺信息
  const queryShop = async () => {
    if (platform.isApp) {
      const res: any = await AKJS.ready().then(() => {
        return AKJS.info.account();
      });
      appStore.token = res.data.token;
      appStore.channel = "akcApp";
    } else if (platform.isMini) {
      appStore.token = null;
      appStore.channel = "xdApplets";
      if (searchParams.get("plt") === "saas") {
        appStore.channel = "SaaSApplets";
      }
      appStore.token = searchParams.get("token");
      console.log("页面信息33333------------", location.href);
    } else {
      if (shopId) {
        const res = await searchShopInfo({}, shopId, distributorId);
        if (res.data.code === 999999 || res.data.code === 999998) {
          const ua = window.navigator.userAgent.toLowerCase();
          const result = ua.match(/MicroMessenger/i);
          if (result) {
            const current = `${window.location.pathname}${window.location.search}${window.location.hash}`;
            let url = `${getBaseurl()}/api/member/auth?backUrl=${window.encodeURIComponent(
              `/#/login?shopId=${shopId}${distributorId ? `&distributorId=${distributorId}` : ""
              }&rdt=1&bU=${window.encodeURIComponent(current)}`,
            )}`;
            if (shopId) {
              window["willRedirect"] = true;
              window.location.href = url;
            }
          }
        }
        localStorage.setItem("userinfo", JSON.stringify(res.data));
      }
    }
    const res = await getShop({
      merchantShopCode,
      needCustomPageCode: true,
      needScore: true,
    });
    // res.data.shopStatusCode = "PAUSED";
    setShopInfo(res?.data);

    const { customPageCode, activityCnt } = res?.data ?? {};
    // 新的展示 tab 逻辑，根据接口返回字段判断 0:首页 1:全部 2:特卖
    // 0:首页 1:全部 2:特卖
    // 初始化tab：有特卖展示特卖，没特卖展示 展示首页 ，首页也没有展示全部
    const brandId = searchParams.get("brandId");
    const forwardBrand = searchParams.get("forwardBrand");
    if ((isEmpty(forwardBrand) || forwardBrand === "false") && isEmpty(brandId)) {
      if (activityCnt > 0) {
        if (searchParams.get("productType") === "1" && customPageCode) {
          setActiveIndex(0);
        } else if (searchParams.get("productType") === "2") {
          setActiveIndex(1);
        } else {
          setActiveIndex(2);
        }
      } else {
        if (customPageCode) {
          setActiveIndex(0);
        } else {
          setActiveIndex(1);
        }
      }
    } else {
      setActiveIndex(1);
    }
    const map = {
      showHomePageTab: 0,
      showAllTab: 1,
      showActivityTab: 2,
    };
    const list: number[] = [];

    for (const key in map) {
      if (res.data[key]) {
        list.push(map[key]);
      }
    }
    setUnShowTabItem(list);

    document.title = getUserRole() !== "R" ? res?.data?.shopTitle : res?.data?.shopName;
    const pageInfo = (await queryPageInfo(merchantShopCode, customPageCode)) as any;

    if (pageInfo?.data?.pageCode) {
      localStorage.removeItem("decorate_url");
      localStorage.setItem("decorate_url", location.href);
      queryPage(res?.data, pageInfo);
      queryCouponWindowForVenue();
    } else {
      setLoading(false);
    }
  };
  const changeIndex = (index: number, flag?: boolean) => {
    setActiveIndex(index);
    tabChangeCount.current += 1;
    if (!flag) {
      changeBrand(null, index);
    }
    setCurrentPromotionType("");
    setAdvancePromotionType("");
    // 锚点
    const dom = document.documentElement;
    const navHeight = navRef?.current?.clientHeight;
    const headerHeight = shopInfoRef?.current?.clientHeight;
    const rem: any = document.documentElement.style.fontSize.split("px")[0];
    if (dom && isShowInNav) {
      if (materialData?.result?.length > 0 && index > 0 && activeIndex === 0) {
        setTimeout(() => {
          dom.scrollTo(0, (headerHeight || 0) + (navHeight || 0) + 4 - 2.7 * ratio * 50);
        }, 16);
      } else if (materialData?.result?.length > 0 && index === 0) {
        showRef.current = true;
        setTimeout(() => {
          dom.scrollTo(0, (headerHeight || 0) + (navHeight || 0) + 4 + 2.7 * ratio * 50);
        });
      } else {
        setTimeout(() => {
          dom.scrollTo(0, rem * 2.4);
        }, 16);
        setTimeout(() => {
          dom.scrollTo(0, rem * 2.4);
        }, 800);
      }
    }

    // 0:首页 1:全部 2:特卖
    switch (index) {
      case 0:
        if (track.track) {
          track.track("$pageview", {
            page_name: "店铺首页",
            previous_page_name,
            domain: "B2R旗舰店",
            merchant_shop_code: merchantShopCode,
            page_code: pageCodeRef.current,
          });
        }
        break;
      case 1:
        if (track.track) {
          track.track("$pageview", {
            page_name: "店铺全部",
            previous_page_name,
            domain: "B2R旗舰店",
            merchant_shop_code: merchantShopCode,
            page_code: pageCodeRef.current,
          });
          track.track("tab_expose", {
            page_name: "店铺全部",
            tab_name: "限时特卖",
            rank: 1,
            previous_page_name,
            desc: merchantShopCode,
          });
          track.track("tab_expose", {
            page_name: "店铺全部",
            tab_name: "更多商品",
            rank: 2,
            previous_page_name,
            desc: merchantShopCode,
          });
        }
        break;
      case 2:
        if (track.track) {
          track.track("$pageview", {
            page_name: "店铺特卖",
            previous_page_name,
            domain: "B2R旗舰店",
            merchant_shop_code: merchantShopCode,
            page_code: pageCodeRef.current,
          });
        }
        break;
      default:
        break;
    }
  };
  const setCurrentTabInUrl = (index: number) => {
    const currentUrl = window.location.href;
    // 解析查询字符串
    const url = new URL(currentUrl);
    const searchParams = url.searchParams;
    // 更新参数的值
    searchParams.set("currentTab", String(index));
    //如果从品牌切换到别的tab,修改分享

    if (index !== 1 && index !== -1) searchParams.set("brandId", "");
    if (index !== 1 && index !== -1) {
      searchParams.set("forwardBrand", "false");
    } else {
      searchParams.set("forwardBrand", "true");
    }
    const newUrl = url.toString();
    history.replaceState(null, document.title, newUrl);
  };
  const Tabs = () => {
    return (
      <div className={styles["tabs"]}>
        {tabList.map((item, index) => {
          if (!item.show) return null;
          return (
            <div key={index} className={styles.item} onClick={() => changeIndex(item.id)}>
              <div
                className={styles.font_nav + " " + (activeIndex === item.id ? styles.active : "")}>
                {item.name}
              </div>
              {activeIndex === item.id && <div className={styles.line}></div>}
              {item.id === 2 && (
                <img
                  className={styles.sign}
                  src="https://akim-oss.aikucun.com/7ae1c559a91b3b559695aa1d1ebeb819e8f1cf8c_1709018812432_99.png"
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };
  // 首页 点击泪目 锚点到顶部
  const goScrollTop = () => {
    const dom = document.documentElement;
    const shopInfoRefDomHeight = shopInfoRef.current?.clientHeight;
    const tabRefDomHeight = tabRef.current?.clientHeight;

    const baseHeight = (shopInfoRefDomHeight || 0) + (tabRefDomHeight || 0) + 10;
    if (dom && dom.scrollTop > baseHeight) {
      dom.scrollTo(0, baseHeight);
    }
  };
  // 监听tab 在导航栏内显示
  const tabListener = () => {
    const navHeight = navRef.current?.clientHeight;
    if (!tabRef.current) return;
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].intersectionRatio === 1 && !showRef.current) {
          setIsShowInNav(false);
        }
        if (entries[0].intersectionRatio === 0) {
          setIsShowInNav(true);
        }
        if (showRef.current) {
          showRef.current = false;
        }
      },
      {
        threshold: [0, 1],
        rootMargin: `-${navHeight}px 0px 0px 0px`,
      },
    );
    observer.observe(tabRef.current);
  };

  //切换品牌，修改分享信息,修改路径上的brandId
  const changeBrand = (brandId?: any, index?: any) => {
    console.log("changeBrand----", brandId, index);
    // 解析查询字符串
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = url.searchParams;
    searchParams.set("brandId", brandId || "");
    const newUrl = url.toString();
    history.replaceState(null, document.title, newUrl);

    if (brandId) {
      setShareBtnText("转发品牌");
    } else {
      setShareBtnText("转发全部");
    }
  };
  const changeNav = (item, isChangeBrand) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = url.searchParams;
    searchParams.set("shareTabId", item.itemCode || "");
    const newUrl = url.toString();
    history.replaceState(null, document.title, newUrl);
  };
  const onClosePoster = () => {
    setSharePosterData({} as any);
    setShowPoster(false);
    document.body.style.overflow = "unset";
  };
  // 分享按钮文本
  const changeShareBtnText = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = url.searchParams;
    const brandId = searchParams.get("brandId");
    switch (activeIndex) {
      case 0:
        if (getUserRole() === "R") {
          setShareBtnText("转发店铺");
        } else if (getUserRole() === "E") {
          setShareBtnText("转发会场");
        }
        break;
      case 1:
        if (brandId || brandIds) {
          setShareBtnText("转发品牌");
        } else {
          setShareBtnText("转发全部");
        }
        break;
      case 2:
        setShareBtnText("转发会场");
        break;
      default:
        break;
    }
  };
  useActivate(() => {
    const brandId = getUrlParam()?.["brandId"];
    shareDecorate({
      brandId: brandId || brandIds || "",
      pageCode: pageCodeRef.current,
      forwardBrand: activeIndex === 1 ? true : false,
      forwardHome: activeIndex === 0 ? true : false,
      shareTabId: selectedNav?.itemCode ? selectedNav?.itemCode : undefined,
      shareTabType: selectedNav?.itemCode ? selectedNav?.itemType : undefined,
      shareTabName: selectedNav?.itemCode ? selectedNav?.itemName : undefined,
    });
    document.title = getUserRole() !== "R" ? shopInfo.shopTitle : shopInfo.shopName;
  });

  const queryMaterialV2 = async () => {
    let res = await searchMaterialV2({
      pageId: "H5_Shop",
      shopCode: merchantShopCode,
    });
    setMaterialData(res?.data);
  };

  useLayoutEffect(() => {
    // document.title = "\u200E";
    if (!pageInfo || pageInfo.pageCode !== customPageCode) {
      queryShop();
      queryMaterialV2();
    }
  }, [customPageCode]);

  useEffect(() => {
    if (!loading) {
      tabListener();
    }
  }, [loading]);

  useEffect(() => {
    setCurrentTabInUrl(activeIndex);
    setBrandId(getUrlParam()?.["brandId"] || "");
    changeShareBtnText();
  }, [activeIndex]);
  useEffect(() => {
    const brandId = getUrlParam()?.["brandId"];
    if (activeIndex !== -1 && brandIds !== null && selectedNav !== null) {
      shareDecorate({
        brandId: brandId || brandIds || "",
        pageCode: pageCodeRef.current,
        forwardBrand: activeIndex === 1 ? true : false,
        forwardHome: activeIndex === 0 ? true : false,
        shareTabId: selectedNav?.itemCode ? selectedNav?.itemCode : undefined,
        shareTabType: selectedNav?.itemCode ? selectedNav?.itemType : undefined,
        shareTabName: selectedNav?.itemCode ? selectedNav?.itemName : undefined,
      });
    }
  }, [brandIds, JSON.stringify(selectedNav), activeIndex]);

  return (
    <div className={styles["scroll-container"]} ref={rootRef} id="page">
      {/* shopStatusCode:店铺经营状态 正常=NORMAL 关闭=CLOSED 暂停=PAUSED */}
      {!loading &&
        (shopInfo?.shopStatusCode === "CLOSED" || shopInfo?.shopStatusCode === "PAUSED") && (
          <div className={`${styles["main"]} ${styles["background-empty"]}`}>
            <div className={styles["top-bar"]} ref={navRef} id="top-bar">
              <TopBar isHideRight={true} />
            </div>
            <ShopEmpty shopInfo={shopInfo} />
          </div>
        )}
      {!loading &&
        shopInfo?.shopStatusCode !== "CLOSED" &&
        shopInfo?.shopStatusCode !== "PAUSED" && (
          <div className={`${identity() ? styles["pd1"] : styles["pd2"]} ${styles["main"]}`}>
            <div className={styles["top-bar"]} ref={navRef} id="top-bar">
              <TopBar center={<Tabs />} isShowCenter={isShowInNav} />
            </div>
            <div className={styles["shopInfo-container"]} ref={shopInfoRef}>
              <ShopInfo
                shopInfo={shopInfo}
                subscribeShop={subscribeShop}
                materialData={materialData}
                pageCode={customPageCode}
                activeIndex={activeIndex}
              />
            </div>
            <div className={styles["tabs-container"]} ref={tabRef}>
              <Tabs />
            </div>
            {/* 激励中心 */}
            <div className={styles.decorate_equities_card}>
              <DecorateEquitiesCard />
            </div>
            <div className={styles["main-container"]}>
              {/* 0:首页 1:全部 2:特卖 */}
              {activeIndex === 0 && (
                <div className={styles["switch-animation"]}>
                  <Home
                    pageInfo={pageInfo}
                    hasMore={hasMore}
                    queryActivity={queryActivity}
                    queryProduct={queryProduct}
                    loadActivity={loadActivity}
                    loadProduct={loadProduct}
                    forwardProduct={forwardProduct}
                    changeIndex={changeIndex}
                  />
                </div>
              )}
              {activeIndex === 1 && (
                <div className={styles["switch-animation"]}>
                  <AllPanel
                    shopInfo={shopInfo}
                    goScrollTop={goScrollTop}
                    changeBrand={changeBrand}
                    count={tabChangeCount.current}
                    changeNav={changeNav}
                    currentPromotionType={currentPromotionType}
                    advancePromotionType={advancePromotionType}
                  />
                </div>
              )}
              {activeIndex === 2 && (
                <div className={styles["switch-animation"]}>
                  <ActivityV2Com />
                </div>
              )}
            </div>
          </div>
        )}
      {loading && (
        <div className={styles.loading}>
          <Image src={loadingPng} width={pxtorem(30)}></Image>
        </div>
      )}
      {!showPoster &&
        !loading &&
        shopInfo?.shopStatusCode !== "CLOSED" &&
        shopInfo?.shopStatusCode !== "PAUSED" && !isExternal && (
          <>
            {getUserRole() === "E" && (
              <div className={styles.share_btn}>
                {/* 全部TAB + 营销/类目 */}
                {activeIndex === 1 && selectedNav?.itemCode && (
                  <>
                    <div
                      className={styles.share_selective_small}
                      onClick={() => goSelectiveForward()}>
                      <img
                        className={styles.img}
                        src="https://akim-oss.aikucun.com/mshop/301668a14e3ce9ab72c4c7fa97d7d0bca3b0a77c_1716458240227_9.png"></img>
                      选择转发
                    </div>
                    <div
                      className={styles.share_category}
                      onClick={() => forwardConference(null, false, true)}>
                      转发{selectedNav?.itemName}
                    </div>
                    <div className={styles.share_category} onClick={() => forwardConference()}>
                      {shareBtnText}
                    </div>
                  </>
                )}
                {(selectedNav?.itemType === "ALL" || !selectedNav) && (
                  <>
                    <div className={styles.share_selective} onClick={() => goSelectiveForward()}>
                      <img
                        className={styles.img}
                        src="https://akim-oss.aikucun.com/mshop/301668a14e3ce9ab72c4c7fa97d7d0bca3b0a77c_1716458240227_9.png"></img>
                      选择性转发
                    </div>
                    <div onClick={() => forwardConference()} className={styles.share_img}>
                      <img
                        className={styles.img}
                        src="https://akim-oss.aikucun.com/5974e4f97ff445bfddee2535bb743230f141a582_1704435634913_22.png"></img>
                      {shareBtnText}
                    </div>
                  </>
                )}
              </div>
            )}
            {getUserRole() === "A" && (
              <div className={styles.share_btn}>
                <div onClick={() => forwardConference()} className={styles.share_img}>
                  <img
                    className={styles.img}
                    src="https://akim-oss.aikucun.com/5974e4f97ff445bfddee2535bb743230f141a582_1704435634913_22.png"></img>
                  {shareBtnText}
                </div>
              </div>
            )}
          </>
        )}
      {sharePosterData && (
        <PosterSwipe
          show={showPoster}
          posterData={sharePosterData}
          shareType="CONFERENCE3"
          btnConfig={["copy"]}
          close={onClosePoster}
        />
      )}
      {(pageInfo === null || pageInfo?.components === "" || pageInfo?.components?.length <= 0) &&
        activeIndex !== 2 && (
          <div className={styles.nullStatus}>
            <Image className={styles.noSecKill} src={noSecKill}></Image>
            <div className={styles.text}>暂无活动</div>
          </div>
        )}
      {pageInfo &&
        couponInfo &&
        couponInfo.totalCouponAmount &&
        couponInfo.thirdCustomerFlag === 1 && (
          <CouponForVenue couponInfo={couponInfo} pageName="店铺详情页" />
        )}
      {/* 用户中奖通知 */}
      <DailyRewardNotice />
      {/* 日日奖红包到期提醒组件 */}
      <RedPacketNotice />
    </div>
  );
};

export default Decorate;
