import React from "react";
import styles from "./index.module.less";

interface Props {
  text?: any;
  goToRankAll: () => void;
}

const SeeAllView: React.FC<Props> = (props: Props) => {
  const { text } = props;

  return (
    <div className={styles.toSeeAllView}>
      <div className={styles.toSeeAll} onClick={props?.goToRankAll}>查看全部榜单
      </div>
    </div>
  );
};

export default SeeAllView;
