import { isInGray } from "@/api/index";
import { fetchCopyLink } from "@/api/scode/copy_link";
import { fetchCopyWriting } from "@/api/scode/copy_writing";
import { PosterInfo, generateSharePoster } from "@/api/scode/poster";
import {
  CarrierElementType,
  ContentType,
  bizInfoParams,
  carrierContextParams,
  envContextParams,
} from "@/api/scode/scode_params";
import PoseterSwipeOld from "@/components/poster-swiper-old";
import { useCommonContext } from "@/utils/context/common";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import { ImageViewer, Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React, { FC, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AbnormalRender from "./components/AbnormalRender";
import ActionRender from "./components/ActionRender";
import ShareOTCBeforeConfirmPopup from "./components/ShareOTCBeforeConfirmPopup";
import SwiperRender from "./components/SwiperRender";
import styles from "./index.module.less";
import { getForwardDefaultConfig, shareTypeForwardConfig } from "./shareTypeForwardConfig";
import type { CustomEditData, PosterSwipeProps } from "./types";
import { calcImgWidthOptions } from "./utils";
export { CustomEditData };

const PosterSwipeInner: FC<PosterSwipeProps> = ({
  show,
  posterData: pd,
  scodeSimpleParams,
  customEditData,
  shareType,
  lockScroll = true,
  topSlot,
  actionSlot,
  btnConfig = [],
  change,
  close,
  edit,
  isOTC,
}) => {
  let imageOptions = calcImgWidthOptions(shareType);
  if (scodeSimpleParams?.contentType === ContentType.activityAggr) {
    imageOptions = calcImgWidthOptions(ContentType.activityAggr);
  }
  const contentType =
    scodeSimpleParams?.contentType ??
    (scodeSimpleParams?.activityId
      ? ContentType.activity
      : scodeSimpleParams?.activitySpuId
        ? ContentType.spuProduct
        : scodeSimpleParams?.conferencePageCode
          ? ContentType.conference3
          : ContentType.activityAggr);
  const contentId =
    scodeSimpleParams?.activityId ??
    scodeSimpleParams?.activitySpuId ??
    scodeSimpleParams?.conferencePageCode ??
    scodeSimpleParams?.brandId;
  const posterData = pd ?? {
    bizInfo: bizInfoParams({
      ...scodeSimpleParams,
      contentType: contentType,
      contentId: contentId,
    }),
    envContext: envContextParams({
      sourceScene: scodeSimpleParams?.sourceScene,
      sourceSceneId: scodeSimpleParams?.sourceSceneId as string | undefined,
    }),
    carrierContext: carrierContextParams(
      scodeSimpleParams?.carrierElements ?? [CarrierElementType.h5Poster],
      scodeSimpleParams?.posterCode,
      scodeSimpleParams?.posterVersion,
    ),
  };
  const { navigate } = useNavigation();

  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>("");
  const [pictureUrls, setPictureUrls] = useState<PosterInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAbnormal, setIsAbnormal] = useState<boolean>(false);
  const copyMessage = useRef<string>("");
  const copyScode = useRef<string>("");
  const copyWriting = useRef<string>("");
  const { track, currentRole } = useCommonContext();
  const [search] = useSearchParams();
  const shareTabName = posterData?.bizInfo?.contentExtra?.shareTabName;

  // 生成海报
  const genPoster = () => {
    generateSharePoster(posterData).then(res => {
      const { success, data } = res || {};
      if (success) {
        setLoading(false);
        Toast.clear();
        setPictureUrls(data.posterInfos || []);
      } else {
        setLoading(false);
        Toast.clear();
        setIsAbnormal(true);
        Toast.show("生成海报失败");
      }
    });
  };

  // 分享文案信息
  const genShareMessages = () => {
    if (!btnConfig?.includes("copy")) {
      return;
    }
    if (shareType === "H5_SHOP") {
      return;
    }
    fetchCopyLink({
      ...posterData,
      carrierContext: carrierContextParams([CarrierElementType.linkUrl]),
    }).then(res => {
      const { success, data } = res || {};
      if (success && data.copyMessage) {
        copyMessage.current = data.copyMessage;
        copyScode.current = data.scode;
      }
    });
  };

  const genWritingMessages = () => {
    if (["ACTIVITY", "SPU_PRODUCT"].includes(contentType) && currentRole !== "C") {
      const { carrierContext, ...params } = posterData;
      fetchCopyWriting({ ...params }).then(res => {
        const { success, data } = res || {};
        if (success && data?.length) {
          const materialItem = data.filter(item => item.type === "MATERIAL")[0] || {};
          const aiItem = data.filter(item => item.type === "AI")[0] || {};
          const defaultItem = data.filter(item => item.type === "DEFAULT")[0] || {};
          const copyWritings =
            materialItem?.copyWritings || aiItem?.copyWritings || defaultItem?.copyWritings || [];
          copyWriting.current = copyWritings[0] ?? "";
        }
      });
    }
  };

  const initSwiper = () => {
    setLoading(true);
    Toast.show({
      icon: "loading",
      content: "加载中…",
      duration: 0,
    });
    setRequestId(new Date().getTime().toString());
    genPoster();
    genShareMessages();
    genWritingMessages();
  };

  useEffect(() => {
    if (show) {

      setIsAbnormal(false);
      copyMessage.current = "";
      copyScode.current = "";
      copyWriting.current = "";
      Toast.clear();
      initSwiper();
      setIsConfirm(false);
    }
  }, [show]);

  // 保存原始overflow状态的ref
  const originalOverflowRef = useRef<string | null>(null);

  useEffect(() => {
    // 处理 overflow 恢复逻辑
    const restoreOverflow = () => {
      if (originalOverflowRef.current === null) { return; }

      // 特殊处理：popup组件内接入海报的情况
      if (lockScroll && originalOverflowRef.current === "hidden") {
        document.body.style.overflow = "unset";
      } else {
        document.body.style.overflow = originalOverflowRef.current;
      }
      originalOverflowRef.current = null;
    };

    if (show) {
      // 只在首次显示时保存原始状态
      if (originalOverflowRef.current === null) {
        originalOverflowRef.current = window.getComputedStyle(document.body).overflow;
      }

      // 设置为hidden（如果需要锁定滚动）
      if (lockScroll) {
        document.body.style.overflow = "hidden";
      }
    } else if (originalOverflowRef.current !== null) {
      restoreOverflow();
    }

    // 清理函数
    return restoreOverflow;
  }, [show, lockScroll]);

  // 长按事件
  const isPressing = useRef(false);
  const isPressingTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const handleLongPress = () => {
    if (isPressing.current) {
      //长按分享
      //复制链接分享
      let share_type = shareType;
      let share_method = "二维码";
      let share_channel = "长按保存";
      let img_type = "";
      if (shareTabName) {
        share_type = "STORE_" + shareTabName;
      }
      let page_name = document.title;
      let market_id = search.get("customPageCode");
      let domain = "CONFERENCE3";
      if (shareType === "B2R") {
        page_name =
          customEditData?.sourceScene === "IncentiveActivity" ? "激励活动详情" : "激励活动落地页";
        market_id = search.get("actId");
        domain = "激励活动";
      } else if (shareType === "SALE_CALENDAR") {
        page_name = "今日必卖日历页";
        share_method = "海报";
        share_channel = "保存到相册";
      } else if (shareType === "HOT_LIST_TOTAL" || shareType === "HOT_LIST_CATEGORY") {
        page_name = shareType === "HOT_LIST_TOTAL" ? "榜单中心" : "榜单详情";
        share_method = "海报";
        share_channel = "保存到相册";
        img_type = shareType === "HOT_LIST_TOTAL" ? "TotalHotListPoster" : "CategoryHotListPoster";
      }
      if (track.track) {
        track.track("$share", {
          share_type,
          share_method,
          share_channel,
          share_setting: "H5",
          scode: copyScode.current,
          is_default: false,
          page_name,
          market_id,
          share_t: new Date().valueOf(),
          market_name: document.title,
          domain,
          img_type
        });
      }
      /* eslint-enable */
    }
  };
  // 鼠标按下事件处理函数
  const handleMouseDown = () => {
    // 设置一个定时器，在 1 秒后触发长按操作
    isPressingTimeoutId.current = setTimeout(() => {
      isPressing.current = true;
      handleLongPress();
    }, 1000);
  };

  // 鼠标松开事件处理函数
  const handleMouseUp = () => {
    // 清除之前设置的定时器
    isPressingTimeoutId.current && clearTimeout(isPressingTimeoutId.current);
    isPressing.current = false;
  };

  const previewImg = (e: React.MouseEvent<HTMLImageElement>, index: number) => {
    e.stopPropagation();
    const urls: string[] = [];
    pictureUrls.forEach((item, idx) => {
      urls[idx] = item.imageUrl;
    });
    if (platform.isWxH5) {
      window.wx.previewImage({
        current: urls[index],
        urls,
      });
    } else {
      ImageViewer.Multi.show({
        images: urls,
        defaultIndex: index,
      });
    }
  };

  const onClose = () => {
    if (loading) {
      return;
    }
    if (lockScroll) {
      document.body.style.overflow = "hidden";
    }
    setPictureUrls([]);
    change && change(false);
    close && close();
  };

  const handleClose = () => {
    if (lockScroll) {
      document.body.style.overflow = "hidden";
    }
    setPictureUrls([]);
    change && change(false);
    close && close();
  };

  const onCopy = () => {
    if (isAbnormal) {
      Toast.show("海报加载失败，请重新尝试～");
      return;
    }
    if (!copyMessage.current) {
      Toast.show({
        content: "复制失败，请重新生成海报～",
        position: "bottom",
        maskClassName: styles["poster-unify-toast"],
      });
    } else {
      copy(copyMessage.current);
      Toast.show({
        content: "链接已复制",
        position: "bottom",
        maskClassName: styles["poster-unify-toast"],
      });
    }

    //复制链接分享
    let share_type = shareType;
    if (shareTabName) {
      share_type = "STORE_" + shareTabName;
    }
    let page_name = document.title;
    let market_id = search.get("customPageCode");
    let domain = "CONFERENCE3";
    if (shareType === "B2R") {
      page_name =
        customEditData?.sourceScene === "IncentiveActivity" ? "激励活动详情" : "激励活动落地页";
      market_id = search.get("actId");
      domain = "激励活动";
    } else if (shareType === "SALE_CALENDAR") {
      page_name = "今日必卖日历页";
    } else if (shareType === "HOT_LIST_TOTAL" || shareType === "HOT_LIST_CATEGORY") {
      page_name = shareType === "HOT_LIST_TOTAL" ? "榜单中心" : "榜单详情";
    }
    if (track.track) {
      track.track("$share", {
        share_type,
        share_method: "链接",
        share_channel: "复制链接",
        shaer_setting: "H5",
        scode: copyScode.current,
        is_default: false,
        page_name,
        market_id,
        share_t: new Date().valueOf(),
        market_name: document.title,
        domain,
      });
    }
  };

  const onEdit = () => {
    if (isAbnormal) {
      Toast.show("海报加载失败，请重新尝试～");
      return;
    }
    onClose();
    let queryObj: Record<string, any> = {};
    if (shareType && shareTypeForwardConfig[shareType]) {
      queryObj = shareTypeForwardConfig[shareType](customEditData, scodeSimpleParams, posterData, search);
    } else {
      queryObj = getForwardDefaultConfig(customEditData, posterData);
    }

    let page_name = document.title;
    let market_id = search.get("customPageCode");
    let domain = "";
    if (queryObj && queryObj.pageInfo) {
      page_name = queryObj.pageInfo.page_name || page_name;
      market_id = queryObj.pageInfo.market_id || market_id;
      domain = queryObj.pageInfo.domain || domain;
      delete queryObj.pageInfo;
    }
    //分享事件前置至按钮上
    /* eslint-disable */
    if (track.track) {
      track.track("$share", {
        share_type: shareType,
        share_method: "转发卡片",
        share_channel: "转发卡片",
        scode: copyScode.current,
        is_default: false,
        page_name,
        market_id,
        share_t: new Date().valueOf(),
        market_name: document.title,
        domain,
      });
    }
    /* eslint-enable */

    navigate({
      h5Options: {
        url: "/#/editForward",
        query: queryObj,
      },
    });
    edit && edit();
  };

  const onCopyWriting = () => {
    if (isAbnormal) {
      Toast.show("海报加载失败，请重新尝试～");
      return;
    }
    const ContentNode = (
      <p style={{ textAlign: "center" }}>
        已将转发文案复制到粘贴板
        <br></br>
        好的文案会带来更多的浏览哦～
      </p>
    );
    if (!copyWriting.current) {
      genWritingMessages();
    } else {
      copy(copyWriting.current);
      Toast.show({
        content: ContentNode,
        position: "center",
        maskClassName: styles["poster-unify-toast"],
      });
    }
  };

  const onReload = () => {
    setIsAbnormal(false);
    copyMessage.current = "";
    copyScode.current = "";
    copyWriting.current = "";
    initSwiper();
  };

  return (
    <>
      {show && ["ACTIVITY", "PRODUCT"].includes(shareType) && isOTC && !isConfirm && (
        <ShareOTCBeforeConfirmPopup setIsConfirm={setIsConfirm} handleClose={handleClose} />
      )}
      {show && (!isOTC || (isOTC && isConfirm)) && (
        <div className={styles["poster_swipe_wrapper"]}>
          {!loading && <div className={styles["poster__mask"]} onClick={onClose}></div>}
          {topSlot && topSlot}
          {!loading && (
            <div className={styles["swipe-content"]}>
              {isAbnormal ? (
                <AbnormalRender imageOptions={imageOptions} onReload={onReload} />
              ) : (
                <SwiperRender
                  pictureUrls={pictureUrls}
                  imageOptions={imageOptions}
                  requestId={requestId}
                  onClose={onClose}
                  previewImg={previewImg}
                  handleMouseDown={handleMouseDown}
                  handleMouseUp={handleMouseUp}
                />
              )}
            </div>
          )}
          {!loading && !isAbnormal && !actionSlot && (
            <div className={styles["poster__tip"]}>
              <img
                className={styles["tip-img"]}
                src="https://akim-oss.aikucun.com/1b4148d1babd98d53b08b3a09f5fe8d460e704e6_1669111122655_99.png"
              />
              长按图片，可转发保存哟~
            </div>
          )}
          {actionSlot && actionSlot}
          {!actionSlot && btnConfig.length > 0 && !loading && !isAbnormal && (
            <ActionRender
              btnConfig={btnConfig}
              contentType={contentType}
              currentRole={currentRole}
              onCopy={onCopy}
              onEdit={onEdit}
              onCopyWriting={onCopyWriting}
            />
          )}
        </div>
      )}
    </>
  );
};

const GrayKey = "B-PosterInprovement";
const useGray = (key: string) => {
  const [isGray, setIsGray] = useState(false);
  const updateGrayStatus = async () => {
    const res = await isInGray(key);
    setIsGray(!!res);
    return !!res;
  };
  useEffect(() => {
    updateGrayStatus();
  }, []);

  return {
    isGray,
  };
};
const PosterSwipe = (props: PosterSwipeProps) => {
  const { isGray } = useGray(GrayKey);
  if (isGray) {
    return <PosterSwipeInner {...props} />;
  }
  return <PoseterSwipeOld {...props} />;
};

export default PosterSwipe; 
