import { getConsultShopOwner, launchConsultation } from "@/api/my/index";
import { getNameAuthStatus, getShopAuthStatus } from "@/api/my/shop";
import { getShareConfig, setShareConfig } from "@/api/share";
import Clickable from "@/components/clickable";
import Beianhao from "@/pages/my/components/common-beian";
import ShopOwnerQrcode from "@/pages/personal/components/shop-owner-qrcode";
import { RouteName } from "@/router/extra";
import { getCSBase } from "@/utils/service";
import useNavigation from "@/utils/useNavigate";
import { Dialog, Switch } from "antd-mobile";
import Cookie from "js-cookie";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

const SettingItem = props => {
  const { item } = props;

  const [searchParams] = useSearchParams();
  const { navigate2MyItem, navigate2H5 } = useNavigation();

  const shopId = searchParams.get("shopId") ?? "";
  const distributorId = searchParams.get("distributorId") ?? "";

  const [contactVisible, setContactVisible] = React.useState(false);
  const [shopOwnerInfo, setShopOwnerInfo] = useState<any>({});
  const [shopAuthInfo, setShopAuthInfo] = useState<any>({});
  const [nameAuthInfo, setNameAuthInfo] = useState<any>(null);
  const [shareNicknameSwitch, setShareNicknameSwitch] = useState(false);
  const [showBeianhao, setShowBeianhao] = useState(false);

  const onSwitchChange = async value => {
    const title = value ? "确认打开开关" : "确认关闭开关";
    const content = value
      ? "打开开关后，分享活动/商品的图片时，会显示您的用户昵称，用来明确是来自您的分享。"
      : "关闭开关后，分享商品/活动海报将不显示用户昵称，这会导致较难区分是否来自你的分享，建议谨慎设置。";
    Dialog.confirm({
      title,
      content,
      bodyClassName: "dialog_switch",
      onConfirm: async () => {
        const data = await setShareConfig({ expandStyleNickName: value ? 1 : 0 });
        setShareNicknameSwitch(data?.expandStyleNickName === 1);
      },
    });
  };

  const queryConsultShopOwner = async () => {
    const res = await getConsultShopOwner();
    if (res?.data) {
      setShopOwnerInfo(res.data);
    }
  };

  const queryShareConfig = async () => {
    const data = await getShareConfig();
    setShareNicknameSwitch(!!data?.expandStyleNickName);
  };

  const queryShopAuthStatus = async () => {
    const data: any = await getShopAuthStatus();
    if (typeof data === "number") {
      let shopAuthInfo = {
        authText: "未认证",
        authStatus: data,
      };
      switch (data) {
        case -3:
          shopAuthInfo.authText = "个体工商户注销中";
          break;
        case -2:
          shopAuthInfo.authText = "临时税务登记";
          break;
        case -1:
          shopAuthInfo.authText = "个人";
          break;
        case 1:
        case 2:
          shopAuthInfo.authText = "个体工商户";
          break;
        case 3:
          shopAuthInfo.authText = "企业";
          break;
        case 99:
        default:
          shopAuthInfo.authText = "未认证";
          break;
      }
      setShopAuthInfo(shopAuthInfo);
    }
  };

  const queryNameAuthStatus = async () => {
    const data: any = await getNameAuthStatus();
    data && setNameAuthInfo(data);
  };

  const jumpToCS = async queryObj => {
    const webChannel = 3;
    const query = {
      ...queryObj,
      webChannel,
      shopId,
      distributorId,
    };
    const queryStr = Object.keys(query).reduce((res, i) => `${res}${i}=${query[i]}&`, "?");
    const auditChannel = 2;
    let sourceQueryStr = `shopId=${shopId}&source=3&auditChannel=${auditChannel}&distributorId=${distributorId}`;
    const msUserCode = Cookie.get("msUserCode");
    if (msUserCode) {
      sourceQueryStr += `&userId=${msUserCode}`;
    }
    const base = await getCSBase({
      webChannel,
      locationType: queryObj.locationType,
      merchantCode: queryObj.merchantCode,
    });
    navigate2H5(`${base}${queryStr}sourceOrigin=${window.encodeURIComponent(
      window.location.origin,
    )}&sourceQueryStr=${window.encodeURIComponent(sourceQueryStr)}`);
  };

  const onServiceToCS = async () => {
    const query = { locationType: 6 };
    const queryObj: any = { locationType: 6 };
    const res: any = await launchConsultation(query);
    if (res && res.success && res.data) {
      const { merchantCode } = res.data;
      if (merchantCode) {
        queryObj.merchantCode = merchantCode;
      }
      jumpToCS(queryObj);
    }
  };

  const onItemClick = e => {
    e.stopPropagation();
    if (item.key === "serviceItem") {
      onServiceToCS();
      return;
    }
    if (item.key === "consultShopOwnerItem") {
      setContactVisible(true);
      return;
    }
    if (item.key === "qualificationItem") {
      setShowBeianhao(!showBeianhao);
      setTimeout(() => {
        const footerDom = document.getElementById("footer");
        footerDom?.scrollIntoView({ behavior: "smooth" });
      }, 100);
      return;
    }
    if (item.key === "authNameItem") {
      navigate2MyItem(item.path, `isAuth=${nameAuthInfo.isVerified || "false"}`);
      return;
    }
    if (item.key === "storeSettingItem") {
      navigate2H5(`/#/store-setting${window.location.search}`);
      return;
    }
    if (item.key === "modifyPhoneItem") {
      navigate2H5(RouteName.ChangePhone);
      return;
    }
    if (item.path) {
      navigate2MyItem(item.path);
      return;
    }
  };

  useEffect(() => {
    if (item.key === "consultShopOwnerItem") {
      queryConsultShopOwner();
    }
    if (item.key === "posterNickname") {
      queryShareConfig();
    }
    if (item.key === "shopCertificationItem") {
      queryShopAuthStatus();
    }
    if (item.key === "authNameItem") {
      queryNameAuthStatus();
    }
  }, []);

  return (
    <>
      <Clickable
        className={`${styles.settingItem} ${item.last ? styles.lastItem : ""}`}
        onClick={onItemClick}
        trackData={{
          page_name: "我的",
          btn_name: item.title,
        }}>
        <div className={styles.flexBetweenCenter}>
          <div className={styles.itemLeft}>{item.title}</div>
          <div className={styles.itemRight}>
            {/* 店铺认证 */}
            {item.key === "shopCertificationItem" && shopAuthInfo.authText && (
              <span
                className={`${shopAuthInfo.authStatus === 0 || shopAuthInfo.authStatus === 99 ? styles.red : ""
                  }`}>
                {shopAuthInfo.authText}
              </span>
            )}
            {/* 实名认证 */}
            {item.key === "authNameItem" && nameAuthInfo && (
              <span className={`${nameAuthInfo.isVerified ? "" : styles.red}`}>
                {nameAuthInfo.isVerified ? "已认证" : "实名认证"}
              </span>
            )}
            {/* 商品/活动海报昵称 */}
            {item.key === "posterNickname" ? (
              <Switch checked={shareNicknameSwitch} onChange={val => onSwitchChange(val)} />
            ) : (
              <i className={styles.arrow} />
            )}
          </div>
        </div>
        {/* 资质信息 */}
        {item.key === "qualificationItem" && showBeianhao && <Beianhao />}
        {/* 联系店主 */}
        {item.key === "consultShopOwnerItem" && (
          <ShopOwnerQrcode
            visible={contactVisible}
            setVisible={setContactVisible}
            shareInfo={shopOwnerInfo}
          />
        )}
      </Clickable>
    </>
  );
};

export default SettingItem;
