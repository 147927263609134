import Clickable from "@/components/clickable";
import { TrackData } from "@akc/taro-service";
import { Popup, SafeArea } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

export interface ActionSheetProps {
  visible: boolean;
  onMaskClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  title?: string;
  actions?: {
    title?: string;
    selected?: boolean;
    trackData?: TrackData;
    onClick: () => void;
  }[];
  cancelTitle?: string;
  onCancelClick?: () => void;
}

const ActionSheet: React.FC<ActionSheetProps> = props => {
  return (
    <Popup visible={props.visible} bodyClassName={styles.popUpWrap} onMaskClick={props.onMaskClick}>
      <div>
        <div>
          <div className={styles.popUpTitle}>{props?.title}</div>
          {props?.actions?.map(item => (
            <Clickable
              key={item.title}
              className={styles.popUpItem + " " + (item.selected && styles.active)}
              onClick={item.onClick}
              trackData={item?.trackData}>
              {item.title}
            </Clickable>
          ))}
        </div>
        <div
          className={styles.popUpItem + " " + styles.popUpBottomBtn}
          onClick={props.onCancelClick}>
          {props.cancelTitle ?? "取消"}
        </div>
        <SafeArea position="bottom" />
      </div>
    </Popup>
  );
};

export default ActionSheet;
