import {
  CarrierElementType,
} from "@/api/scode/scode_params";
import React, { FC } from "react";
import { usePosterSwipePro } from "./hooks/usePosterSwipePro";
import PosterSwipe from "./index";
import {
  OpenPosterOptions,
  PosterSwipeProProps,
  UsePosterSwipeProOptions
} from "./types";

/**
 * PosterSwipePro - 海报分享组件的Pro版本
 * 
 * 该组件对原PosterSwipe组件的参数进行了优化，保留了接口入参的原始结构
 * 
 * @example
 * // 基本用法 - 日日奖场景
 * <PosterSwipePro
 *   show={showPoster}
 *   bizInfo={{
 *     contentId: "1889561226888830977",
 *     contentType: "DAILY_HIGH_PRICE_AWARD_GIFT",
 *     contentTypeConfig: "1"
 *   }}
 *   carrierContext={{
 *     carrierElements: ["H5POSTER"]
 *   }}
 *   uiConfig={{
 *     btnConfig: ["copy"]
 *   }}
 *   callbacks={{
 *     close: onClosePoster
 *   }}
 * />
 * 
 * @example
 * // 店铺分享场景
 * <PosterSwipePro
 *   show={showPoster}
 *   bizInfo={{
 *     contentId: shopId,
 *     contentType: "STORE",
 *     contentExtra: {
 *       page_name: "店铺首页",
 *       carrier_type: "default"
 *     }
 *   }}
 *   envContext={{
 *     sourceScene: "STORE"
 *   }}
 *   carrierContext={{
 *     carrierElements: ["H5POSTER", "MINIPOSTER"]
 *   }}
 *   uiConfig={{
 *     btnConfig: ["copy", "edit"]
 *   }}
 * />
 * 
 * @example
 * // 活动页分享场景 - 使用自定义编辑数据
 * <PosterSwipePro
 *   show={showPoster}
 *   bizInfo={{
 *     contentId: activityId,
 *     contentType: "B2R"
 *   }}
 *   customEditData={{
 *     shareType: "B2R",
 *     sourceScene: "IncentiveActivity"
 *   }}
 *   carrierContext={{
 *     carrierElements: ["H5POSTER"]
 *   }}
 *   callbacks={{
 *     close: () => setShowPoster(false),
 *     edit: () => console.log("编辑分享内容")
 *   }}
 * />
 */

const PosterSwipePro: FC<PosterSwipeProProps> = ({
  show,
  bizInfo,
  envContext = {},
  contentContext,
  carrierContext = { carrierElements: [CarrierElementType.h5Poster] },
  uiConfig = {},
  callbacks = {},
  customEditData = {},
}) => {

  // 构建posterData
  const posterData: any = {
    // 使用提供的bizInfo
    bizInfo: bizInfo ? {
      contentType: bizInfo.contentType,
      contentId: bizInfo.contentId,
      contentTypeConfig: bizInfo.contentTypeConfig,
      contentDetailId: bizInfo.contentDetailId,
      contentExtra: bizInfo.contentExtra,
    } : undefined,

    // 使用提供的envContext
    envContext,

    // 使用提供的contentContext，如果没有则从bizInfo构建
    contentContext: contentContext || (bizInfo ? {
      contentType: bizInfo.contentType,
      contentId: bizInfo.contentId,
      contentExtra: bizInfo.contentExtra,
    } : undefined),

    // 使用提供的carrierContext
    carrierContext,
  };

  // 确定shareType，优先使用customEditData.shareType
  const shareType = customEditData?.shareType || bizInfo?.contentType || contentContext?.contentType;

  // 将Pro版本的参数转换为原始组件的参数
  return (
    <PosterSwipe
      show={show}
      posterData={posterData}
      customEditData={customEditData}
      shareType={shareType as string}
      sourceScene={envContext.sourceScene}
      lockScroll={uiConfig.lockScroll}
      topSlot={uiConfig.topSlot}
      actionSlot={uiConfig.actionSlot}
      btnConfig={uiConfig.btnConfig as string[]}
      isOTC={uiConfig.isOTC}
      change={callbacks.change}
      close={callbacks.close}
      edit={callbacks.edit}
    />
  );
};

export default PosterSwipePro;

// 导出hook和相关类型
export { usePosterSwipePro };
export type { OpenPosterOptions, UsePosterSwipeProOptions };

