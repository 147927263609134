
import { isInGray } from "@/api";
import {
  currentUserInfoServ,
  getConsultShopOwner,
  getLabelViewServ,
  getRecommendProductList,
  getUserInfo,
  getWeChatAccountInfoUrlByUserId,
  memberInfoServ,
} from "@/api/my";
import { fetchShareCardInfo } from "@/api/scode/share_card_info";
import BizPopup from "@/components/biz-popup/index";
import { useCommonContext } from "@/utils/context/common";
import { shareCardByH5 } from "@/utils/share/share";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { Toast } from "antd-mobile";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import FollowWeChat from "./components/follow-wechat";
import JifenBoard from "./components/jifen-board";
import Menu from "./components/menu";
import MyBalance from "./components/my-balance";
import MyEquity from "./components/my-equity";
import OrderCenter from "./components/order-center";
import Recommend from "./components/recommend";
import ServiceCard from "./components/service-card";
import UserInfo from "./components/userinfo";
import "./index.less";
import styles from "./index.module.less";
import { page_view } from "./track";

const Personal: React.FC = () => {
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId");
  const [userInfo, setUserInfo] = React.useState<any>({});
  const [labelViewInfo, setLabelViewInfo] = React.useState<any>({});
  const [productList, setProductList] = React.useState<any[]>([]);
  const [idsClick, setIdsClick] = React.useState<any[]>([]);
  const [lastActivitySpuCode, setLastActivitySpuCode] = React.useState<any>("");
  const [shareInfo, setShareInfo] = React.useState<any>({});
  const [pageIndex, setPageIndex] = React.useState(1);
  const [hasNext, setHasNext] = React.useState(true);
  const [isFollow, setIsFollow] = React.useState<boolean>(true);
  const [isOnlyC, setIsOnlyC] = React.useState<boolean>(false);
  const [pointsInfo, setPointsInfo] = React.useState<any>({});
  const [grayEquity, setGrayEquity] = React.useState<boolean>(false);
  const { userVisitInfo } = useCommonContext();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  const queryUserInfo = async () => {
    try {
      const { data }: any = await getUserInfo({ shopId });
      const currentUserInfoRes: any = await currentUserInfoServ();
      setUserInfo({
        ...(currentUserInfoRes?.data || {}),
        ...data,
      });
      localStorage.setItem("userinfo", JSON.stringify(data));
    } catch (error) {
      console.error(error);
      Toast.show("获取用户信息失败");
    }
  };

  // 加载一页推荐
  const reqRecommendProductList = async () => {
    // setLoading(true);
    const searchRequestId = _.random(100000000000, 999999999999999);
    try {
      const params = {
        scene: "personalCenter",
        pageId: "H5_Search",
        searchRequestId,
        idsClick: idsClick.join(","),
        lastActivitySpuCode,
        pageIndex,
      };
      const { data }: any = await getRecommendProductList(params);
      setHasNext(data.hasNext);
      if (!data.result) {
        return;
      }
      data.result = data.result.map(item => {
        return {
          ...item,
          searchRequestId,
        };
      });
      setProductList(pre =>
        pre.concat(data.result).map((item, index) => ({ ...item, rank: index })),
      );

      setLastActivitySpuCode(data.result[data.result.length - 1].activitySpuId);
      setPageIndex(pageIndex + 1);

      setTrackData(data => ({
        ...data,
        recommend_requestid: searchRequestId,
        resource_name: "专属推荐",
        resource_type: "专属推荐",
        page_name: "我的",
      }));
    } catch (error) {
      console.error(error);
      setHasNext(false);
    }
  };

  // 获取店铺分享需要的信息
  const reqConsultShopOwner = async () => {
    const res = await getConsultShopOwner();
    if (!res?.data) {
      return;
    }
    setShareInfo(res.data);
  };

  // 获取会员信息
  const queryMemberInfo = async () => {
    const res = await memberInfoServ();
    setIsOnlyC(res?.data?.isMember ?? false);
    setPointsInfo(res.data);
  };

  // 获取关注微信状态
  const reqWeChatAccountInfoUrlByUserId = async () => {
    const res = await getWeChatAccountInfoUrlByUserId();
    setIsFollow(res?.data?.isFollow);
  };

  // 加载更多
  const loadMore = async () => {
    if (!hasNext) {
      return;
    }
    await reqRecommendProductList();
  };

  // 获取分享设置
  const shareSetting = async () => {
    const p = {
      bizInfo: {
        contentExtra: {
          "share.tab.activityTagNo": "",
          "share.tab.activityTagName": "",
        },
        contentId: "1",
        contentType: "TAB",
      },
      carrierContext: { carrierElements: ["h5LINK"] },
      contentContext: {
        contentExtra: { page_name: "个人中心" },
        contentId: "",
        contentType: "",
      },
      envContext: {
        sourceScene: "",
        bizCategory: "XD",
        bizSubCategory: "xdWxH5",
        terminalType: "h5",
      },
    };
    const res = await fetchShareCardInfo(p as any);
    if (res && res.data) {
      shareCardByH5(
        {
          title: res.data.urlShareTitle,
          desc: res.data.urlShareDesc,
          link: res.data.urlShareUrl,
          imgUrl: res.data.urlShareImg,
          scode: res.data.scode,
        },
        false,
      );
    }
  };

  // 获取标签信息
  const getLabelView = async () => {
    let data = await getLabelViewServ({ pageId: "H5_Member" });
    let arr = (data || []).filter(item => item.locationCode === "H5_Member_DetailNameDown");
    if (arr.length) {
      setLabelViewInfo(arr[0].labelInfoVOList[0]);
    }
  };

  // 判断是否开启会员积分灰度
  const initGrayEquity = async () => {
    const res = await isInGray("B-PersonalCenter");
    const isHit = !!res;
    setGrayEquity(isHit);
  };
  useEffect(() => {
    if (userVisitInfo?.isBindPhone === false) {
      Toast.show("为了你的账户安全，请绑定手机号，绑定后可快速登陆");
    }
  }, [userVisitInfo?.isBindPhone]);
  useEffect(() => {
    document.title = "我的";
    reqRecommendProductList();
    queryUserInfo();
    reqConsultShopOwner();
    shareSetting();
    queryMemberInfo();
    reqWeChatAccountInfoUrlByUserId();
    page_view();
    getLabelView();
    initGrayEquity();
  }, []);
  useActivate(() => {
    document.title = "我的";
  });

  return (
    <div className={styles["scroll-view"]}>
      <div className={styles["personal-container"]}>
        <div className={styles.userInfoWrap}>
          {/* 用户及店铺信息 */}
          <UserInfo
            userinfo={userInfo}
            shareInfo={shareInfo}
            labelViewInfo={labelViewInfo}
          />
        </div>
        {/* 会员积分 */}
        {((pointsInfo?.showPoint || pointsInfo?.isMember) && !grayEquity) && <JifenBoard pointsInfo={pointsInfo} />}
        {((pointsInfo?.showPoint || pointsInfo?.isMember) && grayEquity) && <MyEquity memberInfo={pointsInfo} />}
        <div
          className={styles["wrapper"]}
          style={{ marginTop: pointsInfo?.showPoint ? (grayEquity ? "8px" : "-20px") : "16px" }}>
          {/* 我的余额 */}
          {userInfo.amount > 0 && <MyBalance userinfo={userInfo} />}
          {/* 订单中心 */}
          <OrderCenter />
          {/* 关注微信 */}
          {!isFollow && <FollowWeChat />}
          {/* 快捷菜单 */}
          <Menu />
          {/* 常用服务 */}
          <ServiceCard />
          {/* 推荐组件 */}
          {
            <Recommend
              productList={productList}
              idsClick={idsClick}
              setIdsClick={setIdsClick}
              loadMore={loadMore}
              hasNext={hasNext}
            />
          }
        </div>
        <BizPopup businessCode="myPage" />
      </div>
    </div>
  );
};
export default inject("appStore")(observer(Personal));
