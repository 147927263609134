interface ShareTypeConfig {
  [key: string]: (
    customEditData: any,
    scodeSimpleParams: any,
    posterData: any,
    search: URLSearchParams,
  ) => any;
}

// 新的海报类型尽量接入默认逻辑
export const getForwardDefaultConfig = (customEditData: any, posterData: any) => {
  const bizInfo = posterData.bizInfo;
  return {
    contentId: bizInfo.contentId || "",
    shareType: customEditData.shareType || bizInfo.contentType,
    contentExtraJSONStr: encodeURIComponent(JSON.stringify(bizInfo.contentExtra || {})),
  };
};

// 旧逻辑
export const shareTypeForwardConfig: ShareTypeConfig = {
  ACTIVITY: (customEditData, scodeSimpleParams) => {
    const queryObj: any = {};
    queryObj.activityId = scodeSimpleParams?.activityId ?? scodeSimpleParams?.extra?.activityId;
    queryObj.queryType = scodeSimpleParams?.extra?.queryType;
    queryObj.sharePageId = scodeSimpleParams?.extra?.sharePageId;
    return queryObj;
  },
  PRODUCT: customEditData => {
    const { activitySpuId, activityId, selectSkuId } = customEditData;
    return {
      activitySpuId: activitySpuId,
      activityId,
      selectSkuId,
    };
  },
  MATERIAL: customEditData => {
    const { materialNo } = customEditData;
    return {
      materialNo,
      shareType: "MATERIAL3",
    };
  },
  B2R: (customEditData, scodeSimpleParams, posterData, search) => {
    const {
      contentType,
      contentId,
      brandId,
      contentDetailId,
      shareBizType,
      shareMainTitle,
      shareSubTitle,
      sourceScene,
    } = customEditData;

    // 添加对页面名称、市场ID和领域的处理
    const result = {
      shareType: contentType,
      contentType,
      contentId,
      brandId: brandId || "",
      contentDetailId: contentDetailId || "",
      shareBizType,
      shareMainTitle,
      shareSubTitle,
      sourceScene,
      // 添加额外信息
      pageInfo: {
        page_name: sourceScene === "IncentiveActivity" ? "激励活动详情" : "激励活动落地页",
        market_id: search.get("actId"),
        domain: "激励活动",
      },
    };

    return result;
  },
  SALE_CALENDAR: customEditData => {
    const { contentType, contentId } = customEditData;
    return {
      shareType: contentType,
      contentType,
      contentId,
      // 添加额外信息
      pageInfo: {
        page_name: "今日必卖日历页",
        domain: "",
        share_method: "海报",
        share_channel: "保存到相册",
      },
    };
  },
  DAILY_AWARD: customEditData => {
    const { promoActivityId, awardName, awardType } = customEditData;
    return {
      shareType: customEditData.shareType,
      promoActivityId,
      awardName,
      awardType,
    };
  },
  DAILY_AWARD_GIFT: customEditData => {
    const { promoActivityId, awardName, awardType } = customEditData;
    return {
      shareType: customEditData.shareType,
      promoActivityId,
      awardName,
      awardType,
    };
  },
  ANNUAL_BONUS: customEditData => {
    const { contentType, contentId, sourceScene } = customEditData;
    return {
      shareType: contentType,
      contentType,
      contentId,
      sourceScene,
    };
  },
  ORDER_SEND_GIFT: (customEditData, scodeSimpleParams, posterData) => {
    const bizInfo = posterData.bizInfo;
    return {
      contentId: bizInfo.contentId || "",
      shareType: bizInfo.contentType,
      contentExtraJSONStr: encodeURIComponent(JSON.stringify(bizInfo.contentExtra || {})),
    };
  },
  HOT_LIST_CATEGORY: (customEditData, scodeSimpleParams, posterData) => {
    const bizInfo = posterData.bizInfo;
    return {
      contentId: bizInfo.contentId || "",
      shareType: bizInfo.contentType,
      contentExtraJSONStr: encodeURIComponent(JSON.stringify(bizInfo.contentExtra || {})),
      // 添加额外信息
      pageInfo: {
        page_name: "榜单详情",
      },
    };
  },
  HOT_LIST_TOTAL: (customEditData, scodeSimpleParams, posterData) => {
    const bizInfo = posterData.bizInfo;
    return {
      contentId: bizInfo.contentId || "",
      shareType: bizInfo.contentType,
      contentExtraJSONStr: encodeURIComponent(JSON.stringify(bizInfo.contentExtra || {})),
      // 添加额外信息
      pageInfo: {
        page_name: "榜单中心",
      },
    };
  },
};
