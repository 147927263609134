import {
  queryAddrPageInfoServ,
  queryAddressDetailServ,
  saveOrUpdateAddressServ,
} from "@/api/appH5";
import loadingPng from "@/base64";
import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";
import { useCommonContext } from "@/utils/context/common";
import { countDown } from "@/utils/date-format";
import { checkIdentity } from "@/utils/identity";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import { Image, Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import styles from "./fillAddress.module.less";

const fillAddress = () => {
  document.title = "地址填写";

  const { isApp } = platform;
  const { userVisitInfo } = useCommonContext();

  const { navigate2AddressList } = useNavigation();

  const [search] = useSearchParams();
  const marketingId = search.get("actId") || search.get("contentId");
  const addressOnlyId = search.get("addressOnlyId");
  const addressId = search.get("addressId");

  const [tabActiveIndex, setTabActiveIndex] = useState(0);
  const [info, setInfo] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [haslogistics, setHaslogistics] = useState<boolean>(false);
  const [baseAddressId, setBaseAddressId] = useState<any>("");
  const [timeId, setTimeId] = useState<any>("");
  const [countDownObj, setCountDownObj] = useState<any>({});
  const [bindPhonePopupVisible, setBindPhonePopupVisible] = useState(false);
  const LogisticsRef = useRef<HTMLDivElement>(null);

  const queryAddressDetail = async (addressId, info) => {
    setBaseAddressId(addressId);
    const res = await queryAddressDetailServ({
      baseAddressId: addressId,
      isApp,
    });
    const { code, success, data, message = "" } = res || {};
    if (code === 0 && success && data) {
      let cacheInfo = { ...info };
      cacheInfo.address = {
        ...cacheInfo.address,
        linkman: data.linkman,
        mobile: data.mobile,
        address: data.address,
      };
      setInfo(cacheInfo);
    } else {
      Toast.show({
        content: message,
        maskClickable: false,
        maskStyle: { zIndex: 9999 },
      });
    }
  };

  const countDownDom = () => {
    if (countDownObj.day) {
      return (
        <>
          <span>{countDownObj.day}</span>天<span>{countDownObj.hou}</span>时
        </>
      );
    } else if (countDownObj.hou >= 1 && countDownObj.hou <= 24) {
      return (
        <>
          <span>{countDownObj.hou}</span>时<span>{countDownObj.min}</span>分
        </>
      );
    } else if (countDownObj.hou <= 1) {
      return (
        <>
          <span>{countDownObj.min}</span>分<span>{countDownObj.ms}</span>秒
        </>
      );
    }
  };

  const toAddressListPage = () => {
    if (!userVisitInfo?.isAuthorized) {
      checkIdentity();
      return;
    } else if (userVisitInfo?.isVisitor) {
      setBindPhonePopupVisible(true);
      return;
    }
    if (info.isPrincipal && !info.isPrincipalSubmit) {
      return;
    }

    navigate2AddressList({
      h5BackUrl: encodeURIComponent(`${window.location.href}`),
      fromPage: "rewardCenterFillAddress",
      addressOnlyId
    });
  };

  const onFinishBindPhone = () => {
    setBindPhonePopupVisible(false);
    toAddressListPage();
  };

  const getInfo = async () => {
    if (!(marketingId && addressOnlyId)) {
      return;
    }
    setLoading(true);
    const res = await queryAddrPageInfoServ({
      marketingId,
      addressId: addressOnlyId,
    });
    const { code, success, data, message = "" } = res || {};
    if (code === 0 && success && data) {
      setInfo(data);
      setBaseAddressId(data?.address?.extraVO?.baseAddressId);
      setHaslogistics(data?.address?.logisticsVOS?.length > 0);
    } else {
      Toast.show({
        content: message,
        maskClickable: false,
        maskStyle: { zIndex: 9999 },
      });
    }
    setLoading(false);
  };

  const onSubmit = e => {
    e.stopPropagation();
    if (!baseAddressId) {
      Toast.show({
        content: "请先选择地址",
        maskClickable: false,
        maskStyle: { zIndex: 9999 },
      });
      return;
    }
    saveOrUpdateAddressServ({
      marketingId,
      addressId: addressOnlyId,
      awardQuantity: info?.address?.awardQuantity,
      baseAddressId,
      isApp,
    }).then(res => {
      const { code, success, message = "" } = res || {};
      if (code === 0 && success) {
        getInfo();
        Toast.show({
          content: "提交成功",
          maskClickable: false,
          maskStyle: { zIndex: 9999 },
        });
      } else {
        Toast.show({
          content: message,
          maskClickable: false,
          maskStyle: { zIndex: 9999 },
        });
      }
    });
  };

  useEffect(() => {
    if (!info) {
      return;
    }
    if (timeId) {
      clearInterval(timeId);
    }
    setTimeId(
      setInterval(() => {
        let obj = countDown(info.redemptionDeadlineTime);
        setCountDownObj(obj);
      }, 1000),
    );
  }, [info]);

  const initPage = () => {
    if (!(marketingId && addressOnlyId)) {
      return;
    }
    setLoading(true);
    queryAddrPageInfoServ({
      marketingId,
      addressId: addressOnlyId,
    }).then(pageInfoRes => {
      const { code, success, data, message = "" } = pageInfoRes || {};
      if (code === 0 && success && data) {
        setInfo(data);
        setBaseAddressId(data?.address?.extraVO?.baseAddressId);
        setHaslogistics(data?.address?.logisticsVOS?.length > 0);
        if (addressId) {
          // 小程序场景
          queryAddressDetail(addressId, data);
        }
      } else {
        Toast.show({
          content: message,
          maskClickable: false,
          maskStyle: { zIndex: 9999 },
        });
      }
      setLoading(false);
    });
  };

  const footerDom = () => {
    if ((info.isPrincipal && info.isPrincipalSubmit) || (!info.isPrincipal && !info.isPrincipalSubmit)) {
      return (
        <div
          className={`${styles.footer__submit} ${!baseAddressId && styles.footer__submit__disable}`}
          onClick={e => onSubmit(e)}>
          提交
        </div>
      );
    }
  };

  //切换tab
  const changeTab = (index: number) => {
    setTabActiveIndex(index);
    LogisticsRef?.current?.scrollTo(0, 0);
  };

  useEffect(() => {
    initPage();
  }, []);

  useActivate(() => {
    console.error("页面获取焦点——", search.get("addressId"));
  });

  const addressDom = () => {
    if (info.address && userVisitInfo?.isVisitor === false) {
      // 有地址 && 非游客
      return (
        <div className={styles.from__address} onClick={e => toAddressListPage()}>
          <div className={styles.address__left}>
            <div className={styles.left__top}>
              <span className={styles.user_name}>{info.address.linkman || ""}</span>
              <span className={styles.phone}>{info.address.mobile || ""}</span>
            </div>
            <div className={styles.address}>{info.address.address || ""}</div>
          </div>
          <img
            className={styles.address_right}
            src="https://akim-oss.aikucun.com/mshop/c07b50f4a8c59f9fbe1aa006360948daabb94bf5_1714999579930_16.png"
          />
        </div>
      );
    } else {
      return (
        <div className={styles.from__not_address} onClick={e => toAddressListPage()}>
          添加收货地址
        </div>
      );
    }
  };

  const addressOrLogisticsDom = () => {
    if (haslogistics && info?.address?.logisticsVOS?.length > 0) {
      return (
        <div ref={LogisticsRef} className={styles.logistics}>
          <div className={styles.logistics__tab}>
            {info?.address?.logisticsVOS.map((logisticsItem, logisticsIndex) => {
              return (
                <div
                  key={logisticsIndex}
                  className={`${styles["tab"]} ${tabActiveIndex === logisticsIndex && styles["tab__active"]
                    }`}
                  onClick={() => changeTab(logisticsIndex)}
                >
                  {logisticsIndex === 0 ? "物流信息" : "补发物流"}
                </div>
              );
            })}
          </div>
          <div className={styles.logistics__current}>
            <div className={styles.logistics__brand}>
              <span className={styles.brand__name}>
                {info.address.logisticsVOS[tabActiveIndex]?.expressCompanName}
              </span>
              <span>{info.address.logisticsVOS[tabActiveIndex]?.expressNo}</span>
            </div>

            <div className={styles.logistics__trace}>
              {info.address.logisticsVOS[tabActiveIndex]?.traceList?.length > 0 &&
                info.address.logisticsVOS[tabActiveIndex].traceList.map((traceItem, traceIndex) => {
                  return (
                    <div key={traceIndex} className={`${styles["trace__item"]}`}>
                      <div className={styles.item__line1}>
                        <img
                          className={styles.item__icon}
                          src={
                            traceItem.traceStatus === 4
                              ? "https://akim-oss.aikucun.com/mshop/31638c8d685da2f7a7b147912126d346d379cb27_1720083965814_41.png"
                              : "https://akim-oss.aikucun.com/mshop/aedce0ea601ab4eb842375ddcb039fe7289d0bb0_1720083987289_31.png"
                          }
                        />
                        <span className={styles.item__status}>{traceItem.traceStatusDesc}</span>
                        <span className={styles.item__time}>{traceItem.time}</span>
                      </div>
                      <div className={styles.item__line2}>
                        <div className={styles.item__node}>
                          <div className={styles.node__start}></div>
                          <div className={styles.node__line}></div>
                          <div className={styles.node__end}></div>
                        </div>
                        <p className={styles.item__content}>{traceItem.content}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.page__from}>
          <div className={styles.from__body}>
            <h3 className={styles.from__title}>请填写收货地址</h3>
            <p className={styles.from__tips}>填写时间剩余{countDownDom()}</p>
            {addressDom()}
          </div>
          <div className={styles.from__footer}>{footerDom()}</div>
        </div>
      );
    }
  };

  return (
    <div className={`${styles.page} ${!info && styles.page_invalid}`}>
      {info && (
        <>
          <div className={styles.page__source}>
            <img className={styles.source__avatar} src={info.avatar} />
            <span className={styles.source__nickname}>{info.nickname}</span>
            <span>赠送您</span>
          </div>
          <div className={styles.award__name}>{info.awardName}</div>
          <img className={styles.award__img} src={info.awardImgUrl} />

          {addressOrLogisticsDom()}
        </>
      )}
      {loading && (
        <div className={`${styles.loading} ${styles.loadingFixed}`}>
          <Image src={loadingPng} width={pxtorem(30)}></Image>
        </div>
      )}
      {!info && !loading && (
        <div className={styles.invalid}>
          <img
            className={styles.invalid__icon}
            src="https://akim-oss.aikucun.com/mshop/d76b5ba1e31ae0331f8deb874a37ba52195be3c4_1715066059658_47.png"
          />
          <p className={styles.invalid__text}>哎呀，当前暂无奖品可以领取哦~</p>
        </div>
      )}
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={onFinishBindPhone}
      />
    </div>
  );
};

export default fillAddress;
