import { HotRankDetailModel } from "@/api/rank";
import { useCommonContext } from "@/utils/context/common";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import { ProductDetailModel } from "@akc/biz-api";
import { Exposable } from "@akc/mx-taro-h5-components";
import { DotLoading, Image, InfiniteScroll, } from "antd-mobile";
import React, { LegacyRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PriceView from "../PriceView";

import styles from "./index.module.less";

interface Props {
  loadMore: (isRetry: boolean) => Promise<void>;
  toRankDetailAction?: (rankno?: string, index?: number) => void;
  // toProductDetailPage?: () => void;
  hasMore: boolean;
  data?: HotRankDetailModel[];
  ref?: LegacyRef<HTMLDivElement> | undefined;
  type?: number;
  tabName?: string;
}

const RankBottomListView: React.FC<Props> = (props: Props) => {
  const { loadMore, toRankDetailAction, hasMore, ref, type, data } = props;
  const { track } = useCommonContext();
  const { navigate2Product } = useNavigation();
  const [search] = useSearchParams();

  const toProductDetail = (product: ProductDetailModel, rankData?: HotRankDetailModel, childRankIndex?: number, rankIndex?: number, e?: React.MouseEvent) => {

    e?.stopPropagation();

    navigate2Product({
      activitySpuId: product.activitySpuId ?? "",
      activityId: product.activityId,
      selectSkuId: product.selectSkuId,
    });

    // if (track.track) {
    //   track.track("resource_click", {
    //     page_name: type === 0 ? "榜单详情" : "榜单中心",
    //     previous_page_name: search.get("page_name"),
    //     tab_name: props?.tabName,
    //     domain: type === 0 ? "榜单详情" : "榜单中心",
    //     module: type === 0 ? "更多榜单" : "榜单中心",
    //     btn_name: "商品点击",
    //     resource_type: "新榜单",
    //     resource_content: "商品",
    //     resource_name: rankData?.rankName,
    //     resource_id: rankData?.rankNo,
    //     resource_rank: childRankIndex ?? 0 + 1,
    //     relative_rank: rankIndex ?? 0 + 1,
    //     click_activity_id: rankData?.productList?.[childRankIndex ?? 0]?.activityId,
    //     click_activity_spu_code: rankData?.productList?.[childRankIndex ?? 0]?.activitySpuId,
    //     click_sku_id: rankData?.productList?.[childRankIndex ?? 0]?.selectSkuId,
    //     product_model: rankData?.productList?.[childRankIndex ?? 0]?.activityType === 4 ? "普通商品" : "档期商品",
    //   });
    // }

  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean; }) => {
    return (
      <div>
        {hasMore ? (
          <>
            <span>Loading</span>
            <DotLoading />
          </>
        ) : (
          <div className={styles.bottomTextshow}>--已经到底了--</div>
        )}
      </div>
    );
  };

  // Top 榜单图片
  const topImageViewUrl = (index?: number) => {
    if (index === 0) {
      return "https://akim-oss.aikucun.com/d3d9f9d836ee09ccdae354fda00ffedd2869cb80_1742204104072_48.png";
    } else if (index === 1) {
      return "https://akim-oss.aikucun.com/3eca6e80d2056bb1a8464d48997814de3a21c44e_1742204091466_49.png";
    } else if (index === 2) {
      return "https://akim-oss.aikucun.com/10c5d184424780be59333a08f6fa1c65944c7e12_1742204098130_22.png";
    } else {
      return undefined;
    }
  };

  //标签
  const formatLabel = (list, type) => {
    const arr: any = [];
    if (list) {
      for (const label of list) {
        if (label.locationCode === type && label?.labelInfoVOList?.length > 0) {
          for (const labelList of label.labelInfoVOList) {
            arr.push({
              type: labelList.contentType,
              value: labelList.labelIconUrl ? labelList.labelIconUrl : labelList.labelText,
              height: 16,
              width: labelList.labelIconWidth / (labelList.labelIconHeight / 16),
            });
          }
        }
      }
    }
    return arr;
  };

  const seeRank = (e, rankno?: string, index?: number) => {
    e?.stopPropagation();
    toRankDetailAction && toRankDetailAction(rankno, index);
  };

  return (
    // <div className={`${styles.pad12} `} ref={ref}>
    <div
      className={styles.pad12}
      style={{ marginBottom: platform?.isApp && data && data.length > 0 ? 0 : 30 }}
      ref={ref}>
      {data?.map((item, parentIndex) => (
        <Exposable
          key={item?.rankNo}
          className={styles.mainItem}
          trackData={{
            page_name: type === 0 ? "榜单详情" : "榜单中心",
            previous_page_name: search.get("page_name"),
            tab_name: type === 0 ? "更多榜单" : props?.tabName,
            domain: type === 0 ? "榜单详情" : "榜单中心",
            module: type === 0 ? "模块：更多榜单" : "榜单中心",
            resource_type: type === 0 ? "更多榜单" : "榜单中心",
            resource_content: "榜单",
            resource_name: item?.rankName,
            resource_id: item?.rankNo,
            resource_rank: parentIndex + 1,
            relative_rank: parentIndex + 1,
            activity_id: item?.productList?.length > 0 ? item?.productList?.map((product) => product?.activityId).join(",") : "",
            activity_spu_code: item?.productList?.map((product) => product?.activitySpuId).join(","),
            sku_id: item?.productList?.map((product) => product?.selectSkuId).join(","),
            product_model: item?.productList?.map((product) => product?.activityType === 4 ? "普通商品" : "档期商品").join(","),
          }}
          clickEventName="resource_click"
          onClick={() => toRankDetailAction && toRankDetailAction(item?.rankNo, parentIndex)}>
          <img
            src="https://akim-oss.aikucun.com/ccbcdf9e51e14c89ffd9bc0f2384751ff9fcde40_1740725434108_42.png"
            alt="Success"
            className={styles.itemBg}
          />
          <div className={styles.listItem}>
            <div className={styles.childtop}>
              <div className={styles.childtopleft}>
                <img
                  src="https://akim-oss.aikucun.com/577004d21a0633c9c615568dd523f07c43ab0efd_1740725286549_81.png"
                  alt="Success"
                  className={styles.childImg}
                />
                <div className={styles.listItemTitle}>{item.rankName}</div>

                <img
                  src="https://akim-oss.aikucun.com/fa084e81bc34078ea6658b83e4e68f13348c1257_1740725290513_48.png"
                  alt="Success"
                  className={styles.childImg}
                />
              </div>
              <Exposable className={styles.seeRank}
                trackData={{
                  page_name: type === 0 ? "榜单详情" : "榜单中心",
                  previous_page_name: search.get("page_name"),
                  tab_name: type === 0 ? "更多榜单" : props?.tabName,
                  domain: type === 0 ? "榜单详情" : "榜单中心",
                  module: type === 0 ? "模块：更多榜单" : "榜单中心",
                  btn_name: "查看榜单",
                  resource_type: type === 0 ? "更多榜单" : "榜单中心",
                  resource_content: "榜单",
                  resource_name: data[parentIndex]?.rankName,
                  resource_id: data[parentIndex]?.rankNo,
                  resource_rank: parentIndex + 1,
                  relative_rank: parentIndex + 1,
                }}
                clickEventName="resource_click"
                onClick={(e) => seeRank(e, item?.rankNo, parentIndex)}>查看榜单</Exposable>
            </div>

            <div className={styles.childDetailList}>
              {
                item?.productList?.map((itemChild, index) =>
                  <Exposable
                    key={itemChild?.activitySpuId}
                    className={styles.itemsize}
                    style={{ zIndex: platform?.isiOS ? 2000 : 20 }}
                    trackData={{
                      page_name: type === 0 ? "榜单详情" : "榜单中心",
                      previous_page_name: search.get("page_name"),
                      tab_name: type === 0 ? "更多榜单" : props?.tabName,
                      domain: type === 0 ? "榜单详情" : "榜单中心",
                      module: type === 0 ? "模块：更多榜单" : "榜单中心",
                      resource_type: "新榜单",
                      resource_content: "商品",
                      resource_name: item?.rankName,
                      resource_id: item?.rankNo,
                      resource_rank: parentIndex + 1,
                      relative_rank: index + 1,
                      activity_id: itemChild?.activityId,
                      activity_spu_code: itemChild?.activitySpuId,
                      sku_id: itemChild?.selectSkuId,
                      product_model: itemChild?.activityType === 4 ? "普通商品" : "档期商品",
                    }}
                    clickEventName="resource_click"
                    onClick={(e) => toProductDetail(itemChild, item, index, parentIndex, e)}>
                    <div className={styles.imgView}>
                      <img className={styles.proImg} src={itemChild?.skuMainImageUrls && itemChild?.skuMainImageUrls[0]} />
                      <img className={styles.proSoftImg} src={topImageViewUrl(index)} />

                      <PriceView item={itemChild?.skuAdvancePriceInfo ||
                        itemChild?.skuCurrentPriceInfo} />

                    </div>

                    {formatLabel(
                      itemChild?.labelLocationVOList,
                      // "H5_SuperMarketing_ProductStyleNo3_ProductPicRight"
                      // "APP_Shop_DecorateSales_ColumnProductPicRight"
                      "H5_TopProduct_ProductList_PicRight",
                      // "APP_Shop_DecorateSales_ColumnProductNameDown"
                    ).map((label, labelIndex) => {

                      if (label.type === "image") {
                        return (
                          <Image
                            key={labelIndex}
                            src={label.value}
                            style={{
                              height: pxtorem(40),
                              width: pxtorem(40),
                              position: "absolute",
                              top: 0,
                              right: 0,
                            }}
                            fit="contain"></Image>
                        );
                      }
                      if (label.type === "text") {
                        return (
                          <div
                            key={labelIndex}
                            style={{
                              height: pxtorem(16),
                              position: "absolute",
                              top: 0,
                              right: 0,
                            }}>
                            {label.value}
                          </div>
                        );
                      }
                    })}
                    <div className={styles.titleProduct}>{itemChild?.title ?? ""}</div>
                    <div className={styles.price_btn}>
                      <div className={styles.price}>
                        {/* {(itemChild?.skuCurrentPriceInfo && itemChild?.skuCurrentPriceInfo.promotionType === "SECKILL" ||
                          itemChild?.skuCurrentPriceInfo && itemChild?.skuCurrentPriceInfo.promotionType ===
                          "FIXED_PRICE") && (
                            <div className={styles.price_text}>
                              {itemChild?.skuCurrentPriceInfo.promotionTypeName}
                            </div>
                          )} */}
                        {/* <div className={styles.price_text}>
                          {itemChild?.skuCurrentPriceInfo.promotionTypeName}
                        </div> */}
                        <div className={styles.price_real}>
                          <div className={styles.price_red_yuan}>¥</div>
                          <div className={styles.price_big}>
                            {itemChild?.skuCurrentPriceInfo?.marketingPrice !== undefined ? Math.trunc(itemChild.skuCurrentPriceInfo.marketingPrice) : "0"}
                          </div>
                          <div className={styles.price_big}>
                            .
                            {itemChild?.skuCurrentPriceInfo?.marketingPrice !== undefined
                              ? Math.round((itemChild.skuCurrentPriceInfo.marketingPrice % 1) * 10)
                              : "00"
                            }
                          </div>
                        </div>
                        {itemChild?.skuCurrentPriceInfo?.tagPrice && itemChild?.skuCurrentPriceInfo?.tagPrice > 0 && (
                          <div className={styles.price_old}>
                            ¥
                            <span
                              style={{
                                paddingLeft: pxtorem(0),
                              }}
                            >
                              {itemChild?.skuCurrentPriceInfo?.tagPrice}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    {
                      itemChild?.statisticsInfo?.salesCountStr && <div className={styles.hotview} >
                        <img
                          src="https://akim-oss.aikucun.com/95b144ba062cead20f5f2700591bde8fc33f2320_1740733663181_51.png"
                          alt="Success"
                          className={styles.hotimg} />
                        <div className={styles.hottext}>当日售出{itemChild?.statisticsInfo?.salesCountStr}件</div>
                      </div>
                    }

                  </Exposable>
                )
              }
            </div>
          </div>
          <div className={styles.line} />
        </Exposable>
      ))
      }

      <div className={styles.lineview}></div>

      {
        data && data.length > 0 && <div >
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} >
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll></div >
      }
    </div >
  );
};

export default RankBottomListView;
