import { ProductDetailModel, ProductModel } from "@akc/biz-api";
import React, { useState } from "react";
import styles from "./index.module.less";
type Props = {
  productDetail?: ProductDetailModel;
  relatedRecommendProducts?: ProductModel[];
  converterMsg?: string | null | undefined;
  className?: string;
  onShowRecommendProducts?: () => void;
};

/**
 * 底部功能按钮上方提示信息
 */
const BottomTipComp: React.FC<Props> = prop => {
  if (!prop?.productDetail) {
    return <></>;
  }
  const [converterMsg, setConverterMsg] = useState<string | undefined | null>(prop.converterMsg);
  //商品已售罄
  const isProductSellOut = prop?.relatedRecommendProducts?.length;
  //活动已结束
  const isActivityEnd =
    prop.productDetail?.activitySimpleVO?.status &&
    [3, 4].includes(prop.productDetail?.activitySimpleVO?.status);
  //商品已下架
  const isProductOffline =
    !prop.productDetail?.activitySimpleVO?.online || !prop?.productDetail?.spuInfoVO?.spuOnline;

  return (
    <div className={`${styles.pdBottomTip}${prop.className?.length ? " " + prop.className : ""}`}>
      {prop.productDetail?.pricePanelType !== 13 && (
        <div className={styles.top}>
          {/* 提示信息 */}
          {(converterMsg !== undefined && converterMsg?.length) ? (
            <div className={styles.newBottomNotice}>
              <div className={styles.bottomNoticeMsg}>{converterMsg}</div>
              <div
                className={styles.arrowBtn}
                onClick={() => {
                  setConverterMsg(undefined);
                }}
              />
            </div>
          ) : isProductSellOut ? (
            <div
              className={styles.bottomNoticeWrapper}
              onClick={() => {
                prop?.onShowRecommendProducts?.();
              }}
            >
              <div className={styles.bottomNoticeCont}>商品已被抢光啦，为你推荐相似热卖款</div>
              <div
                className={styles.arrowBtn}
              />
            </div>
          ) : isActivityEnd ? (
            <div className={styles.bottomTipWrapper}>活动已结束</div>
          ) : isProductOffline ? (
            <div className={styles.bottomTipWrapper}>本商品已下架</div>
          ) : (
            <div />
          )}
        </div>
      )}

      {/* 优惠券  */}
      {(prop.productDetail?.skuCurrentPriceInfo?.promoCouponInfo?.discountList?.length ?? 0) >
        0 && (
          <div className={styles.couponTips}>
            <div className={styles.couponDesc}>当前商品可使用</div>
            <div className={styles.couponsContents}>
              {prop.productDetail?.skuCurrentPriceInfo?.promoCouponInfo?.discountList?.map(
                (item, index) => {
                  return (
                    <span key={index}>
                      {item.marketingTypeDesc}{" "}
                      {index <
                        (prop.productDetail?.skuCurrentPriceInfo?.promoCouponInfo?.discountList
                          ?.length ?? 0) -
                        1
                        ? "、"
                        : ""}
                    </span>
                  );
                },
              )}
            </div>
            <div className={styles.couponDesc}>优惠</div>
          </div>
        )}
    </div>
  );
};

export default BottomTipComp;
