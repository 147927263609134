import { getBaseurl } from "@/utils/url-parse";
import { API } from "@akc/biz-api";
import { CartData } from "./types.d";

export const queryCartData = async (params: {
  shopBizCode?: string;
  cartProducts?: {
    activityId: string;
    skuId: string;
    spuId: string;
  }[];
  addressId?: string;
  cartShareNo?: string;
}) => {
  return API.post<CartData>({
    path: `${getBaseurl()}/api/shoppingCart/queryShopBuyerCartH5ListV3`,
    body: params,
  });
};

export const addShoppingCart = async (params: {
  shopBizCode?: string;
  skus?: any[];
  cartShareNo?: string;
}) => {
  return API.post<any>({
    path: `${getBaseurl()}/api/shoppingCart/batch-add-shoppingcart`,
    body: params,
  });
};

export const querySelectedSku = async (params: { cartShareNo: string }) => {
  return API.get<{
    number?: string;
    buyerAvatar?: string;
    buyerNick?: string;
    shareProductList?: {
      activityNo: string;
      skuId: string;
      externalSkuCode: string;
    }[];
  }>({
    path: `${getBaseurl()}/api/shoppingCart/list-shoppingcart-share`,
    body: params,
  });
};

export const selectSku = async (params: {
  cartShareNo: string;
  cartProducts: {
    activityId: string;
    skuId: string;
    spuId: string;
  }[];
}) => {
  return API.post<any>({
    path: `${getBaseurl()}/api/shoppingCart/select-shoppingcart-share?cartShareNo=${params.cartShareNo}`,
    body: params,
  });
};

// 自动领券
export const batchReceiveCoupon = async (params: {
  couponByIdReqList: any[];
  rcsTraceId: string;
  captchaToken: string;
  pageIndex: string;
  protocolVersion: string;
}) => {
  return API.post<any>({
    path: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/coupon/batchReceiveCoupon`,
    body: params,
  });
};

export const checkCartList = async (params: {
  cartShareNo?: string;
  shopBizCode?: string;
  cartProducts?: { activityId: string; skuId: string; spuId: string }[];
  addressId?: string;
}) => {
  return API.post<any>({
    path: `${getBaseurl()}/api/shoppingCart/checkCartList`,
    body: params,
  });
};
