import { SharePosterParams } from "@/api/scode/poster";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import { sharePosterParams4H5Product, useActions } from "@/utils/actions";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import React, { useState } from "react";
import LabelList from "./LabelList";
import styles from "./ProductCard.module.less";

interface ProductCardProps {
  productInfo: any;
  index: number;
  shared: boolean;
  onShared: (index) => void;
  onResourceClick: (index, obj) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  productInfo,
  index,
  shared,
  onShared,
  onResourceClick,
}) => {
  const { navigate2Product } = useNavigation();
  const [sharePosterData, setSharePosterData] = useState<SharePosterParams>();
  const [customEditData, setCustomEditData] = useState<CustomEditData>();
  const [showPoster, setShowPoster] = useState(false);
  const locationCode = platform?.isApp
    ? "App_Member_Task_ProductTitleDown"
    : platform?.isMini
      ? "Mini_Program_Member_Task_ProductTitleDown"
      : "H5_Member_Task_ProductTitleDown";
  const [isOTC, setIsOTC] = useState(false);
  const { shareProductPosterAction } = useActions();

  const toDetailPage = () => {
    onResourceClick(index, {
      btnName: "查看详情",
    });
    navigate2Product({
      activityId: productInfo.activityId,
      activitySpuId: productInfo.activitySpuId,
      selectSkuId: productInfo.selectSkuId,
      // firstSalePropertyValue: productInfo.salePropertyFirstListPicItem?.value,
    });
  };

  const onShare = async () => {
    onShared(index);
    onResourceClick(index, {
      btnName: "立即转发",
    });
    if (!platform.isApp && !platform.isMini) {
      setSharePosterData(
        sharePosterParams4H5Product({
          pageCode: platform?.isApp
            ? "App_Member"
            : platform?.isMini
              ? "Mini_Program_Member"
              : "H5_Member",
          activitySpuId: productInfo.activitySpuId,
        }),
      );
      setCustomEditData({
        activitySpuId: productInfo.activitySpuId,
        activityId: productInfo.activityId,
        selectSkuId: productInfo.selectSkuId,
      });
      setIsOTC(!!productInfo?.skuExtendInfoVO?.isOTC);
      setShowPoster(true);
    } else {
      shareProductPosterAction({
        product: productInfo,
        pageCode: platform?.isApp
          ? "App_Member"
          : platform?.isMini
            ? "Mini_Program_Member"
            : "H5_Member",
      });
    }
  };

  return (
    <>
      <div className={styles.productCard} onClick={() => toDetailPage()}>
        <div className={styles.cardLeft}>
          <img className={styles.mainPic} src={productInfo?.skuMainImageUrls?.[0]} />
          {productInfo?.skuCurrentPriceInfo?.commission && (
            <div className={styles.commission}>
              {platform.isApp ? "省赚" : platform.isMini ? "饷" : "赚"}¥
              {productInfo.skuCurrentPriceInfo.commission}
              {productInfo.skuCurrentPriceInfo.commissionAddition > 0 &&
                `+${productInfo.skuCurrentPriceInfo.commissionAddition}`}
            </div>
          )}
          {productInfo?.skuAdvancePriceInfo?.commission && (
            <div className={styles.commission}>
              {platform.isApp ? "省赚" : platform.isMini ? "饷" : "赚"}¥
              {productInfo.skuAdvancePriceInfo.commission}
              {productInfo.skuAdvancePriceInfo.commissionAddition > 0 &&
                `+${productInfo.skuAdvancePriceInfo.commissionAddition}`}
            </div>
          )}
        </div>
        <div className={styles.cardRight}>
          <div className={styles.title}>{productInfo.title}</div>
          <div className={styles.labelBox}>
            <LabelList
              labelLocationVOList={productInfo.labelLocationVOList}
              locationCode={locationCode}
            />
          </div>
          <div className={styles.priceBox}>
            <div className={styles.boxLeft}>
              <div className={styles.price}>
                {productInfo?.skuAdvancePriceInfo?.marketingPrice ||
                  productInfo?.skuCurrentPriceInfo?.marketingPrice}
              </div>
              <div className={styles.tagPrice}>
                {productInfo?.skuAdvancePriceInfo?.tagPrice ||
                  productInfo?.skuCurrentPriceInfo?.tagPrice}
              </div>
            </div>
            <div className={styles.boxRight}>
              <div
                className={styles.btn}
                onClick={e => {
                  e.stopPropagation();
                  onShare();
                }}>
                {shared ? "再次转发" : "转发赚钱"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {sharePosterData && (
        <PosterSwipe
          show={showPoster}
          posterData={sharePosterData}
          customEditData={customEditData}
          isOTC={isOTC}
          shareType="PRODUCT"
          btnConfig={["copy", "edit"]}
          close={() => {
            setShowPoster(false);
            document.body.style.overflow = "auto";
          }}
        />
      )}
    </>
  );
};

export default ProductCard;
