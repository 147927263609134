import { querySearchPromoActivityCardListServ } from "@/api/appH5";
import { getCookie } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import { IncentiveProfileCardComp } from "@akc/mx-taro-h5-components";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { UseShopInfoContext } from "../shop-index/useShopInfo";
import styles from "./index.module.less";

type Props = {
  onProgressQueryClick: (any) => void;
}
const IncentiveProfileCard: React.FC<Props> = ({ onProgressQueryClick }) => {
  const [search] = useSearchParams();
  const { navigate } = useNavigation();

  const { dataReport, queryDataReport } = UseShopInfoContext();

  const [incentiveProfileCardCompData, setIncentiveProfileCardCompData] = useState<any>(null);
  const [progressQueryTxt, setProgressQueryTxt] = useState("");

  const querySearchPromoActivityCardList = () => {
    querySearchPromoActivityCardListServ({
      merShopCode: ""
    })
      .then(res => {
        // limitIncentive 限时激励  benefits 权益 redPacketPrizePool 红包
        // 活动类型 FULL_RETURN=满返 GIFT_RETURN=满赠 RANK=PK
        const { limitIncentiveList, everyDayAward, benefitsList } = res || {};
        if (limitIncentiveList || everyDayAward || benefitsList) {
          setIncentiveProfileCardCompData(res);
        }
      });
  };

  // 顶部全部按钮
  const onEnterAllAction = (type) => {
    const typeEnum = {
      inc: 1,
      equ: 2,
      redp: 4,
      daily: 5
    };
    navigate({
      h5Options: {
        url: "/mx-shop-micro/reward-center",
        query: {
          searchType: typeEnum[type] || 1
        }
      },
    });
  };

  const onTopAllAction = () => {
    navigate({
      h5Options: {
        url: "/mx-shop-micro/reward-center",
        query: {
          searchType: 1
        }
      },
    });
  };

  const onEnterDetailAction = (e) => {
    navigate({
      h5Options: {
        url: "/mx-shop-micro/reward-center/detail",
        query: {
          actId: e.actId || ""
        }
      },
    });
  };

  const getProgressQueryTxt = () => {
    const { willCompleteCnt, willEndCnt, waitStartCnt, waitJoinCnt } = dataReport || {};
    let str = "";
    if (willCompleteCnt) {
      str = `即将达标：${willCompleteCnt}场活动`;
    } else if (willEndCnt) {
      str = `即将结束：${willEndCnt}场活动`;
    } else if (waitStartCnt) {
      str = `待开始：${waitStartCnt}场活动`;
    } else if (waitJoinCnt) {
      str = `待参加：${waitJoinCnt}场活动`;
    }
    setProgressQueryTxt(str);
  };

  useEffect(() => {
    getProgressQueryTxt();
  }, [dataReport]);

  useEffect(() => {
    querySearchPromoActivityCardList();
  }, []);

  return (
    <div className={styles.incentive_profile_card}>
      {/* 激励活动-卡片 */}
      <IncentiveProfileCardComp
        limitIncentiveList={incentiveProfileCardCompData?.limitedTimeList}
        everyDayAward={incentiveProfileCardCompData?.everyDayAward}
        benefitList={incentiveProfileCardCompData?.benefitsList}
        progressQueryTxt={progressQueryTxt}
        enterAllAction={(e) => onEnterAllAction(e)}
        onTopAllAction={onTopAllAction}
        enterDetailAction={(e) => onEnterDetailAction(e)}
        onProgressQueryClick={() => onProgressQueryClick(dataReport)}
      />
    </div>
  );
};

export default IncentiveProfileCard;