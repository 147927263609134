import React from "react";
import styles from "./SignCard.module.less";

type CardInfo = {
  day: string;
  dayStatus: number;
  isCanGetSignInAward: boolean;
  isGetSignInAward: boolean;
  isSignIn: boolean;
  statusDesc: string;
  dayOfSignInScopeText: string;
};

const imgs = {
  forget:
    "https://akim-oss.aikucun.com/761f22b2c1593d0bb87e0b606f990ba4974706de_1723464046960_99.png",
  isSignIn:
    "https://akim-oss.aikucun.com/92cfceb39d57d914ed8b14d0e37643de0797ae56_1723464047315_68.png",
  isGetSignInAward:
    "https://akim-oss.aikucun.com/0286dd552c9bea9a69ecb3759e7b94777635514b_1723464047589_48.png",
  isCanGetSignInAward:
    "https://akim-oss.aikucun.com/98fbc42faedc02492397cb5962ea3a3ffc0a9243_1723464047998_7.png",
};

const waitImgs = [
  "https://akim-oss.aikucun.com/fb644351560d8296fe6da332236b1f8d61b2828a_1723464652429_18.png",
  "https://akim-oss.aikucun.com/fe2ef495a1152561572949784c16bf23abb28057_1723464652735_47.png",
  "https://akim-oss.aikucun.com/827bfc458708f0b442009c9c9836f7e4b65557fb_1723464652993_33.png",
  "https://akim-oss.aikucun.com/64e095fe763fc62418378753f9402623bea9e227_1723464653490_67.png",
];

const SignCard: React.FC<{ info: CardInfo }> = ({ info }) => {
  const {
    isCanGetSignInAward,
    isGetSignInAward,
    isSignIn,
    statusDesc,
    dayStatus,
    dayOfSignInScopeText,
  } = info ?? {};
  let imgUrl = "";
  let text = "";
  let textColor = "#222222";
  let text2Color = "#222222";

  // 使用明确的状态枚举方式，按照状态优先级排序
  // 1. 确定当前卡片的状态
  const cardStatus = (() => {
    // 已签到且可领奖但未领奖 - 待抽奖状态
    if (isSignIn && isCanGetSignInAward && !isGetSignInAward) {
      return {
        imgUrl: imgs["isCanGetSignInAward"],
        textColor: undefined // 使用默认颜色
      };
    }

    // 已领奖状态
    if (isGetSignInAward) {
      return {
        imgUrl: imgs["isGetSignInAward"],
        textColor: "#FF4747"
      };
    }

    // 已签到状态
    if (isSignIn) {
      return {
        imgUrl: imgs["isSignIn"],
        textColor: "#FF4747"
      };
    }

    // 可签到且可抽奖状态
    if (isCanGetSignInAward) {
      return {
        imgUrl: imgs["isCanGetSignInAward"],
        textColor: undefined // 使用默认颜色
      };
    }

    // 漏签状态
    if (dayStatus === 1) {
      return {
        imgUrl: imgs["forget"],
        textColor: undefined // 使用默认颜色
      };
    }

    // 默认状态 - 待签到
    return {
      imgUrl: waitImgs[Math.floor(Math.random() * 4)],
      textColor: undefined // 使用默认颜色
    };
  })();

  // 应用状态
  imgUrl = cardStatus.imgUrl;
  if (cardStatus.textColor) {
    textColor = cardStatus.textColor;
  }

  if (dayStatus === 2) {
    text = "今天";
    text2Color = "#FF4747";
  }
  return (
    <div className={styles.item + " " + (dayOfSignInScopeText === "今天" && "todaySignCard")}>
      <div className={styles.card} style={{ backgroundImage: `url(${imgUrl})`, color: textColor }}>
        {statusDesc}
      </div>
      <div style={{ color: text2Color }}>{dayOfSignInScopeText}</div>
    </div>
  );
};

export default SignCard;
