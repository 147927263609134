import { useCommonContext } from "@/utils/context/common";
import { useSearchParams } from "react-router-dom";

export const useTrack = (pageName?: string) => {
  const { track } = useCommonContext();
  // PV
  const trackPageView = (params?: {
    previousPageName?: string | null;
    merchantShopCode?: string | null;
    activitySpuCode?: string | null;
    skuId?: string | null;
    spuId?: string | null;
    productModel?: string | null;
    activityId?: string | null;
  }) => {
    try {
      if (track.track) {
        track.track("$pageview", {
          page_name: pageName,
          previous_page_name: params?.previousPageName,
          merchant_shop_code: params?.merchantShopCode,
          activity_spu_code: params?.activitySpuCode,
          sku_id: params?.skuId,
          spu_id: params?.spuId,
          product_model: params?.productModel,
          activity_id: params?.activityId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 送给朋友
  const trackGiveFriend = () => {
    if (track.track) {
      track.track("btn_click", {
        page_name: pageName,
        btn_name: "送给朋友",
      });
    }
  };

  return {
    trackPageView,
    trackGiveFriend,
  };
};
