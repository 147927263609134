import { queryProductRecommendList } from "@/api/product";
import { ProductDetailModel, ProductModel } from "@akc/biz-api";
import { useEffect, useRef, useState } from "react";

const useSoldOutRecommendProductList = ({
  currentActivitySpuId,
  product,
}: {
  currentActivitySpuId?: string;
  product?: ProductDetailModel;
}) => {
  // 关联推荐的id
  const relatedRecommendReqId = useRef<string>();
  // 是否显示推荐商品弹窗
  const [showRecommendProductDialog, setShowRecommendProductDialog] = useState<boolean>(false);
  // 商品列表
  const [soldOutRecommendProductList, setSoldOutRecommendProductList] = useState<ProductModel[]>(
    [],
  );

  const fetchProductList = async () => {
    if (
      product?.spuInfoVO?.sellOut ||
      !product?.spuInfoVO?.spuOnline ||
      (product?.activitySimpleVO?.status &&
        [3, 4].includes(product?.activitySimpleVO?.status) &&
        product?.activitySpuId)
    ) {
      queryProductRecommendList({
        activitySpuIds: [product?.activitySpuId ?? ""],
        pageId: "H5_RelatedSale",
        relatedScene: product?.spuInfoVO?.sellOut ? "detail_shortage" : "detail_offsale",
      }).then(res => {
        if (res && (res.result?.length ?? 0) > 0) {
          relatedRecommendReqId.current = res?.extra?.searchRequestId;
          const result = res?.result ?? [];
          setSoldOutRecommendProductList(productList => {
            return [
              ...productList,
              ...(result?.map((item, index) => ({
                ...item,
                rank: productList.length + index,
                trackData: {
                  recommend_requestid: `${res?.extra?.searchRequestId ?? Date.now()}`,
                  domain: "商品详情",
                  module: "售罄、下架、活动结束推荐列表",
                  resource_type: "售罄、下架、活动结束推荐列表",
                  resource_content: "商品",
                  resource_name: "售罄、下架、活动结束推荐列表",
                  activity_id: item.activityId,
                  click_activity_spu_code: item.activitySpuId,
                  click_sku_id: item.selectSkuId,
                  btn_name: "商品列表",
                  resource_id: item.activitySpuId,
                  resource_rank: productList.length + index + 1,
                  product_model: item.activityType === 4 ? "普通商品" : "档期商品",
                  activity_spu_code: product?.activitySpuId,
                  sku_id: product?.selectSkuId,
                },
              })) ?? []),
            ];
          });
          if (product?.activitySpuId === currentActivitySpuId) {
            setShowRecommendProductDialog(true);
          }
        }
      });
    } else {
      setSoldOutRecommendProductList([]);
      setShowRecommendProductDialog(false);
    }
  };

  useEffect(() => {
    if (!product) {
      return;
    }
    if (!product?.activitySpuId) {
      return;
    }
    fetchProductList();
  }, [product]);

  return {
    showRecommendProductDialog,
    soldOutRecommendProductList,
    setShowRecommendProductDialog,
  };
};

export default useSoldOutRecommendProductList;
