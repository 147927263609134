import { API, PageableResponse, ProductDetailModel, ProductListItemModel, ProductModel } from "@akc/biz-api";

export interface HotRankDetailModel {
  //榜单编号
  rankNo?: string;
  //榜单名称
  rankName?: string;
  //前台3级类目ID
  categoryId?: string;
  //前台2级类目ID
  categoryTwoId?: string;
  //前台1级类目名称
  categoryOneName?: string;
  categoryOneId?: string;
  //商品列表
  // productList?: ProductDetailModel[];
  productList?: ProductModel[] | ProductDetailModel[] | any;

  isHot?: boolean;

}

export interface CategoryHotRankTabVO {
  //一级类目ID
  categoryId?: string;
  //类目名称
  categoryName?: string;

}

//榜单详情
export const hotRankDetail = async (rankNo: string) => {
  return API.post<HotRankDetailModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/category/hotRankDetail",
    body: {
      rankNo:rankNo,
    },
  });
};

//榜单列表 
export const hotRankList = async (pageId?:string,pageIndex?:number,pageSize?:number, rankNo?: string,oneCategoryId?:string,rankScene?:string) => {
  return API.post<PageableResponse<HotRankDetailModel[]>>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/category/hotRankList",
    body: {pageId:pageId,pageIndex:pageIndex,pageSize:pageSize, rankNo: rankNo,oneCategoryId:oneCategoryId,rankScene:rankScene}
  });
};

//榜单一级类目

export const hotRankCategoryList = async () => {
  return API.post<CategoryHotRankTabVO[]>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/category/hotRankCategoryList",
   
  });
};

