import { queryHideInfo } from "@/api/member";
import { CarrierElementType, ContentType } from "@/api/scode/scode_params";
import { ReactComponent as BrandIcon } from "@/assets/icon_brand.svg";
import { ReactComponent as MoneyIcon } from "@/assets/money.svg";
import { ReactComponent as ShareIcon } from "@/assets/share.svg";
import { ReactComponent as ShopIcon } from "@/assets/shop.svg";
import Clickable from "@/components/clickable";
import PosterSwipe from "@/components/poster-swiper";
import { RouteName } from "@/router/extra";
import { useCommonContext } from "@/utils/context/common";
import { getUserRole, identity } from "@/utils/identity";
import { default as useNavigation } from "@/utils/useNavigate";
import { ActivityBrandModel, ActivityModel, LabelPageId } from "@akc/biz-api";
import { default as React, useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import { useSelectedActivity, useSelectedActivityIdList } from "../hooks/useActivityProduct";
import styles from "./Footer.module.less";

interface FooterProps {
  brandData: ActivityBrandModel;
  activityList: ActivityModel[];
}
const Footer: React.FC<FooterProps> = ({ brandData, activityList }) => {
  const selectedActivity = useSelectedActivity();
  const selectedActivityIdList = useSelectedActivityIdList();
  const [search] = useSearchParams();

  const [showPoster, setShowPoster] = useState(false);
  const [isOTC, setIsOTC] = useState(false);
  const [shopHideStatus, setShopHideStatus] = useState(false);

  const onShare = async () => {
    const isAll = selectedActivity?.activityAggrTitle === "全部" && !selectedActivity?.activityId;
    if (isAll) {
      let bool = !!(activityList || []).find(item => {
        return item.isOTC;
      });
      setIsOTC(bool);
    } else {
      setIsOTC(!!selectedActivity?.isOTC);
    }

    setShowPoster(true);
  };

  const commonContext = useCommonContext();
  const { navigate2H5, navigate2BrandDetail } = useNavigation();

  const brandId = selectedActivity?.brandId;
  const firstActivityId = selectedActivityIdList?.[0];

  const getShopHideInfo = async () => {
    if (selectedActivity?.merchantShopCode) {
      const data = await queryHideInfo({
        bizNo: selectedActivity.merchantShopCode,
        hideType: "shop",
      });
      if (data) {
        setShopHideStatus(!!data.status);
      }
    }
  };

  useEffect(() => {
    getShopHideInfo();
  }, [selectedActivity]);

  useActivate(() => {
    getShopHideInfo();
  });

  return (
    <>
      <div className={styles.footer}>
        {selectedActivity && (
          <>
            {selectedActivity?.activityId && selectedActivity?.merchantShopCode && (
              <Clickable
                className={styles.shop}
                trackData={{
                  resource_type: "底部按钮",
                  btn_name: "会场",
                }}
                trackContextKeys={[
                  "tab_name",
                  "tab_id",
                  "tab_rank",
                  "merchant_shop_code",
                  "activity_id",
                ]}
                onClick={() => {
                  if (shopHideStatus && brandData?.brandId) {
                    navigate2BrandDetail(brandData.brandId);
                  }
                  if (!shopHideStatus) {
                    navigate2H5(RouteName.Decorate, {
                      query: `merchantShopCode=${selectedActivity.merchantShopCode}`,
                    });
                  }
                }}>
                {shopHideStatus ? (
                  <BrandIcon className={styles.icon} />
                ) : (
                  <ShopIcon className={styles.icon} />
                )}
                <div className={styles.text}>{shopHideStatus ? "品牌" : "会场"}</div>
              </Clickable>
            )}
            {!identity() && getUserRole() === "E" && (
              <Clickable
                className={styles.selective}
                trackData={{
                  resource_type: "档期",
                  resource_name: "选择性转发",
                  activity_id: selectedActivityIdList.join(","),
                }}
                onClick={() => {
                  const queryType = search.get("queryType") ?? undefined;
                  const brandId = selectedActivity.activityId
                    ? undefined
                    : selectedActivity.brandId;
                  navigate2H5(RouteName.SelectiveForward, {
                    query: `activityIdList=${selectedActivityIdList.join(",")}${queryType ? `&queryType=${queryType}` : ""}${brandId ? `&brandId=${brandId}` : ""}&timestamp=${Date.now()}`,
                  });
                }}>
                选择性转发
              </Clickable>
            )}
            <Clickable
              className={styles.button}
              onClick={onShare}
              trackData={{
                resource_type: "底部按钮",
                btn_name: commonContext.currentRole === "C" ? "分享" : "转发全部",
              }}
              trackContextKeys={[
                "tab_name",
                "tab_id",
                "tab_rank",
                "merchant_shop_code",
                "activity_id",
              ]}>
              {commonContext.currentRole !== "C" && <MoneyIcon className={styles.icon} />}
              {commonContext.currentRole === "C" && <ShareIcon className={styles.icon} />}
              {commonContext.currentRole === "C" ? "分享" : "转发全部"}
            </Clickable>
          </>
        )}
      </div>

      {selectedActivity && (
        <PosterSwipe
          show={showPoster}
          scodeSimpleParams={{
            brandId: selectedActivity.activityId ? undefined : selectedActivity.brandId,
            activityId: selectedActivity.activityId,
            contentType: selectedActivity.activityId ? undefined : ContentType.activityAggr,
            carrierElements: [CarrierElementType.h5Poster],
            extra: {
              brandId,
              queryType: search.get("queryType") ?? undefined,
              activityId: firstActivityId,
              sharePageId: LabelPageId.h5ActivityDetail,
            },
          }}
          shareType={"ACTIVITY"}
          isOTC={isOTC}
          btnConfig={["copy", "edit"]}
          close={() => {
            setShowPoster(false);
            document.body.style.overflow = "unset";
          }}
        />
      )}
    </>
  );
};

export default Footer;
