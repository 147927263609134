
import { AIMaterialMediaModel, CloudAlbumExtInfoDTO, } from "@akc/biz-api";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React, { CSSProperties, TouchEvent, useEffect, useMemo, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import styles from "./index.module.less";



interface MediaPreviewProps {
  mediaList: AIMaterialMediaModel[];
  currentIndex: number;
  onClose: () => void;
  onChange?: (index: number) => void;
}

interface SliderStyle extends CSSProperties {
  transform: string;
  transition: string;
  willChange: string;
  WebkitBackfaceVisibility: "hidden";
  backfaceVisibility: "hidden";
}

const MIN_SWIPE_DISTANCE = 50;
const EDGE_RESISTANCE = 0.3;
const VISIBLE_ITEMS = 3; // 只渲染当前图片及其前后各一张

const MediaPreview: React.FC<MediaPreviewProps> = ({
  mediaList,
  currentIndex: propIndex,
  onClose,
  onChange
}) => {
  const [contextIndex, setContextIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(propIndex);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const [translateX, setTranslateX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const mediaCache = useRef<Map<string, HTMLImageElement>>(new Map());
  const [visibleIndexes, setVisibleIndexes] = useState<number[]>([]);

  // 计算需要显示的图片索引
  const updateVisibleIndexes = (index: number) => {
    const indexes: number[] = [];
    for (let i = -1; i <= 1; i++) {
      const visibleIndex = index + i;
      if (visibleIndex >= 0 && visibleIndex < mediaList.length) {
        indexes.push(visibleIndex);
      }
    }
    setVisibleIndexes(indexes);
  };

  // 预加载当前图片周围的图片
  const preloadNearbyImages = (index: number) => {
    const preloadIndexes = [index - 1, index, index + 1];

    preloadIndexes.forEach(idx => {
      if (idx >= 0 && idx < mediaList.length) {
        const media = mediaList[idx] as AIMaterialMediaModel;
        if (media.contentType == 2 && !mediaCache.current.has(media.content as string)) {
          const img = new Image();
          img.src = media.content as string;
          mediaCache.current.set(media.content as string, img);
        }
      }
    });
  };

  useEffect(() => {
    updateVisibleIndexes(currentIndex);
    preloadNearbyImages(currentIndex);
  }, [currentIndex]);

  const handleCopy = (text: string) => {
    copy(text);
    Toast.show({
      content: "复制成功",
      position: "center"
    });
  };

  const handleTouchStart = (e: TouchEvent) => {
    e.stopPropagation();
    touchStartX.current = e.touches[0].clientX;
    touchEndX.current = e.touches[0].clientX;
    setIsDragging(true);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (!isDragging) { return; }
    e.stopPropagation();

    touchEndX.current = e.touches[0].clientX;
    const delta = touchEndX.current - touchStartX.current;

    // 添加边缘阻尼效果
    if (
      (currentIndex === 0 && delta > 0) ||
      (currentIndex === mediaList.length - 1 && delta < 0)
    ) {
      setTranslateX(delta * EDGE_RESISTANCE);
    } else {
      setTranslateX(delta);
    }
  };

  const handleIndexChange = (newIndex: number) => {
    setCurrentIndex(newIndex);
    setContextIndex(0);
    onChange?.(newIndex);

    // 预加载新位置周围的图片
    preloadNearbyImages(newIndex);
  };

  const pauseCurrentVideo = () => {
    const currentMedia = mediaList[currentIndex] as AIMaterialMediaModel;
    if (currentMedia.contentType === 3) {
      const videoElement = document.querySelector(`video[src="${currentMedia.content as string}"]`);
      if (videoElement) {
        (videoElement as HTMLVideoElement).pause();
      }
    }
  };

  const handleTouchEnd = (e: TouchEvent) => {
    if (!isDragging) { return; }
    e.stopPropagation();

    const swipeDistance = touchEndX.current - touchStartX.current;
    const isLeftSwipe = swipeDistance < -MIN_SWIPE_DISTANCE;
    const isRightSwipe = swipeDistance > MIN_SWIPE_DISTANCE;

    if (isLeftSwipe && currentIndex < mediaList.length - 1) {
      pauseCurrentVideo();
      handleIndexChange(currentIndex + 1);
    } else if (isRightSwipe && currentIndex > 0) {
      pauseCurrentVideo();
      handleIndexChange(currentIndex - 1);
    }

    setTranslateX(0);
    setIsDragging(false);
  };

  const handleContextChange = (direction: "prev" | "next", contextList: CloudAlbumExtInfoDTO[]) => {
    setContextIndex((prev) => {
      if (direction === "prev") {
        return (prev - 1 + contextList.length) % contextList.length;
      }
      return (prev + 1) % contextList.length;
    });
  };

  const sliderStyle = useMemo<SliderStyle>(() => ({
    transform: `translateX(calc(-${currentIndex * 100}% + ${translateX}px))`,
    transition: isDragging ? "none" : "transform 0.3s ease-out",
    willChange: "transform",
    WebkitBackfaceVisibility: "hidden",
    backfaceVisibility: "hidden"
  }), [currentIndex, translateX, isDragging]);

  // 图片或视频组件
  const renderMedia = (media: AIMaterialMediaModel, index: number) => {
    // 如果不在可视范围内，返回空占位符
    if (!visibleIndexes.includes(index)) {
      return <div key={index} className={styles.mediaItem} style={{ width: "100%", height: "100%" }} />;
    }

    if (media.contentType === 3) {
      return (
        <video
          key={index}
          controls
          src={media.content}
          poster={media.coverImgUrl}
          preload="metadata"
          playsInline
          className={styles.mediaVideo}
        />
      );
    }

    return (
      <TransformWrapper
        key={index}
        panning={{ disabled: isDragging }}
        doubleClick={{ disabled: true }}
        wheel={{ disabled: true }}
        centerOnInit={true}
        minScale={0.5}
        maxScale={3}
        limitToBounds={true}
        pinch={{ disabled: false }}
      >
        <TransformComponent
          wrapperStyle={{
            width: "100%",
            height: "100%",
          }}
          contentStyle={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div className={styles.imageWrapper}>
            <img
              src={media.content}
              alt=""
              className={styles.media}
              draggable={false}
              loading="lazy"
              style={{
                WebkitTouchCallout: "none"
              }}
            />
          </div>
        </TransformComponent>
      </TransformWrapper>
    );
  };

  // 图文内容组件
  const renderContextList = (media: AIMaterialMediaModel, index: number) => {
    if (
      media.contentType !== 2 ||
      !media.cloudAlbumExtInfoDTOList?.length
    ) {
      return null;
    }

    const isCurrentItem = index === currentIndex;
    const currentText = media.cloudAlbumExtInfoDTOList[isCurrentItem ? contextIndex : 0]?.associatedContent || "";

    return (
      <div className={styles.contextList}>
        <pre className={styles.contextText}>{currentText}</pre>
        <div className={styles.contextBtnBox}>
          <div
            className={styles.btnBox}
            onClick={() => isCurrentItem && handleContextChange("prev", media.cloudAlbumExtInfoDTOList || [])}
          >
            上一条
          </div>
          <div
            className={styles.btnBox}
            onClick={() => isCurrentItem && handleContextChange("next", media.cloudAlbumExtInfoDTOList || [])}
          >
            下一条
          </div>
          <div
            className={styles.btnBox}
            onClick={() => handleCopy(currentText)}
          >
            <img
              className={styles.btnIcon}
              src="https://akim-oss.aikucun.com/4edd1d00875d8edeec08e27ad5650fe11fad1d8d_1731565040004_76.png"
              alt=""
            />
            复制
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={styles.previewContainer}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={styles.header}>
        <div className={styles.closeBox} onClick={onClose}>
          <img
            className={styles.closeBtmImg}
            src='https://akim-oss.aikucun.com/b07f427927175d0e745611f0d4342969f9f468f3_1731563330433_10.png'
          />
        </div>
        <div className={styles.counter}>{currentIndex + 1} / {mediaList.length}</div>
      </div>

      <div className={styles.mediaWrapper}>
        <div
          className={styles.mediaSlider}
          style={sliderStyle}
        >
          {mediaList.map((media, index) => (
            <div key={index} className={styles.mediaItem}>
              <div className={styles.mediaContent}>
                {renderMedia(media as AIMaterialMediaModel, index)}
              </div>
              {renderContextList(media as AIMaterialMediaModel, index)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(MediaPreview);