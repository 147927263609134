import { useCommonContext } from "@/utils/context/common";
import { getPlatform } from "@/utils/platform";
import { useCallback, useRef, useState } from "react";

const useCheckBind = () => {
  const { userVisitInfo } = useCommonContext();
  const [bindPhonePopupVisible, setBindPhonePopupVisible] = useState(false);
  // 用于存储绑定手机号任务的解析函数
  const resolveRef = useRef<(() => void) | null>(null);

  const { isMini } = getPlatform();

  const toAuth = (hasScope = true) => {
    let url = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    // 处理微信自动添加字符串问题，替换为空
    url = url.replace(/\/\?from=(message|timeline|singlemessage|groupmessage)(\S*)#/, "/#");
    url = url.replace(/\/\?(scene|subscene|clicktime)=[\d]+(\S*)#/, "/#");
    // eslint-disable-next-line no-restricted-syntax
    window.location.href = `/api/member/auth?${
      hasScope ? "scope=snsapi_userinfo&" : ""
    }backUrl=${window.encodeURIComponent(url)}`;
  };

  const bindPhone = () => {
    if (userVisitInfo?.isBindPhone) {
      return;
    }
    if (isMini) {
      window.wx.miniProgram.navigateTo({
        url: "/pages/auth-page/auth-page",
      });
    } else {
      if (!userVisitInfo?.isAuthorized) {
        toAuth();
        return;
      }
      setBindPhonePopupVisible(true);
    }
  };

  // 创建绑定手机号任务
  const createBindPhoneTask = useCallback(async () => {
    // 如果已经绑定手机号，直接返回
    if (userVisitInfo?.isBindPhone) {
      return Promise.resolve();
    }

    return new Promise<void>(resolve => {
      // 保存resolve函数，以便在弹窗关闭时调用
      resolveRef.current = resolve;

      // 调用绑定手机号函数
      bindPhone();
    });
  }, [userVisitInfo?.isBindPhone, bindPhone]);

  // 完成绑定手机号任务
  const completeBindPhoneTask = useCallback(() => {
    setBindPhonePopupVisible(false);
    if (resolveRef.current) {
      resolveRef.current();
      resolveRef.current = null;
    }
  }, []);

  return {
    isVisitor: userVisitInfo?.isVisitor ?? false,
    bindPhonePopupVisible,
    setBindPhonePopupVisible,
    bindPhone,
    createBindPhoneTask,
    completeBindPhoneTask,
  };
};

export default useCheckBind;
