import { PDSizeRecommandMemberInfoModel } from "@akc/biz-api";
import { ChooseSizeComp } from "@akc/mx-taro-h5-components";
import { Popup, Toast } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

type ChooseSizeRoleProps = {
  visible?: boolean;
  // 是否loading
  needLoading?: boolean;
  close?: () => void;
  // 编辑角色
  onEditRole?: (params?: PDSizeRecommandMemberInfoModel) => void;
  // 确认选择
  onSureSelect?: (roleSizeInfo?: PDSizeRecommandMemberInfoModel) => void;
  onAddRole?: (isFirstAdd: boolean) => void;
  refreshTime?: number;
};

// 选择尺码角色
const ChooseSizeRoleDialog: React.FC<ChooseSizeRoleProps> = props => {

  //关闭
  const onClose = () => {
    props.close?.();
  };

  //底部安全区域高度
  function getSafeAreaInsetBottom() {
    const bodyStyle = window.getComputedStyle(document.body);
    return parseFloat(bodyStyle.getPropertyValue("--safe-area-inset-bottom")) || 10;
  }

  return (
    <React.Fragment>
      <Popup
        style={{ "--z-index": "2000" }}
        maskStyle={{ zIndex: "1999" }}
        visible={props.visible}
        onMaskClick={onClose}
        destroyOnClose={true}
        bodyStyle={{ backgroundColor: "transparent" }}>
        <div className={styles.chooseSizeContainer}>
          <ChooseSizeComp
            style={{ "padding-bottom": getSafeAreaInsetBottom() }}
            isInit
            onEditRole={(params?: PDSizeRecommandMemberInfoModel) => {
              // 编辑角色
              console.log("onEditRole");
              props.onEditRole?.(params);
            }}
            onClose={() => {
              props.close?.();
            }}
            onSureSelect={(params?: PDSizeRecommandMemberInfoModel) => {
              // 提交
              console.log("onSureSelect", params);
              props.onSureSelect?.(params);
            }}
            onAddRole={isFirstAdd => {
              console.log("onAddRole 是否第一次添加:", isFirstAdd);
              props.onAddRole?.(isFirstAdd);
            }}
            onOverMaxCount={maxCount => {
              Toast.show({
                content: `每个用户最多设置${maxCount}个尺码角色`,
                maskStyle: { zIndex: 15000 },
              });
            }}
            refreshTime={props.refreshTime}
          />
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default ChooseSizeRoleDialog;
