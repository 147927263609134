import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import { sharePosterParams4H5Material, useActions } from "@/utils/actions";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import { MaterialContentCardComp } from "@akc/mx-taro-h5-components";
import React, { useState } from "react";
import styles from "./MaterialCard.module.less";

interface MaterialCardProps {
  materialInfo: any;
  index: number;
  shared: boolean;
  onShared: (index) => void;
  onResourceClick: (index, obj) => void;
}

const MaterialCard: React.FC<MaterialCardProps> = ({
  materialInfo,
  index,
  shared,
  onShared,
  onResourceClick,
}) => {
  const { navigate2Material } = useNavigation();
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<any>({});
  const { shareMaterialPosterAction } = useActions();

  const toDetailPage = (e?) => {
    onResourceClick(index, {
      btnName: "查看详情",
    });
    navigate2Material(materialInfo.type, materialInfo.materialNo, "");
  };

  const onForwardAction = async item => {
    onResourceClick(index, {
      btnName: "立即转发",
    });
    if (!platform.isApp && !platform.isMini) {
      setSharePosterData(
        await sharePosterParams4H5Material({
          materialId: item.materialNo,
          activitySpuId: materialInfo.activitySpuId,
        }),
      );
      setCustomEditData({
        activitySpuId: item.activitySpuId,
        activityId: item.activityId,
        selectSkuId: item.selectSkuId,
        materialNo: item.materialNo,
      });
      setShowPoster(true);
    } else {
      shareMaterialPosterAction({
        material: materialInfo,
        pageCode: platform?.isApp
          ? "App_Member"
          : platform?.isMini
            ? "Mini_Program_Member"
            : "H5_Member",
      });
    }
    onShared(index);
  };

  const onClosePoster = () => {
    setShowPoster(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <div className={styles.materialCard}>
        <MaterialContentCardComp
          material={materialInfo}
          showlabel={true}
          hideLabel={true}
          isCollect={true}
          hideCollectBtn={true}
          forwardBtnTitle={shared ? "再次转发" : "转发赚钱"}
          imageWH={103}
          onAction={e => toDetailPage(e)}
          onForwardAction={e => onForwardAction(e)}
        />
      </div>
      {/* 海报*/}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        customEditData={customEditData}
        shareType="MATERIAL"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}></PosterSwipe>
    </>
  );
};

export default MaterialCard;
