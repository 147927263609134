import { getFollowBrands } from "@/api/home";
import BottomNotice from "@/components/bottom-notice";
import CouponNotice from "@/components/coupon-notice";
import DailyRewardNotice from "@/components/daily-reward-notice";
import MarketingPopup from "@/components/marketing-popup";
import RedPacketNotice from "@/components/red-packet-notice";
import ToExChangeTip from "@/pages/rewardCenter/components/ToExchangeTip/ToExchangeTip";
import { useCommonContext } from "@/utils/context/common";
import useSkin from "@/utils/hooks/useSkin";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import ActivityList from "./components/ActivityList";
import CategoryTab from "./components/CategoryTab";
import CouponWindow from "./components/coupon-pop/CouponWindow";
import ModeTab from "./components/ModeTab";
import CouponBoardV1 from "./components/new-person/CouponBoardV1";
import ProductWaterfall from "./components/ProductList";
import ResourceList from "./components/ResourceList";
import SearchBar from "./components/SearchBar";
import useShopInfo from "./hooks/useShopInfo";
import styles from "./index.module.less";

const track = Track();

const Home: React.FC = () => {
  const { skin, currentRole } = useCommonContext();
  const [activityTagNo, setActivityTagNo] = useState<string>("");
  const [hasTopNotice, setHasTopNotice] = useState<boolean>(false);
  const [mode, setMode] = useState<"activity" | "product">("activity");

  const [followBrands, setFollowBrands] = useState<any[]>([]);
  const [showTabPop, setShowTabPop] = useState<boolean>(false);
  const timer = useRef<any>(null);
  const containerDom = useRef<HTMLDivElement>(null);
  const { navigate2H5 } = useNavigation();
  const { shopName } = useShopInfo();
  useSkin();

  const handleClickFavoriteBrand = ({ brandNo }) => {
    navigate2H5("/#/my-focus", {
      query: `brandNo=${brandNo}&type=0`,
    });
  };

  const onShowTabPop = isShow => {
    if (isShow) {
      clearTimeout(timer.current);
      setShowTabPop(isShow);
    } else {
      timer.current = setTimeout(() => {
        setShowTabPop(isShow);
      }, 1000);
    }
  };
  // 埋点上下文数据设置
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  useEffect(() => {
    document.title = shopName;
  }, [shopName]);

  useEffect(() => {
    const shopId = localStorage.getItem("shopId");
    if (!shopId) {
      return;
    }
    // getFollowBrands().then(data => {
    //   setFollowBrands(data);
    // });
    document.body.style.overflow = "unset";

    setTrackData({
      page_name: "首页",
    });
    track.pageView("首页", {
      page_name: "首页",
      domain: "首页",
    });
  }, []);

  useEffect(() => {
    if (activityTagNo && activityTagNo !== "10") {
      setMode("activity");
    }
  }, [activityTagNo]);

  useActivate(() => {
    document.title = shopName;
  });

  return (
    <div ref={containerDom} className={styles.container} id="homeContainer">
      {/* 日日奖红包到期提醒组件 */}
      <RedPacketNotice topOffset={-2} />
      <div
        className={styles.headerWrap}
        style={{
          ...(skin?.home?.categoryBg ? { backgroundImage: `url(${skin?.home?.categoryBg})` } : undefined),
          backgroundColor: skin?.home?.searchBgColor || "white",
          zIndex: showTabPop ? "11001" : "1000",
        }}>
        <CouponNotice afterShow={val => setHasTopNotice(val)} />
        <SearchBar />
        <CategoryTab
          setActivityTagNo={setActivityTagNo}
          containerDom={containerDom}
          hasTopNotice={hasTopNotice}
          onShowTabPop={onShowTabPop}
        />
      </div>
      {activityTagNo && (
        <>
          <div
            className={styles.resourceContainer}
            style={{ backgroundColor: skin?.home?.todaySaleBgColor }}>
            <ToExChangeTip showClose={true} />
            <ResourceList activityTagNo={activityTagNo} />
            {activityTagNo === "10" && (
              <>
                <CouponBoardV1 />
                {/* <IntegralCard /> */}
                {/* <HomeMyFavoriteBrandComp
                  brandList={followBrands}
                  onDetailAction={handleClickFavoriteBrand}
                /> */}
              </>
            )}
          </div>
          <div className={styles.cardContainer}>
            {currentRole === "C" && activityTagNo === "10" && (
              <div className={styles.modeTabWrap}>
                <ModeTab mode={mode} setMode={setMode} />
              </div>
            )}
            {mode === "product" && activityTagNo === "10" && <ProductWaterfall pageId="H5_Feed" />}
            {mode === "activity" && (
              <ActivityList activityTagNo={activityTagNo} pageId="H5_ScheduleList" />
            )}
          </div>
        </>
      )}

      {/* 漂浮在底部的通告栏 */}
      <BottomNotice />

      <CouponWindow />
      {/* 用户中奖通知 */}
      <DailyRewardNotice topOffset={-2} />
      {/* 营销弹框 */}
      <MarketingPopup serverCode="mall_index" />
    </div>
  );
};

export default Home;
