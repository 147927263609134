import { ContentType } from "@/api/scode/scode_params";
import PosterSwipePro, { usePosterSwipePro } from "@/components/poster-swiper/pro";
import { useShare } from "@/utils/share/share";
import { Arrow } from "@react-vant/icons";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Notice.module.less";
import ScrollBanner from "./ScrollBanner";


interface NoticeItem {
  userName: string;      // 用户名称，如"风***"
  amount: number;        // 金额，如3.8
  awardType: string;     // 奖励类型，如"RED_PACKET"
  createTime: string;    // 创建时间，如"2025-03-04 09:44:20"
  headImgUrl: string;    // 头像URL
  customer: boolean;     // 是否为客户
  productTitle?: string;  // 礼品券标题
}

interface NoticeProps {
  dataSource: NoticeItem[];
  promoActivityId?: string;
  activityStatus?: string; // 活动状态
}

const formatterStr = (item: NoticeItem) => {
  const { createTime } = item;
  if (!createTime) { return ""; }
  const now = dayjs();

  const diffInMinutes = now.diff(createTime, "minute");

  let result;
  if (diffInMinutes >= 60) {
    // 转换为小时
    const diffInHours = Math.floor(diffInMinutes / 60);
    result = `${diffInHours}小时前抽中`;
  } else {
    result = `${diffInMinutes > 0 ? diffInMinutes : 1}分钟前抽中`;
  }
  return result;
};

const isShow = (item?: NoticeItem) => {
  if (!item) { return false; }
  const { awardType, createTime, amount, productTitle } = item;
  if (!createTime) { return false; }
  if (awardType === "RED_PACKET") {
    return !!amount;
  } else if (awardType === "PRODUCT") {
    return !!productTitle;
  }
  return false;
};

const Info = (props) => {
  const { dataSource } = props;
  return isShow(dataSource) ? (
    <div className={styles.info}>
      <div className={styles.left}>
        <img src={dataSource.headImgUrl} alt="" />
        {
          dataSource.customer ? <div className={styles.leftTag}>顾客</div> : null
        }
      </div>
      <div className={styles.main}>
        <span>{dataSource?.userName}</span>
        <span>{formatterStr(dataSource)}</span>
        <span className={styles.light}>
          {
            dataSource.awardType === "RED_PACKET"
              ? `${dataSource?.amount}元`
              : dataSource?.productTitle
          }
        </span>
      </div>
    </div>
  ) : null;
};

// 空态组件
const EmptyActivity = ({ emptyText }: { emptyText: string }) => {
  return (
    <div className={styles.emptyActivity}>
      <div className={styles.emptyActivityInner}>
        <div className={styles.title}></div>
        <div className={styles.emptyContent}>
          <img
            src="https://akim-oss.aikucun.com/5679fa0533683de3ae789448b07766da168a56d4_1741599997168_64.png"
            alt="emptyText"
            className={styles.emptyIcon}
          />
          <p className={styles.emptyText}>{emptyText}</p>
        </div>
      </div>
    </div>
  );
};

const Notice: React.FC<NoticeProps> = ({ dataSource, promoActivityId, activityStatus }) => {
  const { shareDailyReward } = useShare();
  const [scrollNotice, setScrollNotice] = useState<any>([]);
  const promoActivityIdRef = useRef(promoActivityId || "");

  // 判断活动是否已结束
  const isActivityEnded = activityStatus === "END" || activityStatus === "CANCEL";

  const handleShareInternal = () => {
    const currentPromoActivityId = promoActivityIdRef.current;

    const params = {
      contentId: currentPromoActivityId,
      shareType: ContentType.dailyHighPriceAwardGift,
      contentDetailId: "",
    };
    console.log("appPosterParams", params);
    shareDailyReward(params);
  };

  const swiperControl = usePosterSwipePro({
    defaultBizInfo: {
      contentType: ContentType.dailyHighPriceAwardGift,
      contentId: promoActivityIdRef.current,
    },
    defaultUIConfig: {
      btnConfig: [],
    },
    onNonH5Open: (options) => {
      handleShareInternal();
    }
  });

  // 更新 ref 以保持最新值
  useEffect(() => {
    if (promoActivityId) {
      promoActivityIdRef.current = promoActivityId;
      swiperControl.updateConfig({
        bizInfo: {
          contentType: ContentType.dailyHighPriceAwardGift,
          contentId: promoActivityId,
        },
      });
    }
  }, [promoActivityId]);


  useEffect(() => {
    const formatterFn = () => {
      if (Array.isArray(dataSource) && dataSource.length) {
        setScrollNotice([dataSource]);
      }
    };

    formatterFn();
  }, [dataSource]);

  if (activityStatus === "ADVANCE") {
    return <EmptyActivity emptyText="活动未开始" />;
  }

  if (isActivityEnded) {
    return <EmptyActivity emptyText="活动已结束" />;
  }

  return (
    <>
      {
        scrollNotice.length ? (
          <div className={styles.notice}>
            <div className={styles.container}>
              <div className={styles.header}>
                <div className={styles.title}></div>
                {!isActivityEnded && (
                  <div className={styles.share} onClick={() => swiperControl.open()}>
                    <span>分享全部</span>
                    <Arrow color='#fff' fontSize={12} />
                  </div>
                )}
              </div>
              <div className={styles.noticeBarContainer}>
                <ScrollBanner baseDuration={200}>
                  <div className={styles.noticeBarContent}>
                    {
                      scrollNotice[0].map((item: any, index: number) => <Info dataSource={item} key={index} />)
                    }
                  </div>
                </ScrollBanner>
              </div>
            </div>
          </div>
        ) : null
      }
      <PosterSwipePro {...swiperControl.props} />
    </>
  );
};

export default Notice;