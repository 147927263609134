// 从 npm 获取 sdk
import AKJS from "@akc/akjs";
import AnalysysAgent from "asd-javascript-sdk";
import { platform } from "../platform";

import { merge, pickBy } from "lodash";

let currentPageName: string | undefined;
let commonProps: any | undefined;

const pagesInAB = ["品牌券新人专区", "首页", "我的", "物流详情"];

// export type TrackData = {
//   page_name?: string;
//   previous_page_name?: string;
//   domain?: string;
//   module?: string;
//   resource_type?: string;
//   resource_id?: string;
//   resource_rank?: number;
//   relative_rank?: number;
//   rank_type?: string;
//   rank_name?: string;
//   activity_id?: string;
//   resource_content?: string;
//   activity_spu_code?: string;
//   sku_id?: string;
//   merchant_shop_code?: string;
//   product_model?: string;
//   material_id?: string;

//   coupons_id?: string;
//   brand_id?: string;
//   btn_name?: string;

//   tab_id?: string;
//   tab_name?: string;
//   tab_rank?: number;
//   page_desc?: string;
// };

// export type TrackDataKey = keyof TrackData;

export default () => ({
  /**
   *
   * @param {*} config { appkey, debugMode, uploadURL }
   * @param {*} superProperties { userID, level, deviceId, }
   */
  init(config, superProperties) {
    if (!platform.isApp) {
      const { appkey, debugMode = 0, uploadURL, ABkey } = config;
      if (!appkey) {
        throw new Error("must config the appkey of yiguan");
      }
      if (!uploadURL) {
        throw new Error("must config the uploadURL of yiguan server");
      }
      // 易观埋点
      AnalysysAgent.init({
        appkey, // APPKEY
        uploadURL: uploadURL || process.env.REACT_APP_ANALYS_UPLOAD_URL, // 上传数据的地址
        debugMode,
        auto: false,
        sendType: "post",
        isHybrid: false,
      });
    }
    if (superProperties) {
      this.registerSuperProperty(superProperties);
    }
  },
  /**
   * 注册公共参数
   */
  registerSuperProperty(superProperties) {
    const props = pickBy(
      superProperties,
      v => v !== undefined && v !== null && v !== "" && !Number.isNaN(v),
    );
    if (props) {
      if (!platform.isApp && props.userID) {
        AnalysysAgent.alias(props.userID);
      }
      const { ...others } = props;

      if (Object.keys(others).length) {
        commonProps = props;
        if (!platform.isApp) {
          AnalysysAgent.registerSuperProperties(others);
        }
      }
    }
  },
  alias(user) {
    if (platform.isApp) {
      return;
    }
    if (user) {
      AnalysysAgent.alias(user);
    }
  },
  track(eventName, props) {
    try {
      const properties = pickBy(
        props,
        v => v !== undefined && v !== null && v !== "" && !Number.isNaN(v),
      );
      if (platform.isApp) {
        AKJS.ready().then(() => {
          AKJS.action.dispatch("event.track.action", {
            name: eventName,
            params: merge(properties, commonProps),
            on: currentPageName,
          });
        });
      } else {
        AnalysysAgent.track(eventName, properties);
      }
    } catch (e) {
      console.error(e);
    }
  },
  pageView(pageName: string, props) {
    try {
      const properties = pickBy(
        props,
        v => v !== undefined && v !== null && v !== "" && !Number.isNaN(v),
      );
      currentPageName = pageName;
      if (platform.isApp) {
        AKJS.ready().then(() => {
          AKJS.action.dispatch("event.track.pageView", {
            name: pageName,
            params: merge(properties, commonProps),
          });
        });
      } else {
        AnalysysAgent.track("$pageview", {
          ...properties,
          page_name: pageName,
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
});

// import React from "react";
// import { useActivate } from "react-activation";
// import { TrackDataContext } from "@akc/mx-taro-h5-components";

// export const TrackDataContext = createContext<{
//   trackData: TrackData;
//   setTrackData: React.Dispatch<React.SetStateAction<TrackData>>;
// }>({
//   trackData: {},
//   setTrackData: () => {
//     console.error("setTrackData must be implement");
//   },
// });

// export const TrackDataContextProvider = TrackDataContext.Provider;

// export const useTrackData = () => {
//   const dataRef = React.useRef<Record<string, string | number> | undefined>();
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   const { setTrackData } = React.useContext(TrackDataContext);

//   useActivate(() => {
//     if (dataRef.current) {
//       setTrackData(dataRef.current);
//     }
//   });

//   const updateTrackData = (data: Record<string, string | number>) => {
//     dataRef.current = data;
//     setTrackData(data);
//   };

//   return { setTrackData: updateTrackData };
// };
