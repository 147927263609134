import { TagData } from "@/api/decorate";
import { queryCategoryTab4Index } from "@/api/home";
import { fetchShareCardInfo } from "@/api/scode/share_card_info";
import Tabs, { Props as TabProps } from "@/components/tabs";
import { RouteName } from "@/router/extra";
import { useCommonContext } from "@/utils/context/common";
import { shareCardByH5 } from "@/utils/share/share";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useRef } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import { json } from "stream/consumers";
import styles from "./CategoryTab.module.less";

type Props = {
  setActivityTagNo: (tagNo: string) => void;
  hasTopNotice?: boolean;
  containerDom: any;
  onShowTabPop: (isShow: boolean) => void;
};


const tabsDatass = [
  {
    "activityTagNo": "10",
    "activityTagName": "全部"
  },
  { "activityTagNo": "557262024690123019", "activityTagName": "美妆个护" },
  {
    "activityTagNo": "blocday_86",
    "activityTagName": "金枝重构验证H5",
    "type": 1,
    "href": "http://h5shop-stable.akucun.com/mx-shop-micro?customPageCode=24042500000011001105817",
    "subscriptType": "",
    "subscriptImg": "",
    "activityTagNameColor": "",
    "activityTagImg": "",
    "activityTagImgDropDown": "",
    "promotionLevel": "B",
    "programIcon": "https://filetest-oss.aikucun.com/akucun-cms-manager/538baf28254ab11a118881e28dca52817144104f_1715162814121_83.jpg",
    "activityTagShowType": 1
  },
  { "activityTagNo": "557262024690123016", "activityTagName": "副H箱包" },
  { "activityTagNo": "557262024690123012", "activityTagName": "合并H鞋靴" },
  { "activityTagNo": "557262024690123017", "activityTagName": "流行饰品" },
  { "activityTagNo": "557262024690123020", "activityTagName": "美味食品" },
  { "activityTagNo": "557262024690123011", "activityTagName": "图书卡券" },
];


const setShareInfo = async ({ activityTagNo, activityTagName }) => {
  let params = {
    bizInfo: {
      contentExtra: {
        "share.tab.activityTagNo": activityTagNo,
        "share.tab.activityTagName": activityTagName,
      },
      contentId: "1",
      contentType: "TAB",
    },
    carrierContext: {
      carrierElements: ["h5LINK"],
    },
    contentContext: {
      contentExtra: {
        page_name: "首页",
      },
      contentId: "1",
      contentType: "TAB",
    },
    envContext: {
      bizCategory: "XD",
      bizSubCategory: "xdWxH5",
      terminalType: "h5",
      createTime: new Date().getTime(),
    },
  };
  // 写进seesionStorage
  sessionStorage.setItem("homeShareQueryParams", JSON.stringify(params));
  const res = await fetchShareCardInfo(params as any);
  if (res && res.data) {
    shareCardByH5(
      {
        title: res.data.urlShareTitle,
        desc: res.data.urlShareDesc,
        link: res.data.urlShareUrl,
        imgUrl: res.data.urlShareImg,
        scode: res.data.scode,
      },
      false,
    );
  }
};


const Header: React.FC<Props> = ({
  setActivityTagNo,
  containerDom,
  hasTopNotice = false,
  onShowTabPop,
}) => {
  const { skin, track } = useCommonContext();
  const { navigate2H5 } = useNavigation();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [tabsData, setTabsData] = React.useState<TabProps["data"]>([]);
  const tags = useRef<TagData[]>([]);
  const [search] = useSearchParams();
  const activityTagNameFromUrl = search.get("activityTagName");

  useEffect(() => {
    queryCategoryTab4Index().then(data => {
      if (!data || data.length <= 0) {
        return;
      }
      tags.current = data;
      const tmp = data.map(item => ({
        text: item.activityTagImg ? "" : item.activityTagName,
        imgUrl: item.activityTagImg || item.programIcon,
        activityTagNameColor: item.activityTagNameColor,
        promotionLevel: item.promotionLevel || "",
      }));
      const { activityTagNo, activityTagName } = tags.current[0] || {};
      console.log("tangshang", JSON.stringify(tmp));
      setTabsData(tmp);
      if (activityTagNameFromUrl) {
        const initialTagIndex = tags.current.findIndex(
          item => item.activityTagName === activityTagNameFromUrl,
        );
        if (initialTagIndex > -1) {
          const { activityTagNo, activityTagName } = tags.current[initialTagIndex];
          setTimeout(() => {
            setSelectedTabIndex(initialTagIndex);
          }, 1000);
          setActivityTagNo(activityTagNo);
          setShareInfo({ activityTagNo, activityTagName });
          return;
        }
      }
      setActivityTagNo(activityTagNo);
      setShareInfo({ activityTagNo, activityTagName });
    });
  }, []);

  useActivate(() => {
    const { activityTagNo, activityTagName } = tags.current[selectedTabIndex] || {};
    setShareInfo({ activityTagNo, activityTagName });
  });

  const onClickTab = (index = 0) => {
    const { href, activityTagNo, activityTagName } = tags.current[index] || {};
    if (track.track) {
      track.track("resource_click", {
        page_name: "首页",
        resource_id: activityTagNo,
        resource_name: activityTagName,
        resource_type: "类目tab",
        resource_rank: index + 1,
        desc: "类目",
      });
    }
    if (href) {
      navigate2H5(RouteName.Index, {
        query: href.split("/mx-shop-micro?")[1],
      });
      return;
    };
    setActivityTagNo(activityTagNo);
    setSelectedTabIndex(index);
    setShareInfo({ activityTagNo, activityTagName });
  };

  return (
    <div
      className={styles.container}
      style={{
        zIndex: 1001,
      }}>
      <Tabs
        data={tabsData}
        bodyClassName={hasTopNotice ? styles.popupBody : ""}
        activeIndex={selectedTabIndex}
        onChange={onClickTab}
        activeColor={skin?.home?.categorySelectedTextColor}
        lineColor={skin?.home?.categorySelectedTextLineColor}
        color={skin?.home?.categoryTextColor}
        expandable={true}
        onExpand={isExpand => {
          onShowTabPop(isExpand);
          containerDom.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        bgColor={skin?.home?.searchBgColor}
        navBtnColor={skin?.home?.categoryArrowBg}
      />
    </div>
  );
};

export default Header;
