import { getBaseurl } from "@/utils/url-parse";
import { shouldAuth } from "./identity";

const reenterShop = () => {
  let url = location.href;
  const { pathname, searchParams, search, hash, origin } = new URL(url);
  if (!shouldAuth()) {
    return;
  }
  const queryParams = searchParams;
  // 移除shopId参数、distributorId参数
  ["shopId", "distributorId"].forEach(param => queryParams.delete(param));
  const newSearch = queryParams.toString();
  const newPathname = newSearch ? `${pathname}?` : pathname;
  const newUrl = new URL(newPathname + newSearch + hash, origin);
  url = newUrl.toString();
  const href = `${getBaseurl()}/api/member/auth?backUrl=${window.encodeURIComponent(url)}`;
  console.log("checkUrl", url, getBaseurl(), href);
  // eslint-disable-next-line no-restricted-syntax
  window.location.href = href;
};

// 检查url中是否有shopId，没有则重新进店
const checkUrl = () => {
  const { searchParams, pathname } = new URL(location.href);
  const queryParams = searchParams;
  const shopId = queryParams.get("shopId");
  if (!shopId || shopId === "undefined") {
    reenterShop();
  }
  return;
};

export { checkUrl, reenterShop };
