import { getShareShop } from "@/api/my/shop";
import Clickable from "@/components/clickable";
import PosterSwipe from "@/components/poster-swiper";
import RoleSwitchPopup from "@/pages/personal/components/role-switch-popup";
import { sharePosterParams4Shop } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { UseUserInfoContext } from "../../hooks/useUserInfo";
import styles from "./index.module.less";

const AdminHeader = props => {
  const { shopInfo, shopDesc, userInfo } = props;
  const { isExternal } = useCommonContext();

  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId") ?? "";
  const { handleSwitchRole } = UseUserInfoContext();
  const [showPoster, setShowPoster] = useState(false);
  const [sharePosterData, setSharePosterData] = useState<any>({});
  const [roleSwitchPoupVisible, setRoleSwitchPoupVisible] = useState(false);
  const defaultAvatarUrl =
    "https://akim-oss.aikucun.com/mshop/068e94004fd177d0aa182bf7a7831fe672dcfdb8_1710238003899_26.png";

  const onCopy = e => {
    e.stopPropagation();
    copy(userInfo.userIdCode);
    Toast.show("复制成功");
  };

  const handleRoleSwitchConfirm = switchedRole => {
    setRoleSwitchPoupVisible(false);
    if (switchedRole === 4) {
      return;
    }
    handleSwitchRole(switchedRole);
  };

  const onShowShopPoster = async () => {
    setSharePosterData(
      await sharePosterParams4Shop({
        shopId: shopId,
      }),
    );
    setShowPoster(true);
  };

  const onClosePoster = () => {
    setSharePosterData({} as any);
    setShowPoster(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    const page = document.getElementById("my-page");
    if (showPoster && page) {
      page.style.overflowY = "hidden";
    }
    if (!showPoster && page) {
      page.style.overflowY = "scroll";
    }
  }, [showPoster]);

  return (
    <>
      <div className={styles.adminHeader}>
        <div className={styles.userInfo}>
          <div className={styles.avatarWrap}>
            <img className={styles.avatar} src={shopInfo?.logoUrl || defaultAvatarUrl} alt="" />
            <Clickable
              className={styles.switch}
              onClick={() => setRoleSwitchPoupVisible(true)}
              trackData={{
                page_name: "我的",
                btn_name: "切换身份",
              }}>
              <i className={styles.iconSwitch} />
              <span>切换身份</span>
            </Clickable>
          </div>
          <div className={styles.flexColumn}>
            <div className={styles.shopName}>
              <p>{shopInfo?.name}</p>
              <i className={styles.iconAdmin} />
            </div>
            {!!shopDesc && <div className={styles.shopDesc}>{shopDesc}</div>}
            {userInfo?.userIdCode && (
              <div className={styles.userId}>
                用户编号: {userInfo.userIdCode}
                <span className={styles.copyBtn} onClick={onCopy}>
                  复制
                </span>
              </div>
            )}
          </div>
        </div>
        {!isExternal && (
          <Clickable
            className={styles.shareShop}
            onClick={() => onShowShopPoster()}
            trackData={{
              page_name: "我的",
              btn_name: "分享店铺",
            }}>
            <i className={styles.iconShare} />
            <span>分享店铺</span>
          </Clickable>
        )}
      </div>
      {/* 切换身份弹出层 */}
      <RoleSwitchPopup
        userinfo={userInfo}
        visible={roleSwitchPoupVisible}
        setVisible={setRoleSwitchPoupVisible}
        currentRole={userInfo.currentRole}
        onConfirm={handleRoleSwitchConfirm}
      />
      {/* 店铺海报 */}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        shareType="H5_SHOP"
        close={onClosePoster}
      />
    </>
  );
};

export default AdminHeader;
