import React from "react";
import styles from "../index.module.less";

interface AbnormalRenderProps {
  imageOptions: { styles: React.CSSProperties };
  onReload: () => void;
}

const AbnormalRender: React.FC<AbnormalRenderProps> = ({ imageOptions, onReload }) => {
  return (
    <div className={styles["poster-abnormal-con"]} style={imageOptions.styles}>
      <img
        className={styles["con__img"]}
        src="https://akim-oss.aikucun.com/06e2474169dc7feeb3bcb4dcc6a0199a0c2c94b2_1668404815589_98.png"
      />
      <div className={styles["con__tips"]}>海报加载失败，请重新尝试~</div>
      <div className={styles["reload-btn"]} onClick={onReload}>
        重新加载
      </div>
    </div>
  );
};

export default AbnormalRender;
