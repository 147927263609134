import InfiniteScrollContent from "@/components/InfiniteScrollContent";
import VirtualList from "@/components/virtual-list";
import { getRatio } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import { ActivityCardModel, ActivityModel } from "@akc/biz-api";
import { ActivityListModel, ItemType, useActivityList } from "@akc/biz-logic";
import { ActivityCardNormalV2Comp, CardSlotComp } from "@akc/mx-taro-h5-components";
import { InfiniteScroll, Skeleton } from "antd-mobile";
import React, { useCallback, useState } from "react";
import styles from "./ActivityList.module.less";
import B2RShopList from "./B2RShopList";
import Banner from "./Banner";
import FilterBar from "./FilterBar";

type Props = {
  activityTagNo: string;
  pageId: string;
};

const ActivityList: React.FC<Props> = ({ activityTagNo }) => {
  const { navigate2H5, navigate2Product } = useNavigation();
  const [filterInfo, setFilterInfo] = useState<any>({
    sortModel: 1,
    sortType: 2,
  });

  // 获取最近10条用户点击的店铺ID，若返回大于10条只取最后10条
  const getShopCodes = useCallback(() => {
    return [];
  }, []);

  const ratio = getRatio();

  const measureHeight = useCallback(async (_isLarge: boolean, curActivity?: ActivityListModel) => {
    if (!curActivity) {
      return 0;
    }
    switch (curActivity?.type) {
      // 单个图片资源
      case ItemType.NormalCard: {
        const model = curActivity.data as ActivityCardModel;
        return ((model.imageHeight ?? 0) / ((model.imageWidth ?? 0) / 355)) * ratio;
      }
      // 会场资源位
      case ItemType.ConferenceCard: {
        const model = curActivity.data as ActivityCardModel;
        return ((model.imageHeight ?? 0) / ((model.imageWidth ?? 0) / 355) + 50) * ratio;
      }
      // B2R店铺
      case ItemType.B2rShopCard:
        return 195 * ratio;
      // 档期列表
      default: {
        const model = curActivity.data as ActivityModel;
        if (
          model?.labelLocationVOList?.find(
            item =>
              item.locationCode === "H5_ScheduleList_BrandDown" &&
              (item.labelInfoVOList?.length ?? 0) > 0,
          ) ||
          model?.isShowEndTime
        ) {
          return 230 * ratio;
        } else {
          return 206 * ratio;
        }
      }
    }
  }, []);

  const {
    data: activityList,
    hasNext,
    onLoadMore,
    isLoading,
    error,
  } = useActivityList({
    pageId: "H5_ScheduleList",
    categoryId: activityTagNo,
    activityStatus: 2,
    sortModel: filterInfo.sortModel,
    sortType: filterInfo.sortType,
    isLarge: false,
    scene: "INDEX",
    getShopCodes,
    measureHeight,
  });

  const onDetailAction = actInfo => {
    console.log(actInfo);
    navigate2H5(actInfo.ringshopLinkUrl);
  };
  const onForwardAction = actInfo => {
    console.log(actInfo);
    navigate2H5(actInfo.ringshopLinkUrl);
  };

  return (
    <>
      <div className={styles.container}>
        <FilterBar filterInfo={filterInfo} setFilterInfo={setFilterInfo} />
        <div className={styles.waterfallWrapper}>
          <div className={styles.waterfallContainer}>
            {(activityList?.length ?? 0) === 0 && hasNext ? (
              new Array(3)
                .fill(0)
                .map((_, index) => (
                  <Skeleton key={index} animated className={styles.cardSkeleton} />
                ))
            ) : (
              <>
                <div className={styles.listWrap}>
                  <VirtualList
                    dataList={activityList ?? []}
                    childRender={item => {
                      switch (item.type) {
                        // 单个图片资源
                        case ItemType.NormalCard: {
                          return <Banner dataList={[item.data]} />;
                        }
                        // 会场资源位
                        case ItemType.ConferenceCard: {
                          return (
                            <CardSlotComp
                              activityCard={item.data}
                              onDetailAction={onDetailAction}
                              onForwardAction={onForwardAction}
                            />
                          );
                        }
                        case ItemType.B2rShopCard:
                          return (
                            <B2RShopList
                              dataList={(item.data as ActivityCardModel).shopVOList ?? []}
                            />
                          );
                        default: {
                          return (
                            <ActivityCardNormalV2Comp
                              model={item.data}
                              trackData={{
                                domain: "首页",
                              }}
                              mainLocationCode="H5_ScheduleList_BrandDown"
                              productTLLocationCode="H5_ScheduleList_TopProductPicLeft"
                              productTRLocationCode="H5_ScheduleList_TopProductPicRight"
                            />
                          );
                        }
                      }
                    }}
                    itemHeight={index => {
                      const curActivity = activityList?.[index];
                      return curActivity?.height ?? 0;
                    }}
                    marginY={8 * ratio}
                  />
                </div>
                <InfiniteScroll loadMore={onLoadMore} hasMore={hasNext} threshold={250}>
                  <InfiniteScrollContent hasMore={hasNext} />
                </InfiniteScroll>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityList;
