import {
  queryCouponShareFromPay,
  querySubscribeOfficial,
  SubscribeOfficialResponse,
  yeePayQueryOrderDetails,
  YeePayQueryOrderDetailsResponse,
} from "@/api/afterPay";
import React from "react";
import { useSearchParams } from "react-router-dom";

export const useAfterPay = () => {
  React.useEffect(() => {
    // 嵌入点金计划页面的商家小票链接均需在 HTML 的 header 中嵌入这个标签
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js";
    script.async = true;
    document.head.appendChild(script);

    // 清理工作，组件卸载时移除 script 标签
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const [searchParams] = useSearchParams();

  const [orderDetail, setOrderDetail] = React.useState<YeePayQueryOrderDetailsResponse>();
  const [subscribeRes, setSubscribeRes] = React.useState<SubscribeOfficialResponse>();
  const [subscribeVisible, setSubscribeVisible] = React.useState<boolean>();
  const [shareCouponVisible, setShareCouponVisible] = React.useState<boolean>(); // 分享券
  const [shareObj, setShareObj] = React.useState<{ link?: string; placardImgUrl?: string }>();

  // 查询推荐商品、分享券、关注助手
  const queryExtraData = React.useCallback(async (od: YeePayQueryOrderDetailsResponse) => {
    // 查询分享券信息
    const getCouponBanner = async () => {
      try {
        const { shopId, orderNo } = od;
        const res = await queryCouponShareFromPay({ shopId, orderId: orderNo });
        if (res?.placardImgUrl) {
          setShareObj(res);
          setShareCouponVisible(true);
        } else {
          setShareCouponVisible(false);
        }
      } catch (err) {
        // Do nothing
      }
    };

    // 查询公众号关注状态
    const queryShowFollowUs = async () => {
      if (!od?.userId) {
        return;
      }
      try {
        const res = await querySubscribeOfficial(od.userId);
        if (res?.isSubscribe) {
          // 已订阅，不显示
          setSubscribeVisible(false);
        } else {
          setSubscribeVisible(true);
        }
        setSubscribeRes(res);
      } catch (err) {
        // Do nothing
      }
    };
    try {
      await Promise.all([queryShowFollowUs(), getCouponBanner()]);
    } catch (err) {
      // Do nothing
    }
  }, []);

  React.useEffect(() => {
    const sub_mch_id = searchParams.get("sub_mch_id");
    const out_trade_no = searchParams.get("out_trade_no");
    const check_code = searchParams.get("check_code");

    // 查询订单
    const queryOrder = async ({ sub_mch_id, out_trade_no, check_code }) => {
      try {
        const res = await yeePayQueryOrderDetails({
          checkCode: check_code,
          outTradeNo: out_trade_no,
          subMchId: sub_mch_id,
        });
        setOrderDetail(res);
        const height = res?.isGiftOrder ? 200 : 960;
        if (res && !res.isGiftOrder) {
          // 非送礼订单，查询推荐商品、分享券、关注助手
          queryExtraData(res);
        }
        // 设置小票页面高度
        const mchData = { action: "onIframeReady", displayStyle: "SHOW_CUSTOM_PAGE", height };
        const postData = JSON.stringify(mchData);
        parent.postMessage(postData, "https://payapp.weixin.qq.com");
      } catch (err) {
        console.error("查询订单失败", err);
      }
    };
    if (sub_mch_id && out_trade_no && check_code) {
      queryOrder({ sub_mch_id, out_trade_no, check_code });
    }
  }, []);

  /// Actions

  const jumpOut = (uri: string) => {
    let url = `${process.env.REACT_APP_PAGE_URL}${uri}`;
    if (!url.includes("shopId=")) {
      url = `${url}${url.includes("?") ? "&" : "?"}shopId=${orderDetail?.shopId}`;
    }
    if (!url.includes("distributorId=")) {
      url = `${url}${url.includes("?") ? "&" : "?"}distributorId=${orderDetail?.distributorId}`;
    }
    const postData = JSON.stringify({
      action: "jumpOut",
      jumpOutUrl: url,
    });
    parent.postMessage(postData, "https://payapp.weixin.qq.com");
  };

  /**
   * 跳出到首页
   */
  const handleGoHome = () => jumpOut("/mx-shop-micro/home");

  /**
   * 跳出到订单详情页面
   */
  const handleLookOrder = () => {
    let jumpOutUrl = `/#/order/detail?orderNo=${orderDetail?.subOrderNo}`;
    if (orderDetail?.isGiftOrder) {
      jumpOutUrl = `/mx-shop-micro/gift-pay-status?orderId=${orderDetail.orderNo}&orderNos=${orderDetail.subOrderNo}`;
    }
    jumpOut(jumpOutUrl);
  };

  /**
   * 跳出到商品详情页面
   */
  const jumpOut2Product = ({ activityId, activitySpuId, selectSkuId }) => {
    const jumpOutUrl = `/#/productDetail?activityId=${activityId}&activitySpuId=${activitySpuId}&selectSkuId=${selectSkuId}`;
    jumpOut(jumpOutUrl);
  };

  /**
   * 跳出到分享券页面
   */
  const handleJumpOut2Coupon = link => jumpOut(link);

  return {
    orderDetail,
    subscribeVisible,
    subscribeRes,
    shareCouponVisible,
    shareObj,
    // 关注公众号不显示 && 分享券不显示时，显示6个商品推荐，其他显示3个
    recommandProductListVisibleCount: !subscribeVisible && !shareCouponVisible ? 6 : 3,
    handleGoHome,
    handleLookOrder,
    jumpOut2Product,
    handleJumpOut2Coupon,
  };
};
