import {
  CarrierElementType,
} from "@/api/scode/scode_params";
import { bizSubCategory, terminalType } from "@/utils/actions";
import { platform } from "@/utils/platform";
import { useMemoizedFn, useMount, useUnmount, useUpdateEffect } from "ahooks";
import { get, merge } from "lodash";
import { useMemo, useState } from "react";
import {
  BizInfo,
  CarrierContext,
  EnvContext,
  OpenPosterOptions,
  PosterSwipeProProps,
  UsePosterSwipeProOptions
} from "../types";

/**
 * usePosterSwipePro Hook
 * 
 * 提供便捷的方法来控制PosterSwipePro组件的显示和隐藏，以及配置海报参数
 * 在H5平台打开海报弹窗，在其他平台（小程序、App）将逻辑抛给外部处理
 * 
 * @param options Hook配置参数
 * @returns 包含控制方法和组件属性的对象
 * 
 * @example
 * // 基本用法
 * const poster = usePosterSwipePro({
 *   defaultBizInfo: {
 *     contentType: 'DAILY_HIGH_PRICE_AWARD_GIFT',
 *   },
 *   defaultCarrierContext: {
 *     carrierElements: [CarrierElementType.h5Poster]
 *   },
 *   onNonH5Open: (options) => {
 *     // 在非H5平台的处理逻辑
 *     if (options.platform.isApp) {
 *       // App 处理逻辑
 *     } else if (options.platform.isMini) {
 *       // 小程序处理逻辑
 *     }
 *   }
 * });
 */
export const usePosterSwipePro = (options: UsePosterSwipeProOptions = {}) => {
  // 解构默认配置
  const {
    defaultBizInfo,
    defaultEnvContext,
    defaultContentContext,
    defaultCarrierContext = { carrierElements: [CarrierElementType.h5Poster] },
    defaultUIConfig = { btnConfig: ["copy"] },
    defaultCallbacks = {},
    onNonH5Open,
  } = options;

  // 控制海报显示状态
  const [visible, setVisible] = useState(false);

  // 存储平台相关信息
  const [platformInfo, setPlatformInfo] = useState({
    bizSubCategory: bizSubCategory(),
    terminalType: "h5" // 提供一个合理的默认值
  });

  // 确保 defaultBizInfo 中的必需属性存在
  const initialBizInfo: BizInfo | undefined = useMemo(() => {
    if (!defaultBizInfo) { return undefined; }

    return {
      contentId: defaultBizInfo.contentId || "",
      contentType: defaultBizInfo.contentType || "",
      contentTypeConfig: defaultBizInfo.contentTypeConfig,
      contentDetailId: defaultBizInfo.contentDetailId,
      contentExtra: defaultBizInfo.contentExtra
    };
  }, [defaultBizInfo]);

  /**
   * 创建标准的环境上下文
   */
  const createStandardEnvContext = useMemoizedFn((baseEnvContext?: Partial<EnvContext>): EnvContext => {
    return {
      ...baseEnvContext,
      bizCategory: "XD", // 始终设置 bizCategory 为 XD
      bizSubCategory: platformInfo.bizSubCategory,
      terminalType: platformInfo.terminalType,
      createTime: new Date().getTime() // 始终设置为当前时间戳
    } as EnvContext;
  });

  /**
   * 合并 bizInfo 对象
   */
  const mergeBizInfo = useMemoizedFn((newBizInfo?: BizInfo, baseBizInfo?: BizInfo, forceOverride = false): BizInfo | undefined => {
    if (!newBizInfo) { return baseBizInfo; }
    if (forceOverride) { return newBizInfo; }

    return {
      contentId: get(newBizInfo, "contentId", "") || get(baseBizInfo, "contentId", ""),
      contentType: get(newBizInfo, "contentType", "") || get(baseBizInfo, "contentType", ""),
      contentTypeConfig: get(newBizInfo, "contentTypeConfig") || get(baseBizInfo, "contentTypeConfig"),
      contentDetailId: get(newBizInfo, "contentDetailId") || get(baseBizInfo, "contentDetailId"),
      contentExtra: get(newBizInfo, "contentExtra") || get(baseBizInfo, "contentExtra")
    };
  });

  /**
   * 合并 carrierContext 对象
   */
  const mergeCarrierContext = useMemoizedFn((newCarrierContext?: Partial<CarrierContext>, baseCarrierContext?: CarrierContext, forceOverride = false): CarrierContext => {
    const defaultCarrierElements = [CarrierElementType.h5Poster];

    if (!newCarrierContext) {
      return baseCarrierContext || { carrierElements: defaultCarrierElements };
    }

    if (forceOverride) {
      return {
        ...newCarrierContext,
        carrierElements: newCarrierContext.carrierElements || defaultCarrierElements
      } as CarrierContext;
    }

    return {
      ...baseCarrierContext,
      ...newCarrierContext,
      carrierElements: newCarrierContext.carrierElements ||
        (baseCarrierContext?.carrierElements || defaultCarrierElements)
    } as CarrierContext;
  });

  // 海报配置参数
  const [posterConfig, setPosterConfig] = useState<Omit<PosterSwipeProProps, "show">>(() => ({
    bizInfo: initialBizInfo,
    envContext: createStandardEnvContext(defaultEnvContext),
    contentContext: defaultContentContext,
    carrierContext: defaultCarrierContext,
    uiConfig: defaultUIConfig,
    callbacks: {
      ...defaultCallbacks,
      // 默认关闭回调
      close: () => {
        setVisible(false);
        defaultCallbacks?.close?.();
      }
    }
  }));

  /**
   * 更新海报配置
   * @param options 海报配置参数
   * @param forceOverride 是否强制覆盖而不是合并配置
   * @returns 更新后的配置
   */
  const updateConfig = useMemoizedFn((options: OpenPosterOptions = {}, forceOverride = false) => {
    // 处理 bizInfo
    const combinedBizInfo = mergeBizInfo(options.bizInfo, posterConfig.bizInfo, forceOverride);

    // 处理 callbacks，确保关闭回调始终存在
    const combinedCallbacks = forceOverride && options.callbacks
      ? {
        ...options.callbacks,
        close: () => {
          setVisible(false);
          options.callbacks?.close?.();
        }
      }
      : {
        ...posterConfig.callbacks,
        ...options.callbacks,
        close: () => {
          setVisible(false);
          options.callbacks?.close?.();
          posterConfig.callbacks?.close?.();
        }
      };

    // 创建新的配置对象
    const newConfig: Omit<PosterSwipeProProps, "show"> = forceOverride
      ? {
        bizInfo: combinedBizInfo,
        envContext: createStandardEnvContext(options.envContext),
        contentContext: options.contentContext,
        carrierContext: mergeCarrierContext(options.carrierContext, undefined, true),
        uiConfig: options.uiConfig,
        callbacks: combinedCallbacks,
        customEditData: options.customEditData,
      }
      : {
        ...posterConfig,
        bizInfo: combinedBizInfo,
        envContext: createStandardEnvContext(merge({}, posterConfig.envContext, options.envContext)),
        contentContext: options.contentContext || posterConfig.contentContext,
        carrierContext: mergeCarrierContext(options.carrierContext, posterConfig.carrierContext),
        uiConfig: options.uiConfig ? merge({}, posterConfig.uiConfig, options.uiConfig) : posterConfig.uiConfig,
        callbacks: combinedCallbacks,
        customEditData: options.customEditData || posterConfig.customEditData,
      };

    // 更新状态
    setPosterConfig(newConfig);

    // 返回新的配置，以便立即使用
    return newConfig;
  });

  /**
   * 打开海报
   * @param options 海报配置参数
   * @param forceOverride 是否强制覆盖而不是合并配置
   */
  const open = useMemoizedFn((options: OpenPosterOptions = {}, forceOverride = false) => {
    if (platform.isH5) {
      // 更新配置并立即使用新的配置
      updateConfig(options, forceOverride);
      // 显示弹窗
      setVisible(true);
    } else if (onNonH5Open) {
      // 在非H5平台，将逻辑抛给外部处理
      const combinedBizInfo = mergeBizInfo(options.bizInfo, posterConfig.bizInfo, forceOverride);
      const combinedEnvContext = createStandardEnvContext(
        forceOverride ? options.envContext : merge({}, posterConfig.envContext, options.envContext)
      );
      const combinedCarrierContext = mergeCarrierContext(
        options.carrierContext,
        posterConfig.carrierContext,
        forceOverride
      );

      onNonH5Open({
        ...(!forceOverride ? options : {}),
        bizInfo: combinedBizInfo,
        envContext: combinedEnvContext,
        carrierContext: combinedCarrierContext,
        // 将 platform 作为参数传递出去
        platform: {
          isH5: platform.isH5,
          isApp: platform.isApp,
          isMini: platform.isMini
        }
      });
    }
  });

  /**
   * 关闭海报
   */
  const close = useMemoizedFn(() => {
    setVisible(false);
    posterConfig.callbacks?.close?.();
  });

  /**
   * 异步获取平台信息
   */
  useMount(() => {
    const fetchPlatformInfo = async () => {
      try {
        const type = await terminalType();
        setPlatformInfo({
          bizSubCategory: bizSubCategory(),
          terminalType: type
        });
      } catch (error) {
        console.error("获取平台信息失败:", error);
      }
    };

    fetchPlatformInfo();
  });

  // 清理副作用
  useUnmount(() => {
    // 确保在组件卸载时关闭海报
    setVisible(false);
  });

  /**
   * 当平台信息更新时，更新海报配置
   */
  useUpdateEffect(() => {
    // 仅当平台信息变化时更新配置
    setPosterConfig(prev => ({
      ...prev,
      envContext: createStandardEnvContext(prev.envContext)
    }));
  }, [platformInfo]);

  /**
   * 组件属性
   */
  const props = useMemo(() => ({
    ...posterConfig,
    show: visible
  }), [posterConfig, visible]);

  // 返回控制方法和组件属性
  return {
    // 控制方法
    open,
    close,
    updateConfig,
    // 组件状态
    visible,
    // 组件属性
    props,
    // 平台信息
    platformInfo
  };
};
