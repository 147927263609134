import { getBaseurl } from "@/utils/url-parse";
import { Request } from "../request";

import { AIMaterialMediaModel, MaterialModuleModel, PageableResponse } from "@akc/biz-api";

export async function queryMaterialPageList(params) {
  params.scene = "MORE_MATERIAL";
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v2/material/create/materialPageList`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export async function queryMaterialTabs(params) {
  params.scene = "MORE_MATERIAL";
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v2/material/querySelectedMaterialTab`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export async function queryMaterialFilter(params) {
  params.scene = "MORE_MATERIAL";
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v2/material/filter`,
    method: "post",
    data: params,
  });
  return res?.data;
}


// 分页数据类型定义
  /**
   * 响应数据
   * @property {number} pageIndex - 当前页码
   * @property {number} pageSize - 每页显示条数
   * @property {number} currentPageSize - 当前页显示条数
   * @property {number | string} total - 总条数
   * @property {number} totalCount - 总条数
   * @property {number} pages - 总页数
   * @property {AIMaterialMediaModel[]} result - 图文内容列表
   * @property {boolean} hasNext - 是否有下一页
   */
export interface IMaterialRsp {
  pageIndex: number;
  pageSize: number;
  currentPageSize: number;
  total: number | string;
  totalCount: number;
  pages: number;
  result: AIMaterialMediaModel[];
  hasNext: boolean;
}
/**
 * 查询图文内容分页列表
 */
export async function queryMaterialContentPage(params: {
  contentType?: number;
  itemCode?: string;
  activitySpuCode?: string;
  labelId?: number | string;
  publish?: number;
  pageIndex?: number;
  pageSize?: number;
}): Promise<IMaterialRsp> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/material/content/page`,
    method: "post",
    data: params,
  });
  return res?.data;
}
// 标签类型定义
export interface ILabelItem {
  labelName?: string;
  labelId?: number | string;
}
/**
 * 查询图文标签列表
 */
export async function queryCloudAlbumLabelTable(params: {
  userId?: string;
  itemCode?: string;
  publish?: number;
}) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/material/queryCloudAlbumLabelTable`,
    method: "post",
    data: params,
  });
  return res?.data;
}


export type MaterialParam = {
  pageId: string;
  objectIds: string[];
  pageNo: number;
  pageSize: number;
  queryType: number;
  useType: number;
};

export async function queryMaterial(
  params: MaterialParam,
): Promise<PageableResponse<MaterialModuleModel>> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/materialUse/v1/queryTopMaterialPage`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export type SimpleMaterial = {
  materialContent?: string;
  materialPhoto?: Array<{
    imageUrl: string;
  }>;
  outSideType: number;
};

export async function queryMaterialSimple(activityId): Promise<SimpleMaterial[]> {
  const res = await Request({
    url: `${getBaseurl()}/api/commodity/material/list/simple/${localStorage.getItem(
      "shopId",
    )}/${activityId}?relationActivityType=1`,
    method: "get",
  });
  return res.data.materialList.map(item => ({ ...item, outSideType: res.data.outSideType }));
}

export async function getBrandMetarialList(params) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v2/material/create/materialPageList`,
    method: "post",
    data: params,
  });
}
