import { ProductDetailModel } from "@akc/biz-api";
import { Image, Popup } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
type Props = {
    productDetail?: ProductDetailModel;
    visible?: boolean;
    onClose?: () => void;
};

/**
 * 送礼说明提示弹窗
 */
const GiveGiftDialog: React.FC<Props> = props => {
    const descList = [
        {
            title: "送礼是什么？",
            desc: "“送礼物”是平台推出的一项支持用户为好友在线送礼的功能",
        },
        {
            title: "如何送礼？",
            desc: "用户在线上挑选「支持送礼」的商品并赠送分享给到指定好友，好友打开礼物后填写收货地址并成功提交订单，即可完成礼物的接收",
        },
        {
            title: "送礼注意事项",
            desc: "1. 单次送礼只能送给一个人<br/>2. 收礼人打开礼包并填写地址后算作收礼完成<br/>3. 付款人下单24小时后，礼物无人领取订单将自动取消，付款金额自动退回付款人账户",
        },
    ];
    return (
        <Popup
            visible={props.visible}
            onMaskClick={() => {
                props.onClose?.();
            }}
            bodyStyle={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                zIndex: 10000,
            }}>
            <div className={styles.giveGiftDialog}>
                <div className={styles.top}>
                    <div className={styles.title}>送礼说明</div>
                    <Image
                        className={styles.close}
                        src="https://devdevnew.obs.cn-east-3.myhuaweicloud.com/taro/resources/close_black.png"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    />
                </div>
                <div className={styles.list}>
                    {descList.map((e, index) => {
                        return (
                            <div key={index} className={styles.content}>
                                <div className={styles.contentTitle}>{e.title}</div>
                                <div className={styles.contentDesc} dangerouslySetInnerHTML={{ __html: e.desc }}></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Popup>
    );
};

export default GiveGiftDialog;
