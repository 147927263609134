import { Swiper, SwiperRef } from "antd-mobile";
import React from "react";

const ReplaceSwiperComp = React.forwardRef<any, any>((props, ref) => {
  if (!props.children || (Array.isArray(props.children) && props.children.length === 0)) {
    return null;
  }

  const swiperRef = React.useRef<any>(null);

  React.useEffect(() => {
    ((ref as any) ?? swiperRef)?.current?.swipeTo(props.current ?? 0);
  }, [props.current]);

  return (
    <Swiper
      ref={r => {
        swiperRef.current = r;
        if (ref) {
          (ref as any).current = r;
        }
      }}
      defaultIndex={props.current}
      indicator={props.indicatorDots}
      autoplay={props.autoplay}
      loop={props.circular}
      direction={props.vertical ? "vertical" : "horizontal"}
      stuckAtBoundary={props.stuckAtBoundary}
      slideSize={props.slideSize}
      onIndexChange={idx => {
        props.onChange?.({ detail: { current: idx } });
      }}>
      {props.children.map((item: any) => (
        <Swiper.Item key={item}>{item}</Swiper.Item>
      ))}
    </Swiper>
  );
});

export default ReplaceSwiperComp;
