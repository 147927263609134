import React from "react";
import styles from "./GiftPanel.module.less";

const GiftPanelBase = ({ dataSource, onClose }) => {
  const handleButtonClick = () => {
    // 如果有 onClose 回调函数，则调用它
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <div className={styles.giftPanelBase}>
      <div className={styles.product}>
        <img src={dataSource?.productImg} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{dataSource?.productTitle || "小店"}</div>
        <div>赠送你的奖品</div>
      </div>
      <div className={styles.footer}>
        <div className={styles.btn} onClick={handleButtonClick}></div>
      </div>
    </div>
  );
};

const GiftPanelOther = ({ dataSource, onClose }) => {
  const handleButtonClick = () => {
    // 如果有 onClose 回调函数，则调用它
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <div className={styles.giftPanelOther}>
      <div className={styles.title}>
        <div>恭喜今日中奖用户</div>
        <div>{dataSource.userName}</div>
      </div>
      <div className={styles.product}>
        <img src={dataSource?.productImg} alt="" />
      </div>
      <div className={styles.footer}>
        <div className={styles.btn} onClick={handleButtonClick}></div>
      </div>
    </div>
  );
};


const GiftPanel = ({ type = "default", dataSource = {}, highDataSource = {}, onClose }) => {

  return (
    <>
      {
        type === "default"
          ? <GiftPanelBase dataSource={dataSource} onClose={onClose} />
          : <GiftPanelOther dataSource={highDataSource} onClose={onClose} />
      }
    </>
  );
};


export default GiftPanel;