import { onClosePage } from "@/utils/page_utils";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import React from "react";
import styles from "./index.module.less";

interface Props {
  text?: any;
}

const BackView: React.FC<Props> = (props: Props) => {
  const { text } = props;


  const { navigate2Product, navigateBack } = useNavigation();

  const getStatusBar = () => {
    if (platform.isApp) {
      AKJS.ready().then(() => {
        AKJS.info.device().then((res: any) => {
          return (res?.data?.statusBarHeight || 50);
        });
      });
    }
    return 50;
  };

  const goBack = () => {
    if (platform.isApp) {
      onClosePage();
    } else {
      navigateBack();
    }
  };

  return (

    <div className={styles.container} onClick={() => goBack()}>
      <img
        className={`${styles.ic_back} `}
        style={{ marginTop: getStatusBar() }}
        src="https://akim-oss.aikucun.com/11d024154013c0ca47dfaeca33ecdeb6a5b9fcb6_1741862236773_33.png"
      />
    </div>
  );
};

export default BackView;
