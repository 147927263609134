import { platform } from "@/utils/platform";

/**
 * 内容类型
 */
export enum ContentType {
  // 热卖榜单
  toplist = "HOT_LIST",
  // 素材
  material = "MATERIAL",
  // skc商品
  product = "PRODUCT",
  // 档期活动
  activity = "ACTIVITY",
  // 超级会场
  superBrand = "SUPER_BRAND",
  // 普通会场
  brand = "BRAND",
  // 直播
  live = "LIVE",
  // 营销活动
  promoActivity = "PROMO_ACTIVITY",
  // 券
  coupon = "COUPON",
  // 团长拼团
  groupon = "TUANGROUPON",
  // 团单提货
  grouponPick = "TUANGROUPONPICK",
  // 品牌
  shareBrand = "SHARE_BRAND",
  // 类目
  cartegory = "SHARE_CATEGORY",
  // SPU商品
  spuProduct = "SPU_PRODUCT",
  // 店铺分享券
  shopCoupon = "SHOP_COUPON",
  // 悦己图书会场转发
  bookConference = "DREAM_BOOK_CONFERENCE",
  // 会场3.0 - 搭建会场
  conference3 = "CONFERENCE3",
  // 店铺装修
  decorate = "STORE",
  // 品牌馆
  market = "BRAND_RECOMMEND",
  // 店铺列表
  list = "BRAND_LIST",
  // 档期详情-全部
  activityAggr = "ACTIVITY_AGGREGATION",
  // R开放体系-落地页
  b2r = "B2R",
  // 日日奖
  dailyReward = "DAILY_AWARD",
  // 日日奖清单
  dailyRewardGift = "DAILY_AWARD_GIFT",
  // 日日奖-高价值海报
  dailyHighPriceAwardGift = "DAILY_HIGH_PRICE_AWARD_GIFT",
  //商品问答
  productAnswer = "PRODUCT_ANSWER",
  // 用户送礼
  gift = "ORDER_SEND_GIFT",
  // 类目榜单
  categoryRank = "HOT_LIST_CATEGORY",
  // 榜单中心
  rankCenter = "HOT_LIST_TOTAL",
  // 购物车
  CART_PRODUCT = "CART_PRODUCT",
}

/**
 * 载体类型
 */
export enum CarrierElementType {
  // h5海报
  h5Poster = "h5POSTER",
  // 小程序海报
  miniPoster = "MINIPOSTER",
  // h5复制链接
  linkUrl = "LINKURL",
  // h5链接
  h5Link = "h5LINK",
  // 小程序卡片
  miniCard = "MINICARD",
  // 小程序复制链接
  copyMiniLink = "COPYMINILINK",
  // h5推送消息
  h5PushMessage = "h5PushMessage",
  // 小程序推送消息
  miniPushMessage = "miniPushMessage",
}

/**
 * 来源场景
 */
export enum SourceScene {
  // 1、超级会场
  superBrand = "SUPER_BRAND",
  // 2、普通会场
  brand = "BRAND",
  // 3、热销榜单
  toplist = "TOP_LIST",
  // 4、档期活动
  activity = "ACTIVITY",
  // 5、直播间
  live = "LIVE",
  // 6、拼团
  groupBuy = "GROUP_BUY",
  // 7、搜索
  search = "SEARCH",
  // 8、商品详情
  product = "PRODUCT_DETAIL",
  // 9、转发助手
  proxy = "PROXY",
  // 10、智能播货助手
  intelligent = "INTELLIGENT",
  // 11、素材
  material = "MATERIAL",
  // 12、灵魂引擎
  soul = "SOUL",
  // 13、乡村振兴（证书、会场、商品）
  revitalization = "RURAL_REVITALIZATION",
  // 14、帖子内容
  content = "CONTENT",
  // 15、今日必卖
  bestSeller = "BEST_SELLER",
  // 16、一键转链
  transfer = "TRANSFER",
  // 17、品牌
  shareBrand = "SHARE_BRAND",
  // 18、类目
  category = "SHARE_CATEGORY",
  // 19、会场3.0
  conference3 = "CONFERENCE3",
  // 20、店铺装修
  decorate = "STORE",
  //21、品牌馆
  market = "BRAND_RECOMMEND",
  //22、店铺列表
  list = "BRAND_LIST",
  //23、H5店铺
  h5shop = "H5_SHOP",
  //24、选择性转发
  selective = "SELECTIVE_FORWARDING_PAGE",
  // 日日奖
  dailyReward = "DAILY_AWARD",
  // 日日奖清单
  dailyRewardGift = "DAILY_AWARD_GIFT",
  //素材商详
  productMaterial = "MATERIAL_PRODUCT_DETAIL",
  DAILY_HIGH_PRICE_AWARD_GIFT = "DAILY_HIGH_PRICE_AWARD_GIFT",
  //商品问答
  productAnswer = "PRODUCT_ANSWER",
  // 购物车
  CART_PRODUCT = "CART_PRODUCT",
}

/**
 * 常规 bizInfo
 */
export interface NormalBizInfo {
  // 内容类型
  contentType: ContentType;

  // 内容id
  contentId?: string | null;

  // 必须场景 contentType: ACTIVITY
  //   1: 转发档期的最低价的sku
  //   null : 老版本逻辑(灰度/低版本使用) 0: 老版本逻辑(灰度/低版本使用)
  // 必须场景 contentType: SPU_PRODUCT
  //  1: 转发商品的最低价的sku
  contentTypeConfig?: string;

  // 场景 contentType: SPU_PRODUCT 值 contentDetailId=商品SkuID
  contentDetailId?: string;

  // 内容扩展信息, key:value结构
  contentExtra?: Record<string, any>;
}

/**
 * 海报 bizInfo
 */
export interface PosterBizInfo extends NormalBizInfo {
  // 海报模板
  templateNo?: string;
}

/**
 * 基础要素
 */
export interface EnvContext {
  bizCategory: string;
  bizSubCategory: string;
  terminalType: string;
  sourceScene?: SourceScene | string;
  sourceSceneId?: string;
  createTime?: number;
  shareFrom?: string;
  scodeType?: string;
  targetTerminalType?: string;
}

/**
 * 内容要素
 */
export interface ContentContext {
  // 内容类型
  contentType?: NormalBizInfo["contentType"];

  // 内容id
  contentId?: NormalBizInfo["contentId"];

  // 内容扩展信息, key:value结构
  contentExtra?: Record<string, any>;
}

/**
 * 载体类型
 */
export interface CarrierContext {
  // 载体类型
  carrierElements: CarrierElementType[];
  posterInfo?: {
    // 载体如果是海报：海报编号 POSTER_CODE（用户选择或默认策略指定）业务子类_DEFAULT
    posterCode?: string;
    // 载体如果是海报：海报版本 POSTER_VERSION（仅限爱库存转发）0
    posterVersion?: string;
  };
}

export interface NormalScodeParams {
  envContext: EnvContext;
  contentContext?: ContentContext;
  carrierContext: CarrierContext;
}

export type BizInfoExtra = {
  brandId?: string;
  queryType?: string | null; // 档期-全部分割类型 1:黑+白; 2:黑/白
  activityId?: string;
  sharePageId?: string;
  awardName?: string;
  awardType?: string;
};

const bizSubCategory = () => {
  const { isApp, isMini } = platform;
  return isApp ? "akcApp" : isMini ? "xdApplets" : "xdWxH5";
};
const terminalType = () => {
  const { isApp, isMini } = platform;
  return isApp ? (platform.isAndroid ? "android" : "iOS") : isMini ? "applets" : "h5";
};

export const bizInfoParams = (props: {
  contentType: ContentType;
  contentId?: string | null;
  contentDetailId?: string;
  extra?: BizInfoExtra;
}): NormalBizInfo => {
  return {
    contentType: props.contentType,
    contentId: props.contentId,
    contentDetailId: props?.contentDetailId,
    contentExtra: {
      share_store_brandId: props.extra?.brandId,
      queryType: props.extra?.queryType,
      shareActivityId: props.extra?.activityId,
      sharePageId: props.extra?.sharePageId,
      awardName: props.extra?.awardName,
      awardType: props.extra?.awardType,
    },
  };
};

export const envContextParams = (props: {
  sourceScene?: SourceScene;
  sourceSceneId?: string;
}): EnvContext => ({
  bizCategory: "XD",
  bizSubCategory: bizSubCategory(),
  terminalType: terminalType(),
  sourceScene: props.sourceScene,
  sourceSceneId: props.sourceSceneId,
  createTime: new Date().getTime(),
});

export const carrierContextParams = (
  carrierElements: CarrierElementType[],
  posterCode?: string,
  posterVersion?: string,
): CarrierContext => ({
  carrierElements: carrierElements,
  posterInfo: {
    posterCode,
    posterVersion,
  },
});

export type NormalParamsType = {
  contentType?: ContentType;
  carrierElements: CarrierElementType[];
  sourceScene?: SourceScene;
  sourceSceneId?: string;
  posterCode?: string;
  posterVersion?: string;
  extra?: BizInfoExtra;
};
