import { TabBar } from "@/components/tab-bar";
import { checkUrl } from "@/utils/checkUrl";
import { TaroCommonContextProvider } from "@/utils/context/basic";
import { setupGrayService } from "@/utils/gray";
import { SubAppEventName, useSubAppMessage } from "@/utils/hooks/useSubAppMessage";
import { resetRem } from "@/utils/rem";
import Track from "@/utils/track";
import { wxShare } from "@/utils/wx-share";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { TrackData } from "@akc/taro-service";
import microApp from "@micro-zoe/micro-app";
import React, { lazy, useEffect, useState } from "react";
import KeepAlive, { autoFixContext, useActivate } from "react-activation";
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

export enum RouteName {
  Index = "Index",
  Activity = "Activity",
  Decorate = "Decorate",
  JoinBill = "JoinBill",
  SecKill = "SecKill",
  SecKill2 = "SecKill2",
  CouponList = "CouponList",
  TopList = "TopList",
  Logistics = "Logistics",
  MicroAppDetail = "MicroAppDetail",
  MicroAppDetail2 = "MicroAppDetail2",
  HighCommission = "HighCommission",
  Categroy = "Categroy",
  ShopInfo = "ShopInfo",
  ShowImages = "ShowImages",
  Market = "Market",
  MarketSearch = "MarketSearch",
  Focus = "Focus",
  ShopList = "ShopList",
  Promote = "Promote",
  PromoteDetail = "PromoteDetail",
  RecommendList = "RecommendList",
  MediaList = "MediaList",
  EnticeSearch = "EnticeSearch",
  VideoShare = "VideoShare",
  My = "My",
  MySetting = "MySetting",
  Personal = "Personal",
  PersonalSetting = "PersonalSetting",
  Home = "Home",
  VideoSharerInstruction = "VideoSharerInstruction",
  NewPerson = "NewPerson",
  Logout = "Logout",
  Material = "Material",
  PerformanceBoard = "PerformanceBoard",
  HistoryData = "HistoryData",
  ShareSetting = "ShareSetting",
  Search = "Search",
  SelectiveForward = "SelectiveForward",
  SelectivePosters = "SelectivePosters",
  DispatchTask = "DispatchTask",
  UserTrack = "UserTrack",
  RewardCenter = "RewardCenter",
  RewardCenterDetail = "RewardCenterDetail",
  RewardCenterDetailDistributor = "RewardCenterDetailDistributor",
  RewardCenterFillAddress = "RewardCenterFillAddress",
  RewardCenterLanding = "RewardCenterLanding",
  RewardSearch = "RewardSearch",

  AftersaleApp = "aftersaleApp",
  CschatApp = "cschatApp",
  YXCalendar = "yxCalendar",
  YXInvite = "yxInvite",
  YXSignUp = "yxSignUp",
  YXHistory = "yxHistory",
  YXTravelConfirm = "yxTravelConfirm",
  HideSetting = "HideSetting",
  HideSettingSearch = "HideSettingSearch",
  SaleCalendar = "SaleCalendar",
  DailyReward = "DailyReward",
  MyReward = "MyReward",
  RewardOrder = "RewardOrder",
  CustomerReward = "CustomerReward",
  RedPacketDetail = "RedPacketDetail",

  ProductDetail = "ProductDetail",
  ProductDetailMaterial = "ProductDetailMaterial",
  ImageSearch = "ImageSearch",
  BrandBole = "BrandBole",
  GoldMedal = "GoldMedal",
  Achievement = "Achievement",
  YearEndBonusLanding = "YearEndBonusLanding",
  YearEndBonusSearch = "YearEndBonusSearch",
  YearEndBonusDetail = "YearEndBonusDetail",
  YearEndBonusRank = "YearEndBonusRank",
  Empowerment = "Empowerment",
  AfterPay = "AfterPay",

  BrandMaterial = "BrandMaterial",
  TradeSnapshotList = "TradeSnapshotList",
  TradeSnapshotDetail = "TradeSnapshotDetail",
  CommentList = "CommentList",
  JoinOrderNX = "JoinOrderNX", // 多买优惠，原 /#/goodsOfNpieceXfold

  RankScale = "RankScale",
  RankAll = "RankAll",
  QuestionsAnswers = "QuestionsAnswers",// 全部问答
  GiftPayStatus = "GiftPayStatus",
  GiftList = "GiftList",
  GiftNotice = "GiftNotice",
  ChangePhone = "ChangePhone",
  SizeInfo = "SizeInfo", // 更多尺码参考
  CartShare = "CartShare",
  ShopAuthGuide = "ShopAuthGuide",
}

// react-actication@0.8.0 版本配合 React 17+ 需要做如下调整以达修复对 Context 的破坏性影响
autoFixContext(
  [require("react/jsx-runtime"), "jsx", "jsxs", "jsxDEV"],
  [require("react/jsx-dev-runtime"), "jsx", "jsxs", "jsxDEV"],
);

export const TrackDataComp: React.FC<{ children?: React.ReactNode; }> = props => {
  const [trackData, setTrackData] = useState<TrackData>({});

  useActivate(() => {
    // 激活时，刷入页面 trackData 缓存
    setTrackData(trackData);
  });
  return (
    <TrackDataContext.Provider
      value={{
        trackData,
        setTrackData,
      }}>
      {props.children}
    </TrackDataContext.Provider>
  );
};

export interface KeepAliveProps {
  element: React.ReactNode; //入口react节点
  name: string; // 缓存页面的key
  keepAliveList?: string[]; // 缓存页面的key,会从路由上去取
}

export const KeepAliveContainer: React.FC<KeepAliveProps> = (props: KeepAliveProps) => {
  const { element, name, keepAliveList } = props;
  const [search] = useSearchParams();
  const params = useParams();
  let cacheKey = name;
  if (keepAliveList && keepAliveList.length > 0) {
    cacheKey = keepAliveList.reduce((prev, cur) => {
      return prev + "-" + (search.get(cur) ?? params[cur]);
    }, name);
  }
  return (
    <KeepAlive cacheKey={cacheKey} saveScrollPosition="screen">
      <TrackDataComp>{element}</TrackDataComp>
    </KeepAlive>
  );
};

// 路由懒加载
export const lazyLoad = (path: string) => {
  const Comp = lazy(() => import(`@/pages/${path}`));
  return (
    <React.Suspense
      fallback={
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}></div>
      }>
      <Comp />
    </React.Suspense>
  );
};

export const Layout: React.FC = () => {
  const [currentPath, setCurrentPath] = useState("");
  const location = useLocation();
  const track = Track();
  const navigater = useNavigate();

  useEffect(() => {
    setupGrayService();
  }, []);

  const getBaseNavi = () => {
    return navigater;
  };

  const getWechatShare = () => {
    return wxShare;
  };

  useEffect(() => {
    const href = decodeURIComponent(window.location.href.replace(/%/g, "%25"));
    if (href.indexOf("/mx-shop-micro") > -1) {
      setTimeout(() => {
        const tmp = decodeURIComponent(window.location.href.replace(/%/g, "%25"));
        if (tmp.indexOf("/mx-shop-micro") > -1 && tmp.endsWith("#/")) {
          const newUrl = tmp.split("#")[0];
          history.replaceState(null, document.title, newUrl);
        }
      }, 0);
    }

    setCurrentPath(location.pathname + location.hash);
    if (location.hash?.startsWith("#/index?")) {
      microApp.setData("h5shop", {
        type: "scroll-recover",
        target: "index",
        getBaseNavi,
        getWechatShare,
        resetRem,
      });
    }
    // 检测url,检测失败则重新进店
    checkUrl();
  }, [location]);

  useSubAppMessage(SubAppEventName.nav, data => setCurrentPath(data));
  useSubAppMessage(SubAppEventName.switchRole, data => console.log("switchRole~~~~~~~~~~", data));

  //监听路由变化时是否需要设置overflow
  //单独给会场3.0设置下domain=CONFERENCE3
  useEffect(() => {
    if (
      location.pathname.includes("/mx-shop-micro/activity/") ||
      location.pathname === "/mx-shop-micro/decorate" ||
      location.pathname === "/mx-shop-micro/decorate/profit" ||
      location.pathname === "/mx-shop-micro/home" ||
      location.pathname === "/mx-shop-micro/daily-reward" ||
      location.pathname === "/mx-shop-micro/product-material" ||
      location.pathname === "/mx-shop-micro/product" ||
      location.pathname === "/mx-shop-micro/trade-snapshot/detail"
    ) {
      document.body.style.overflow = "unset";
    } else if (location.hash.includes("#/")) {
      document.body.style.overflow = "hidden auto";
    } else {
      document.body.style.overflow = "hidden";
    }
    if (location.pathname === "/mx-shop-micro") {
      track.registerSuperProperty({
        domain: "CONFERENCE3",
      });
    } else {
      track.registerSuperProperty({
        domain: "",
      });
    }
  }, [location]);

  return (
    <>
      <TaroCommonContextProvider>
        <main>
          <Outlet />
        </main>
        <TabBar currentPath={currentPath} />
      </TaroCommonContextProvider>
    </>
  );
};
