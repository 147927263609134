import React from "react";
import styles from "./Certificate.module.less";


// 别人的高价值红包
const CertificateOfOther = ({ dataSource, onClose }) => {
  const handleButtonClick = () => {
    // 如果有 onClose 回调函数，则调用它
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <div className={styles.certificateOther}>
      <div className={styles.transform}>
        <div className={styles.certificateContent}>
          <div className={styles.title}>
            {`恭喜幸运用户${dataSource.userName || ""}`}
          </div>
          <div className={styles.certificateContentMain}>
            <div className={styles.amount}>
              <span className={styles.amountTextOther}>{dataSource?.amount || "0"}
                <div className={styles.amountSuffix}>
                  <span className={styles.amountSuffixUnitOther}>元</span>
                </div>
              </span>
            </div>
            <div className={styles.brandWrapper}>
              <span className={styles.brandName}>{dataSource.brandName || ""}</span>
              <span className={styles.brandSuffix}>品牌赠送</span>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.btn} onClick={handleButtonClick}></div>
          </div>
        </div>
      </div>
    </div>
  );
};


// 自己的高价值红包
const CertificateBase = ({ dataSource, onClose }) => {
  console.log("dataSource", dataSource);
  const handleButtonClick = () => {
    // 如果有 onClose 回调函数，则调用它
    if (typeof onClose === "function") {
      onClose();
    }
  };
  const expiredMessage = dataSource?.redPacketAward?.expiredMessage;

  return (
    <div className={styles.certificateBase}>
      <div className={styles.transform}>
        <div className={styles.certificateContent}>
          <div className={styles.amount}>
            <span className={styles.amountText}>{dataSource?.redPacketAward?.amount}
              <div className={styles.amountSuffix}>
                {!!expiredMessage &&
                  <div className={styles.amountSuffixText}>
                    {expiredMessage}
                  </div>
                }
                <span className={styles.amountSuffixUnit}>元</span>
              </div>
            </span>
          </div>
          <div className={styles.certificateContentMain}>
            <div className={styles.titleWrapper}>
              {`${dataSource?.redPacketAward?.hotBrandInfo?.brandName}`}
            </div>
            <div className={styles.title}>送你的通用红包</div>

          </div>
          <div className={styles.footer}>
            <div className={styles.btn} onClick={handleButtonClick}></div>
          </div>
        </div>
      </div>
    </div>
  );
};


const Certificate = ({ type = "default", dataSource = {}, highDataSource = {}, onClose }) => {
  return (
    <>
      {
        type === "default"
          ? <CertificateBase dataSource={dataSource} onClose={onClose} />
          : <CertificateOfOther dataSource={highDataSource} onClose={onClose} />
      }
    </>
  );
};


export default Certificate;