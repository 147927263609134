import {
  bindPhoneNum,
  bindPhoneNumCancel,
  bindPhoneNumConfirm,
  bindUserPhone,
  getSmsCode,
  sendSms,
} from "@/api/my";
import { showCaptcha } from "@/utils/captcha/captcha";
import { useCommonContext } from "@/utils/context/common";
import { Button, Form, Input, Mask, Popup, Toast } from "antd-mobile";
import { FormInstance } from "antd-mobile/es/components/form";
import React, { useRef } from "react";
import styles from "./index.module.less";
interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onFinish?: React.Dispatch<React.SetStateAction<number>>;
}
const BindPhonePopup: React.FC<IProps> = ({ visible, setVisible, onFinish }) => {
  const { userVisitInfo, reloadUserVisitInfo } = useCommonContext();
  const formRef = useRef<FormInstance>(null);
  const [isSubmitCode, setIsSubmitCode] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [countDown, setCountDown] = React.useState(60);
  const sendCaptchaToken = React.useRef<string | undefined>("");
  const [bindCaptchaToken, setBindCaptchaToken] = React.useState<string | undefined>("");
  const sendRcsTraceId = React.useRef<string | undefined>("");
  const [bindRcsTraceId, setBindRcsTraceId] = React.useState<string | undefined>("");
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [alreadyInUse, setAlreadyInUse] = React.useState<any>({});

  const handleClickGetCode = async () => {
    if (isSending) {
      return;
    }
    setIsSubmitCode(true);
    const number = formRef?.current?.getFieldValue(["number"]);
    const isValid = formRef?.current?.getFieldError(["number"]).length === 0;
    if (number !== "" && isValid) {
      setIsSending(true);
      let count = 60;
      const timer = setInterval(() => {
        if (count === 1) {
          setIsSending(false);
          setCountDown(60);
          clearInterval(timer);
          return;
        }
        count--;
        setCountDown(count);
      }, 1000);
      let res: any = null;
      if (userVisitInfo?.isVisitor) {
        // 游客未绑定
        res = await getSmsCode(
          { phone: number, captchaToken: sendCaptchaToken.current || undefined },
          sendRcsTraceId.current,
        );
        const { code, data } = res || {};
        if (code === 770100) {
          const tcp = data.tcp;
          showCaptcha(tcp).then(token => {
            sendCaptchaToken.current = token;
            sendRcsTraceId.current = data.rcsTraceId;
            handleClickGetCode();
          });
        }
        if (code === 770000) {
          Toast.show(res.message);
          return;
        }
      } else if (userVisitInfo?.isBindPhone === false) {
        // 非游客未绑定
        res = await sendSms(
          { phone: number, type: 6, captchaToken: sendCaptchaToken.current || undefined },
          sendRcsTraceId.current,
        );
        const { code, data } = res || {};
        if (code === 770100) {
          const tcp = data.tcp;
          showCaptcha(tcp).then(token => {
            sendCaptchaToken.current = token;
            sendRcsTraceId.current = data.rcsTraceId;
            handleClickGetCode();
          });
        }
      }

      if (res?.success) {
        Toast.show("发送成功");
      }
    }
  };
  const getCodeBtnElement = () => {
    if (!isSubmitCode) {
      return (
        <div className={styles["code-text"]} onClick={handleClickGetCode}>
          获取验证码
        </div>
      );
    } else {
      return (
        <div
          className={isSending ? styles["code-text-sending"] : styles["code-text"]}
          onClick={handleClickGetCode}>{`重新发送${isSending ? `(${countDown}s)` : ""}`}</div>
      );
    }
  };
  const bindSuccessed = () => {
    Toast.show("绑定成功");
    sessionStorage.setItem("updateBindingInfoFlag", "1");
    setVisible(false);
    reloadUserVisitInfo();
    onFinish && onFinish(1);
    // window.location.reload();
  };
  const handleFinish = async ({ number, code }) => {
    console.log(number, code);
    let res: any = null;
    if (userVisitInfo?.isVisitor) {
      // 游客未绑定
      res = await bindPhoneNum(
        { phone: number, captchaToken: bindCaptchaToken, smsCode: code },
        bindRcsTraceId,
      );
      if (!res) {
        return;
      }
      if (res.code === 770100) {
        const tcp = res.data.tcp;
        showCaptcha(tcp).then(token => {
          setBindCaptchaToken(token);
        });
        setBindRcsTraceId(res.data.rcsTraceId);
      }
      if (res.code === 770000) {
        Toast.show(res.message);
        return;
      }
      if (res.data.visitorStatus === 0) {
        bindSuccessed();
      } else if (res.data.visitorStatus === 3 && res.data?.alreadyInUse.nickname) {
        setAlreadyInUse({ phone: number, ...res.data.alreadyInUse });
        setVisible(false);
        setShowConfirmDialog(true);
      } else {
        Toast.show(res.message);
      }
    } else if (userVisitInfo?.isBindPhone === false) {
      // 非游客未绑定
      res = await bindUserPhone({
        phone: number,
        verifyCode: code,
        isUserDefaultPhone: false,
      });
      if (res && res.success && res.data) {
        if (!res.data.isBindPhone) {
          Toast.show(res.data.content);
        } else {
          bindSuccessed();
        }
      } else {
        Toast.show(res.message);
      }
    }
  };

  const onDialogCancel = () => {
    bindPhoneNumCancel();
    setVisible(false);
    setShowConfirmDialog(false);
    setAlreadyInUse({});
  };

  const onDialogConfirm = async () => {
    const res = await bindPhoneNumConfirm({}, bindRcsTraceId);
    if (res && res.data && res.data.visitorStatus === 0) {
      Toast.show("绑定成功");
    } else {
      Toast.show("绑定失败～");
    }
    reloadUserVisitInfo();
    setVisible(false);
    setShowConfirmDialog(false);
    setAlreadyInUse({});
    window.location.reload();
  };
  return (
    <>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          zIndex: 10000,
        }}>
        <div className={styles["container"]}>
          <div className={styles["header"]}>登录</div>
          <Form layout="horizontal" mode="card" ref={formRef} onFinish={handleFinish}>
            <Form.Item
              label="手机号码"
              validateTrigger="onBlur"
              name="number"
              rules={[
                { required: true, message: "手机号不能为空" },
                {
                  // 手机号正则
                  pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
                  message: "手机号格式不正确",
                },
              ]}>
              <Input placeholder="请输入手机号" maxLength={11} />
            </Form.Item>
            <Form.Item
              label="验证码"
              validateTrigger="onBlur"
              name="code"
              extra={getCodeBtnElement()}
              rules={[{ required: true, message: "验证码不能为空" }]}>
              <Input placeholder="请输入验证码" maxLength={6} />
            </Form.Item>
            <div className={styles["footer"]}>
              <Button
                className={styles["footer-btn"]}
                block
                color="primary"
                size="large"
                type="submit">
                登录
              </Button>
            </div>
          </Form>
        </div>
      </Popup>
      <Mask visible={showConfirmDialog}>
        <div className={styles["confirmDialog"]}>
          <div className={styles["body"]}>
            <div className={styles["title"]}>确认通知</div>
            <div className={styles["info"]}>
              <p>此手机号 {alreadyInUse.phone} 已被如下账号占用，请确定是否更换绑定。</p>
              <div className={styles["store"]}>
                <div className={styles["logo"]}>
                  <img src={alreadyInUse.headImgUrl} />
                </div>
                <div className={styles["store-name"]}>{alreadyInUse.nickname}</div>
              </div>
            </div>
          </div>
          <div className={styles["btns"]}>
            <span className={`${styles["btn"]} ${styles["cancel"]}`} onClick={onDialogCancel}>
              取消
            </span>
            <span className={`${styles["btn"]} ${styles["ok"]}`} onClick={onDialogConfirm}>
              确认更换
            </span>
          </div>
        </div>
      </Mask>
    </>
  );
};
export default BindPhonePopup;
