import React, { useEffect, useState } from "react";

import useQueryAllPlatRole from "@/utils/hooks/useQueryAllPlatRole";
import { getCookie, platform } from "@/utils/platform";
import { useSearchParams } from "react-router-dom";

import useNavigation from "@/utils/useNavigate";
import styles from "./index.module.less";

interface IProps {
  achievementDataList: any[];
  distributorCnt: number;
  lastUpdateTime: string;
  children?: React.ReactNode;
}

const AchievementWrap: React.FC<IProps> = ({
  achievementDataList,
  distributorCnt,
  lastUpdateTime,
  children,
}) => {
  const statusTypes = [
    { text: "店铺数据", type: "0" },
    { text: "店主数据", type: "1" },
    { text: "店长数据", type: "2" },
  ];
  const { navigate } = useNavigation();
  const { getRole } = useQueryAllPlatRole();
  const role = getRole(); //r e c

  const [search] = useSearchParams();
  const [chosenStatusTab, setChosenStatusTab] = useState(0);
  const [list, setList] = useState<any[]>([]);
  //数据处理
  const dataProcess = (data: number) => {
    const dataString = data.toString();

    if (dataString.includes(".")) {
      const parts = dataString.split(".");

      const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return {
        integer: formattedInteger,
        decimal: parts[1],
      };
    } else {
      const formattedInteger = dataString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return {
        integer: formattedInteger,
        decimal: "",
      };
    }
  };
  const goContribute = () => {
    const path = "v2/analysis/agent/achievement";
    const query = {
      date: "TD",
      fromPageType: "video",
      LS: true,
    };
    navigate({
      appOptions: {
        url: `${process.env.REACT_APP_PAGE_URL_4_APP}/${path}`,
        query,
      },
      miniOptions: {
        url: `${origin}/${path}`,
        query,
      },
      h5Options: {
        url: `/${path}`,
        query,
      },
    });
  };

  useEffect(() => {
    const processedList = achievementDataList?.map(item => {
      return {
        orderCount: item?.orderCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        newBuyerCnt: item?.newBuyerCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        oldBuyerCnt: item?.oldBuyerCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        avgPrice: dataProcess(item.avgPrice),
        sale: dataProcess(item.sale),
        commission: dataProcess(item.commission),
      };
    });
    setList(processedList);
  }, [achievementDataList]);

  return (
    <div className={styles.achievement}>
      <div className={styles.achievementWrap}>
        {children && children}
        {role === "R" && Number(distributorCnt) > 0 && achievementDataList?.length > 0 && (
          <div className={styles.status_tab}>
            {statusTypes.map((item, index) => {
              return (
                <div
                  className={` ${styles["normal"]} ${chosenStatusTab === index ? styles["active"] : styles["normal"]
                    }
                `}
                  key={index}
                  onClick={() => {
                    setChosenStatusTab(index);
                  }}>
                  {item.text}
                </div>
              );
            })}
          </div>
        )}
        {chosenStatusTab === 2 && achievementDataList?.length > 0 && (
          <div className={styles.shopkeeper}>
            <div className={styles.name}>分享员店长数(人)</div>
            <span className={styles.shopkeeper_number}>{distributorCnt}</span>
            <div className={styles.contribute} onClick={goContribute}>
              店长贡献榜
              <img src="https://akim-oss.aikucun.com/890a6158d5b75701e98735b397939cfa0caeec28_1706771323098_31.png" />
            </div>
          </div>
        )}
        {achievementDataList?.length > 0 && (
          <>
            <div className={styles.contentWrap}>
              <div className={styles.contentWrap1}>
                <div className={styles.saledata}>
                  <div className={styles.name}>订单数(份)</div>
                  <div
                    className={styles.number}
                    style={{
                      fontSize: (list[chosenStatusTab]?.orderCount + "").length > 10 ? 14 : 18,
                    }}>
                    {list[chosenStatusTab]?.orderCount}
                  </div>
                </div>
                <div className={styles.saledata}>
                  <div className={styles.name}>销售额(元)</div>
                  <div className={styles.number}>
                    <div
                      className={styles.integer}
                      style={{
                        fontSize: list[chosenStatusTab]?.sale?.integer.length > 10 ? 14 : 18,
                      }}>
                      {list[chosenStatusTab]?.sale?.integer}
                    </div>
                    {list[chosenStatusTab]?.sale?.decimal && (
                      <div className={styles.decimal}>.{list[chosenStatusTab]?.sale?.decimal}</div>
                    )}
                  </div>
                </div>
                <div className={styles.saledata}>
                  <div className={styles.name}>预估收入(元)</div>
                  <div className={styles.number}>
                    <div
                      className={styles.integer}
                      style={{
                        fontSize: list[chosenStatusTab]?.commission?.integer.length > 10 ? 14 : 18,
                      }}>
                      {list[chosenStatusTab]?.commission?.integer}
                    </div>
                    {list[chosenStatusTab]?.commission?.decimal && (
                      <div className={styles.decimal}>
                        .{list[chosenStatusTab]?.commission?.decimal}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.contentWrap2}>
                <div className={styles.saledata}>
                  <div className={styles.name}>下单新客(人)</div>
                  <div
                    className={styles.number}
                    style={{
                      fontSize: (list[chosenStatusTab]?.newBuyerCnt + "").length > 10 ? 14 : 18,
                    }}>
                    {list[chosenStatusTab]?.newBuyerCnt}
                  </div>
                </div>
                <div className={styles.saledata}>
                  <div className={styles.name}>下单老客(人)</div>
                  <div
                    className={styles.number}
                    style={{
                      fontSize: (list[chosenStatusTab]?.oldBuyerCnt + "").length > 10 ? 14 : 18,
                    }}>
                    {list[chosenStatusTab]?.oldBuyerCnt}
                  </div>
                </div>
                <div className={styles.saledata}>
                  <div className={styles.name}>客单价(元)</div>
                  <div className={styles.number}>
                    <div
                      className={styles.integer}
                      style={{
                        fontSize: list[chosenStatusTab]?.avgPrice?.integer.length > 10 ? 14 : 18,
                      }}>
                      {list[chosenStatusTab]?.avgPrice?.integer}
                    </div>
                    {list[chosenStatusTab]?.avgPrice?.decimal && (
                      <div className={styles.decimal}>
                        .{list[chosenStatusTab]?.avgPrice?.decimal}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.update_time}>数据更新时间:{lastUpdateTime}</div>
          </>
        )}
      </div>
    </div>
  );
};
export default AchievementWrap;
