import { queryMaterialFilter, queryMaterialPageList, queryMaterialTabs } from "@/api/material";
import Bottom from "@/components/bottom/index";
import DailyRewardNotice from "@/components/daily-reward-notice";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import RedPacketNotice from "@/components/red-packet-notice";
import TopBar from "@/components/top-bar";
import { sharePosterParams4H5Material } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import {
  activityAPI,
  ActivityBrandModel,
  ActivityModel,
  LabelPageId,
  ResourceBannerModel,
} from "@akc/biz-api";
import { MaterialListCardComp } from "@akc/mx-taro-h5-components";
import { InfiniteScroll, Popup } from "antd-mobile";
import { throttle } from "lodash";
import { default as React, useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import SideBar from "../../components/side/index";
import { getUrlParam } from "../../utils/url-parse";
import ActivitySelector from "../activity/components/ActivitySelector";
import Header from "../activity/components/Header";
import { ActivityProductProvider } from "../activity/hooks/useActivityProduct";
import FilterBoard from "./components/filter/FilterBoard";
import Card from "./components/shopCard/index";
import Sort from "./components/sort/index";
import Tab from "./components/tabs/index";
import { FilterOptionProvider } from "./hooks/useFilterOptions";
import styles from "./index.module.less";
import { initParams, sortListFunc } from "./utils/tool";

const getDefaultActivityInfo = (activityNo: string) => {
  if (!activityNo || typeof activityNo !== "string") {
    return {
      activityId: "",
      isShowAll: false,
    };
  }

  const ids = activityNo.split(",");
  return {
    activityId: ids[0],
    isShowAll: ids.length > 1,
  };
};

interface ExtraActivityModel {
  /** 品牌素材信息 */
  materialResult?: {
    total: string | number;
    materialsResultVOList: ({ materialNo: string } & Record<string, any>)[];
  };
}

const Material: React.FC = () => {
  const defaultParams = initParams();
  let [params, setParams] = React.useState<any>(defaultParams);
  const [brandData, setBrandData] = useState<ActivityBrandModel & ExtraActivityModel>({});
  const [materialTabs, setMaterialTabs] = useState<any>();
  const [tabIndex, setTabIndex] = useState(0);
  const [sortIndex, setSortIndex] = useState(0);
  const [materialFilter, setMaterialFilter] = useState<any>();
  const [queryLoading, setQueryLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [materialList, setMaterialList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [has, setHas] = useState(false);
  const [tabDomTop, setTabDomTop] = useState(0);
  const [sticky, setSticky] = useState(false);
  const { navigate2Material } = useNavigation();
  const {
    activitySpuId = "",
    resourceCode,
    merchantShopCode,
    activityNo,
    previous_page_name,
  } = getUrlParam();
  const sortList = sortListFunc(merchantShopCode);
  const [filterBoardVisible, setFilterBoardVisible] = useState(false);
  let [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { track } = useCommonContext();
  const [searchParams] = useSearchParams();
  let [sortOrder, setSortOrder] = useState<any>(0);
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);

  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<any>({});

  // 新头部和档期切换的逻辑
  const headerDomRef = useRef<HTMLDivElement>(null);
  const mainContentDom = useRef<HTMLDivElement>(null);
  const selectorContainerDom = useRef<HTMLDivElement>(null);
  const [activitySelectorBorderRadis, setActivitySelectorBorderRadis] = useState<number>(10);
  const [activitySelectorBgColor, setActivitySelectorBgColor] = useState<string>("");
  const defaultActivityInfo = getDefaultActivityInfo(activityNo);
  const [selectedId, setSelectedId] = useState<any>(
    defaultActivityInfo.isShowAll ? undefined : defaultActivityInfo.activityId,
  );
  const stickyHeigthRef = useRef<number>(0);

  const isActivity = params.queryType === 5;

  // 档期数据
  const getActivityDataRequest = async () => {
    const datas = await Promise.all([
      activityAPI.queryActivityBrandData({
        activityId: defaultActivityInfo.activityId,
        pageId: LabelPageId.h5ActivityDetail,
        queryType: searchParams.get("queryType") ?? undefined,
      }),
      // 默认请求第一个即可
      activityAPI.queryActivityData({
        activityId: defaultActivityInfo.activityId,
        pageId: LabelPageId.h5ActivityDetail,
      }),
    ]);

    const data = datas[1] as ActivityModel & ExtraActivityModel;
    if (!data?.brandId) {
      return;
    }
    // setActivity(data);
    const brandData = datas[0];
    if (!brandData?.brandId) {
      return;
    }
    brandData?.activityList?.forEach((item, index) => (item.rank = index));
    if (brandData?.activityList && brandData?.activityList?.length > 1) {
      brandData?.activityList.unshift({
        activityAggrTitle: "全部",
        activityAggrSubTitleList: [`共${brandData.totalOnlineProductCount}款`],
        brandStoryVO: brandData?.activityList[0].brandStoryVO,
        merchantShopCode: brandData?.activityList[0].merchantShopCode,
        brandId: brandData.brandId,
        backgroundBrandVideoUrl: brandData?.activityList[0].backgroundBrandVideoUrl,
      });
    }
    setBrandData({
      ...brandData,
      materialResult: data.materialResult,
    });
    console.log(">>>>>", datas);
  };
  const scrollIntoTop = () => {
    const pageDom = document.getElementById("material");
    const barDom = document.getElementById("material-topBar")?.getBoundingClientRect();
    const tabDom = document.getElementById("material-sort")?.getBoundingClientRect();
    const barDomHeight = barDom?.height || 0;
    const tabTop = tabDom?.top || 0;
    if (tabTop > barDomHeight) {
      return;
    }
    pageDom?.scrollTo(0, tabDomTop);
  };
  const initMainContentTop = () => {
    const pageDom = document.getElementById("material");
    const barDom = document.getElementById("material-topBar");
    if (!mainContentDom.current || !pageDom || !barDom) {
      return;
    }
    console.log(
      "mainContentDom.current?.getBoundingClientRect().top",
      mainContentDom.current?.getBoundingClientRect().top,
    );
    const mainContentDomTop = mainContentDom.current?.getBoundingClientRect().top || 0;
    const barDomHeight = barDom.getBoundingClientRect().height || 0;
    stickyHeigthRef.current = mainContentDomTop - barDomHeight;
  };

  // mainContentDom滚动到顶部
  const scrollToMainContentTop = () => {
    const pageDom = document.getElementById("material");
    if (!pageDom) {
      return;
    }
    pageDom.scrollTo(0, stickyHeigthRef.current);
  };

  const openMore = () => {
    setTimeout(() => {
      const dom = document.getElementById("material-sort")?.getBoundingClientRect()?.top || 0;
      const domHeight =
        document.getElementById("material-sort")?.getBoundingClientRect()?.height || 0;
      const pageDom = document.getElementById("material")?.scrollTop || 0;
      setTabDomTop(dom + pageDom - domHeight || 0);
    }, 300);
  };

  const getMaterialList = async (params, init?: boolean) => {
    setLoading(true);
    params.pageNo = pageIndex;
    params.pageSize = pageSize;
    params.sortOrder = sortOrder > 0 ? sortOrder : null;
    let res = await queryMaterialPageList(params);
    const list = pageIndex > 1 ? materialList.concat(res.result) : res.result;
    setMaterialList(list || []);
    setHasMore(res.hasNext);
    setQueryLoading(false);
    setLoading(false);
    setPageIndex(pageIndex);
    if (init) {
      setTimeout(() => {
        const dom = document.getElementById("material-sort")?.getBoundingClientRect();
        const top = dom?.top || 0;
        const height = dom?.height || 0;
        setTabDomTop(top - height || 0);
      }, 500);
    }
  };

  const getMaterialFilter = async queryParams => {
    const query = { ...queryParams };
    query.frontCodes = [
      {
        type: materialTabs.selectedMaterialTabVOS[tabIndex].frontType,
        code: materialTabs.selectedMaterialTabVOS[tabIndex].frontValue,
      },
    ];
    let res = await queryMaterialFilter(query);
    setMaterialFilter(res);
  };

  const getMaterialTabs = async queryParams => {
    let res = await queryMaterialTabs(queryParams);
    setMaterialTabs(res);
    if (res?.selectedMaterialTabVOS?.length <= 1 && queryParams.queryType !== 5) {
      setSticky(true);
    }
    queryParams.frontCodes = [
      {
        type: res.selectedMaterialTabVOS[tabIndex].frontType,
        code: res.selectedMaterialTabVOS[tabIndex].frontValue,
      },
    ];
    getMaterialList(queryParams, true);
  };

  const Tabs = () => {
    return (
      <div className={styles.tabs}>
        <div className={styles.text}>
          {materialTabs?.selectedMaterialTabVOS?.[tabIndex]?.tabDesc}
        </div>
        <div className={styles.nums}>
          <div className={styles.text}>
            {materialTabs?.selectedMaterialTabVOS?.[tabIndex]?.materialCount > 99
              ? "99+"
              : materialTabs?.selectedMaterialTabVOS?.[tabIndex]?.materialCount}
          </div>
        </div>
      </div>
    );
  };

  const initSomeParams = params => {
    pageIndex = 1;
    setQueryLoading(true);
    setParams(params);
  };

  const changeSort = index => {
    params.sortModel = sortList[index].code;
    setSortIndex(index);
    initSomeParams(params);
    if (sortList[index].name === "价格") {
      if (sortOrder === 0) {
        sortOrder = 1;
      } else {
        sortOrder = sortOrder === 1 ? 2 : 1;
      }
    } else {
      sortOrder = 0;
    }
    setSortOrder(sortOrder);
    getMaterialList(params);
    // scrollIntoTop();
    if (sticky) {
      scrollToMainContentTop();
    }
  };

  const openFilter = async () => {
    await getMaterialFilter(params);
    console.log(params);
    setFilterBoardVisible(true);
  };

  const changeActive = index => {
    params.frontCodes = [
      {
        type: materialTabs.selectedMaterialTabVOS[index].frontType,
        code: materialTabs.selectedMaterialTabVOS[index].frontValue,
      },
    ];
    setTabIndex(index);
    setSortOrder(0);
    setHas(false);
    setSortIndex(0);
    initSomeParams(params);
    getMaterialList(params);
    // scrollIntoTop();
    if (sticky) {
      scrollToMainContentTop();
    }
    /* eslint-disable */
    if (track.track) {
      track.track("resource_click", {
        page_name:
          params.queryType === 14
            ? "商品素材列表"
            : params.queryType === 17
              ? "店铺素材列表"
              : "档期素材列表",
        previous_page_name: searchParams.get("previous_page_name"),
        resource_type: "素材TAB",
        resource_name: materialTabs?.selectedMaterialTabVOS?.[index]?.tabDesc,
        domain: "",
        merchant_shop_code: merchantShopCode,
        activity_id: activityNo,
        activity_spu_code: activitySpuId,
      });
    }
  };

  useActivate(() => {
    document.title = "更多素材";
  });

  useEffect(() => {
    //初始化请求参数
    document.title = "更多素材";
    const queryParams = initParams();
    queryParams.sortModel = sortList[0].code;
    setParams(queryParams);
    const _isActivity = queryParams.queryType === 5;
    if (_isActivity) {
      getActivityDataRequest()
        .then(() => getMaterialTabs(queryParams))
        .then(() => {
          initMainContentTop();
          scrollToMainContentTop();
        });
    } else {
      getMaterialTabs(queryParams).then(() => {
        initMainContentTop();
        scrollToMainContentTop();
      });
    }
    /* eslint-disable */
    if (track.track) {
      track.track("$pageview", {
        page_name:
          queryParams.queryType === 14
            ? "商品素材列表"
            : queryParams.queryType === 17
              ? "店铺素材列表"
              : "档期素材列表",
        previous_page_name: searchParams.get("previous_page_name"),
        domain: "",
        merchant_shop_code: merchantShopCode,
        activity_id: activityNo,
        activity_spu_code: activitySpuId,
      });
    }
  }, []);

  const loadMore = async () => {
    if (hasMore && !loading) {
      pageIndex++;
      getMaterialList(params);
    }
  };

  const getServerTime = () => {
    return new Date().getTime();
  };

  const onForwardAction = async item => {
    setSharePosterData(
      await sharePosterParams4H5Material({
        materialId: item.materialNo,
        activitySpuId: params.activitySpuId,
      }),
    );
    setCustomEditData({
      activitySpuId: item.activitySpuId,
      activityId: item.activityId,
      selectSkuId: item.selectSkuId,
      materialNo: item.materialNo,
    });
    setShowPoster(true);
  };

  const toMaterialDetailPage = item => {
    const { type, materialNo } = item;
    navigate2Material(type, materialNo, activitySpuId);
  };

  const onClosePoster = () => {
    setShowPoster(false);
  };

  const onScroll = throttle(() => {
    const barDom = document.getElementById("material-topBar")?.getBoundingClientRect();
    const sortDom = document.getElementById("material-sort")?.getBoundingClientRect();
    const tabHeight =
      document.getElementById("material-tabs")?.getBoundingClientRect()?.height || 0;
    const scrollTop = document.getElementById("material")?.scrollTop || 0;
    const barDomHeight = barDom?.height || 0;
    const sortTop = sortDom?.top || 0;
    const selectorContainerDomTop = selectorContainerDom.current?.getBoundingClientRect().top || 0;
    if (params.queryType === 5) {
      const calcTop = selectorContainerDom.current ? selectorContainerDomTop : sortTop;
      if (scrollTop - stickyHeigthRef.current < 0) {
        setSticky(false);
      }
      if (scrollTop - stickyHeigthRef.current >= 0) {
        setSticky(true);
      }
      console.log(
        scrollTop,
        stickyHeigthRef.current,
        selectorContainerDomTop,
        barDomHeight,
        sticky,
      );
    } else {
      if (materialTabs?.selectedMaterialTabVOS?.length <= 1) {
        setSticky(true);
        return;
      }
      if (scrollTop > tabHeight && !sticky) {
        setSticky(true);
      }
      if (scrollTop <= 0 && sticky) {
        setSticky(false);
      }
    }
  }, 100);

  const updateFilterInfo = queryParams => {
    const tab = queryParams.frontCodes.filter(
      item =>
        item.type === materialTabs.selectedMaterialTabVOS[tabIndex].frontType &&
        item.code === materialTabs.selectedMaterialTabVOS[tabIndex].frontValue,
    );
    if (tab.length <= 0) {
      queryParams.frontCodes.push({
        type: materialTabs.selectedMaterialTabVOS[tabIndex].frontType,
        code: materialTabs.selectedMaterialTabVOS[tabIndex].frontValue,
      });
    }
    // 更新筛选信息
    setQueryLoading(true);
    setParams(queryParams);
    pageIndex = 1;
    if (queryParams.frontCodes.length > 1) {
      setHas(true);
    } else {
      setHas(false);
    }
    getMaterialList(queryParams);
  };

  const hasActivityList = !!(brandData?.activityList && brandData?.activityList?.length > 1);
  const hasMaterialTabs = !!(materialTabs?.selectedMaterialTabVOS?.length > 1);
  console.log("sticky", sticky);
  return (
    <ActivityProductProvider
      initialSelectedActivityId={
        defaultActivityInfo.isShowAll ? undefined : defaultActivityInfo.activityId
      }
      activityList={brandData?.activityList}>
      <div className={styles.container} id="material" onScroll={onScroll}>
        <RedPacketNotice />
        <div
          id="material-topBar"
          className={styles.topBar}
          style={{ backgroundColor: isActivity && !sticky ? "transparent" : "#ffffff" }}>
          {isActivity ? (
            <TopBar center={<Tabs />} isShowCenter={sticky} isShowSearch={false} />
          ) : (
            <TopBar center={<Tabs />} isShowCenter={sticky} isShowSearch />
          )}
        </div>
        {isActivity && (
          <div ref={headerDomRef}>
            <Header
              brandData={brandData}
              isInitialFollowing={false}
              forceHideRight={true}
              previous_page_name={previous_page_name}
            />
          </div>
        )}
        {/* 待废弃  */}
        {/* {params.queryType === 5 && (
        <img
          className={styles.backImg}
          src="https://akim-oss.aikucun.com/6e14d4994150dc45e209d3497bae312babf30a48_1706334173732_25.png"
        />
      )} */}
        {/* 待废弃  */}
        {/* {params.queryType === 5 && <Card data={materialTabs} openMore={openMore} params={params} />} */}
        <div
          ref={mainContentDom}
          className={styles.content}
          style={{ marginTop: isActivity ? pxtorem(-56) : 0 }}>
          {isActivity && hasActivityList && (
            <div ref={selectorContainerDom} className={styles.sticky}>
              <ActivitySelector
                brandData={brandData}
                borderRadis={activitySelectorBorderRadis}
                bgColor={activitySelectorBgColor}
                initialSelectedActivityId={selectedId}
                previous_page_name={previous_page_name}
                onSelectionChange={id => {
                  console.log("选择的档期id", id, brandData);
                  const ids =
                    brandData?.activityList?.map(item => item.activityId).filter(item => !!item) ||
                    [];
                  const queryParams = {
                    ...params,
                    frontCodes: undefined,
                    objectIds: id ? [id] : ids,
                  };
                  setParams(queryParams);
                  getMaterialTabs(queryParams);
                  if (sticky) {
                    scrollToMainContentTop();
                  }
                }}
              />
            </div>
          )}
          <img
            className={styles.back}
            src="https://akim-oss.aikucun.com/51ee95baed3fa287b6288ce47382a3f6656279b9_1706342251582_95.png"
          />
          {materialTabs?.selectedMaterialTabVOS?.length > 1 && (
            <Tab
              data={materialTabs?.selectedMaterialTabVOS}
              tabIndex={tabIndex}
              changeTab={changeActive}
              isActivity={isActivity}
              hasActivityList={hasActivityList}
            />
          )}
          <Sort
            data={sortList}
            sortIndex={sortIndex}
            sortOrder={sortOrder}
            has={has}
            sticky={sticky}
            changeSort={changeSort}
            openFilter={openFilter}
            hasActivityList={hasActivityList}
            hasMaterialTabs={hasMaterialTabs}
          />
          <div id="list" className={styles.list}>
            {queryLoading ? (
              <img
                style={{ width: "100%", minHeight: "100vh", position: "relative", zIndex: 80 }}
                src="https://akim-oss.aikucun.com/bd74cd889c96dc4ee569c3832c9ea547ccf944dc_1706269271107_35.png"
              />
            ) : (
              <>
                {materialList && materialList?.length > 0 ? (
                  <>
                    {materialList?.map((item, index) => {
                      return (
                        <div className={styles.card} key={index}>
                          <MaterialListCardComp
                            material={item}
                            isCollect={true}
                            showlabel={true}
                            getServerTime={getServerTime}
                            onForwardAction={onForwardAction}
                            onAction={toMaterialDetailPage}
                            onAvatarAction={toMaterialDetailPage}
                            onLabelAction={toMaterialDetailPage}
                          />
                        </div>
                      );
                    })}
                    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
                      <Bottom
                        loading={queryLoading}
                        hasMore={hasMore}
                        reachBottom={materialList && materialList?.length > 0 ? true : false}
                      />
                    </InfiniteScroll>
                  </>
                ) : (
                  <div className={styles.empty}>
                    <img
                      className={styles.img}
                      src="https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1706514708184_43.png"
                    />
                    <div className={styles.text}>暂无相关素材</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* 海报 */}
        <PosterSwipe
          show={showPoster}
          posterData={sharePosterData}
          customEditData={customEditData}
          shareType="MATERIAL"
          btnConfig={["copy", "edit"]}
          close={onClosePoster}></PosterSwipe>
        <FilterOptionProvider
          filterBoardVisible={filterBoardVisible}
          filters={materialFilter}
          queryParams={params}
          scene="ACTIVITY_DETAIL"
          pageId="H5_ScheduleDetail">
          <Popup
            visible={filterBoardVisible}
            showCloseButton
            onMaskClick={() => {
              setFilterBoardVisible(false);
            }}
            onClose={() => {
              setFilterBoardVisible(false);
            }}
            bodyStyle={{ height: "80vh" }}
            className={styles.filterBoard}>
            <FilterBoard
              onFilterChange={params => {
                setFilterBoardVisible(false);
                updateFilterInfo(params);
              }}
            />
          </Popup>
        </FilterOptionProvider>
        <SideBar />
        <DailyRewardNotice />
      </div>
    </ActivityProductProvider>
  );
};

export default Material;
