import ActivityCom from "@/components/activity/Index";
import BannerCom from "@/components/banner/Index";
import CouponCom from "@/components/coupon/Index";
import ImageCom from "@/components/image/Index";
import NavigationCom from "@/components/navigation/Index";
import ProductCom from "@/components/product/Index";
import SizePopup from "@/components/size-popup";
import TouchComponent from "@/components/TouchComponent";
import VideoCom from "@/components/video/Index";
import { ProductModel } from "@/models/product";
import exposure from "@/utils/expose/exposure";
import { platform } from "@/utils/platform";
import { project } from "@/utils/project";
import { pxtorem } from "@/utils/pxtorem";
import { showSKUPreview } from "@/utils/select-color";
import useNavigation from "@/utils/useNavigate";
import {
  DecorateMaterialComp,
  DecorateProfitAdditionalComp,
  DecorateTopListComp,
  SeckillCard,
} from "@akc/mx-taro-h5-components";
import { Image, InfiniteScroll, Toast } from "antd-mobile";
import { isEmpty, throttle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ICON_LOADING from "@/assets/loading2.png";
import { useCommonContext } from "@/utils/context/common";
import { getUrlParam } from "@/utils/url-parse";
import styles from "./index.module.less";

interface IProps {
  pageInfo: any;
  hasMore: boolean;
  queryActivity: any;
  queryProduct: any;
  loadActivity: any;
  loadProduct: any;
  forwardProduct: any;
  changeIndex: any;
}
const Home: React.FC<IProps> = props => {
  const {
    pageInfo,
    hasMore,
    queryActivity,
    queryProduct,
    loadActivity,
    loadProduct,
    forwardProduct,
  } = props;
  const taroH5CompTypeList = ["TOPLIST", "PROFITADDITIONAL", "SECKILL", "MATERIAL"];

  console.log("pageInfo", pageInfo);

  const { track } = useCommonContext();
  const [search] = useSearchParams();
  const navigater = useNavigate();
  const { navigate2Product, navigate2H5, navigate2MaterialList, navigate2MaterialContent } =
    useNavigation();
  const merchantShopCode = search.get("merchantShopCode");
  // 选色
  const [sizePopupProduct, setSizePopupProduct] = useState<ProductModel>({} as ProductModel);
  const [showSizePopup, setShowSizePopup] = useState(false);
  const [proIndex, setProIndex] = useState(0);
  const [showBottom, setShowBottom] = useState(false);
  const [searchParams] = useSearchParams();
  const [previous_page_name] = useState<string | null>(searchParams.get("previous_page_name"));
  const topListRef = useRef<any>(null);
  //加载更多(仅限最后一个组件为档期或商品)
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean; }) => {
    return (
      <>
        {hasMore ? (
          <div className={styles.loadingMore}>
            <Image src={ICON_LOADING}></Image>
          </div>
        ) : (
          <>
            {showBottom ? (
              <div className={styles.info} style={{ display: "flex", alignItems: "center" }}>
                <div className={styles.bottom}>
                  松开切换到全部
                  <img
                    className={styles.upImg}
                    src="https://akim-oss.aikucun.com/f104f4f297057c15380488d716a36037e992287d_1704793964354_54.png"
                  />
                </div>
              </div>
            ) : (
              <div className={styles.info2} style={{ display: "flex", alignItems: "center" }}>
                <div className={styles.bottom}>已到底啦，下拉跳转页面~</div>
              </div>
            )}
          </>
        )}
      </>
    );
  };
  // 秒杀查看更多
  const goSecKill = (item: any, pageData: any, tabIndex: number) => {
    const distributorId = search.get("distributorId");
    const shopId = search.get("shopId");
    const isC = search.get("isC");
    const merchantShopCode = search.get("merchantShopCode");
    const token = search.get("token");
    const { componentCode, seckillConfigDetails } = item ?? {};
    if (isNaN(tabIndex)) {
      tabIndex = 0;
    }
    if (track.track) {
      track.track("btn_click", {
        page_name: "店铺首页",
        previous_page_name,
        domain: "B2R旗舰店",
        module: "秒杀",
        resource_type: "SECKILL",
        btn_name: "商品点击",
        merchant_shop_code: merchantShopCode,
        page_code: pageInfo.pageCode,
      });
    }
    if (search.get("distributorId")) {
      if (!platform.isMini) {
        navigater(
          `./seckill?componentCode=${componentCode}&oneDetailCode=${seckillConfigDetails[0].componentDetailCode
          }&twoDetailCode=${seckillConfigDetails[1].componentDetailCode}&customPageCode=${pageData.pageCode
          }&customPageVersion=${pageData.version
          }&shopId=${shopId}&distributorId=${distributorId}&activeIndex=${tabIndex + 1
          }&preview=${search.get("preview")}&isC=${isC}&decorate=${project() === 2 ? "1" : "0"
          }&merchantShopCode=${merchantShopCode}`,
        );
      } else {
        const url = location.href.split("mx-shop-micro");
        const newUrl = `${url[0]
          }mx-shop-micro/seckill?componentCode=${componentCode}&oneDetailCode=${seckillConfigDetails[0].componentDetailCode
          }&twoDetailCode=${seckillConfigDetails[1].componentDetailCode}&customPageCode=${pageData.pageCode
          }&customPageVersion=${pageData.version
          }&shopId=${shopId}&distributorId=${distributorId}&activeIndex=${tabIndex + 1
          }&preview=${search.get("preview")}&isC=${isC}&decorate=${project() === 2 ? "1" : "0"
          }&merchantShopCode=${merchantShopCode}&token=${token}`;
        // console.log("链接-----", encodeURIComponent(newUrl));
        window.wx.miniProgram.navigateTo({
          url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}&pageName=activity`,
        });
      }
    } else {
      console.log("判断是否是小程序-----", platform.isMini);
      if (!platform.isMini) {
        navigater(
          `./seckill?componentCode=${componentCode}&oneDetailCode=${seckillConfigDetails[0].componentDetailCode
          }&twoDetailCode=${seckillConfigDetails[1].componentDetailCode}&customPageCode=${pageData.pageCode
          }&customPageVersion=${pageData.version}&shopId=${shopId}&activeIndex=${tabIndex + 1
          }&preview=${search.get("preview")}&isC=${isC}&decorate=${project() === 2 ? "1" : "0"
          }&merchantShopCode=${merchantShopCode}`,
        );
      } else {
        const url = location.href.split("mx-shop-micro");
        const newUrl = `${url[0]
          }mx-shop-micro/seckill?componentCode=${componentCode}&oneDetailCode=${seckillConfigDetails[0].componentDetailCode
          }&twoDetailCode=${seckillConfigDetails[1].componentDetailCode}&customPageCode=${pageData.pageCode
          }&customPageVersion=${pageData.version}&shopId=${shopId}&activeIndex=${tabIndex + 1
          }&preview=${search.get("preview")}&isC=${isC}&decorate=${project() === 2 ? "1" : "0"
          }&merchantShopCode=${merchantShopCode}&token=${token}`;
        console.log("链接-----", encodeURIComponent(newUrl));
        window.wx.miniProgram.navigateTo({
          url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}&pageName=activity`,
        });
      }
    }
  };
  const skuPreview = (item: any, index?: any) => {
    // console.log("选色", e);
    const productInfo: ProductModel = {
      salePropertyFirstList: item.salePropertyFirstList,
      spuInfoVO: {
        sellOut: item.spuSellOut,
      },
      spuId: item.spuId,
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
    };

    showSKUPreview(productInfo).then(result => {
      if (result) {
        return;
      }
      setSizePopupProduct(productInfo);
      setShowSizePopup(true);
      setProIndex(index);
    });
  };
  //跳转商详
  const goProduct = (
    item: any,
    resourceRank: any,
    index?: any,
    salePropertyFirstListPicItem?: any,
    type?: any,
  ) => {
    let btnInfo: any = null;
    if (type === "btn") {
      btnInfo = {
        page_name: document.title,
        module: "秒杀",
        btn_name: "立即购买",
        btn_text: "立即购买",
      };
    }
    if (type === "size") {
      btnInfo = {
        page_name: document.title,
        module: "秒杀选色",
        btn_name: "查看详情",
        btn_text: "查看详情",
      };
    }
    navigate2Product(
      {
        activityId: item.activityId,
        activitySpuId: item.activitySpuId,
        selectSkuId: item.selectSkuId,
        firstSalePropertyValue: salePropertyFirstListPicItem?.value,
        marketName: pageInfo.name,
      },
      {
        page_name: "店铺首页",
        previous_page_name,
        domain: "B2R旗舰店",
        resource_type: "SECKILL",
        resource_content: item.title,
        relative_rank: index + 1,
        tab_name: "首页",
        btn_name: "商品点击",
        merchant_shop_code: merchantShopCode,
        page_code: pageInfo.pageCode,
        activity_spu_code: item.activitySpuId,
        sku_id: item.selectSkuId,
      },
    );
  };

  // 进入商品详情
  const goProductDetailForTop = (item, index?: any) => {
    const naviThr = throttle(
      (item, index?: any) => {
        navigate2Product(
          {
            activityId: item.activityId,
            activitySpuId: item.activitySpuId,
            selectSkuId: item.selectSkuId,
            marketName: pageInfo.name,
          },
          {
            page_name: "店铺首页",
            previous_page_name,
            domain: "B2R旗舰店",
            resource_type: "TOPLIST",
            resource_content: item.title,
            relative_rank: index + 1,
            tab_name: "首页",
            btn_name: "商品点击",
            merchant_shop_code: merchantShopCode,
            page_code: pageInfo.pageCode,
            activity_spu_code: item.activitySpuId,
            sku_id: item.selectSkuId,
          },
        );
      },
      500,
      {
        leading: true,
        trailing: false,
      },
    );
    naviThr(item, index);
  };

  // 进入商品详情
  const goProductDetailForPro = (item, index?: any) => {
    const naviThr = throttle(
      (item, index?: any) => {
        navigate2Product(
          {
            activityId: item.activityId,
            activitySpuId: item.activitySpuId,
            selectSkuId: item.selectSkuId,
            marketName: pageInfo.name,
          },
          {
            page_name: "店铺首页",
            previous_page_name,
            domain: "B2R旗舰店",
            resource_type: "PROFITADDITIONAL",
            resource_content: item.title,
            resource_rank: 1,
            relative_rank: index + 1,
            tab_name: "首页",
            btn_name: "商品点击",
            merchant_shop_code: merchantShopCode,
            page_code: pageInfo.pageCode,
            activity_spu_code: item.activitySpuId,
            sku_id: item.selectSkuId,
          },
        );
      },
      500,
      {
        leading: true,
        trailing: false,
      },
    );
    naviThr(item, index);
  };

  // 高佣点击查看全部
  const goHighSecPage = () => {
    const isC = search.get("isC");
    if (track.track) {
      track.track("btn_click", {
        page_name: "店铺首页",
        previous_page_name,
        domain: "B2R旗舰店",
        resource_type: "PROFITADDITIONAL",
        tab_name: "首页",
        btn_name: "查看全部",
        merchant_shop_code: merchantShopCode,
        page_code: pageInfo.pageCode,
      });
    }
    if (!platform.isMini) {
      const path = "/mx-shop-micro/decorate/profit";
      const { pageCode } = pageInfo ?? {};
      const query = `merchantShopCode=${merchantShopCode}&isC=${isC}&customPageCode=${pageCode}`;
      navigate2H5(path, { query });
    }
  };
  // 榜单点击查看全部
  const goTopListPage = componentCode => {
    const shopId = search.get("shopId");
    const isC = search.get("isC");
    const token = search.get("token");
    const { pageCode, version } = pageInfo ?? {};
    if (track.track) {
      track.track("btn_click", {
        page_name: "店铺首页",
        previous_page_name,
        domain: "B2R旗舰店",
        resource_type: "TOPLIST",
        tab_name: "首页",
        btn_name: "查看全部",
        merchant_shop_code: merchantShopCode,
        page_code: pageInfo?.pageCode,
      });
    }
    if (!platform.isMini) {
      const path = "/mx-shop-micro/decorate/toplist";
      const query = `componentCode=${componentCode}&customPageCode=${pageCode}&customPageVersion=${version}&merchantShopCode=${merchantShopCode}&shopId=${shopId}&isC=${isC}`;
      navigate2H5(path, { query });
    } else {
      const url = location.href.split("mx-shop-micro");
      const newUrl = `${url[0]}mx-shop-micro/decorate/toplist?componentCode=${componentCode}&customPageCode=${pageCode}&customPageVersion=${version}&merchantShopCode=${merchantShopCode}&shopId=${shopId}&isC=${isC}&token=${token}`;
      window.wx.miniProgram.navigateTo({
        url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
      });
    }
  };
  //加载更多
  const loadMore = async () => {
    if ((pageInfo?.components?.length ?? 0) === 0) {
      return;
    }
    const last = pageInfo?.components?.[(pageInfo?.components?.length ?? 0) - 1];
    if (last?.dataInfo) {
      if (last?.type === "ACTIVITY") {
        await queryActivity(last, pageInfo, last?.dataInfo?.result?.length ?? 0);
      }
      if (last.type === "PRODUCT") {
        await queryProduct(last, pageInfo, last?.dataInfo?.result?.length ?? 0);
      }
    }
  };
  //页面下拉
  // const handleTouchMove = () => {
  //   const height = document.documentElement?.clientHeight as number;
  //   const scrollHeight = document.documentElement?.scrollHeight as number;
  //   const scrollTop = document.documentElement.scrollTop as number;
  //   const bottomOfPage = scrollHeight - height;
  //   console.log(bottomOfPage, scrollTop);
  //   if (bottomOfPage - scrollTop <= -40 && !hasMore) {
  //     setShowBottom(true);
  //   }
  // };

  // const handleSwitchTab = () => {
  //   if (showBottom) {
  //     setTimeout(() => {
  //       setShowBottom(false);
  //       props.changeIndex(1, true);
  //     }, 800);
  //   }
  // };

  // useEffect(() => {
  //   //监听touchmove事件
  //   window.addEventListener("touchmove", handleTouchMove);
  //   window.addEventListener("touchend", handleSwitchTab);
  //   return () => {
  //     window.removeEventListener("touchmove", handleTouchMove);
  //     window.removeEventListener("touchend", handleSwitchTab);
  //   };
  // });

  const goMaterialList = extra => {
    const merchantShopCode = getUrlParam()?.["merchantShopCode"];
    const resourceCode = extra?.resourceCode;
    if (track.track) {
      track.track("btn_click", {
        page_name: "店铺首页",
        previous_page_name,
        domain: "B2R旗舰店",
        resource_type: "MATERIAL",
        module: "店铺素材",
        btn_name: "查看全部",
        merchant_shop_code: merchantShopCode,
        page_code: pageInfo.pageCode,
      });
    }
    navigate2MaterialList(merchantShopCode, resourceCode);
  };

  const goMaterialContent = () => {
    return function (material) {
      if (track.track) {
        track.track("resource_click", {
          page_name: "店铺首页",
          previous_page_name,
          domain: "B2R旗舰店",
          resource_type: "店铺素材",
          resource_content: "素材",
          btn_name: "查看详情",
          material_id: material?.materialNo,
          merchant_shop_code: merchantShopCode,
          page_code: pageInfo.pageCode,
        });
      }
      navigate2MaterialContent(material?.type, material?.materialNo);
    };
  };

  useEffect(() => {
    const list = pageInfo?.components.filter(item =>
      ["SECKILL", "TOPLIST", "PROFITADDITIONAL", "MATERIAL"].includes(item.type),
    );
    if (list.length <= 0) {
      return;
    }
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        const dom = document.getElementById(`${list[i].componentCode}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, [pageInfo?.components]);

  const handle = () => {
    props.changeIndex(1);
  };

  if (pageInfo?.components.length <= 0) {
    return;
  }
  return (
    <TouchComponent
      hasMore={hasMore}
      scrollBottom={handle}
      className=""
      styles={{ display: "block" }}>
      {pageInfo?.components?.map((item, index) => {
        return (
          <div
            key={index}
            className={taroH5CompTypeList.includes(item.type) ? styles["component-container"] : ""}>
            {item.type === "IMAGE" && (
              <React.Suspense>
                <ImageCom
                  propData={item}
                  pageData={pageInfo}
                  resourceRank={index + 1}
                  trackInfo={{
                    page_name: "店铺首页",
                    previous_page_name,
                    domain: "B2R旗舰店",
                    resource_type: "IMAGE",
                    merchant_shop_code: merchantShopCode,
                    page_code: pageInfo.pageCode,
                    resource_rank: index + 1,
                    page_version: pageInfo.version,
                  }}></ImageCom>
              </React.Suspense>
            )}
            {item.type === "BANNER" && (
              <React.Suspense>
                <BannerCom
                  propData={item}
                  pageData={pageInfo}
                  resourceRank={index + 1}
                  trackInfo={{
                    page_name: "店铺首页",
                    previous_page_name,
                    domain: "B2R旗舰店",
                    resource_type: "BANNER",
                    merchant_shop_code: merchantShopCode,
                    page_code: pageInfo.pageCode,
                    resource_rank: index + 1,
                  }}></BannerCom>
              </React.Suspense>
            )}
            {item.type === "VIDEO" &&
              item.videoConfigDetails[0].height > 0 &&
              item.videoConfigDetails[0].width > 0 && (
                <React.Suspense>
                  <VideoCom
                    propData={item}
                    pageData={pageInfo}
                    resourceRank={index}
                    trackInfo={{
                      page_name: "店铺首页",
                      previous_page_name,
                      domain: "B2R旗舰店",
                      resource_type: "VIDEO",
                      merchant_shop_code: merchantShopCode,
                      page_code: pageInfo.pageCode,
                      video_name: item.videoConfigDetails?.[0]?.name,
                      resource_rank: index + 1,
                    }}></VideoCom>
                </React.Suspense>
              )}
            {item.type === "ACTIVITY" && !isEmpty(item.dataInfo) && (
              <React.Suspense>
                {item.showTitleImg && item?.dataInfo?.result?.length > 0 && (
                  <img src={item.titleImgUrl} style={{ width: "100%", height: pxtorem(44) }}></img>
                )}
                <ActivityCom
                  propData={item}
                  pageData={pageInfo}
                  resourceRank={index + 1}
                  last={index === (pageInfo?.components?.length ?? 0) - 1 ? true : false}
                  loadMore={() => loadActivity(index)}
                  trackInfo={{
                    page_name: "店铺首页",
                    previous_page_name,
                    domain: "B2R旗舰店",
                    resource_type: "ACTIVITY",
                    merchant_shop_code: merchantShopCode,
                    page_code: pageInfo.pageCode,
                    resource_rank: index + 1,
                  }}></ActivityCom>
              </React.Suspense>
            )}
            {/* 秒杀 */}
            {item.type === "SECKILL" && item?.dataInfo?.length > 0 && (
              <React.Suspense>
                <div
                  id={`${item.componentCode}`}
                  data-param={JSON.stringify({
                    page_name: "店铺首页",
                    previous_page_name,
                    domain: "B2R旗舰店",
                    resource_type: "SECKILL",
                    resource_content: "商品",
                    resource_rank: index + 1,
                    relative_rank: 1,
                    tab_name: "首页",
                    merchant_shop_code: merchantShopCode,
                    page_code: pageInfo.pageCode,
                  })}
                  data-eventid={item.componentCode}>
                  <SeckillCard
                    propData={item}
                    pageData={pageInfo}
                    resourceRank={index}
                    {...item}
                    titleFront="H5_Shop_DecorateSales_ColumnProductNameFront"
                    titleDown="H5_Shop_DecorateSales_ColumnProductNameDown"
                    goList={(tabIndex: number) => goSecKill(item, pageInfo, tabIndex)}
                    onSKUPreview={skuPreview}
                    onDetailAction={(item: any, index: any) => goProduct(item, index)}
                    onForwardAction={forwardProduct}
                    showToast={(msg: string) => Toast.show({ content: msg })}
                  />
                </div>
              </React.Suspense>
            )}
            {item.type === "COUPON" && (
              <React.Suspense>
                <CouponCom
                  propData={item}
                  pageData={pageInfo}
                  resourceRank={index + 1}
                  trackInfo={{
                    page_name: "店铺首页",
                    previous_page_name,
                    domain: "B2R旗舰店",
                    resource_type: "COUPON",
                    type: "COUPON",
                    tab_name: "首页",
                    resource_rank: index + 1,
                    merchant_shop_code: merchantShopCode,
                    page_code: pageInfo.pageCode,
                  }}></CouponCom>
              </React.Suspense>
            )}
            {item.type === "PRODUCT" && (
              <React.Suspense>
                {item.showTitleImg && item?.dataInfo?.result?.length > 0 && (
                  <img src={item.titleImgUrl} style={{ width: "100%", height: pxtorem(44) }}></img>
                )}
                <ProductCom
                  propData={item}
                  pageData={pageInfo}
                  resourceRank={index}
                  subItem={item}
                  eventId="07_learnmaterial_materiallist_show"
                  eventParam={{ type: "product" }}
                  index={index}
                  last={index === (pageInfo?.components?.length ?? 0) - 1 ? true : false}
                  loadMore={() => loadProduct(index)}
                  trackInfo={{
                    page_name: "店铺首页",
                    previous_page_name,
                    domain: "B2R旗舰店",
                    resource_type: "PRODUCT",
                    merchant_shop_code: merchantShopCode,
                    page_code: pageInfo.pageCode,
                  }}></ProductCom>
              </React.Suspense>
            )}
            {item.type === "NAVIGATION" && (
              <React.Suspense>
                <NavigationCom
                  stickTop={44}
                  propData={item}
                  pageData={pageInfo}
                  resourceRank={index}
                  last={index === (pageInfo?.components?.length ?? 0) - 1 ? true : false}></NavigationCom>
              </React.Suspense>
            )}
            {/* 榜单 */}
            {item.type === "TOPLIST" && item?.dataInfo?.result?.length > 0 && (
              <div
                id={item.componentCode}
                data-param={JSON.stringify({
                  page_name: "店铺首页",
                  previous_page_name,
                  domain: "B2R旗舰店",
                  resource_type: "TOPLIST",
                  resource_content: "商品",
                  resource_rank: index + 1,
                  relative_rank: 1,
                  tab_name: "首页",
                  merchant_shop_code: merchantShopCode,
                  page_code: pageInfo.pageCode,
                })}
                data-eventid={item.componentCode}>
                <DecorateTopListComp
                  productList={item?.dataInfo?.result}
                  onMoreAction={() => goTopListPage(item.componentCode)}
                  onProductAction={goProductDetailForTop}
                />
              </div>
            )}
            {/* 高佣 */}
            {item.type === "PROFITADDITIONAL" && (
              <div
                id={`${item.componentCode}`}
                data-param={JSON.stringify({
                  page_name: "店铺首页",
                  previous_page_name,
                  domain: "B2R旗舰店",
                  resource_type: "PROFITADDITIONAL",
                  resource_content: "商品",
                  resource_rank: index + 1,
                  relative_rank: 1,
                  tab_name: "首页",
                  merchant_shop_code: merchantShopCode,
                  page_code: pageInfo.pageCode,
                })}
                data-eventid={item.componentCode}>
                <DecorateProfitAdditionalComp
                  productList={item?.list}
                  onProductAction={goProductDetailForPro}
                  onMoreAction={goHighSecPage}
                />
              </div>
            )}
            {/* 素材 */}
            {item.type === "MATERIAL" && (
              <div
                id={`${item.componentCode}`}
                data-param={JSON.stringify({
                  page_name: "店铺首页",
                  previous_page_name,
                  domain: "B2R旗舰店",
                  resource_type: "MATERIAL",
                  resource_content: "素材",
                  material_id: item.materialNo,
                  resource_rank: index + 1,
                  relative_rank: 1,
                  tab_name: "首页",
                  merchant_shop_code: merchantShopCode,
                  page_code: pageInfo.pageCode,
                })}
                data-eventid={item.componentCode}>
                <DecorateMaterialComp
                  models={item?.dataInfo?.result}
                  count={item?.dataInfo?.totalCount}
                  titleFrontLocationCode="H5_MaterialList_MaterialTitlefront"
                  onMoreAction={() => goMaterialList(item?.dataInfo?.extra)}
                  onMaterialAction={goMaterialContent()}
                />
              </div>
            )}
          </div>
        );
      })}
      <SizePopup
        product={sizePopupProduct}
        show={showSizePopup}
        close={() => setShowSizePopup(false)}
        toDetailByFirstProp={(product, salePropertyFirstListPicItem) =>
          goProduct(product, proIndex, salePropertyFirstListPicItem, "size")
        }
      />
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
    </TouchComponent>
  );
};
export default Home;
