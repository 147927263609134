import microApp from "@micro-zoe/micro-app";
import { useCallback, useEffect } from "react";

export enum SubAppName {
  h5shop = "h5shop",
}

export enum SubAppEventName {
  nav = "nav",
  switchRole = "switchRole",
  updateCartNum = "updateCartNum",
  posterVisibilityChange = "posterVisibilityChange",
}

/**
 * - 使用该自定义 Hook 可以为微前端子应用添加事件监听器，当子应用触发特定事件时，会执行传入的回调函数。
 * - 该 Hook 主要用于在父应用中监听子应用的消息，以便于进行相应的数据处理或状态更新。
 *
 * 为什么这么做：
 * - 解决  microApp.addDataListener 使用没有规范的情况
 * - 预防在使用 microApp.addDataListener 时，忘记移除监听器，导致内存泄漏
 * - 避免在每个监听器中重复编写对 data.type 的判断逻辑。
 */
export const useSubAppMessage = (
  eventName: SubAppEventName,
  callback: CallableFunction,
  appName = SubAppName.h5shop,
) => {
  const listener = useCallback(
    data => {
      console.log("receive message from app", data);
      if (!data || data.type !== eventName || typeof callback !== "function") {
        return;
      }
      return callback(data.value);
    },
    [callback],
  );
  useEffect(() => {
    microApp.addDataListener(appName, listener);
    return () => microApp.removeDataListener(appName, listener);
  }, [listener]);
};
