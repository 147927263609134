import { batchReceiveCoupon, clearWindowNum } from "@/api/coupon";
import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";
import { showCaptcha } from "@/utils/captcha/captcha";
import { useCommonContext } from "@/utils/context/common";
import Track from "@/utils/track";
import { Mask, Toast } from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useCheckBind from "../../hook/useCheckBind";
import { CouponInfoExt, CouponItemExt } from "../../model/CouponModel";
import CouponVenueItem from "./components/CouponItem";
import style from "./index.module.less";

interface Props {
  // 优惠券信息
  couponInfo?: CouponInfoExt;
  // 页面名称
  pageName: string;
  // 店铺code
  shopCode: string;
  // 活动id
  activityId: string;
  // 活动spuId
  activitySpuId: string;
  // 更新
  update: () => void;
  // 关闭页面
  onClose: () => void;
}

/**
 * 新人专区 新弹框
 */
const NewCustomerZonePopupV2: React.FC<Props> = ({
  couponInfo,
  pageName,
  shopCode,
  activityId,
  activitySpuId,
  update,
  onClose,
}) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [couponsId, setCouponsId] = useState<string[]>([]);
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const captchaRef = useRef<HTMLDivElement>(null);
  const rcsTraceId = useRef<string>("");
  const track = Track();
  const { userVisitInfo } = useCommonContext();
  const { bindPhonePopupVisible, setBindPhonePopupVisible, toAuth, bindPhone } = useCheckBind();

  const onCloseMask = () => {
    setShowPopup(false);
    onClose();
    track.track("resource_click", {
      page_name: pageName,
      btn_name: "关闭",
      resource_name: "品牌新客券",
      resource_type: "自动弹窗",
      resource_content: "优惠券",
      merchant_shop_code: shopCode,
      activity_id: activityId,
      activity_spu_code: activitySpuId,
      coupons_id: couponsId.join(","),
    });
  };

  const getDayDiff = (time1: number | string, time2: number | string) => {
    if (Number(time2) <= Number(time1)) {
      return 0;
    }
    const timeDiff = Math.abs(Number(time2) - Number(time1));
    const diffDays = timeDiff / (1000 * 3600 * 24);
    return diffDays;
  };

  const getFormatTime = (timestamp: number | string) => {
    let dateTime = new Date(Number(timestamp));
    return `${dateTime.getMonth() + 1}月${dateTime.getDate()}日`;
  };

  const getCountdown = (time1: number | string, time2: number | string) => {
    const time = Math.ceil((Number(time2) - Number(time1)) / 1000);
    const h = Math.trunc(time / 3600);
    const m = Math.trunc((time % 3600) / 60);
    return `${h}时${m}分`;
  };
  const getCouponList = (couponList: CouponItemExt[]) => {
    return couponList?.map(item => {
      const time1 = item.systemTime || Date.now();
      const time2 = item.endTime || Date.now();
      const diffDays = getDayDiff(time1, time2);
      const diffDaysInteger = Math.floor(diffDays);
      return {
        ...item,
        diffDays,
        endTimeDesc: getFormatTime(time2),
        diffDaysInteger,
        countdownDesc: diffDays > 0 && diffDays < 1 ? getCountdown(time1, time2) : "",
      };
    });
  };

  const thirdCustomerCoupons = useMemo(() => {
    if (couponInfo?.thirdCustomerCoupons && couponInfo?.thirdCustomerCoupons?.length) {
      return getCouponList(couponInfo.thirdCustomerCoupons);
    }
    return [];
  }, [couponInfo?.thirdCustomerCoupons]);

  const spCouponActivities = useMemo(() => {
    if (couponInfo?.spCouponActivities && couponInfo?.spCouponActivities?.length) {
      return getCouponList(couponInfo?.spCouponActivities);
    }
    return [];
  }, [couponInfo?.spCouponActivities]);

  const couponVenueClass = useMemo(() => {
    const classList = ["", "single", "couple", "triple"];
    if ((spCouponActivities?.length ?? 0) <= 3) {
      return classList[spCouponActivities?.length];
    }
    return "triple";
  }, [spCouponActivities]);

  useEffect(() => {
    let coupons: string[] = [];
    if (thirdCustomerCoupons?.length) {
      setShowPopup(true);
      const list = thirdCustomerCoupons
        ?.filter(i => i.ticketId !== undefined)
        ?.map(item => item.ticketId);
      if (list) {
        coupons = coupons.concat(list as string[]);
      }
    }
    if (spCouponActivities.length) {
      setShowPopup(true);
      const list = spCouponActivities
        ?.filter(i => i.ticketId !== undefined)
        ?.map(item => item.ticketId);
      if (list) {
        coupons = coupons.concat(list as string[]);
      }
    }
    if ((coupons?.length ?? 0) > 0) {
      setCouponsId(coupons);
      const params = {
        page_name: pageName,
        resource_name: "品牌新客券",
        resource_type: "自动弹窗",
        resource_content: "优惠券",
        merchant_shop_code: shopCode,
        activity_id: activityId,
        activity_spu_code: activitySpuId,
        coupons_id: couponsId.join(","),
      };
      track.track("resource_expose", params);
    }
  }, [thirdCustomerCoupons, spCouponActivities]);
  const initCaptcha = (tpc: string, callback: (token: string) => void) => {
    showCaptcha(tpc).then(token => {
      callback(token ?? "");
    });
  };

  const onReceiveCoupon = async () => {
    track.track("resource_click", {
      page_name: pageName,
      btn_name: "立即领券",
      resource_name: "品牌新客券",
      resource_type: "自动弹窗",
      resource_content: "优惠券",
      merchant_shop_code: shopCode,
      activity_id: activityId,
      activity_spu_code: activitySpuId,
      coupons_id: couponsId.join(","),
    });

    if (!userVisitInfo?.isAuthorized) {
      toAuth();
      return;
    }
    if (userVisitInfo?.isVisitor) {
      // 游客需要绑定手机号
      bindPhone();
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    const couponByIdReqList = [
      ...(spCouponActivities?.map(item => ({
        awdId: item.ticketId,
        promoActivityId: item.promoActivityId,
      })) || []),
      ...(thirdCustomerCoupons?.map(item => ({
        awdId: item.ticketId,
        promoActivityId: item.promoActivityId,
      })) || []),
    ];

    try {
      const res = await batchReceiveCoupon({
        couponByIdReqList,
        rcsTraceId: rcsTraceId.current,
        captchaToken,
        pageIndex: window.location.href,
        protocolVersion: "",
      });
      const { code, success, message } = res || {};
      rcsTraceId.current = res.data.rcsTraceId || "";
      if (code === "666B00004") {
        setLoading(false);
        initCaptcha(res.data && res.data.tpc, (token: string) => {
          setCaptchaToken(token);
          onReceiveCoupon();
        });
        return;
      }
      if (code === "00000" && success) {
        Toast.show("优惠券领取成功");
        setShowPopup(false);
        clearWindowNum();
        update();
      } else {
        Toast.show(message || "优惠券被领光啦");
      }
    } catch (e: any) {
      Toast.show(`${e?.message ?? "系统异常"} `);
    } finally {
      setLoading(false);
    }
  };

  const onFinishBindPhone = () => {
    setBindPhonePopupVisible(false);
  };

  return (
    <>
      <Mask visible={showPopup} onMaskClick={onCloseMask}>
        <div className={style.coupon_venue_popup}>
          <div className={style.coupon_content}>
            <i className={style.icon_close} onClick={onCloseMask}></i>
            <div className={style.coupon_title}>
              <i className={style.icon_title1}></i>
              <span className={style.amount}>{couponInfo?.totalCouponAmount}元</span>
              <i className={style.icon_title2}></i>
            </div>
            <div className={style.coupon_label}></div>
            {(thirdCustomerCoupons?.length ?? 0) > 0 && (
              <div
                className={`${style.coupon_newcomer} ${thirdCustomerCoupons.length === 2 ? style["couple"] : ""} ${thirdCustomerCoupons.length === 1 ? style["single"] : ""}`}>
                {thirdCustomerCoupons?.map((item, index) => (
                  <CouponVenueItem
                    key={index}
                    coupon={item}
                    modelType={thirdCustomerCoupons?.length > 2 ? 3 : thirdCustomerCoupons?.length}
                    type="newcomer"
                  />
                ))}
              </div>
            )}
            {(spCouponActivities?.length ?? 0) > 0 && (
              <div className={`${style.coupon_venue} ${style[couponVenueClass]}`}>
                <div
                  className={spCouponActivities.length > 3 ? style.coupon_venue_list : style.flex}>
                  {spCouponActivities?.map((item, index) => (
                    <CouponVenueItem
                      key={index}
                      coupon={item}
                      modelType={spCouponActivities?.length > 2 ? 3 : spCouponActivities?.length}
                      type="venue"
                    />
                  ))}
                </div>
              </div>
            )}
            <div className={style.coupon_btn} onClick={onReceiveCoupon}>
              领券去使用
            </div>
            <i className={style.coupon_icon}></i>
          </div>
          <div ref={captchaRef}></div>
        </div>
      </Mask>
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={onFinishBindPhone}
      />
    </>
  );
};

export default NewCustomerZonePopupV2;
