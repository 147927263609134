import useNavigation from "@/utils/useNavigate";

const useSkip = () => {
  const { navigate2Home, navigateBack } = useNavigation();

  const goHome = () => {
    navigate2Home();
  };

  return {
    goHome,
    navigateBack,
  };
};

export default useSkip;
