import { queryBrandPromotionWin } from "@/api/yearEndBonus";
import { useActions } from "@/utils/actions";
import Track from "@/utils/track";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Empty from "./components/Empty";
import RankItem from "./components/rank-item/rank-item";
import styles from "./rank.module.less";

const Rank: React.FC = () => {
  document.title = "品牌销售榜";
  const track = Track();

  const [search] = useSearchParams();
  const brandActivityCode = search.get("brandActivityCode") || "";
  const ruleType = search.get("ruleType");
  const userType = search.get("userType");

  const [pageInfo, setPageInfo] = useState<any>({});
  const [currentUser, setCurrentUser] = useState<any>({});
  const [showEmpty, setShowEmpty] = useState(false);
  const { shareAnnualBounsPosterAction } = useActions();

  const onShare = async e => {
    shareAnnualBounsPosterAction({
      contentId: brandActivityCode,
      contentType: "ANNUAL_BONUS",
      pageName: "品牌销售榜",
      src: "AnnualBonusActivity",
    });
  };

  const footerDom = () => {
    if (["AWARD_CALCULATION", "AWARD_PROCESSING", "END"].includes(pageInfo?.taskStatus)) {
      // 活动已结束
      return <div className={`${styles.footer__btn} ${styles.disabled}`}>活动已结束</div>;
    } else if (pageInfo?.lotteryFlag) {
      // 中奖
      return (
        <div className={styles.footer__btn} onClick={e => onShare(e)}>
          已达标，前往巩固排名
        </div>
      );
    } else {
      // 转发赚钱
      return (
        <div className={styles.footer__btn} onClick={e => onShare(e)}>
          <img
            className={styles.icon}
            src="https://akim-oss.aikucun.com/mshop/eeaaa563822b7c0c875f3c49e65c48e966db9716_1731416880122_13.png"
          />
          转发赚钱
        </div>
      );
    }
  };

  const getPageInfo = async () => {
    const data: any = await queryBrandPromotionWin({
      brandActivityCode,
      ruleType,
      userType,
    });
    setPageInfo(data || {});
  };

  useEffect(() => {
    const { top, middle, bottom } = pageInfo || {};
    const list = [...(top || []), ...(middle || []), ...(bottom || [])];
    let current = list.find(item => {
      return item.myselfFlag === true;
    });
    setCurrentUser(current || {});
    let bool =
      (top || []).length === 0 && (middle || []).length === 0 && (bottom || []).length === 0;
    setShowEmpty(bool);
  }, [pageInfo]);

  useEffect(() => {
    getPageInfo();
    track.pageView("品牌销售榜", {
      domain: "年终奖",
    });
    setTimeout(() => {
      document.title = "品牌销售榜";
    }, 10);
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.scroll_view}>
        {/* header */}
        {pageInfo?.showBannerCommentFlag && pageInfo?.brandRecommendVO?.bannerComment && (
          <div className={styles.page__header}>{pageInfo.brandRecommendVO.bannerComment}</div>
        )}
        {/* body */}
        <div className={styles.page__body}>
          {showEmpty && <Empty text="暂无入围用户" />}
          {pageInfo?.top?.length > 0 &&
            pageInfo.top.map(item => {
              return <RankItem item={item} key={item.rank} />;
            })}
          {pageInfo?.top?.length > 0 && pageInfo?.bottom?.length > 0 && (
            <div className={styles.rank__line}>
              <span className={styles.line__drop}></span>
              <span className={styles.line__drop}></span>
              <span className={styles.line__drop}></span>
            </div>
          )}
          {pageInfo?.middle?.length > 0 &&
            pageInfo.middle.map(item => {
              return <RankItem item={item} key={item.rank} />;
            })}
          {pageInfo?.middle?.length > 0 && pageInfo?.bottom?.length > 0 && (
            <div className={styles.rank__line}>
              <span className={styles.line__drop}></span>
              <span className={styles.line__drop}></span>
              <span className={styles.line__drop}></span>
            </div>
          )}
          {pageInfo?.bottom?.length > 0 &&
            pageInfo.bottom.map(item => {
              return <RankItem item={item} key={item.rank} />;
            })}
        </div>
      </div>
      <div className={styles.page__footer}>{footerDom()}</div>
    </div>
  );
};
export default Rank;
