import { pxtorem } from "@/utils/pxtorem";
import React from "react";
import styles from "./index.module.less";

interface Props {
  data: any;
  sortIndex: number;
  sortOrder: any;
  has: boolean;
  sticky: boolean;
  hasActivityList: boolean;
  hasMaterialTabs: boolean;
  changeSort: (index) => void;
  openFilter: () => void;
}
const calcTopHeight = (props: Props) => {
  // 44 60 40
  const defaultTopHeight = pxtorem(44);
  if (props?.hasActivityList && props?.hasMaterialTabs) {
    return pxtorem(144);
  } else if (props?.hasActivityList && !props?.hasMaterialTabs) {
    return pxtorem(104);
  }

  return defaultTopHeight;
};

const Sort: React.FC<Props> = (props: Props) => {
  const inlinStyle = {
    top: calcTopHeight(props),
    borderBottom: props.sticky ? "1px solid rgba(34, 34, 34, 0.08)" : "unset",
  };
  return (
    <div className={styles.sort} style={inlinStyle} id="material-sort">
      <div className={styles.sortDiv}>
        {props.data.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => props.changeSort(index)}
              className={styles.sortItem + " " + (props.sortIndex === index ? styles.active : "")}>
              <div>{item.name}</div>
              {item.name === "价格" && (
                <>
                  {props.sortOrder === 0 ? (
                    <img
                      className={styles.price}
                      src="https://akim-oss.aikucun.com/mshop/f763bb96a88b46618aa6010b4cc55550f80d5b4a_1676773550252_89.png"
                    />
                  ) : (
                    <>
                      {props.sortOrder === 1 ? (
                        <img
                          className={styles.price}
                          src="https://akim-oss.aikucun.com/mshop/eec3ad2e003b47ca2dd387bc88c570ac39ada344_1676773606270_99.png"
                        />
                      ) : (
                        <img
                          className={styles.price}
                          src="https://akim-oss.aikucun.com/mshop/84305e4a3a66c6db3d3b8e9eb1e5fced03f996cc_1676773633105_52.png"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          );
        })}
        {!props.has ? (
          <img
            onClick={() => props.openFilter()}
            className={styles.filter}
            src="https://akim-oss.aikucun.com/26a5c876c3e669c130c7bcf9c882a462da5ec2fe_1706239520705_44.png"
          />
        ) : (
          <img
            onClick={() => props.openFilter()}
            className={styles.filter}
            src="https://akim-oss.aikucun.com/951c5b1f8de6c9cb22f27c71cfc367a843cba1ad_1706515876962_77.png"
          />
        )}
      </div>
    </div>
  );
};

export default Sort;
