import React from "react";
import styles from "./index.module.less";

interface PurchaseLimitInfo {
  checkResult?: number;
  checkResultDesc?: string;
  imageUrl?: string;
  productTitle?: string;
  quantity?: number;
  purchaseLimitMinNum?: number;
  minPurchaseDiffNum?: number;
}

interface PurchaseLimitContentProps {
  info: PurchaseLimitInfo;
}

const PurchaseLimitContent: React.FC<PurchaseLimitContentProps> = ({ info }) => {
  if (info.checkResult === 2) {
    return (
      <div className={styles.textNotice}>
        {info.checkResultDesc || "部分商品不满足购买条件，请重新选择数量"}
      </div>
    );
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.productWrap}>
        <img src={info.imageUrl} className={styles.productPic} />
        <dl className={styles.productMain}>
          <dt className={styles.productTitle}>
            {info.productTitle}
          </dt>
          <dd className={styles.productInfo}>
            x{info.quantity}
          </dd>
        </dl>
      </div>
      {info.checkResult === 1 && (
        <div className={styles.textNotice}>
          该商品满<span className={styles.hl}>{info.purchaseLimitMinNum}</span>件起购，还差<span className={styles.hl}>{info.minPurchaseDiffNum}</span>件，请重新选择数量
        </div>
      )}
      {info.checkResult === 3 && (
        <div className={styles.textNotice}>
          {info.checkResultDesc}
        </div>
      )}
    </div>
  );
};

export default PurchaseLimitContent;