import BackPng from "@/assets/icon-arrow-left.png";
import HomePng from "@/assets/icon-home.png";
import useSkip from "@/pages/userTrack/hooks/useSkip";
import React from "react";
import styles from "./index.module.less";

const Nav: React.FC = () => {
  const { goHome, navigateBack } = useSkip();
  return (
    <>
      <div className={styles["navWrapper"]}>
        <div className={styles["nav-box"]}>
          <div className={styles["nav-box__image-box"]} onClick={() => navigateBack()}>
            <img src={BackPng} className={styles["image-box__image"]} />
          </div>
          <div className={styles["nav-box__image-box"]} onClick={goHome}>
            <img src={HomePng} className={styles["image-box__image"]} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
