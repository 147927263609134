import { queryPromoActivityCardInfo } from "@/api/appH5";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import useNavigation from "@/utils/useNavigate";
import { DecorateEquitiesCardComp } from "@akc/mx-taro-h5-components";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

interface IProps {
  appStore?: any;
  [key: string]: any;
}

const DecorateEquitiesCard: React.FC<IProps> = () => {

  const { navigate, navigate2Activity } = useNavigation();

  const [search] = useSearchParams();
  const merchantShopCode = search.get("merchantShopCode") || "";

  const [limitIncentive, setLimitIncentive] = useState(null); // 限时激励
  const [benefits, setBenefits] = useState(null); // 权益 
  const [coupon, setCoupon] = useState(null); // 优惠券信息
  const [redPacket, setRedPacket] = useState(null); // 红包活动
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<any>({});

  const querySearchPromoActivityCardList = () => {
    queryPromoActivityCardInfo({
      merShopCode: merchantShopCode
    })
      .then(res => {
        // limitIncentive-限时激励  benefits-权益 couponInfo-优惠券信息 redPacketPrizePool-红包
        const { limitedTimeDetail, benefitsDetail } = res || {};
        setLimitIncentive(limitedTimeDetail || null);
        setBenefits(benefitsDetail || null);
        // if (limitedTimeDetail || benefitsDetail || couponInfo || redPacketActivity) {
        //   setLimitIncentive(limitedTimeDetail || null);
        //   setBenefits(benefitsDetail || null);
        //   setCoupon(couponInfo || null);
        //   setRedPacket(redPacketActivity || null);
        // }
      });
  };

  const onEnterAllAction = (e) => { // 奖励中心-活动列表
    console.error("onEnterAllAction", e);
    navigate({
      h5Options: {
        url: "/mx-shop-micro/reward-center",
        query: {
          shopCode: merchantShopCode
        }
      },
    });
  };
  const onIncentiveDetailAction = (e) => {  // 激励-进入详情
    console.error("onIncentiveDetailAction", e);
    navigate({
      h5Options: {
        url: "/mx-shop-micro/reward-center/detail",
        query: {
          actId: e.actId || ""
        }
      },
    });
  };
  const onIncentiveRegisterAction = (e) => {  // 激励-立即报名
    console.error("onIncentiveRegisterAction", e);
  };
  const onCouponShareAction = (e) => {  // 优惠券-去分享
    console.error("onCouponShareAction", e);
    // shareCoupon(e as CouponItemDs);
    setSharePosterData({
      shareType: "TENANT_FULL_REDUCTION_COUPON",
      shareParam: coupon,
    });
    setShowPoster(true);
  };
  const onCouponDetailAction = (e) => { // 优惠券-去查看
    console.error("onCouponDetailAction", e);
  };
  const onRedPacketShareAction = (activity) => {
    console.error("onRedPacketShareAction", activity);
    setSharePosterData({
      shareType: "activity",
      shareParam: {
        activity,
        contentExtra: {
          queryType: "2",
          sharePageId: "H5_ScheduleDetail",
          shareActivityId: activity.activityId,
        }
      },
    });
    setShowPoster(true);
  };
  const onRedPacketActivityDetailAction = (activity) => {
    console.error("onRedPacketActivityDetailAction", activity);
    navigate2Activity(({
      activityNo: activity.activityId
    }));
  };

  const onClosePoster = () => {
    setShowPoster(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    querySearchPromoActivityCardList();
  }, []);

  return (
    <>
      <DecorateEquitiesCardComp
        limitIncentive={limitIncentive}
        benefits={benefits}
        coupon={coupon}
        redPacket={redPacket}
        enterAllAction={(e) => onEnterAllAction(e)}
        incentiveDetailAction={(e) => onIncentiveDetailAction(e)}
        incentiveRegisterAction={(e) => onIncentiveRegisterAction(e)}
        couponShareAction={(e) => onCouponShareAction(e)}
        couponDetailAction={(e) => onCouponDetailAction(e)}
        redPacketShareAction={(e) => onRedPacketShareAction(e)}
        redPacketActivityDetailAction={(e) => onRedPacketActivityDetailAction(e)}
      />
      {/* 优惠券-海报*/}
      <PosterSwipe
        show={showPoster && sharePosterData?.shareType === "TENANT_FULL_REDUCTION_COUPON"}
        posterData={sharePosterData}
        customEditData={customEditData}
        shareType="TENANT_FULL_REDUCTION_COUPON"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}
      />
      {/* 优惠券-海报*/}
      <PosterSwipe
        show={showPoster && sharePosterData?.shareType === "activity"}
        posterData={sharePosterData}
        customEditData={customEditData}
        shareType="activity"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}
      />
    </>
  );
};

export default DecorateEquitiesCard;