import { Toast } from "antd-mobile";
import { platform } from "../../utils/platform";
import { fetchCounpon, toUseCoupon, useShareConferencePoster } from "../actions";
import { useShare } from "../share/share";
import Track from "../track";
import useNavigation from "../useNavigate";

const track = Track();

/**
 * 优惠券展示状态
 */
export enum CouponDisplayStatus {
  unknown = 0, // 未知(未处理)
  pendding = 1, // 暂未开抢
  fetchNow = 2, // 立即领取
  use = 3, // 去使用
  exhaust = 4, // 已领完
  fetched = 5, // 已领取
  forward = 1000, // 去分享
}

/**
 * 获取优惠券展示状态
 *
 * @param coupon 优惠券对象
 * @returns 优惠券展示状态
 */
export const displayStatus4Coupon = coupon => {
  const { isApp } = platform;
  if (isApp) {
    // App 显示去分享
    return CouponDisplayStatus.forward;
  }

  // 1：领券 2：条件不符 3：暂未开始 4：已领取 5.去使用 6.已抢光
  switch (coupon.buttonStatus) {
    case 1: // 立即领取
      return CouponDisplayStatus.fetchNow;
    case 3: // 未开始
      return CouponDisplayStatus.pendding;
    case 4: // 已领取
      return CouponDisplayStatus.fetched;
    case 5: // 去使用
      return CouponDisplayStatus.use;
    case 6: // 已领完
      return CouponDisplayStatus.exhaust;
    default:
      break;
  }
  return CouponDisplayStatus.unknown;
};

function useCouponAction() {
  const {
    navigate2Product,
    navigate2Activity,
    navigate2OfferOrderList,
    navigate2GoodsOfNpieceXfold,
  } = useNavigation();
  const { shareConferencePosterAction } = useShareConferencePoster();
  const { shareCoupon } = useShare();
  const couponBtnAction = async (props: {
    pageData?: any;
    coupon: any;
    layoutType?: string;
    share4H5Action: () => void;
    fetchSuccessAction: () => void;
    resourceRank?: any;
    relativeRank?: any;
    trackInfo?: any;
  }) => {
    if (!props.coupon) {
      return;
    }

    const status = props.coupon.displayStatus ?? displayStatus4Coupon(props.coupon);
    switch (status) {
      case CouponDisplayStatus.forward: {
        // 分享
        track.track("btn_click", {
          page_name: document.title,
          module: "优惠券",
          btn_name: "去分享",
          btn_text: "去分享",
          ...props.trackInfo,
        });
        if (platform.isH5) {
          props.share4H5Action();
          return;
        }
        if (platform.isApp) {
          shareConferencePosterAction(props.pageData);
          return;
        }
        // 进入小程序分享页面
        shareCoupon(props.coupon.voucherNo);
        break;
      }
      case CouponDisplayStatus.fetchNow: {
        // 领取
        fetchCounpon(props.coupon).then(result => {
          if (result) {
            // 领取成功后
            props.coupon.displayStatus = CouponDisplayStatus.use;
            props.fetchSuccessAction();
            //操作埋点
            /* eslint-disable */
            if (track.track) {
              track.track("pick_up_coupon", {
                coupon_name:
                  props.layoutType == "10" || props.layoutType == "20"
                    ? `满${props.coupon.baseAmount}可用`
                    : `满${props.coupon.baseAmount}减${props.coupon?.quotaAmount}`,
                coupons_id: props.coupon.awdId,
                coupon_amount: props.coupon.quotaAmount,
                coupon_type: props.coupon.bizType,
                page_name: document.title,
                resource_type: "优惠券",
                resource_rank: props.resourceRank,
                relative_rank: props.relativeRank,
                resource_content: "优惠券",
                is_manual: "1",
                market_id: props.pageData.pageCode,
                market_name: document.title,
                btn_name: "立即领取",
                ...props.trackInfo,
              });
            }
            /* eslint-enable */
          }
        });
        break;
      }
      case CouponDisplayStatus.use: {
        /* eslint-disable */
        if (track.track) {
          track.track("btn_click", {
            page_name: document.title,
            module: "优惠券",
            btn_name: "去使用",
            btn_text: "去使用",
          });
        }
        /* eslint-enable */
        // 去使用
        const { isOldCoupon, awdId, voucherNo } = props.coupon;
        const params = isOldCoupon
          ? {
              couponId: awdId,
            }
          : {
              voucherNo,
            };
        toUseCoupon(params).then(res => {
          const { success, data, message } = res || {};
          if (success) {
            const {
              jumpType,
              couponId: _couponId,
              voucherNo: _voucherNo,
              activityId,
              awdInstanceId,
            } = data || {};
            switch (jumpType) {
              case "productDetail":
                navigate2Product({
                  activitySpuId: data.activitySpuId,
                  activityId: data.activityId,
                  selectSkuId: data.selectSkuId,
                });
                break;
              case "productList":
                navigate2GoodsOfNpieceXfold({
                  h5Query: {
                    couponId: _couponId,
                    voucherNo: _voucherNo,
                    awdInstanceId,
                  },
                  miniQuery: isOldCoupon
                    ? {
                        id: _couponId,
                        awdInstanceId,
                      }
                    : {
                        voucherNo: _voucherNo,
                        awdInstanceId,
                      },
                });
                break;
              case "activityDetail":
                navigate2Activity({
                  activityNo: activityId,
                });
                break;
              case "activityList":
                navigate2OfferOrderList({
                  h5Query: isOldCoupon ? { couponId: awdId } : { voucherNo },
                  miniQuery: isOldCoupon ? { id: awdId } : { voucherNo },
                });
                break;
              default:
                break;
            }
          } else {
            Toast.show(message || "服务异常，请稍后重试！");
          }
        });
        break;
      }
    }
  };
  return {
    couponBtnAction,
  };
}

export default useCouponAction;
