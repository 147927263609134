import { AddressListModel } from "@/pages/product-detail/model/AddressListModel";
import { MaterialListModel } from "@/pages/product-detail/model/MaterialListModel";
import { TopMaterialResponse } from "@/pages/product-detail/model/TopMaterialResponse";
import { ConverterProductInfo } from "@/pages/product-detail/normal/model/ConverterProductInfo";
import { ConvertProductIdModel } from "@/pages/product-detail/normal/model/ConvertProductIdModel";
import { PDBanner } from "@/pages/product-detail/normal/model/PDBanner";
import { platform } from "@/utils/platform";
import { getBaseurl } from "@/utils/url-parse";
import {
  ActivityModel,
  API,
  CouponModel,
  MaterialCombinationModel,
  MaterialMediaModel,
  PageableResponse,
  ProductDetailModel,
  ProductListRequestParams,
  ProductMaterialModel,
  ProductModel,
  SpuSalePropertyModel,
} from "@akc/biz-api";
import { convertResourceBanner } from "./activity";
import { Request } from "./request";
import { SearchParam } from "./type";

export type VOConfig = {
  onlyCountTotal?: boolean;
  queryCollectionFlag?: boolean;
  [key: string]: any;
};

export type ProductReqItem = {
  activitySpuId?: string;
  activityId?: string;
  firstSalePropertyValue?: string;
  selectSkuId?: string;
};

export async function queryProductList(
  pageId: string,
  searchParams: SearchParam,
  voConfig?: VOConfig,
  productReqList?: Array<ProductReqItem>,
): Promise<PageableResponse<ProductModel>> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/list`,
    method: "post",
    data: {
      pageId,
      searchParams,
      voConfig,
      productReqList,
    },
  });
  return res?.data;
}

// 查询商品销售属性信息和skuId映射列表
export const querySalePropertySkuList = async (params: {
  activitySpuId?: string;
  firstPropertyValue?: string;
  activityId?: string;
}) => {
  return API.post<SpuSalePropertyModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/product/querySpuSalePropertyVO",
    body: {
      ...params,
      needSaleProperty: true,
      needSalePropertySkuId: true,
    },
  });
};

// 查询商品详情
export const queryProductDetail = async (params: {
  activityId?: string | null;
  activitySpuId?: string | null;
  selectSkuId?: string | null;
  firstSalePropertyValue?: string | null;
  secondSalePropertyValue?: string | null;
  addressId?: string | null;
  pageId?: string;
}) => {
  return API.post<ProductDetailModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/product/info",
    body: {
      ...params,
    },
  });
};

// 请求商品连带推荐列表
export const queryProductRecommendList = async (params: {
  /** 活动SpuID（商品ID） */
  activitySpuIds?: string[];
  /** 页面ID（标签需要） */
  pageId?: string;
  /** 连带率推荐场景:
    detail_shortage:商品详情页缺货推荐
    detail_offsale:商品详情页下架推荐
    detail_buytogether:商品详情页 一起购买
    order_buymore:订单确认页顺手加购
    cartlist_buymore:购物车加购推荐
 */
  relatedScene:
    | "detail_shortage"
    | "detail_offsale"
    | "detail_buytogether"
    | "order_buymore"
    | "cartlist_buymore";
  voConfig?: ProductListRequestParams["voConfig"];
}) => {
  return API.post<PageableResponse<ProductModel>>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/product/recommendByRelated",
    body: params,
  });
};

/**
 * 获取优惠券列表
 */
export async function fetchCouponList(props?: {
  activityId?: string;
  activitySpuId?: string;
  skuId?: string;
  productPrice?: number;
  advanceActivityId?: string;
  advanceActivitySpuId?: string;
}): Promise<CouponModel[]> {
  const params = {
    activityId: props?.activityId,
    activitySpuId: props?.activitySpuId,
    skuId: props?.skuId,
    sourceType: "SKU",
    productPrice: props?.productPrice,
    advanceActivityId: props?.advanceActivityId,
    advanceActivitySpuId: props?.advanceActivitySpuId,
  };
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/coupon/queryList`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * 获取素材商品详情
 */
export const fetchProductMaterialDetail = async (params: {
  activitySpuId: string;
  pageId: string;
  activityId?: string | null;
  selectSkuId?: string | null;
  firstSalePropertyValue?: string | null;
  addressId?: string | null;
  materialNo?: string | null;
  sourceScene?: string | null;
  promoActivityIds?: string[] | null;
  awdInstanceId?: string | null;
}) => {
  return API.post<ProductMaterialModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/materialUse/v1/productMaterialDetail",
    body: params,
  });
};

/**
 * 请求商品精选素材信息
 */
export const queryTopMaterialPage = (props: {
  pageId?: string;
  baseProductIds?: string[];
  objectIds?: string[];
  queryType?: number;
  useType?: number;
  pageSize?: number;
  pageNo?: number;
  voConfig?: VOConfig;
}) => {
  const params = {
    baseProductIds: props.baseProductIds,
    objectIds: props.objectIds,
    queryType: 14,
    useType: 1,
    pageSize: 5,
    pageNo: 1,
    pageId: props.pageId,
    voConfig: props.voConfig,
    version: "3",
    channel: "h5",
  };
  return API.post<TopMaterialResponse>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/materialUse/v1/queryTopMaterialPage",
    body: params,
  });
};

/**
 * 请求商品素材信息(实拍专区)
 */
export const queryMaterialList = (props: {
  pageId?: string;
  baseProductIds?: string[];
  objectIds?: string[];
  queryType?: number;
  useType?: number;
  pageSize?: number;
  pageNo?: number;
}) => {
  const params = {
    baseProductIds: props.baseProductIds,
    objectIds: props.objectIds,
    queryType: 14,
    useType: 1,
    pageSize: 4,
    pageNo: 1,
    pageId: props.pageId,
  };
  return API.post<MaterialListModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/materialUse/v1/queryProductDetailMaterial",
    body: params,
  });
};

// 请求地址列表
export const queryAddressList = () => {
  return API.get<AddressListModel[]>({
    hostID: "h5shop",
    path: "/api/member/xdwxh5/member/address/list",
    body: {},
  });
};

export interface PMDSwitchBean {
  //素材商品详情开关状态 1:开启素材商品详情 0：关闭素材商品详情
  switchStatus?: number;
  //素材商品详情开关是否展示（配置页返回） true:展示 false：不展示
  showFlag?: boolean;
  //素材编号：switchStatus为1且商详页场景返回
  materialNo?: string;
  //商品上下架状态 ture:上架 false:下架
  spuOnline?: boolean;
  //活动在线状态 true:活动在线 false:活动结束
  activityOnline?: boolean;
}

// 获取素材商品详情开关情况
export const fetchMaterialProductSwitch = async (activitySpuCode?: string | undefined | null) => {
  return API.post<PMDSwitchBean>({
    path: "/api/mshop/mshop-aggr-prod/outer/materialUse/v1/productMaterialDetailSwitch/query",
    body: {
      scene: "PRODUCT_DETAIL",
      activitySpuCode: activitySpuCode,
    },
  });
};

// 请求商品推荐列表
export const queryRecommendList = async (params: {
  pageIndex?: number;
  pageSize?: number;
  activitySpuIds?: string[];
  pageId?: string;
  scene?: string;
  categoryCode?: string;
  voConfig?: ProductListRequestParams["voConfig"];
}) => {
  return API.post<PageableResponse<ProductModel>>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/product/recommend",
    body: params,
  });
};

// 请求banner信息
export const queryBannerInfo = async () => {
  const res = await API.post<PDBanner[]>({
    hostID: "h5shop",
    path: `/api/commodity/subscribe/v2/queryActivityBannerV2/${localStorage.getItem(
      "shopId",
    )}?location=PRODUCT_DETAIL&newFlag=1`,
    body: {},
  });
  return convertResourceBanner(res ?? []);
};

// 以图搜索商品列表
export const searchByImage = async (params: {
  enableDetect: boolean; // 是否开启多主体识别
  imgUrl: string; // 图片url
  pageId: string; // 页面ID（标签需要）
  pageNum: number; // 当前页码
  pageSize: number; // 每页条数
  searchRequestId?: string; // 搜索请求ID
  voConfig: {
    showType: number;
  };
  currentRect?: {
    // 当 isInit 为 true 时才需要的字段
    x: number;
    y: number;
    width: number;
    height: number;
  };
}) => {
  return API.post({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/product/searchByImage",
    body: params,
  });
};

/**
 * @description 活动卡片for商详页
 * @link https://yapi.akcops.com/project/1799/interface/api/409155
 * @author 白国庆
 * */
export const queryActivityCardForProductDetail = async (params: {
  pageId?: string;
  activityId?: string;
  brandId?: string;
  spuId?: string;
  categoryCode?: string;
  merchantShopCode?: string;
  searchRequestId?: string;
}) => {
  return API.post<ActivityModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/activity/cardForProductDetail",
    body: params,
  });
};

/**
 * @description 商家店铺卡片for商详页
 * @link https://yapi.akcops.com/project/1799/interface/api/409164
 * @author 白国庆
 * */
export const queryMerchantShopCardForProductDetail = async (params: {
  pageId?: string;
  activityId?: string;
  brandId?: string;
  spuId?: string;
  categoryCode?: string;
  merchantShopCode?: string;
  searchRequestId?: string;
}) => {
  return API.post<ActivityModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/merchant-shop/cardForProductDetail",
    body: params,
  });
};

/**
 * productId 换 spuId
 */
export const convertProIdToSpuId = async (params: { convertType?: number; skcIds?: string[] }) => {
  return API.post<ConvertProductIdModel[]>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/product/convertProductId",
    body: params,
  });
};

/**
 * 商品详情转换逻辑
 * productNum: 播号（商品失效信息不全时，兜底使用）
 */
export const getConverterInfo = async (params: {
  activityId?: string | null;
  activitySpuId?: string | null;
  productNum?: string | null;
}) => {
  return API.post<ConverterProductInfo>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/product/converter",
    body: params,
  });
};

/**
 * 单独查询C积分总额
 */
export const gqueryCGoldCoinAmount = async params => {
  let paramdata = {
    variables: {
      ...params,
    },
    query: `query integralCoinCenter {
      integralCoinCenter {
        coinAccount {
          amount
          id
          yesterdaySendCount
          totalAmount
        }
      }
    }`,
  };
  const res = await Request({
    url: `${getBaseurl()}/api/gquery`,
    method: "post",
    data: paramdata,
  });
  return res.data;
};

/**
 * @description 素材合并
 * @link https://yapi.akcops.com/project/1799/interface/api/412089
 * @author 王亮亮
 *
 * */
export const queryProductDetailMaterialInfo = (params: {
  pageId?: string;
  activityId?: string;
  activitySpuId?: string;
  selectSkuId?: string;
  itemCode?: string;
}) => {
  return API.post<MaterialCombinationModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/materialUse/v1/queryProductMaterial",
    body: { ...params, useType: 1, version: 5 },
  });
};

/**
 * @description 查询商品视频素材内容
 * @link https://yapi.akcops.com/project/2427/interface/api/420973
 * @author 堇泽
 */
export const queryProductMediaMaterial = (params: {
  activityId?: string;
  activitySpuId?: string;
  selectSkuId?: string;
  itemCode?: string;
}) => {
  return API.post<MaterialMediaModel[]>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/materialUse/v1/queryProductMediaMaterial",
    body: { ...params, useType: 1, version: 5 },
  });
};

/**
 * @description 固定入口icon显示开关
 * @link https://yapi.akcops.com/project/1799/interface/api/322998
 * @author 桑国凯
 *
 * */
export const queryFavioriteStatus = (params: { businessCode?: string }) => {
  return API.get<boolean>({
    hostID: "h5shop",
    path: "api/mshop/mshop-aggr-prod/outer/api/group/popup/iconView",
    body: params,
  });
};
export const queryProductSnapshotDetail = async (params: {
  itemCode?: string | null;
  skuId?: string | null;
  spuVersion?: number | null;
}) => {
  const { isApp, isSaas, isMini } = platform;
  let url = `${process.env.REACT_APP_MICRO_APP_URL}/api/order/v2/product/snapshot`;
  if (isApp) {
    url = `${process.env.REACT_APP_ZUUL_INFRA}/akucun-order-center/api/order/product/snapshot`;
  } else if (isMini) {
    url = `${process.env.REACT_APP_PAGE_URL}/api/order/v2/product/snapshot`;
  } else if (isSaas) {
    url = `${process.env.REACT_APP_SAAS_URL}/api/order/v2/product/snapshot`;
  }
  console.log("queryProductSnapshotDetail>>>", url, ",params=", params);
  const res = await Request({
    url: url,
    method: "get",
    params: params,
  });
  return res?.data;
};

export type CommentItem = {
  id?: number;
  userName?: string;
  userHeadImgUrl?: string;
  content?: string;
  productSpecs?: string;
  commentTime?: string;
  score?: number;
};

export type CommentListResponse = {
  currentPageSize?: number;
  extra?: null;
  pageIndex?: number;
  pageSize?: number;
  pages?: number;
  result?: CommentItem[];
  total?: string;
};

/**
 * @description 获取评论列表
 *
 * */
export const getCommentList = (params: {
  productId: string;
  pageIndex: number;
  pageSize: number;
}) => {
  return API.post<CommentListResponse>({
    hostID: "h5shop",
    path: "/api/cms/simple/comment/pageList",
    body: { ...params },
  });
};
