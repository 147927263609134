import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { config } from "../jifen-board/jifenConfig";
import styles from "./index.module.less";

interface MyEquityProps {
  memberInfo?: any;
}

const MyEquity: React.FC<MyEquityProps> = ({ memberInfo }) => {
  const { level = 1, memberDiscountVO = {} } = memberInfo || {};
  const [search] = useSearchParams();
  const { bannerInfos = [] } = memberDiscountVO;
  const { navigate2H5, navigate2OfferOrderList, navigate2RankAll } = useNavigation();
  const track = Track();
  const countdownTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [bannerInfo, setBannerInfo] = useState<any>(null);
  const [expireTime, setExpireTime] = useState("");
  const [cfg, setCfg] = useState(config[1]);
  const [memberData, setMemberData] = useState({
    growthValue: "0",
    levelAliasName: "萌芽会员"
  });

  // 辅助方法：更新倒计时显示
  const updateCountdown = (remainTime: number) => {
    const hours = Math.floor(remainTime / (60 * 60 * 1000));
    const minutes = Math.floor((remainTime % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((remainTime % (60 * 1000)) / 1000);

    // 格式化为两位数
    const formatNumber = (n: number) => n < 10 ? `0${n}` : n;

    const timeStr = `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
    setExpireTime(timeStr);
  };

  // 设置过期时间逻辑
  const setExpireTimeLogic = (bannerInfo: any) => {
    if (!bannerInfo) {
      return;
    }

    // 获取过期时间
    const expireTime = Number(bannerInfo.expire);
    if (!expireTime) {
      return;
    }

    // 将过期时间转换为毫秒时间戳
    const expireTimestamp = new Date(expireTime).getTime();
    const currentTimestamp = new Date().getTime();

    // 计算剩余时间（毫秒）
    let remainTime = expireTimestamp - currentTimestamp;
    if (remainTime <= 0) {
      setExpireTime("00:00:00");
      return;
    }

    // 计算剩余天数
    const days = Math.floor(remainTime / (24 * 60 * 60 * 1000));

    // 逻辑1：当过期时间大于等于1天且小于等于7天：展示天、时、分
    if (days >= 1 && days <= 7) {
      const hours = Math.floor((remainTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
      const minutes = Math.floor((remainTime % (60 * 60 * 1000)) / (60 * 1000));

      const timeStr = `${days}天${hours}时${minutes}分`;
      setExpireTime(timeStr);
    }
    // 逻辑2：如果当过期时间小于1天：展示时、分、秒，需要显示倒计时的变化
    else if (days < 1) {
      // 初始设置一次
      updateCountdown(remainTime);

      // 清除可能存在的旧定时器
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current);
      }

      // 设置定时器，每秒更新倒计时
      countdownTimerRef.current = setInterval(() => {
        remainTime -= 1000;
        if (remainTime <= 0) {
          if (countdownTimerRef.current) {
            clearInterval(countdownTimerRef.current);
          }
          setExpireTime("00:00:00");
          return;
        }
        updateCountdown(remainTime);
      }, 1000);
    }
    // 大于7天的情况
    else {
      setExpireTime(`${days}天后过期`);
    }
  };

  // 点击埋点
  const clickPoint = (name: string) => {
    track?.track("btn_click", {
      page_name: "我的",
      resource_type: "会员中心",
      btn_name: name,
      previous_page_name: search.get("page_name") ?? search.get("previous_page_name"),
    });
  };

  // 跳转到我的奖励页面
  const toDailyReward = () => {
    navigate2H5("/mx-shop-micro/reward");
  };

  // 跳转到优惠券
  const toCoupon = () => {
    navigate2H5("/#/coupon");
  };

  // 跳转到会员中心
  const toCenter = () => {
    if (memberInfo.showPoint) {
      navigate2H5("/#/membercenter");
      return;
    }
    const levelMap = {
      0: "zero",
      1: "one",
      2: "two",
      3: "three",
      4: "four",
      5: "five",
    };
    let query = `rankequity=${levelMap[memberInfo.level]}`;
    if (memberInfo.returnRatio !== undefined) {
      query += `&returnRatio=${memberInfo.returnRatio}`;
    }
    // 跳转到更多权益
    navigate2H5("/#/rankequity", { query });
  };

  // 初始化数据
  const initData = () => {
    setCfg(config[level] || config[1]);
    setBannerInfo(bannerInfos[0] || null);

    // 设置会员信息
    setMemberData({
      growthValue: memberInfo.growthValue?.toString() || "0",
      levelAliasName: config[level]?.name || "萌芽会员"
    });

    // 设置过期时间
    if (bannerInfos[0]) {
      setExpireTimeLogic(bannerInfos[0]);
    }
  };

  // 处理tab点击
  const handleTabClick = (type: string) => {
    if (type === "packet") {
      toDailyReward();
      clickPoint("红包");
    } else if (type === "coupon") {
      toCoupon();
      clickPoint("优惠券");
    } else if (type === "point") {
      toCenter();
      clickPoint("积分");
    }
  };

  // 点击跳转到会员中心
  const handleCenterClick = () => {
    toCenter();
    clickPoint("会员中心");
  };

  // 去使用跳转
  const handleRedPacketClick = () => {
    const { type, voucherNo = "", couponId = "", awdInstanceId = "" } = bannerInfo || {};

    // type = 1, 是红包调整到榜单页面
    if (type === 1) {
      navigate2RankAll();
      clickPoint("红包去使用");
      return;
    }
    if (type === 2 || type === 3) {
      // 优惠券跳转到凑单页面
      navigate2OfferOrderList({
        h5Query: { voucherNo, couponId, awdInstanceId },
      });
      clickPoint("优惠券去使用");
      return;
    }
    // 日日奖跳转到日日奖页面
    navigate2H5("/mx-shop-micro/daily-reward");
    clickPoint("日日奖去使用");
  };

  // 初始化数据
  useEffect(() => {
    if (!memberInfo) {
      return;
    }

    initData();

    // 组件卸载时清除定时器
    return () => {
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current);
      }
    };
  }, [memberInfo]);

  // 页面加载时埋点
  useEffect(() => {
    track?.track("resource_expose", {
      page_name: "我的",
      resource_type: "会员中心",
      previous_page_name: search.get("page_name") ?? search.get("previous_page_name"),
    });
  }, []);

  // XX元红包待使用
  const BannerCpnt = useMemo(() => {
    if (!bannerInfo) {
      return null;
    }
    // 1红包，2平台优惠券，3店铺优惠券，4日日奖
    if (bannerInfo.type === 1 || bannerInfo.type === 2 || bannerInfo.type === 3) {
      return (
        <div className={styles["red-packet-banner"]}>
          <div className={styles["red-packet-left"]}>
            <img
              className={styles["red-packet-icon"]}
              src={bannerInfo.icon}
              alt="红包图标"
            />
            {bannerInfo && <div className={styles["red-packet-info"]}>
              <div className={styles["red-packet-value"]}>
                {bannerInfo.amount && <span className={styles["red-packet-ticket"]}>{bannerInfo.amount}元</span>}
                {bannerInfo.text}
              </div>
              {expireTime && <div className={styles["red-packet-expire"]}>{expireTime} 后失效</div>}
            </div>}
          </div>
          <div
            className={styles["red-packet-btn"]}
            onClick={handleRedPacketClick}
          >
            去使用
          </div>
        </div>
      );
    }
    return (
      <div className={styles["red-packet-banner"]}>
        <div className={styles["red-packet-left"]}>
          <img className={styles["red-packet-icon"]} src={bannerInfo.icon} alt="红包图标" />
          <div className={styles["red-packet-info"]}>
            <div className={styles["red-packet-value"]}>{bannerInfo.text || "签到抽奖领红包"}</div>
          </div>
        </div>
        <div className={styles["red-packet-btn"]} onClick={handleRedPacketClick} >去抽奖</div>
      </div>
    );
  }, [bannerInfo]);


  return (
    <div className={styles["my-equity-wrap"]}>
      <div className={styles["equity-card"]}>
        {/* 左侧：我的权益 */}
        <div className={styles["equity-section"]}>
          <div className={styles["section-title"]}>我的权益</div>
          <div className={styles["equity-content"]} style={{ marginTop: bannerInfo ? "0px" : "30px" }}>
            {/* 红包 */}
            <div
              className={styles["equity-item"]}
              onClick={() => handleTabClick("packet")}
            >
              <div className={styles["equity-label"]}>红包</div>
              <div className={styles["equity-value"]}>
                <span className={styles["label-packet"]}>￥</span>
                <span>{memberInfo?.memberDiscountVO?.redTotalAmount}</span>
              </div>
            </div>
            {/* 优惠券 */}
            <div
              className={styles["equity-item"]}
              onClick={() => handleTabClick("coupon")}
            >
              <div className={styles["equity-label"]}>优惠券</div>
              <div className={styles["equity-value"]}>
                <span>{memberInfo?.memberDiscountVO?.couponQuantity}</span>
                <span className={styles["label-coupon"]}>张</span>
              </div>
            </div>
            {/* 积分 */}
            {memberInfo?.freeBalance > 0 && (
              <div
                className={styles["equity-item"]}
                onClick={() => handleTabClick("point")}
              >
                <div className={styles["equity-label"]}>积分</div>
                <div className={styles["equity-value"]}>{memberInfo.freeBalance}</div>
              </div>
            )}
          </div>

          {/* XX元红包待使用 */}
          {BannerCpnt}
        </div>

        {/* 右侧：会员（权益）中心 */}
        <div
          className={styles["member-section"]}
          onClick={handleCenterClick}
        >
          <div className={styles["member-header"]}>
            <div className={styles["section-title"]}>{memberInfo?.showPoint ? "会员中心" : "权益中心"}</div>
            <div className={styles["arrow-right"]}>
              <img
                src="https://akim-oss.aikucun.com/35b362ad53d641a08e9d5c54f336832077907f4d_1742372416857_98.png"
                alt="箭头"
              />
            </div>
          </div>

          <div className={styles["member-content"]}>
            <div className={styles["growth-box"]}>
              <div className={styles["growth-value"]}>{memberData.growthValue}</div>
              <div className={styles["growth-label"]}>成长值</div>
            </div>

            <div className={styles["member-tag"]}>
              <img
                className={styles["member-tag-icon"]}
                src={cfg.icon}
              />
              <span>{memberData.levelAliasName}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyEquity;
