import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

interface Props {
  text?: any;
}

const EmptyShowTipView: React.FC<Props> = (props: Props) => {
  const { text } = props;
  const [countdown, setCountdown] = useState(3); // 初始化倒计时为3秒

  useEffect(() => {
    // 设置定时器
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          clearInterval(timer); // 清理定时器
          return 0; // 倒计时结束
        }
        return prev - 1; // 每秒减少1
      });
    }, 1000);

    // 清理定时器
    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.textView}>
        <div className={styles.text}>榜单数据实时更新，当前内容失效啦～</div>
      </div>
      <div className={styles.tips}>还有 {countdown} 秒页面自动刷新~</div>
    </div>
  );
};

export default EmptyShowTipView;
