import { ContentType } from "@/api/scode/scode_params";
import loadingPng from "@/base64";
import PosterSwipePro from "@/components/poster-swiper/pro";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import AKJS from "@akc/akjs";
import { Image, } from "antd-mobile";
import { head, set } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import BackView from "./components/BackView";
import customStyles from "./components/CustomTopBack.module.less";
import EmptyShowTipView from "./components/EmptyShowTipView";
import EmptyView from "./components/EmptyView";
import FloatingTopBar from "./components/FloatingTopBar";
import RankBottomListView from "./components/RankBottomListView";
import { useRankAll } from "./hooks/useRankAll";
import { useRankShare } from "./hooks/useRankShare";
import styles from "./RankAll.module.less";

/**
 * 全部榜单
 */

const RankAll: React.FC = () => {
  const control = useRankAll();
  const { rankDataList, getRankDataList, hasMore, containerDom,
    onShowTabPop, tabsData, skin, showTabPop, goToRankScale, selectedTabIndex, track, search, loading, showEmptyShowTip, commonContext } = control;

  // 添加吸顶效果相关状态
  const [isTabSticky, setIsTabSticky] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const scrollableContentRef = useRef<HTMLDivElement | null>(null);
  const tabDivRef = useRef<HTMLDivElement | null>(null);
  const tabThreshold = useRef<number>(0);
  const isInitialized = useRef<boolean>(false);

  // 使用榜单分享Hook
  const { swiperControl, handleShareRank } = useRankShare({
    rankShare: {
      contentType: ContentType.rankCenter,
      contentId: tabsData?.[selectedTabIndex]?.categoryId || "0",
      title: tabsData?.[selectedTabIndex]?.categoryName || "热卖榜单",
      marketName: "榜单中心",
    },
    dependencies: [tabsData, selectedTabIndex],
  });


  const getStatusBar = () => {
    if (platform.isApp) {
      AKJS.ready().then(() => {
        AKJS.info.device().then((res: any) => {
          return (res?.data?.statusBarHeight || 50);
        });
      });
    }
    return 50;
  };

  const recoverTabs = (index: number) => {
    onShowTabPop(false);
    setIsTabSticky(false);
    control.onClickTab(index);
  };

  const tabsHeight = () => {
    if (platform.isApp) {
      if (isTabSticky) {
        return (getStatusBar() - 20) + "px";
      } else {
        return "90px";
      }
    } else if (platform.isMini) {
      if (isTabSticky) {
        return "0px";
      } else {
        return "68px";
      }
    } else {
      if (isTabSticky) {
        return "44px";
      } else {
        return "68px";
      }
    }
  };

  const getTsabModalTop = () => {
    if (platform.isApp) {
      if (isTabSticky) {
        return "70px";
      } else {
        return "130px";
      }
    } else if (platform.isMini) {
      if (isTabSticky) {
        return "45px";
      } else {
        return "114px";
      }

    } else {
      if (isTabSticky) {
        return "94px";
      } else {
        return "121px";
      }

    }
  };


  const getScrollableTop = () => {
    if (platform.isApp) {
      if (isTabSticky) {
        return (getStatusBar() - 20) + "px";
      } else {
        return "128px";
      }
    } else if (platform.isMini) {
      if (isTabSticky) {
        return "0px";
      } else {
        return "108px";
      }
    } else {
      if (isTabSticky) {
        return "0px";
      } else {
        return "108px";
      }
    }
  };

  const clickShareMontyBtn = () => {
    swiperControl.open();
    console.log("clickShareMontyBtn");

    if (track.track) {
      track.track("btn_click", {
        page_name: "榜单中心",
        tab_name: tabsData[selectedTabIndex]?.categoryName,
        tab_id: tabsData[selectedTabIndex]?.categoryId,
        previous_page_name: search.get("page_name"),
        btn_name: commonContext.currentRole === "C" ? "分享榜单" : "转发赚钱",
        btn_text: commonContext.currentRole === "C" ? "分享榜单" : "转发赚钱"
      });
    }
  };

  useEffect(() => {
    // app取消吸顶效果
    if (platform.isApp) {
      return;
    }

    const scrollableContent = containerDom.current;
    if (!scrollableContent) {
      console.error("scrollableContent is not defined");
      return; // 退出 useEffect，避免后续代码出错
    }
    scrollableContentRef.current = scrollableContent;

    const initTabPosition = () => {
      const tadDiv = document.getElementById("tadDiv");
      if (tadDiv) {
        tabDivRef.current = tadDiv as HTMLDivElement;
        const rect = tadDiv.getBoundingClientRect();
        tabThreshold.current = rect.top + (window.pageYOffset || document.documentElement.scrollTop || 0);
        isInitialized.current = true;
      } else {
        console.error("tadDiv is not found");
      }
    };

    if (!isInitialized.current) {
      setTimeout(initTabPosition, 300);
    }

    let lastScrollTop = 0; // 使用变量存储上次滚动位置
    let isTabStickyRef = false; // 使用变量存储吸顶状态
    let isAtBottom = false; // 新增状态标识，判断是否滑动到底部

    const handleScroll = () => {
      const scrollTop = scrollableContent.scrollTop;
      const { scrollHeight, clientHeight } = scrollableContent;

      // 使用更精确的阈值判断是否滑动到底部
      const isAtBottomThreshold = 5; // 设置阈值
      if (scrollTop + clientHeight >= scrollHeight - isAtBottomThreshold) {
        isAtBottom = true; // 设置为已到达底部
        scrollableContent.scrollTo({ top: scrollHeight - clientHeight, behavior: "smooth" }); // 使用 scrollTo 方法确保不回弹
        return; // 到达底部，阻止后续处理
      } else {
        isAtBottom = false; // 未到达底部
      }

      // 使用 requestAnimationFrame 来优化状态更新
      window.requestAnimationFrame(() => {
        if (!isAtBottom) { // 仅在未到达底部时更新状态
          if (scrollTop > 100) {
            if (!isTabStickyRef) {
              setIsTabSticky(true);
              isTabStickyRef = true; // 更新状态
            }
          } else {
            if (isTabStickyRef) {
              setIsTabSticky(false);
              isTabStickyRef = false; // 更新状态
            }
          }
        }

        lastScrollTop = scrollTop; // 更新上次滚动位置
      });
    };

    scrollableContent.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollableContent) {
        scrollableContent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerDom]);

  useEffect(() => {
    document.title = "热卖榜单";
    if (platform.isApp) {
      AKJS.ready().then(() => {
        AKJS.action
          .dispatch("event.navigationBar.hide", {
            hide: true,
          })
          .catch(error => {
            console.error("设置顶部导航栏失败:", error);
          });
      });
    }
  }, []);


  return (
    <div className={`${styles["container"]} ${platform.isH5 ? customStyles.container_with_top_back : ""}${platform.isiOS ? customStyles.noBounce : ""}`}>
      {/* 榜单分享 */}
      <PosterSwipePro {...swiperControl.props} />

      {
        showEmptyShowTip && <EmptyShowTipView />
      }

      {
        platform.isH5 && (<FloatingTopBar type={isTabSticky ? 1 : 0} />)
      }

      {
        platform.isApp && <BackView />
      }

      {
        platform.isApp ? <div style={{ visibility: isTabSticky ? "hidden" : "visible" }}>
          <img className={styles.bg_imgapp} />
          <div className={styles.shop_headerapp} style={{ top: getStatusBar() }}>
            <img
              className={styles.header_icon_left}
              src="https://akim-oss.aikucun.com/mshop/0f9c59dc8f356bb53f340af885b02bb8be70c3ed_1700709311339_34.png"
            />
            <span className={styles.header_titleapp}>热卖榜单</span>
            <img
              className={styles.header_icon_right}
              src="https://akim-oss.aikucun.com/mshop/9315f3842dbebfd918d6f567aff82d6c55038d34_1700710051322_13.png"
            />
          </div>
        </div> : <div style={{ visibility: isTabSticky ? "hidden" : "visible" }}>
          <img className={styles.bg_img} />
          <div className={styles.shop_header}>
            <img
              className={styles.header_icon_left}
              src="https://akim-oss.aikucun.com/mshop/0f9c59dc8f356bb53f340af885b02bb8be70c3ed_1700709311339_34.png"
            />
            <span className={styles.header_title}>热卖榜单</span>
            <img
              className={styles.header_icon_right}
              src="https://akim-oss.aikucun.com/mshop/9315f3842dbebfd918d6f567aff82d6c55038d34_1700710051322_13.png"
            />
          </div>
        </div>
      }

      {loading ? null : tabsData && tabsData.length === 0 && <EmptyView text={"数据实时更新，当前页面失效啦~"} />}

      {/* 固定的Tab栏 */}
      <div
        className={`${styles.tabs}`}
        style={{
          position: "fixed",
          top: tabsHeight(),
          zIndex: 100
        }}
      >
        {tabsData && tabsData.length > 0 && <div
          className={`${styles.tadDiv} ${isTabSticky ? styles.stickyTadDiv : ""}`}
          id="tadDiv"
          style={{
            background: "#fff",
            width: "100%"
          }}
        >
          <div className={`${styles.tabLine} ${showTabPop ? styles.line11 : ""}`}>
            <div className={styles.tabTile}>
              {tabsData && tabsData.map((item, index) => {
                return (
                  <div
                    className={`${styles.tab} ${index === selectedTabIndex ? styles.curTab : styles.uncurTab}`}
                    style={{
                      width: `${(item?.categoryName || "").length * 0.36}rem`,
                    }}
                    id={`category-${index}`}
                    key={index}
                    onClick={() => recoverTabs(index)
                    }>
                    <div className={styles.text}>{item?.categoryName || ""}</div>
                    {/* 在每个Tab内部放置下划线，这样它会跟随文字一起移动 */}
                    {index === selectedTabIndex && (
                      <div className={styles.selectedLineContainer}>
                        <div className={styles.selectedLine} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>}

        {/* 将按钮放在tabs内部，确保它能正确显示 */}
        {tabsData && tabsData.length > 0 && !showTabPop && (
          <img
            onClick={() => onShowTabPop(true)}
            className={styles.btn}
            id="modal_content"
            src="https://akim-oss.aikucun.com/e5df14d00708c3e59aecc79227c867c87d73a747_1742203402491_24.png"></img>
        )}

        {tabsData && tabsData.length > 0 && showTabPop && (
          <img
            onClick={() => onShowTabPop(false)}
            className={styles.btn}
            id="modal_content"
            src="https://akim-oss.aikucun.com/38f258b236203d761b8cd7711e6b109335dcc09c_1742203470355_15.png"></img>
        )}
      </div>


      {
        tabsData && tabsData.length > 0 && (
          <div
            className={styles.tabModal}
            style={{
              visibility: `${showTabPop ? "visible" : "hidden"}`,
              top: getTsabModalTop(),  // 保持固定的顶部位置
              height: isTabSticky ? "calc(100vh - 40px)" : "calc(100vh - 145px)",
              zIndex: 99,
              position: "fixed",
              width: "100%",
            }}
            onClick={() => onShowTabPop(false)}>
            <div
              className={styles.content}
              id="categories-in-modal"
              style={{
                transform: "translateY(0px)",
                transition: "transform 0.3s",
              }}>
              {tabsData.map((item, index) => {
                return (
                  <div
                    key={index}
                    id="modal_content"
                    className={
                      styles.category +
                      " " +
                      (index === selectedTabIndex ? styles.category_selected : styles.uncategory_selected)
                    }
                    onClick={() =>
                      recoverTabs(index)
                    }>
                    <div id="modal_content" className={(index === selectedTabIndex ? styles.text : styles.untext)}>
                      {item?.categoryName || ""}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.popBottom} />
          </div>
        )
      }

      <div
        className={styles.scrollable_content}
        ref={containerDom}
        style={{
          paddingBottom: isTabSticky ? platform.isiOS ? "100px" : "80px" : "0px", // 在吸顶状态下增加顶部内边距

          // paddingBottom: isTabSticky ? "160px" : "160px", // 在吸顶状态下增加顶部内边距
          top: getScrollableTop(),  // 保持固定的顶部位置
          height: platform.isApp ? "calc(100vh - 200px )" : "calc(100vh - 90px )" // 减去顶部和底部的高度
        }}
      >

        {loading ? (
          <div className={styles.loading}>
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div>) : <RankBottomListView data={rankDataList} loadMore={() => getRankDataList(selectedTabIndex)} tabName={tabsData?.[selectedTabIndex]?.categoryName}
            hasMore={hasMore} toRankDetailAction={(rankno, tsindex) => goToRankScale(rankno, tsindex)} type={1} />
        }
      </div>

      {
        tabsData && tabsData.length > 0 && <div className={styles.bottomBtn} onClick={() => clickShareMontyBtn()}>
          {
            commonContext.currentRole === "C" ?
              <div className={styles.bottomBtnText}>分享榜单</div>
              : <div className={styles.bottomBtnTextMoney}>转发赚钱</div>
          }
        </div>
      }

      {
        showTabPop && <div className={styles.bottomBtnBg}></div>
      }

    </div >
  );
};

export default RankAll;
