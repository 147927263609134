import { HotRankDetailModel } from "@/api/rank";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import React, { useEffect } from "react";
import EmptyView from "../EmptyView";
import styles from "./index.module.less";

import {
  PriceInfoModel,
  ProductDetailModel,
  ProductMaterialModel,
  ProductModel,
} from "@akc/biz-api";

import {
  ProductCard11Comp,
} from "@akc/mx-taro-h5-components";
import { useSearchParams } from "react-router-dom";


interface Props {
  rankDetail?: HotRankDetailModel;
  handleShareProduct?: (product: ProductModel | ProductDetailModel | any) => void;
  pageName?: string;
}

const TopListView: React.FC<Props> = (props: Props) => {
  const { rankDetail, handleShareProduct, pageName } = props;

  const [search] = useSearchParams();

  const { navigate2Product, navigateBack } = useNavigation();

  useEffect(() => {
    console.log("rankDetail", rankDetail);
    console.log("rankDetail", rankDetail?.productList);
    console.log("rankDetail", rankDetail?.productList?.length);

  }, []);

  const goToProductDetail = (product?: ProductModel | ProductDetailModel | any) => {
    navigate2Product({
      activitySpuId: product.activitySpuId ?? "",
      activityId: product.activityId,
      selectSkuId: product.selectSkuId,
    });
  };

  if (rankDetail?.productList && rankDetail?.productList.length > 0) {
    return (
      <div className={styles.list}>
        {
          rankDetail?.productList.map((item, index) => <ProductCard11Comp
            key={index}

            className={styles.pCard}
            product={item}
            isHotRankPage={true}
            priceInfo={
              item?.skuAdvancePriceInfo ||
              item?.skuCurrentPriceInfo
            }
            titleBottomLocationCode="H5_ProductDetail_Material_ProductTitleDown"
            isShowAddCart={false}
            onSkusClick={product => {
              // sku切换
              goToProductDetail(product);
            }}
            onBuyClick={product => {
              //点击「立即购买」
              goToProductDetail(product);
              // control.onBuyAction(product);
            }}
            onProdutClick={product => {
              //点击「商品卡片」
              goToProductDetail(product);
            }}
            onAddCartClick={product => {
              // 加购
              goToProductDetail(product);
            }}
            onItemClick={product => {
              //点击「商品卡片」
              goToProductDetail(product);
            }}
            onForwardClick={product => {
              //点击「分享」
              handleShareProduct && handleShareProduct(product);
            }}
            rankIndex={index + 1}
            trackData={{
              page_name: pageName,
              page_code: rankDetail?.rankNo,
              previous_page_name: search.get("previous_page_name") ?? search.get("page_name") ?? pageName,
              domain: "榜单详情",
              module: "TOP 商品",
              resource_type: "新榜单",
              resource_content: "商品",
              resource_name: rankDetail?.rankName,
              resource_id: rankDetail?.rankNo,
              resource_rank: index + 1,
              activity_id: item?.activityId,
              activity_spu_id: item?.activitySpuId,
              select_sku_id: item?.selectSkuId,
              product_model: item.activityType === 4 ? "普通商品" : "档期商品",
            }}
          />)
        }
      </div>
    );

  } else {
    return <EmptyView text={
      <>
        数据实时更新，当前榜单已过期，
        < br />
        逛逛其他榜单吧~
      </>
    } />;
  }

};

export default TopListView;
