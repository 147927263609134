import { API } from "@akc/biz-api";

// 查询默认手机号
export const queryDefaultPhone = async () => {
  return API.get<string>({
    hostID: "h5shop",
    path: "/api/mini/member/dccIdentify/queryDefaultPhone",
  });
};

// 校验修改手机号次数标识
export const checkChangeMobileTimesFlag = async () => {
  return API.post<string>({
    hostID: "h5shop",
    path: "/api/mini/member/checkChangeMobileTimesFlag",
  });
};

// 发送旧手机号验证码
export const verifyCode = async () => {
  return API.post<boolean | Record<string, any>>({
    hostID: "h5shop",
    path: "/api/mini/member/dccIdentify/default/verifyCode",
  });
};

// 校验修改手机号验证码
export const checkCode = async ({ code }: { code: string }) => {
  return API.post<string>({
    hostID: "h5shop",
    path: "/api/mini/member/dccIdentify/checkCode",
    body: {
      code,
    },
  });
};

// 发送新手机号验证码（会校验该手机是否已注册）
export const verifyCode2 = async ({ phone }: { phone: string }) => {
  return API.post<boolean | Record<string, any>>({
    hostID: "h5shop",
    path: "/api/mini/member/dccIdentify/verifyCode",
    body: {
      phone,
    },
  });
};
export type ChangePhoneParams = {
  phone: string;
  code: string;
};
// 修改手机号
export const changePhone = async ({ phone, code }: ChangePhoneParams) => {
  return API.post<boolean | Record<string, any>>({
    hostID: "h5shop",
    path: "/api/mini/member/changePhone",
    body: {
      phone,
      code,
    },
  });
};
