import useNavigation from "@/utils/useNavigate";
import React from "react";

import styles from "./index.module.less";

import BackPng from "@/assets/icon-arrow-left.png";
import HomePng from "@/assets/icon-home.png";
import { RouteName } from "@/router/extra";

const Navigation: React.FC = () => {
  const { navigate2H5, navigateBack } = useNavigation();

  const goHome = () => {
    navigate2H5(RouteName.Home);
  };

  return (
    <div className={styles["nav"]}>
      <div className={styles["nav-box"]}>
        <div className={styles["nav-box__image-box"]} onClick={() => navigateBack()}>
          <img src={BackPng} className={styles["image-box__image"]} />
        </div>
        <div className={styles["nav-box__image-box"]} onClick={goHome}>
          <img src={HomePng} className={styles["image-box__image"]} />
        </div>
      </div>
    </div>
  );
};
export default Navigation;
