import { queryProductRecommendList } from "@/api/product";
import ProductWaterfall from "@/components/product-waterfall";
import { ProductModel } from "@akc/biz-api";
import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
const ProductList: React.FC = () => {
  // 商品列表
  const [productList, setProductList] = useState<ProductModel[]>([]);
  // 是否有下一页
  const [hasNext, setHasNext] = useState<boolean>(true);
  // 加载状态
  const [loading, setLoading] = useState<boolean>(false);
  // 关联推荐请求ID
  const relatedRecommendReqId = useRef<string>();
  // 已点击的商品ID
  const [idsClick, setIdsClick] = useState<string[]>([]);

  // 请求推荐商品列表
  const fetchRecommendProductList = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      // 使用 queryProductRecommendList 接口获取推荐列表
      const res = await queryProductRecommendList({
        pageId: "H5_RelatedSale", // 页面ID
        relatedScene: "cartlist_buymore", // 购物车加购推荐场景
      });

      if (!res || (res.result?.length ?? 0) === 0) {
        setHasNext(false);
        return;
      }

      // 保存请求ID
      relatedRecommendReqId.current = res?.extra?.searchRequestId;
      const result = res?.result ?? [];

      // 更新商品列表
      setProductList(prevList => {
        return [
          ...prevList,
          ...(result?.map((item, index) => ({
            ...item,
            rank: prevList.length + index,
            trackData: {
              recommend_requestid: `${res?.extra?.searchRequestId ?? Date.now()}`,
              domain: "购物车分享页",
              module: "购物车分享页推荐列表",
              resource_type: "购物车分享页推荐列表",
              resource_content: "商品",
              resource_name: "购物车分享页推荐列表",
              activity_id: item.activityId,
              click_activity_spu_code: item.activitySpuId,
              click_sku_id: item.selectSkuId,
              btn_name: "商品列表",
              resource_id: item.activitySpuId,
              resource_rank: prevList.length + index + 1,
              product_model: item.activityType === 4 ? "普通商品" : "档期商品",
            },
          })) ?? []),
        ];
      });

      // 更新是否有下一页 - 这个接口不支持分页，所以设置为false
      setHasNext(false);
    } catch (error) {
      console.error("加载推荐商品失败:", error);
      setHasNext(false);
    } finally {
      setLoading(false);
    }
  };

  // 初始加载
  useEffect(() => {
    fetchRecommendProductList();
  }, []);

  // 加载更多
  const loadMore = async () => {
    if (!hasNext || loading) {
      return;
    }
    await fetchRecommendProductList();
  };

  // 处理商品点击
  const handleProductClick = (product: ProductModel) => {
    setIdsClick([...idsClick, product?.activitySpuId || ""]);
  };

  return (
    <div className={styles.block}>
      <div className={styles.feedTitle}>
        <img
          className={styles.feedTitleImg}
          src="https://akim-oss.aikucun.com/518edcdf460cd0094a1e816cbc116011c403c706_1743040439337_43.png"
        />
        <div className={styles.observerDom}>你可能还喜欢</div>
        <img
          className={styles.feedTitleImg}
          src="https://akim-oss.aikucun.com/518edcdf460cd0094a1e816cbc116011c403c706_1743040439337_43.png"
        />
      </div>
      <div className={styles.waterfallConatainer}>
        <ProductWaterfall
          productList={productList}
          hasNext={hasNext}
          loadMore={loadMore}
          onClickProduct={handleProductClick}
          provideBackground={false}
          topRightLocationCode="H5_Feed_ProductList_ProductPicRight"
          titleFrontLocationCode="H5_Feed_ProductList_TitleFront"
          titleBottomLocationCode="H5_SmartRecommend_ProductTitleDown"
          btnClickName="resource_click"
        />
      </div>
    </div>
  );
};

export default ProductList;
