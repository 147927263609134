import { rewardProductDetial } from "@/api/rewardCenter";
import confetti from "canvas-confetti";
import { useEffect, useState } from "react";

const defaultAnimationOptions = [
  {
    ratio: 0.25,
    option: {
      spread: 26,
      startVelocity: 55,
    },
  },
  {
    ratio: 0.2,
    option: {
      spread: 60,
    },
  },
  {
    ratio: 0.35,
    option: {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    },
  },
  {
    ratio: 0.1,
    option: {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    },
  },
  {
    ratio: 0.1,
    option: {
      spread: 120,
      startVelocity: 45,
    },
  },
];

const celebrateAnimation = (options = defaultAnimationOptions) => {
  let count = 1000;
  let defaults = {
    origin: { y: 0.7 },
    scalar: 1.5,
  };

  function fire(particleRatio, opts) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    });
  }

  if (Array.isArray(options) && options.length) {
    options.forEach(item => {
      fire(item.ratio, item.option);
    });
  }
};

const useCelebrateModule = () => {
  const [visible, setVisible] = useState(false);
  const [panelType, setPanelType] = useState("certificate");
  const [type, setType] = useState("defalut"); // 展示弹窗类型
  const [giftInfo, setGiftInfo] = useState({});

  const queryProductDetail = params => {
    rewardProductDetial(params).then(res => {
      // setGiftInfo()
      console.log("res", res);
    });
  };

  useEffect(() => {
    if (visible) {
      celebrateAnimation();
    }
  }, [visible]);

  return {
    visible,
    panelType,
    type,
    giftInfo,
    setVisible,
    celebrateAnimation,
    setType,
    setPanelType,
    queryProductDetail,
  };
};

export default useCelebrateModule;
