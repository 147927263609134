import { queryBrandPromotionStimulation } from "@/api/yearEndBonus";
import ProductWaterfall from "@/components/product-waterfall";
import { useActions } from "@/utils/actions";
import { countDown } from "@/utils/date-format";
import { hostEnv } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import StimulationCard from "./components/stimulation-card/stimulation-card";
import styles from "./detail.module.less";
import useProduct from "./hooks/useProduct";

const Detail: React.FC = () => {
  document.title = "品牌伯乐奖";
  const pageName = "品牌伯乐奖详情页";
  const track = Track();
  const { shareAnnualBounsPosterAction } = useActions();
  const [search] = useSearchParams();
  const brandActivityCode = search.get("brandActivityCode") || "";

  const { setBrandIds, setMerchantCodeList, productList, hasNext, loadMore } = useProduct();

  const [pageInfo, setPageInfo] = useState<any>({});
  const [brandRecommendVO, setBrandRecommendVO] = useState<any>({});
  const [finalistFlag, setFinalistFlag] = useState(false);
  const [timeId, setTimeId] = useState<any>("");
  const [countDownObj, setCountDownObj] = useState<any>({});
  const [activeKey, setActiveKey] = useState("teamList");
  const [tabList, setTabList] = useState<any>([]);

  const trackData = {
    page_name: pageName,
    previous_page_name: search.get("page_name") || "",
    resource_id: brandActivityCode,
  };

  const statusDom = () => {
    switch (pageInfo?.taskStatus) {
      case "AWARD_CALCULATION": // 奖励计算中
        return <div className={styles["status"]}>奖励计算中</div>;
        break;
      case "AWARD_PROCESSING": // 发奖中
        return <div className={styles["status"]}>奖励发放中</div>;
        break;
      case "END": // 结束
        return <div className={styles["status"]}>奖励发放结束</div>;
        break;
      default:
        break;
    }
  };
  const countDownDom = () => {
    if (countDownObj.day) {
      return (
        <>
          <span className={styles["block"]}>{countDownObj.day}</span>天
          <span className={styles["block"]}>{countDownObj.hou}</span>时
        </>
      );
    } else if (countDownObj.hou >= 1 && countDownObj.hou <= 24) {
      return (
        <>
          <span className={styles["block"]}>{countDownObj.hou}</span>时
          <span className={styles["block"]}>{countDownObj.min}</span>分
        </>
      );
    } else if (countDownObj.hou <= 1) {
      return (
        <>
          <span className={styles["block"]}>{countDownObj.min}</span>:
          <span className={styles["block"]}>{countDownObj.sec}</span>
        </>
      );
    }
  };

  const stimulationTitleDom = () => {
    if (activeKey === "teamList") {
      // 业绩争霸[团队激励]
      return (
        pageInfo?.teamLotteryContent && (
          <div
            className={`${styles.stimulation__title} ${pageInfo?.teamLotteryFlag && styles.pass}`}>
            {pageInfo?.teamLotteryContent}
          </div>
        )
      );
    } else if (activeKey === "aidouList") {
      // 爱豆激励
      return (
        pageInfo?.aiDouLotteryContent && (
          <div
            className={`${styles.stimulation__title} ${pageInfo?.aiDouLotteryFlag && styles.pass}`}>
            {pageInfo?.aiDouLotteryContent}
          </div>
        )
      );
    }
  };

  const onShare = async e => {
    e.stopPropagation();
    shareAnnualBounsPosterAction({
      contentId: brandActivityCode,
      contentType: "ANNUAL_BONUS",
      pageName: "品牌伯乐奖",
      src: "AnnualBonusActivity",
    });
  };

  const footerDom = () => {
    if (["AWARD_CALCULATION", "AWARD_PROCESSING", "END"].includes(pageInfo?.taskStatus)) {
      // 活动已结束
      return <div className={`${styles.footer__btn} ${styles.disabled}`}>活动已结束</div>;
    } else {
      // 转发赚钱
      return (
        <div className={styles.footer__btn} onClick={e => onShare(e)}>
          <img
            className={styles.icon}
            src="https://akim-oss.aikucun.com/mshop/eeaaa563822b7c0c875f3c49e65c48e966db9716_1731416880122_13.png"
          />
          转发赚钱
        </div>
      );
    }
  };

  const onChangeTask = currentTask => {
    const { gmvSuccessFlag, increaseSuccessFlag } = currentTask || {};
    setFinalistFlag(gmvSuccessFlag && increaseSuccessFlag);
  };

  const getPageInfo = async () => {
    const data: any = await queryBrandPromotionStimulation({
      brandActivityCode,
    });
    setPageInfo(data || {});
    setBrandRecommendVO(
      data?.brandRecommendVO
        ? {
          ...data?.brandRecommendVO,
          brandLogos: data?.brandRecommendVO?.brandLogo.split(","),
        }
        : {},
    );
    setBrandIds(data?.brandRecommendVO?.brandId ? data?.brandRecommendVO?.brandId.split(",") : []);
    setMerchantCodeList(
      data?.brandRecommendVO?.merchantCode ? data?.brandRecommendVO?.merchantCode.split(",") : [],
    );
  };

  const initTabList = () => {
    const { teamList, aidouList } = pageInfo || {};
    let tabList: any = [];
    if (teamList?.length > 0) {
      tabList.push({
        label: "业绩争霸",
        value: "teamList",
      });
    }
    if (aidouList?.length > 0) {
      tabList.push({
        label: "爱豆激励",
        value: "aidouList",
      });
    }
    setTabList(tabList);
    setActiveKey(tabList?.[0]?.value || "");
  };

  useEffect(() => {
    if (pageInfo?.taskStatus === "BEGIN") {
      setTimeId(
        setInterval(() => {
          // 任务状态 BEGIN:进行中、AWARD_PROCESSING:发奖中、END:结束
          let obj = countDown(pageInfo?.taskEndTime);
          setCountDownObj(obj);
        }, 1000),
      );
    }
    initTabList();
  }, [pageInfo]);

  useEffect(() => {
    if (timeId) {
      clearInterval(timeId);
    }
    track.pageView(pageName, {
      domain: "年终奖",
    });
    getPageInfo();
  }, [brandActivityCode]);

  return (
    <div className={styles.page}>
      <div className={styles.scroll_view}>
        <div className={styles.scroll__content}>
          <div className={styles.page__header}>
            <div className={styles["activity_status"]}>
              <div className={styles["activity-status__left"]}>{statusDom()}</div>
              <div className={styles["activity-status__right"]}>
                {["BEGIN"].includes(pageInfo?.taskStatus) && (
                  <div className={styles["right__count-down"]}>
                    <span>距活动结束</span>
                    {countDownDom()}
                  </div>
                )}
              </div>
            </div>
            <div className={styles["activity-name-wrap"]}>
              {brandRecommendVO?.brandLogos?.length > 0 && (
                <div className={styles["logo-list"]}>
                  {brandRecommendVO.brandLogos.slice(0, 3).map((item, index) => {
                    return (
                      <img
                        className={styles["item"]}
                        key={index}
                        style={{ marginLeft: `${pxtorem(index === 0 ? 0 : -10)}` }}
                        src={item}
                      />
                    );
                  })}
                </div>
              )}
              <h3 className={`${styles["activity_name"]}`}>{brandRecommendVO.activityName}</h3>
            </div>
            {brandRecommendVO?.activityTitle && (
              <p className={`${styles["activity_desc"]} ${styles["text-ellipsis1"]}`}>
                {brandRecommendVO.activityTitle}
              </p>
            )}
            <div className={styles.activity_time}>
              活动时间：
              {moment(Number(brandRecommendVO.activityStartTime)).format("YYYY年MM月DD日")}-
              {moment(Number(brandRecommendVO.activityEndTime)).format("YYYY年MM月DD日")}
            </div>
          </div>
          <div className={styles.page__body}>
            <div className={styles.body__content}>
              {/* tab */}
              {tabList.length > 0 && (
                <div className={styles.body__tab}>
                  {tabList.map(item => (
                    <div
                      className={`${styles.tab__item} ${activeKey === item.value && styles.tab__active}`}
                      key={item.value}
                      onClick={() => setActiveKey(item.value)}>
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
              <div className={styles.body__stimulation}>
                {/* [中奖 || 未中奖]文案展示 */}
                {stimulationTitleDom()}
                {/* 激励列表 */}
                <StimulationCard
                  list={activeKey === "teamList" ? pageInfo?.teamList : pageInfo?.aidouList}
                  onChangeTask={onChangeTask}
                />
              </div>
            </div>
            {/* 商品列表 */}
            {productList?.length > 0 && (
              <div className={styles.body__product}>
                <div className={styles.product__title}>参与商品</div>
                <ProductWaterfall
                  productList={productList}
                  hasNext={hasNext}
                  loadMore={loadMore}
                  provideBackground={false}
                  isOuterMargin={true}
                  topRightLocationCode={`${hostEnv}_Search_ProductList_ProductPicRight`}
                  titleFrontLocationCode={`${hostEnv}_Search_ProductList_TitleFront`}
                  titleBottomLocationCode={`${hostEnv}_Search_ProductList_TitleDown`}
                  trackData={trackData}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* footer */}
      <div className={styles.page__footer}>{footerDom()}</div>
    </div>
  );
};
export default Detail;
