import {
  queryDailyPrizeInfo,
  requestHighValue,
  requestLotteryInfo,
  requestRankList,
  type HighValue,
  type RankList,
} from "@/api/dailyReward";
import { useEffect, useState } from "react";

/**
 * 每日奖励抽奖数据Hook
 * 负责获取和管理抽奖活动相关的数据
 * @param refreshTimes 刷新次数，用于触发数据重新获取
 */
const useLottery = ({ refreshTimes }) => {
  // 活动信息状态
  const [activityInfo, setActivityInfo] = useState<any>({});
  // 排行榜数据
  const [ranList, setRanList] = useState<RankList[]>([]);
  // 高价值奖品数据
  const [highDataOther, setHighDataOther] = useState<HighValue | null>(null);

  /**
   * 查询活动信息
   * 包括活动基本信息、排行榜和高价值奖品数据
   */
  const queryActivityInfo = async () => {
    // 获取活动基本信息
    const info = await queryDailyPrizeInfo({});

    // 获取排行榜数据
    const list = await requestRankList({
      promoActivityId: info?.promoActivityId,
    });

    // 更新状态
    setActivityInfo(info);
    setRanList(list ?? []);
  };

  const queryHighValue = async () => {
    const highValue = await requestHighValue({
      promoActivityId: activityInfo?.promoActivityId,
    });
    const result = highValue ?? null;
    setHighDataOther(result);
    return result;
  };

  /**
   * 查询抽奖信息
   * @param params 查询参数
   */
  const queryLotteryInfo = async params => requestLotteryInfo(params);

  // 当刷新次数变化时，重新获取数据
  useEffect(() => {
    console.log("刷新数据", refreshTimes);
    queryActivityInfo();
  }, [refreshTimes]);

  return {
    activityInfo,
    ranList,
    highDataOther,
    queryLotteryInfo,
    queryHighValue,
  };
};

export default useLottery;
