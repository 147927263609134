import clsx from "clsx";
import React from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../index.module.less";

interface SwiperRenderProps {
  pictureUrls: { imageUrl: string; scode: string }[];
  imageOptions: { spaceBetween: number; class: string };
  requestId: string;
  onClose: () => void;
  previewImg: (e: React.MouseEvent<HTMLImageElement>, index: number) => void;
  handleMouseDown: () => void;
  handleMouseUp: () => void;
}

const SwiperRender: React.FC<SwiperRenderProps> = ({
  pictureUrls,
  imageOptions,
  requestId,
  onClose,
  previewImg,
  handleMouseDown,
  handleMouseUp,
}) => {
  return (
    <div className={styles["swiper-container"]}>
      <Swiper
        className={styles["swiper-wrapper"]}
        slidesPerView={1.2}
        centeredSlides
        spaceBetween={imageOptions.spaceBetween}
      >
        {pictureUrls.map((item, index) => (
          <SwiperSlide key={index} className={styles["swiper-slide"]} onClick={onClose}>
            <img
              className={clsx(styles["poster__img"], styles[imageOptions.class])}
              src={item.imageUrl}
              data-scode={item.scode}
              data-reqid={requestId}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src =
                  "https://akim-oss.aikucun.com/saas/f378f9726fc41f88df5af93052684fa9cb4ce10c_1639487955668_97.png";
              }}
              onClick={(e: React.MouseEvent<HTMLImageElement>) => previewImg(e, index)}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperRender;
