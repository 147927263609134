import { ContentType } from "@/api/scode/scode_params";
import loadingPng from "@/base64";
import PosterSwipePro from "@/components/poster-swiper/pro";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import AKJS from "@akc/akjs";
import { Image, } from "antd-mobile";
import React, { useEffect, } from "react";
import BackView from "./components/BackView";
import customStyles from "./components/CustomTopBack.module.less";
import EmptyView from "./components/EmptyView";
import FloatingTopBar from "./components/FloatingTopBar";
import RankBottomListView from "./components/RankBottomListView";
import SeeAllView from "./components/SeeAllView";
import TopListView from "./components/TopLIstView";
import { useRankScale } from "./hooks/useRankScale";
import { useRankShare } from "./hooks/useRankShare";
import styles from "./index.module.less";


/**
 * 榜单规模化
 */
const RankScale: React.FC = () => {
  const control = useRankScale();
  const { getRankDataList, hasMore, rankDetail, goToRankAll, goToProductDetail, goToRankScale, rankDataList, role, loading, track, search, pageName, commonContext } = control;
  // 使用榜单分享Hook
  const { swiperControl, productControl, handleShareProduct } = useRankShare({
    rankShare: {
      contentType: ContentType.categoryRank,
      contentId: rankDetail?.rankNo || "",
      marketName: "榜单详情",
    },
    dependencies: [rankDetail],
  });

  const clickShareMontyBtn = () => {
    swiperControl.open();
    console.log("clickShareMontyBtn");

    if (track.track) {
      track.track("btn_click", {
        page_name: "榜单详情",
        page_code: rankDetail?.rankNo || "",
        previous_page_name: search.get("page_name"),
        btn_name: commonContext.currentRole === "C" ? "分享榜单" : "转发赚钱",
        btn_text: commonContext.currentRole === "C" ? "分享榜单" : "转发赚钱"
      });
    }
  };


  useEffect(() => {
    document.title = "热卖榜单";
    if (track.track) {
      track.track("$pageview", {
        page_name: "榜单详情",
        content_id: rankDetail?.rankNo,
        previous_page_name: search.get("page_name"),
      });
    }
    if (platform.isApp) {
      AKJS.ready().then(() => {
        AKJS.action
          .dispatch("event.navigationBar.hide", {
            hide: true,
          })
          .catch(error => {
            console.error("设置顶部导航栏失败:", error);
          });
      });
    }
  }, []);


  const showAllEmptyView = () => {
    return <div className={styles.tsss}>
      {
        !platform.isApp && <BackView />
      }
      <FloatingTopBar />
      <div style={{ paddingTop: "100px" }}></div>
      <EmptyView text={"数据实时更新，当前页面失效啦～"} />

      <SeeAllView goToRankAll={goToRankAll} />
    </div>;
  };


  if (rankDetail) {
    if (rankDataList && rankDataList.length > 0) {
      return (
        <div className={`${styles["container"]} ${platform.isH5 ? customStyles.container_with_top_back : ""} ${platform.isiOS ? customStyles.noBottomBounce : ""}`}>
          {/* 榜单分享 */}
          < PosterSwipePro {...swiperControl.props} />
          {/* 商品分享 */}
          <PosterSwipePro {...productControl.props} />
          <FloatingTopBar />
          {
            platform.isApp && <BackView />
          }

          {loading ? (
            <div className={styles.loading}>
              <Image src={loadingPng} width={pxtorem(30)}></Image>
            </div>
          ) : (<div className={styles.container}>
            {
              platform.isApp ? <img
                src={"https://akim-oss.aikucun.com/b78abf914809eb42ea1e1dcbed7ac52ad65d4bc0_1742379635755_97.png"
                }
                alt="Success"
                className={styles.bgImgapp}
              /> : <img
                src={"https://akim-oss.aikucun.com/7b752ae4f099241571f118c4fc44ebc86e875b45_1742440501583_98.png"
                }
                alt="Success"
                className={styles.bgImg}
              />
            }
            {!search.get("fromAll") && (
              <div className={platform.isApp ? styles.apprankallview : styles.rankallview} onClick={goToRankAll}>
                <div className={styles.allRank}>全部榜单</div>
                <img
                  src="https://akim-oss.aikucun.com/1db67b528a43de2bebca495a6b1a0ef4931e3316_1741166862255_15.png"
                  alt="Success"
                  className={styles.moreIcon}
                />
              </div>
            )}
            <div className={styles.main} >
              <div className={styles.mainHeader}>
                <img
                  src="https://akim-oss.aikucun.com/b49d405ebebad83db17f09a4931ab5548650b563_1740641684863_39.png"
                  alt="Success"
                  className={styles.img}
                />
                <div className={styles.mainTitle}>
                  {rankDetail?.rankName}
                </div>
                <img
                  src="https://akim-oss.aikucun.com/cd1ea46643110c67fcc1079bee1d5ea724624fba_1740641689386_28.png"
                  alt="Success"
                  className={styles.img}
                />
              </div>
              <div className={styles.mainCenterView}>
                <div className={styles.mainCenter}>
                  <img
                    src="https://akim-oss.aikucun.com/f4cee18dfc7f9b3e70ba922ad13cab6cc9ec8c3a_1742280389758_57.png"
                    alt="Success"
                    className={styles.imglabke}
                  />
                </div>

              </div>

              <div className={styles.tips}>数据实时更新，请以榜单页面排名为准</div>

              <div className={styles.mainList}>

                <TopListView rankDetail={rankDetail} handleShareProduct={handleShareProduct} pageName={pageName} />

                {
                  rankDataList && rankDataList.length > 0 && (<div className={styles.moreRank}>
                    <img
                      src="https://akim-oss.aikucun.com/5ab5746f52602eaf15d5d6561d5d882967bc7c83_1740645244223_99.png"
                      alt="Success"
                      className={styles.moreRankImg}
                    />
                    <div className={styles.moreRankText}>更多榜单</div>
                    <img
                      src="https://akim-oss.aikucun.com/5ab5746f52602eaf15d5d6561d5d882967bc7c83_1740645244223_99.png"
                      alt="Success"
                      className={styles.moreRankImg}
                    />
                  </div>
                  )
                }

                <RankBottomListView data={rankDataList} loadMore={getRankDataList} tabName={"更多榜单"}
                  hasMore={hasMore} toRankDetailAction={(rankno, tsindex) => goToRankScale(rankno, tsindex)} type={0} />

                {
                  !hasMore && <div className={styles.toSeeAllView}><div className={styles.toSeeAll} onClick={goToRankAll}>查看全部榜单</div></div>
                }

              </div>
            </div>

            <div className={styles.bottomBtn} onClick={() => clickShareMontyBtn()} >
              {
                commonContext.currentRole === "C" ?
                  <div className={styles.bottomBtnText}>分享榜单</div>
                  : <div className={styles.bottomBtnTextMoney}>转发赚钱</div>
              }
            </div>
          </div>)
          }
        </div >);
    } else {
      return (
        <div className={`${styles["container"]} ${platform.isH5 ? customStyles.container_with_top_back : ""} ${platform.isiOS ? customStyles.noBottomBounce : ""}`}>
          {/* 榜单分享 */}
          < PosterSwipePro {...swiperControl.props} />
          {/* 商品分享 */}
          <PosterSwipePro {...productControl.props} />
          <FloatingTopBar />
          {
            platform.isApp && <BackView />
          }
          {loading ? (
            <div className={styles.loading}>
              <Image src={loadingPng} width={pxtorem(30)}></Image>
            </div>
          ) : (
            <div className={styles.container}>
              {
                platform.isApp ? <img
                  src={"https://akim-oss.aikucun.com/b78abf914809eb42ea1e1dcbed7ac52ad65d4bc0_1742379635755_97.png"
                  }
                  alt="Success"
                  className={styles.bgImgapp}
                /> : <img
                  src={"https://akim-oss.aikucun.com/7b752ae4f099241571f118c4fc44ebc86e875b45_1742440501583_98.png"
                  }
                  alt="Success"
                  className={styles.bgImg}
                />
              }
              <div className={platform.isApp ? styles.apprankallview : styles.rankallview} onClick={goToRankAll}>
                <div className={styles.allRank}>全部榜单</div>
                <img
                  src="https://akim-oss.aikucun.com/1db67b528a43de2bebca495a6b1a0ef4931e3316_1741166862255_15.png"
                  alt="Success"
                  className={styles.moreIcon}
                />
              </div>
              <div className={styles.main} >
                <div className={styles.mainHeader}>
                  <img
                    src="https://akim-oss.aikucun.com/1e629a0b6d39e51e59273cfe7846e48bf7236498_1742200887633_61.png"
                    alt="Success"
                    className={styles.img}
                  />
                  <div className={styles.mainTitle}>
                    {rankDetail?.rankName}
                  </div>
                  <img
                    src="https://akim-oss.aikucun.com/5c7f0bf02ad95b8377d7096775c4d17b228ec732_1742200882836_6.png"
                    alt="Success"
                    className={styles.img}
                  />
                </div>
                <div className={styles.mainCenterView}>
                  <div className={styles.mainCenter}>
                    <img
                      src="https://akim-oss.aikucun.com/f4cee18dfc7f9b3e70ba922ad13cab6cc9ec8c3a_1742280389758_57.png"
                      alt="Success"
                      className={styles.imglabke}
                    />
                  </div>

                </div>

                <div className={styles.tips}>数据实时更新，请以榜单页面排名为准</div>

                <div className={styles.mainList}>

                  <TopListView rankDetail={rankDetail} handleShareProduct={handleShareProduct} pageName={pageName} />

                  <div className={styles.hastopnobottom}>
                    <div className={styles.bottomTextshow}>--已经到底了--</div>

                    <div className={styles.toSeeAllViewNoBottom}><div className={styles.toSeeAllNoBottom} onClick={goToRankAll}>查看全部榜单</div></div>
                  </div>

                </div>
              </div>

              <div className={styles.bottomBtn} onClick={() => clickShareMontyBtn()} >
                {
                  commonContext.currentRole === "C" ?
                    <div className={styles.bottomBtnText}>分享榜单</div>
                    : <div className={styles.bottomBtnTextMoney}>转发赚钱</div>
                }
              </div>
            </div>
          )
          }
        </div >
      );
    }

  } else {
    if (rankDataList && rankDataList.length > 0) {
      return (
        <div className={`${styles["container"]} ${platform.isH5 ? customStyles.container_with_top_back : ""} ${platform.isiOS ? customStyles.noBottomBounce : ""}`}>
          {/* 榜单分享 */}
          < PosterSwipePro {...swiperControl.props} />
          {/* 商品分享 */}
          <PosterSwipePro {...productControl.props} />
          <FloatingTopBar />
          {
            platform.isApp && <BackView />
          }

          {loading ? (
            <div className={styles.loading}>
              <Image src={loadingPng} width={pxtorem(30)}></Image>
            </div>
          ) : <div className={styles.container}>

            <div className={styles.main} >
              <div className={styles.mainList}>
                <div style={{ paddingTop: "100px" }}></div>

                <EmptyView text={
                  <>
                    数据实时更新，当前榜单已过期，
                    <br />
                    逛逛其他榜单吧~
                  </>
                } />

                {
                  rankDataList && rankDataList.length > 0 && (<div className={styles.moreRank}>
                    <img
                      src="https://akim-oss.aikucun.com/5ab5746f52602eaf15d5d6561d5d882967bc7c83_1740645244223_99.png"
                      alt="Success"
                      className={styles.moreRankImg}
                    />
                    <div className={styles.moreRankText}>更多榜单</div>
                    <img
                      src="https://akim-oss.aikucun.com/5ab5746f52602eaf15d5d6561d5d882967bc7c83_1740645244223_99.png"
                      alt="Success"
                      className={styles.moreRankImg}
                    />
                  </div>
                  )
                }

                <RankBottomListView data={rankDataList} loadMore={getRankDataList} tabName={"更多榜单"}
                  hasMore={hasMore} toRankDetailAction={(rankno, tsindex) => goToRankScale(rankno, tsindex)} type={0} />

                {
                  !hasMore && <div className={styles.toSeeAllView}><div className={styles.toSeeAll} onClick={goToRankAll}>查看全部榜单</div></div>
                }

              </div>
            </div>
            {/* 
            <AddPurchasePanel
              visible={control.cartModalVisible}
              product={control.modalProduct}
              needLoading={false}
              close={e => {
                if (e?.skuId !== control.selectSkuId) {
                  control.initPageRequest({
                    firstSalePropertyValue: "",
                    selectSkuId: e?.skuId,
                  });
                }
                control.setCartModalVisible(false);
              }}
              confirm={control.changeCartNum}
              trackData={{ pageName: "素材商品详情页" }}
              buttonType={control.cartPannelButtonType}
              buttonName="确定"
            /> */}

            {rankDetail && <div className={styles.bottomBtn} onClick={() => clickShareMontyBtn()} >
              {
                commonContext.currentRole === "C" ?
                  <div className={styles.bottomBtnText}>分享榜单</div>
                  : <div className={styles.bottomBtnTextMoney}>转发赚钱</div>
              }
            </div>}
          </div>
          }
        </div >
      );

    } else {
      return <div className={`${styles["container"]} ${platform.isH5 ? customStyles.container_with_top_back : ""} ${platform.isiOS ? customStyles.noBottomBounce : ""}`}>
        {
          loading ? <div className={styles.loading
          } >
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div > : showAllEmptyView()
        }
      </div>;
    }
  }
};

export default RankScale;
