import { AddRoleSizeComp } from "@akc/mx-taro-h5-components";
import { Popup } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

type AddPurchasePanelProps = {
  visible?: boolean;
  // 是否loading
  needLoading?: boolean;
  isFirstAdd?: boolean;
  sizeRoleId?: string;
  close?: () => void;
  /** 保存、编辑角色成功 */
  onSaveSuccees?: (roleId?: string) => void;
};

// 尺码角色添加
const AddRoleSizeDialog: React.FC<AddPurchasePanelProps> = props => {

  //关闭弹窗
  const onClose = () => {
    props?.close?.();
  };

  const saveRoleInfoSuccees = (roleId?: string) => {
    console.log("saveRoleInfoSuccees roleId:", roleId);
    props?.onSaveSuccees?.(roleId);
  };


  //底部安全区域高度
  function getSafeAreaInsetBottom() {
    const bodyStyle = window.getComputedStyle(document.body);
    return parseFloat(bodyStyle.getPropertyValue("--safe-area-inset-bottom")) || 10;
  }

  return (
    <React.Fragment>
      <Popup
        style={{ "--z-index": "2000" }}
        maskStyle={{ zIndex: "1999" }}
        visible={props.visible}
        destroyOnClose={true}
        onMaskClick={() => {
          onClose();
        }}
        bodyStyle={{ backgroundColor: "transparent" }}>
        <div className={styles.addRoleContainer}>
          <AddRoleSizeComp
            style={{ "padding-bottom": getSafeAreaInsetBottom() }}
            onClose={onClose}
            isFirstAdd={props.isFirstAdd}
            needLoading={true}
            sizeRoleId={props?.sizeRoleId}
            onSaveRoleInfoCallback={roleId => {
              saveRoleInfoSuccees(roleId);
            }}
          />
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default AddRoleSizeDialog;
