import TopBar from "@/components/top-bar";
import TopBackSticky from "@/pages/daily-reward/components/TopBackSticky";
import { platform } from "@/utils/platform";
import React from "react";
import styles from "../CustomTopBack.module.less";

interface FloatingTopBarProps {
  isHideRight?: boolean;
  type?: number;
}

/**
 * 浮动顶部导航栏组件
 */
const FloatingTopBar: React.FC<FloatingTopBarProps> = ({ isHideRight = true, type = 0 }) => {
  if (!platform.isH5) {
    return null;
  }

  const title = () => {
    return <div className={styles.titleview}>
      <img
        className={styles.img}
        src="https://akim-oss.aikucun.com/4a59601c8f6727f5ba6ec791e07a9417b0d1e346_1701867890366_22.png"
      />
      <div className={styles.text}>全场100%正品</div>
    </div>;

  };

  if (type === 0) {
    return (
      <div className={styles.custom_top_back}>
        <TopBackSticky />

      </div>
    );
  }

  return (
    <div className={styles.custom_top_back_white}>
      <TopBar isHideRight={isHideRight} center={title()} isShowCenter={true} />
      <div></div>
    </div>
  );
};

export default FloatingTopBar;
